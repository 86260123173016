<script setup></script>
<template>
  <div class="re-edit-wrapper">
    <div class="re-edit-header">
      <h3 class="u-subheader--heavy">
        <slot name="header" />
      </h3>
      <div v-if="$slots['header_buttons']" class="re-edit-button-container">
        <slot name="header_buttons" />
      </div>
      <div v-if="$slots['subheader']">
        <slot name="subheader" />
      </div>
    </div>
    <slot name="re_edits_content" />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.re-edit-wrapper {
  background-color: variables.$periwink-blue-10;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.625rem;

  .re-edit-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    .re-edit-button-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.75rem;
    }
  }
  .re-edit-category {
    .category-dropdown {
      margin-top: 8px;
    }
  }
  .re-edit-bulk-select {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid variables.$gray-50;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  :deep(.soona-button--tertiary.cancel-button) {
    background-color: transparent;
  }
  :deep(.soona-button--tertiary) {
    background-color: transparent;
  }
}

:deep(.vs__dropdown-toggle) {
  background-color: variables.$white-default;
  max-width: 325px;
}
:deep(.vs__dropdown-menu) {
  background-color: variables.$white-default;
  max-width: 325px;
}
</style>
