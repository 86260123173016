// Temporary file remove when contract API is ready
export const photoUnitRates = [
  { label: '$44.00', value: 0 },
  { label: '$42.00', value: 1 },
  { label: '$40.00', value: 2 },
];

export const videoUnitRates = [
  { label: '$104.00', value: 0 },
  { label: '$102.00', value: 1 },
  { label: '$100.00', value: 2 },
];

export const gifUnitRates = [
  { label: '$104.00', value: 0 },
  { label: '$102.00', value: 1 },
  { label: '$100.00', value: 2 },
];

export const ugcUnitRates = [
  { label: '$4.40', value: 0 },
  { label: '$4.20', value: 1 },
  { label: '$4.40', value: 2 },
];

export const discountRates = [
  { label: '10%', value: 0 },
  { label: '12%', value: 1 },
  { label: '15%', value: 2 },
  { label: '18%', value: 3 },
  { label: '20%', value: 4 },
  { label: '22%', value: 5 },
  { label: '25%', value: 6 },
];

export const mockCompleteContract = {
  id: 1,
  total_value: 123456,
  account_balance: 123000,
  startAt: '2025-01-07T06:00:00Z',
  duration: 3,
  photoUnitRate: 1,
  proServiceDiscountRate: 1,
  videoUnitRate: 0,
  gifUnitRate: 1,
  ugcUnitRate: 0,
  clientCanOverdraft: true,
  enterpriseIncluded: true,
  fastPassIncluded: true,
};

export const mockTransactions = [
  {
    id: 0,
    date: '2025-01-07T06:00:00Z',
    amount: 123456,
    balance: 123000,
    charged_by: 'Don',
    type: 'other',
    service: 'Styling',
    pro_service_hours: 1,
    quantity: 1,
    invoice_id: 4321,
    booking_id: 1234,
    order_id: 5678,
    status: 'completed',
  },
  {
    id: 1,
    date: '2025-01-07T06:00:00Z',
    amount: 123456,
    balance: 123000,
    charged_by: 'Dan',
    type: 'invoice-paid',
    service: 'Content',
    pro_service_hours: 22,
    quantity: 22,
    invoice_id: 4321,
    booking_id: 1234,
    order_id: 5678,
    status: 'pending',
  },
  {
    id: 2,
    date: '2025-01-07T06:00:00Z',
    amount: 123456,
    balance: 123000,
    charged_by: 'Donna',
    type: 'ugc',
    service: 'Content',
    pro_service_hours: 10,
    quantity: 10,
    invoice_id: 4321,
    booking_id: 1234,
    order_id: 5678,
    status: 'completed',
  },
  {
    id: 3,
    date: '2025-01-07T06:00:00Z',
    amount: 123456,
    balance: 123000,
    charged_by: 'Dawn',
    type: 'photo',
    service: 'Credit',
    pro_service_hours: 19,
    quantity: 19,
    invoice_id: 4321,
    booking_id: 1234,
    order_id: 5678,
    status: 'completed',
  },
];
