<script setup>
import { toRefs } from 'vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';

const props = defineProps({
  overdraftType: {
    required: true,
    type: [String],
    default: 'none',
  },
});

const emit = defineEmits(['update']);

const { overdraftType } = toRefs(props);

const handleUpdate = () => {
  emit('update', {
    overdraftType: overdraftType.value,
  });
};
</script>
<template>
  <div class="create-discounts-rates__overdraft-container">
    <p class="u-label--heavy">Can client overdraft?</p>
    <p class="create-discounts-rates__subtext">
      Accessible only by account admins and admin collaborators.
    </p>

    <fieldset class="create-discounts-rates__overdraft-radio-group">
      <SoonaToggle
        v-model="overdraftType"
        type="radio"
        label="yes"
        name="client-can-overdraft"
        native-value="inclusive"
        @update:model-value="handleUpdate"
      />
      <SoonaToggle
        v-model="overdraftType"
        type="radio"
        label="no"
        name="client-can-overdraft"
        native-value="none"
        @update:model-value="handleUpdate"
      />
    </fieldset>
  </div>
</template>
<style lang="scss" scoped>
.create-discounts-rates {
  &__overdraft-radio-group {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      padding-bottom: 0.5rem;
    }
  }
}
</style>
