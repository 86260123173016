<script setup>
import NotificationModalItem from '@/components/NotificationModalItem.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { FriendlyRed50, FriendlyRed20 } from 'src/variables.module.scss';
import { useAccount } from '@/composables/useAccount';
import { useMe } from '@/composables/user/useMe';
import GenericNotificationDialogItem from '@/components/notification/GenericNotificationDialogItem.vue';
import { useFlag } from '@/composables/useFlag';
import basicImage from '@images/subscriptions/membership-tier-one-card-with-text.svg';
import standardImage from '@images/subscriptions/membership-tier-two-card-with-text.svg';
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';

defineProps({
  additionalChargeUnpaid: {
    type: Array,
    required: true,
    default: () => [],
  },
  notificationsCount: {
    type: Number,
    required: true,
    default: 0,
  },
});
const emit = defineEmits(['close']);

const { currentAccountId } = useMe();
const { isPendingInvoiceSubscription, pendingInvoice, subscriptionTierSlug } =
  useAccount(currentAccountId);

function closeModal() {
  emit('close');
}

const stripeNotificationLinkFlag = useFlag('payments_stripe_notification_link');

const membershipImage = computed(() => {
  return subscriptionTierSlug.value === 'tier-one' ? basicImage : standardImage;
});

const membershipTotal = computed(() => {
  if (!pendingInvoice.value?.amount_due) return 0;

  const totalInDollars = parseFloat(pendingInvoice.value.amount_due) / 100;

  return toCurrency(totalInDollars);
});
</script>

<template>
  <SoonaDialog
    icon-name="bell"
    :icon-color="FriendlyRed50"
    :icon-bg="FriendlyRed20"
    @close="closeModal"
  >
    <template #heading>
      notifications
      <template v-if="notificationsCount">
        ({{ notificationsCount }})
      </template>
    </template>
    <template v-if="!notificationsCount">
      <p>You have no notifications.</p>
    </template>
    <GenericNotificationDialogItem
      v-if="
        stripeNotificationLinkFlag &&
        isPendingInvoiceSubscription &&
        pendingInvoice?.hosted_invoice_url
      "
      :created-at="pendingInvoice.created_at"
      :img-url="membershipImage"
      link-target="_blank"
      :total-display="membershipTotal"
      :url="pendingInvoice.hosted_invoice_url"
    />
    <NotificationModalItem
      v-for="charge in additionalChargeUnpaid"
      :key="charge.id"
      :order="charge"
      @close-modal="closeModal"
    />
  </SoonaDialog>
</template>
<style lang="scss" scoped>
p {
  text-transform: lowercase;
}
</style>
