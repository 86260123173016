<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useBatchEditStore } from '../store/useBatchEditStore';

import BatchLocationLayout from './BatchLocationLayout.vue';
import { useInfiniteAlbumCollections } from '@/queries/album_collections/useAlbumCollections';
import SoonaRadioCardItem from '@/components/ui_library/soona_radio_cards/SoonaRadioCardItem.vue';
import SoonaRadioCardGroup from '@/components/ui_library/soona_radio_cards/SoonaRadioCardGroup.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const batchEditStore = useBatchEditStore();
const { locationAction, selectedAssets, accountId } =
  storeToRefs(batchEditStore);

const multipleAssets = computed(() => selectedAssets.value.length > 1);

const { data: albumCollectionPages, isLoading } = useInfiniteAlbumCollections(
  accountId,
  {
    itemsPerPage: 5,
  }
);

const albumCollections = computed(() =>
  (albumCollectionPages.value?.pages || []).flatMap(page => page.collections)
);
const hasAlbums = computed(() => albumCollections.value.length > 0);

const selectLocation = location => {
  if (!hasAlbums.value && location === 'existing-album') return;
  locationAction.value = location;
};
</script>
<template>
  <SoonaLoading v-if="isLoading" is-loading />
  <BatchLocationLayout class="location-select">
    <template #heading>
      where would you like to save {{ multipleAssets ? 'these' : 'this' }}
      {{ selectedAssets.length }}
      {{ multipleAssets ? 'assets' : 'asset' }}?
    </template>

    <SoonaRadioCardGroup
      always-show-loader
      class="location-select__radio-group"
      label="create or choose an album"
      name="create or choose an album"
    >
      <div
        title="create a new album"
        class="location-select__radio-item"
        @click="selectLocation('new-album')"
      >
        <SoonaRadioCardItem value="new-album" label="new album">
          <template #image>
            <img src="/images/bulk-edits/new-album.png" alt="new album" />
          </template>
        </SoonaRadioCardItem>
        <h4 class="u-subheader--heavy">new album</h4>
      </div>

      <div
        class="location-select__radio-item"
        :class="{ 'location-select__radio-item--disabled': !hasAlbums }"
        :title="hasAlbums ? 'pick an existing album' : 'no albums found'"
        @click="selectLocation('existing-album')"
      >
        <SoonaRadioCardItem
          value="existing-album"
          label="existing album"
          :disabled="!hasAlbums"
          :is-loading="isLoading"
        >
          <template #image>
            <img src="/images/bulk-edits/existing-album.png" alt="new album" />
          </template>
        </SoonaRadioCardItem>
        <h4 class="u-subheader--heavy">existing album(s)</h4>
      </div>
    </SoonaRadioCardGroup>
  </BatchLocationLayout>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.location-select {
  img {
    display: block;
  }

  &__radio-group {
    gap: 2.25rem;
    margin: 0 auto;
    flex-direction: row;
  }

  &__radio-item {
    gap: 0.75rem;
    display: flex;
    max-width: 12.5rem;
    align-items: center;
    flex-direction: column;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__save-to-gallery {
    width: 100%;
  }
}
</style>
