export const SALES_TAX_STORE_ID = 'sales-tax-store';
export const ACCOUNT_ADDITIONAL_ORDER_STORE_ID =
  'account-additional-order-store';
export const PAYMENT_METHODS_STORE_ID = 'payment-methods-store';
export const RESERVATION_ADDITIONAL_ORDER_STORE_ID =
  'reservation-additional-order-store';
export const MEDIA_EDITOR_STORE_ID = 'media-editor-store';
export const AI_ASSISTANT_STORE_ID = 'ai-assistant-store';
export const AI_PRODUCER_STORE_ID = 'ai-producer-store';
export const BATCH_EDIT_STORE_ID = 'batch-edit-store';
