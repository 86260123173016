<script setup>
import { computed, toRefs } from 'vue';

import BYOImage from '@images/build-your-own.png';
import ExpertImage from '@images/plan-with-pros-400@2x.jpg';
import TrendImage from '@images/ai-producer/ai-producer-trend-forward.png';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCreateABrief } from '@/composables/trend/useCreateABrief';
import { useRouter } from 'vue-router';

const props = defineProps({
  forwardData: {
    type: Object,
    required: true,
  },
});

const { forwardData } = toRefs(props);
const { linkClicked } = useBaseEvents();
const router = useRouter();

// UGC
const utmContent = 'ai_producer_create_ugc_brief';
const ugcButtonCopy = 'make a free brief';
const subContext = 'ai producer forward';

const { handleCreateABrief } = useCreateABrief({
  utmContent,
  subContext,
  buttonCopy: ugcButtonCopy,
});

const imageMap = {
  byo: BYOImage,
  trend: TrendImage,
  expert: ExpertImage,
};

const forwardImage = computed(() => imageMap[forwardData.value?.destination]);

const titleCopy = computed(() => {
  switch (forwardData.value?.destination) {
    case 'trend':
      return 'hire content creators on our UGC platform, Trend.';
    case 'expert':
      return 'plan a shoot shoot with our experts';
    case 'byo':
      return 'customize and confirm all the details of your shoot';
    default:
      return '';
  }
});

const descriptionCopy = computed(() => {
  switch (forwardData.value?.destination) {
    case 'trend':
      return 'submit a free brief and choose from our highly vetted network of thousands of creators interested in your products.';
    case 'expert':
      return 'speak with our team to get a recommendation for your content needs.';
    case 'byo':
      return 'our build-your-own option allows you to customize everything from photo to video to backgrounds to models and more!';
    default:
      return '';
  }
});

const buttonCopy = computed(() => {
  switch (forwardData.value?.destination) {
    case 'trend':
      return 'make a free brief';
    case 'expert':
      return 'talk to an expert';
    case 'byo':
      return 'create booking manually';
    default:
      return '';
  }
});

function forward() {
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: `forwarded to ${forwardData.value?.destination}`,
    linkHref: null,
  });

  switch (forwardData.value?.destination) {
    case 'trend':
      handleCreateABrief();
      break;
    case 'expert':
      window.open('https://soona.co/contact-sales', '_blank');
      break;
    case 'byo':
      router.push('/book');
      break;
    default:
      break;
  }
}
</script>

<template>
  <div class="forward-action">
    <div class="forward-action__content">
      <h2 class="u-subheader--heavy">{{ titleCopy }}</h2>

      <p>{{ descriptionCopy }}</p>

      <SoonaButton
        size="medium"
        variation="solid-black"
        class="forward-action__button"
        @click="forward"
      >
        {{ buttonCopy }}
        <SoonaIcon name="arrow-up-right-from-square" />
      </SoonaButton>
    </div>

    <div class="forward-action__image">
      <img :src="forwardImage" alt="" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.forward-action {
  display: flex;
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  flex-wrap: wrap-reverse;
  flex-direction: column-reverse;
  background: variables.$white-default;
  border: 1px solid variables.$periwink-blue-30;

  &__content {
    flex: 2;
    gap: 0.5rem;
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: 0.875rem;
    }
    p {
      font-size: 0.75rem;
    }
  }

  &__button {
    margin-top: auto;
  }

  &__image {
    height: 6rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: variables.$screen-xs-min) {
    flex-direction: row;

    &__image {
      flex: 1;
      height: 12rem;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__content {
      padding: 1.5rem;

      h2 {
        font-size: 1.125rem;
      }
      p {
        font-size: 1rem;
      }
    }

    &__image {
      height: 15rem;
    }
  }
}
</style>
