<script setup>
import {
  ALBUMS_ACTION,
  NOTES_ACTION,
  PRODUCTS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';

import ActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBar.vue';
import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import DigitalAssetCollections from '@/components/user/anytime/gallery/media-editor/DigitalAssetCollections.vue';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import DigitalAssetProducts from '@/components/user/anytime/gallery/media-editor/DigitalAssetProducts.vue';
import AIExpandGenerations from '@/components/user/anytime/gallery/media-editor/media-multiplier/uncrop/AIExpandGenerations.vue';
import MediaEditorNotes from '@/components/user/anytime/gallery/media-editor/MediaEditorNotes.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';

const {
  isAIExpandActive,
  isAlbumsActive,
  isNotesActive,
  isProductsActive,
  isRightFlyoutOpen,
  rightFlyoutHeading,
} = useMediaEditor();

const mediaEditorStore = useMediaEditorStore();
</script>

<template>
  <ActionBar
    class="right-action-bar"
    :is-flyout-open="isRightFlyoutOpen"
    position="right"
    :content-width="isNotesActive ? '20rem' : null"
  >
    <ActionBarButton
      column="right"
      icon-name="album"
      :is-selected="isAlbumsActive"
      label="albums"
      data-cypress="digital-asset-collections-action"
      @on-click="mediaEditorStore.setActiveAction(ALBUMS_ACTION)"
    />
    <template #flyout-heading> {{ rightFlyoutHeading }} </template>
    <ActionBarButton
      column="right"
      icon-name="shapes"
      :is-selected="isProductsActive"
      label="products"
      data-cypress="digital-asset-products-action"
      @on-click="mediaEditorStore.setActiveAction(PRODUCTS_ACTION)"
    />
    <ActionBarButton
      column="right"
      icon-name="message-circle-lines-alt"
      :is-selected="isNotesActive"
      label="notes"
      @on-click="mediaEditorStore.setActiveAction(NOTES_ACTION)"
    />

    <template #flyout-content>
      <DigitalAssetCollections v-if="isAlbumsActive" />
      <DigitalAssetProducts v-else-if="isProductsActive" />
      <MediaEditorNotes v-else-if="isNotesActive" />
      <AIExpandGenerations v-else-if="isAIExpandActive" />
    </template>
  </ActionBar>
</template>
