import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<"accounts" | "reservations" | "digital_assets">} subjectType
 * @param {import('vue').MaybeRefOrGetter<number>} subjectId
 * @param {Object} [params]
 * @params {Ref<number>} params.currentPage
 * @params {Ref<number>} params.itemsPerPage
 * @params {Ref<boolean>} params.withLocationMetadata
 * @param {UseQueryOptions} queryOptions
 */
export function useNotes(
  subjectType,
  subjectId,
  { currentPage, itemsPerPage, withLocationMetadata } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.notes(subjectType, subjectId, {
      currentPage,
      itemsPerPage,
      withLocationMetadata,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (toValue(itemsPerPage)) params.items = toValue(itemsPerPage);
      if (toValue(currentPage)) params.page = toValue(currentPage);
      if (toValue(withLocationMetadata))
        params.with_location_metadata = toValue(withLocationMetadata);

      const response = await http.get(
        `/${toValue(subjectType)}/${toValue(subjectId)}/notes`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        notes: response.data,
      };
    },
    ...queryOptions,
  });
}
