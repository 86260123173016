<script setup>
import { computed, ref, toRefs } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default: 'fade',
    validator: function (value) {
      return [
        'fade',
        'fade-up',
        'fade-down',
        'fade-left',
        'fade-right',
        'fade-up-short',
        'fade-down-short',
      ].includes(value);
    },
  },
  exitName: {
    type: String,
    default: null,
    validator: function (value) {
      return [
        null,
        'fade',
        'fade-up',
        'fade-down',
        'fade-left',
        'fade-right',
        'fade-up-short',
        'fade-down-short',
      ].includes(value);
    },
  },
  mode: {
    type: String,
    default: 'default',
    validator: function (value) {
      return ['default', 'in-out', 'out-in'].includes(value);
    },
  },
  appear: {
    type: Boolean,
    default: false,
  },
  appearDelay: {
    type: [String, Number],
    default: 0,
  },
  enterDelay: {
    type: [String, Number],
    default: 0,
  },
  exitDelay: {
    type: [String, Number],
    default: 0,
  },
  exitStyles: {
    type: Object,
    default: () => ({}),
  },
  exitAbsolute: {
    type: Boolean,
    default: false,
  },
});

const {
  name,
  exitName,
  exitDelay,
  enterDelay,
  exitStyles,
  appearDelay,
  exitAbsolute,
} = toRefs(props);

const transitionName = computed(() => `soona-${name.value}`);
const transitionExitName = computed(() => `soona-${exitName.value}`);
const dynamicTransitionName = ref(transitionName.value);

function onBeforeAppear(el) {
  el.style.transitionDelay = appearDelay.value + 'ms';
}
function onAfterAppear(el) {
  el.style.transitionDelay = 0 + 'ms';
}
function onBeforeEnter(el) {
  el.style.transitionDelay = enterDelay.value + 'ms';
}
function onAfterEnter(el) {
  el.style.transitionDelay = 0 + 'ms';
  if (exitName.value) dynamicTransitionName.value = transitionExitName.value;
}
function onBeforeLeave(el) {
  el.style.transitionDelay = exitDelay.value + 'ms';

  if (exitStyles.value) {
    Object.entries(exitStyles.value).forEach(([key, value]) => {
      el.style[key] = value;
    });
  }
  if (exitAbsolute.value) {
    el.style.position = 'absolute';
  }
}
function onAfterLeave(el) {
  el.style.transitionDelay = 0 + 'ms';
  if (exitName.value) dynamicTransitionName.value = transitionName.value;
}
</script>

<template>
  <Transition
    :mode="mode"
    :name="dynamicTransitionName"
    :appear="appear || appearDelay > 0"
    @before-appear="onBeforeAppear"
    @after-appear="onAfterAppear"
    @before-enter="onBeforeEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @after-leave="onAfterLeave"
  >
    <slot />
  </Transition>
</template>

<style lang="scss">
//fade-*
.soona-fade-enter-active,
.soona-fade-up-enter-active,
.soona-fade-down-enter-active,
.soona-fade-left-enter-active,
.soona-fade-right-enter-active,
.soona-fade-up-short-enter-active,
.soona-fade-down-short-enter-active {
  will-change: opacity, transform;
  transition:
    opacity 0.25s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.25s cubic-bezier(0.16, 1, 0.3, 1);
}

//fade-*
.soona-fade-leave-active,
.soona-fade-up-leave-active,
.soona-fade-down-leave-active,
.soona-fade-left-leave-active,
.soona-fade-right-leave-active,
.soona-fade-up-short-leave-active,
.soona-fade-down-short-leave-active {
  will-change: opacity, transform;
  transition:
    opacity 0.2s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

//fade
.soona-fade-enter-from,
.soona-fade-leave-to {
  opacity: 0;
}

//fade-up
.soona-fade-up-enter-from,
.soona-fade-up-leave-to {
  opacity: 0;
  transform: translateY(1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-down
.soona-fade-down-enter-from,
.soona-fade-down-leave-to {
  opacity: 0;
  transform: translateY(-1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-left
.soona-fade-left-enter-from,
.soona-fade-left-leave-to {
  opacity: 0;
  transform: translateX(1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-right
.soona-fade-right-enter-from,
.soona-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-up-short
.soona-fade-up-short-enter-from,
.soona-fade-up-short-leave-to {
  opacity: 0;
  transform: translateY(0.5rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-down-short
.soona-fade-down-short-enter-from,
.soona-fade-down-short-leave-to {
  opacity: 0;
  transform: translateY(-0.5rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
</style>
