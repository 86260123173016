<script setup>
import { ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { toCurrency } from '@/lib/currency';
import dayjs from 'dayjs';

defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  transactions: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['onSort']);

const currentSort = ref({
  column: 'date',
  order: 'desc',
});

const handleSort = column => {
  console.log('sort', column);
  if (currentSort.value.column === column) {
    currentSort.value.order =
      currentSort.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    currentSort.value.column = column;
    currentSort.value.order = 'asc';
  }
  emit('onSort', currentSort.value);
};
</script>

<template>
  <div class="contract-order-history-table">
    <table>
      <thead>
        <tr>
          <th
            class="contract-order-history-table__sortable-col"
            @click="() => handleSort('date')"
          >
            <span
              :class="`contract-order-history-table__sort contract-order-history-table__sort--${
                currentSort.column === 'date' ? currentSort.order : 'hide'
              }`"
            >
              Date
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th>Charged by</th>
          <th>Content type</th>
          <th>Service</th>
          <th>Quantity</th>
          <th>Hours</th>
          <th>Booking ID</th>
          <th>Amount</th>
          <th>Balance</th>
          <!-- CTA -->
          <th class="contract-order-history-table__fixed-col" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="transaction in transactions"
          :key="transaction.id"
          :class="`contract-order-history-table__row contract-order-history-table__row--${transaction.type}`"
        >
          <td id="date">{{ dayjs(transaction.date).format('MMM D, YYYY') }}</td>
          <td id="charged_by">{{ transaction.charged_by }}</td>
          <td id="type">
            <span
              :class="`contract-order-history-table__tag contract-order-history-table__tag--${transaction.type}`"
            >
              {{ transaction.type }}
            </span>
          </td>
          <td id="service">{{ transaction.service ?? '--' }}</td>
          <td id="quantity">{{ transaction.quantity ?? '--' }}</td>
          <td id="pro-service-hours">
            {{ transaction.pro_service_hours ?? '--' }}
          </td>
          <td>{{ transaction.booking_id }}</td>
          <td
            id="amount"
            :class="`contract-order-history-table__amount contract-order-history-table__amount--${transaction.type}`"
          >
            {{
              (transaction.type === 'invoice-paid' ? '+' : '-') +
              toCurrency(transaction.amount)
            }}
          </td>
          <td id="balance">{{ toCurrency(transaction.balance) }}</td>
          <td id="cta" class="contract-order-history-table__fixed-col">
            <SoonaIcon name="search" size="small" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-order-history-table {
  position: relative;
  overflow-x: auto;
  overflow-y: visible;

  table {
    table-layout: auto;
    white-space: nowrap;
    width: stretch;
    // border-collapse: separate;
    // border-spacing: 0.75rem 0;
    // margin-left: -0.75rem;
  }

  thead {
    border-radius: 5px;
  }

  tr {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid variables.$gray-30;
  }

  tbody {
    tr:last-child {
      border-bottom: none;
    }
  }

  th {
    @include variables_fonts.u-small--heavy;
    color: #6f7073;
    text-transform: lowercase;
    padding: 0.5rem 1rem 0.5rem 0;
  }

  td {
    padding: 1rem 1rem 1rem 0;
  }

  &__row {
    @include variables_fonts.u-small--regular;

    #cta {
      color: variables.$gray-60;
      text-align: center;
      cursor: pointer;
      min-width: 32px;
    }

    &:last-child {
      position: sticky;
    }
  }

  &__amount {
    font-size: 14px;
    font-weight: 800;
    &--invoice-paid {
      color: variables.$avo-toast-60;
    }
  }

  &__tag {
    @include variables_fonts.u-badge--small;
    padding: 0.25rem 0.375rem;
    border-radius: 3px;
    &--photo,
    &--video {
      background: variables.$bubbletape-pink-20;
    }
    &--other {
      color: variables.$tangerine-80;
      background: variables.$tangerine-20;
    }
    &--ugc {
      background: #ebedb8;
    }
    &--invoice-paid {
      background: #b3cfb7;
    }
  }

  &__fixed-col {
    position: sticky;
    right: 0;
    background: white;
    // border-bottom: 1px solid variables.$gray-30;
    // box-shadow: 0 1px 0 0 variables.$gray-30;
  }

  &__sortable-col {
    cursor: pointer;
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &--desc {
      svg {
        transform: rotate(180deg);
      }
    }

    &--hide {
      svg {
        display: none;
      }
    }
  }
}
</style>
