import { computed } from 'vue';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import { useGetIntegrations } from '@/queries/useGetIntegrations';

export default accountId => {
  const {
    hasTierThreeSubscription,
    hasLiveSubscription,
    userCanUpgrade,
    isSuccess: subscriptionLoaded,
  } = useActiveSubscription(accountId);
  const { data: integrationsData, isSuccess: integrationsLoaded } =
    useGetIntegrations(accountId);

  const hasAmazonIntegration = computed(
    () =>
      !!integrationsData.value?.find(
        x => x.type === 'AmazonIntegration' && x.status === 'active'
      )
  );

  const hasShopifyIntegration = computed(
    () =>
      !!integrationsData.value?.find(
        x => x.type === 'ShopifyIntegration' && x.status === 'active'
      )
  );

  return {
    hasLiveSubscription,
    hasTierThreeSubscription,
    subscriptionLoaded,
    integrationsData,
    integrationsLoaded,
    hasAmazonIntegration,
    hasShopifyIntegration,
    userCanUpgrade,
  };
};
