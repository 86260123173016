<script setup>
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { NOTES_ACTION } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import DigitalAssetNotesDialog from '@/components/notes/digital-asset-notes/DigitalAssetNotesDialog.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';

defineProps({
  label: {
    type: String,
    default: 'notes',
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
});

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

mediaEditorStore.registerComponent(NOTES_ACTION, {
  handleSave: () => {},
  handleReset: () => {},
  warnBeforeClose: () => {},
});
</script>

<template>
  <template v-if="assetAccountId && assetId">
    <DigitalAssetNotesDialog
      v-if="isMobileView"
      :account-id="assetAccountId"
      :digital-asset-id="assetId"
      @close="mediaEditorStore.setActiveAction(NOTES_ACTION)"
    />
    <DigitalAssetNotesList
      v-else
      :account-id="assetAccountId"
      :digital-asset-id="assetId"
    />
  </template>
</template>
