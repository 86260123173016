<script setup>
import { storeToRefs } from 'pinia';

import { useBatchEditStore } from '../store/useBatchEditStore';

import BatchLocationLayout from './BatchLocationLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { computed } from 'vue';

const batchEditStore = useBatchEditStore();
const { locationAlbumName } = storeToRefs(batchEditStore);

const albumName = computed({
  get() {
    return locationAlbumName.value;
  },
  set(newName) {
    batchEditStore.setLocationAlbumName(newName);
  },
});
</script>

<template>
  <BatchLocationLayout class="new-album">
    <template #illustration>
      <img
        alt=""
        class="new-album__illustration"
        src="/images/bulk-edits/new-album.png"
      />
    </template>
    <template #heading>name your new album</template>

    <form
      class="new-album__footer"
      @submit.prevent="batchEditStore.submitBatchAction"
    >
      <SoonaTextfield
        v-model="albumName"
        label="album name"
        class="new-album__name-input"
      />
      <SoonaButton type="submit"> create & save </SoonaButton>
    </form>
  </BatchLocationLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.new-album {
  &__illustration {
    display: none;
    margin: 0 auto;
    height: 9.375rem;
    object-fit: fill;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  h2 {
    line-height: 0.675;
  }

  &__footer {
    gap: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 40rem;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: variables.$screen-sm-min) {
      height: fit-content;
      flex-direction: row;
    }
  }

  &__name-input {
    flex: 1;
    width: 100%;
    padding-bottom: 0;
  }
}
</style>
