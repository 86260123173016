import { computed, ref } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useReservations } from 'src/queries/reservations/useReservations';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

export function useShootSetupDialog(currentAccountId) {
  const { linkClicked } = useBaseEvents();
  const route = useRoute();
  const { hasCapability: isFtSoonaStaff } = useCapability({
    capability: 'ft_soona_staff',
  });
  const pegasusDuplicateReservationFlag = useFlag(
    'pegasus_duplicate_reservation'
  );

  const { data: previousReservationsData } = useReservations(
    {
      accountId: currentAccountId,
      itemsPerPage: 1,
      currentPage: 1,
      filter: 'duplicatable',
    },
    { staleTime: 60 * 1000 }
  );

  const hasPreviousBookings = computed(
    () => previousReservationsData.value?.reservations.length > 0
  );

  const enableShootSetupDialog = computed(
    () =>
      hasPreviousBookings.value &&
      !isFtSoonaStaff.value &&
      pegasusDuplicateReservationFlag.value
  );

  const displayShootSetupDialog = ref(false);
  const displayDuplicateBookingDialog = ref(false);
  const reservationIdToDuplicate = ref(null);

  const handleDisplayDuplicateReservationDialog = reservationId => {
    displayShootSetupDialog.value = false;
    reservationIdToDuplicate.value = reservationId;
    displayDuplicateBookingDialog.value = true;

    linkClicked({
      context: route.meta?.context ?? 'unknown',
      subContext: `reservation ${reservationId} selected from shoot setup dialog`,
      linkLabel: 'duplicate shoot',
      linkHref: null,
    });
  };

  const handleCloseDisplayDuplicateReservationDialog = () => {
    displayDuplicateBookingDialog.value = false;
    displayShootSetupDialog.value = true;
    reservationIdToDuplicate.value = null;
  };

  return {
    enableShootSetupDialog,
    displayShootSetupDialog,
    displayDuplicateBookingDialog,
    reservationIdToDuplicate,
    handleDisplayDuplicateReservationDialog,
    handleCloseDisplayDuplicateReservationDialog,
  };
}
