<script setup>
import { ref, computed } from 'vue';
import accountImage from 'images/account-placeholder.svg';
import ProServiceDeclineDialog from './ProServiceDeclineDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useUpdateCrewAssignment } from '@/queries/useUpdateCrewAssignment';
import CrewAssignmentDeclineDialog from '../crew/CrewAssignmentDeclineDialog.vue';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
  assignment: {
    type: Object,
    required: true,
  },
  showImage: {
    type: Boolean,
    required: false,
  },
});

const showDeclineModal = ref(false);

const assignmentStatus = computed(() => {
  if (props.assignment.status === 'sent') {
    return 'pending';
  } else {
    return props.assignment.status;
  }
});

const isProServiceAssignment = computed(() => {
  return !!props.assignment.is_pro_service_assignment;
});

const assignmentName = computed(() => {
  return props.assignment.pro_service_profile?.name
    ? props.assignment.pro_service_profile?.name
    : props.assignment.profile_full_name
      ? props.assignment.profile_full_name
      : props.assignment.user_name;
});

const formattedName = computed(() => {
  let nameArray = assignmentName.value.split(' ');
  return nameArray.length > 1
    ? nameArray[0] + ' ' + nameArray[1].slice(0, 1) + '.'
    : nameArray[0];
});

const crewAssignmentId = computed(() => props.assignment.id);
const reservationId = computed(() => props.reservation.id);

const {
  mutate: updateCrewAssignment,
  isPending: updateCrewAssignmentPending,
  error: updateCrewAssignmentError,
} = useUpdateCrewAssignment({ crewAssignmentId, reservationId });

const handleAccept = () => {
  updateCrewAssignment({ status: 'accepted' });
};

function openDeclineModal() {
  showDeclineModal.value = true;
}

const closeDeclineModal = async () => {
  showDeclineModal.value = false;
};

const priorityError = usePriorityErrors(updateCrewAssignmentError);
</script>

<template>
  <div class="assignment-item">
    <div class="provider-name-section section">
      <div class="section-header">
        {{ assignment.crew_role
        }}<span v-if="!showImage">{{ ' - ' + formattedName }}</span>
      </div>
      <div :class="assignmentStatus + ' banner'">
        {{ assignmentStatus }}
      </div>
    </div>
    <div class="assignment-details">
      <div v-if="showImage" class="assignment-img">
        <img
          v-if="props.assignment.main_image_url"
          :alt="assignment.crew_role + ' service thumbnail'"
          :src="props.assignment.main_image_url"
        />
        <img
          v-else
          class="user-info-image"
          :src="accountImage"
          alt="account profile"
        />
      </div>
      <div class="assignment-info">
        <div v-if="showImage" class="section-header">{{ formattedName }}</div>
        <div v-if="assignment.note" class="notes-section section">
          <div class="section-header">assignment notes:</div>
          <div>{{ assignment.note }}</div>
        </div>
        <div
          class="actions-section section"
          :class="{ 'with-image': showImage }"
        >
          <SoonaButton
            :variation="
              assignment.status === 'sent' ? 'secondary-black' : 'tertiary'
            "
            size="medium"
            :on-click="openDeclineModal"
            :disabled="updateCrewAssignmentPending"
            :data-cypress="
              assignment.status === 'accepted'
                ? 'button-psp-cancel-assignment'
                : 'button-psp-decline-assignment'
            "
          >
            {{ assignment.status === 'accepted' ? 'cancel' : 'decline' }}
          </SoonaButton>
          <SoonaButton
            v-if="assignment.status !== 'accepted'"
            variation="primary"
            size="medium"
            class="left-margin"
            :on-click="handleAccept"
            :loading="updateCrewAssignmentPending"
            data-cypress="button-psp-accept-assignment"
          >
            accept
          </SoonaButton>
        </div>
        <SoonaError v-if="priorityError" :priority-errors="priorityError" />
      </div>
    </div>
  </div>
  <ProServiceDeclineDialog
    v-if="showDeclineModal && isProServiceAssignment"
    :reservation-id="reservation.id"
    :assignment-id="assignment.id"
    @close="closeDeclineModal"
  />
  <CrewAssignmentDeclineDialog
    v-if="showDeclineModal && !isProServiceAssignment"
    :reservation-id="reservation.id"
    :assignment="assignment"
    @close="closeDeclineModal"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
.assignment-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .section-header {
    color: variables.$black-default;
    font-weight: 900;
    font-size: 0.875rem;
  }
  .section {
    display: flex;
    width: 100%;
    padding: 0rem;
    margin-bottom: 0.75rem;
  }
  .actions-section {
    justify-content: flex-end;
    width: 100%;
    > * {
      margin-left: 0.5rem;
    }
    &.with-image {
      justify-content: flex-start;
      > * {
        margin-left: 0rem;
        margin-right: 0.5rem;
      }
      :deep(.soona-button--tertiary) {
        min-width: 0rem;
      }
    }
  }
  .provider-name-section {
    align-items: center;
    .section-header {
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      margin-right: 0.25rem;
    }
  }
  .assignment-details {
    width: 100%;
    display: flex;
    .assignment-info {
      width: 100%;
      .notes-section {
        flex-direction: column;
        word-break: break-all;
        .section-header {
          text-transform: lowercase;
        }
      }
    }
    .assignment-img {
      margin: 0rem 1rem 0.5rem 0rem;
      img {
        min-width: 9.375rem;
        min-height: 9.375rem;
        max-width: 9.375rem;
        max-height: 9.375rem;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .banner {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 0.05rem 0.3rem;
    color: variables.$white-default;
    margin-right: 0.25rem;
    &.accepted {
      background-color: variables.$avo-toast-60;
    }
    &.pending {
      background-color: variables.$tangerine-70;
    }
    &.declined {
      background-color: variables.$roses-70;
    }
  }
}
</style>
