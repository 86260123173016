<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import { useAlbumCollectionDigitalAssets } from '@/queries/album_collection_digital_assets/useAlbumCollectionDigitalAssets';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  albumCollectionId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: null,
  },
  albumTitle: {
    type: String,
    default: null,
  },
});

const activeAssetId = computed(() => props.activeAssetId);
const albumCollectionId = computed(() => props.albumCollectionId);
const currentPage = ref(null);
const userChangeablePage = ref(0);

watch([activeAssetId, albumCollectionId], () => {
  currentPage.value = null;
  userChangeablePage.value = 0;
});

const { data: filePages, isLoading } = useAlbumCollectionDigitalAssets(
  albumCollectionId,
  {
    activeAssetId,
    currentPage,
    itemsPerPage: 12,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!activeAssetId.value),
  }
);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});
</script>

<template>
  <CarouselBase
    v-if="filePages?.assets?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ albumTitle }} gallery</template>
    <router-link
      v-for="acda in filePages?.assets"
      :key="acda.id"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active':
          activeAssetId === acda.digital_asset?.id,
      }"
      :to="{
        name: 'album-assets-media-view',
        params: {
          accountId: acda.digital_asset?.account_id,
          collectionId: albumCollectionId,
          digitalAssetId: acda.digital_asset?.id,
        },
      }"
    >
      <DigitalAssetImage
        :src="acda.digital_asset?.preview?.url"
        :asset-type="acda.digital_asset?.asset_type"
      />
    </router-link>
  </CarouselBase>
</template>
