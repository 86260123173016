import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';

/**
 *
 * @param {Object} [params]
 * @param {MaybeRef<number> | number} [params.defaultType]
 * @param {MaybeRef<number> | number | MaybeRef<string> | string} [params.priceType]
 * @param {MaybeRef<number> | number} [params.productId]
 * @param {MaybeRef<string> | string} [params.productName]
 * @param {MaybeRef<string> | string} [params.productType]
 * @param {MaybeRef<number> | number} [params.retainerOriented]
 * @param {Object} [queryOptions]
 */
export function usePrices(
  {
    defaultType,
    priceType,
    productId,
    productName,
    productType,
    retainerOriented,
    recurringInterval,
  } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.prices({
      defaultType,
      priceType,
      productId,
      productName,
      productType,
      retainerOriented,
      recurringInterval,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (!isNaN(toValue(defaultType)))
        params['default'] = toValue(defaultType);
      if (toValue(priceType)) params['price_type'] = toValue(priceType);
      if (toValue(productId)) params['product_id'] = toValue(productId);
      if (toValue(productName)) params['product_name'] = toValue(productName);
      if (toValue(productType)) params['product_type'] = toValue(productType);
      if (toValue(recurringInterval)) {
        params['recurring_interval'] = toValue(recurringInterval);
      }
      if (toValue(retainerOriented)) {
        params['retainer_oriented'] = toValue(retainerOriented);
      }

      const response = await http.get(`/prices`, {
        params,
        signal,
      });

      return {
        prices: response.data?.prices || [],
      };
    },
    ...queryOptions,
  });
}
