<script setup>
import { computed, ref, useId, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useFlags } from '@/queries/useFlags';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useAccount } from '@/queries/account/useAccount';
import { useUpdateAccountFlags } from '@/queries/account/useUpdateAccountFlags';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => Number(props.accountId));

const formId = useId();

const {
  data: account,
  isLoading: isAccountLoading,
  error: accountError,
} = useAccount(accountId);

const {
  data: allFlags,
  isLoading: isAllFlagsLoading,
  error: allFlagsError,
} = useFlags();

const {
  mutate: updateAccountFlags,
  isPending: isUpdatingAccountFlags,
  error: updateAccountFlagsError,
} = useUpdateAccountFlags(accountId);

const isLoading = computed(
  () =>
    isAccountLoading.value ||
    isAllFlagsLoading.value ||
    isUpdatingAccountFlags.value
);

const priorityErrors = usePriorityErrors(
  accountError,
  allFlagsError,
  updateAccountFlagsError
);

const flags = ref([]);

const accountsFlags = computed(() => account.value?.accounts_flags ?? []);
const accountsFlagsMap = computed(() => {
  return new Map(accountsFlags.value.map(af => [af.flag_id, af]));
});

watchEffect(() => {
  flags.value = (allFlags.value ?? []).map(f => {
    return {
      flag_id: f.id,
      account_id: accountId.value,
      title: f.title,
      checked: accountsFlagsMap.value.has(f.id),
    };
  });
});

function saveFlags() {
  const accounts_flags_attributes = flags.value
    .filter(
      // only keep flags that are checked or where previously checked
      f => f.checked || accountsFlagsMap.value.has(f.flag_id)
    )
    .map(f => {
      const accountsFlag = {
        flag_id: f.flag_id,
        account_id: f.account_id,
        _destroy: f.checked ? 0 : 1,
      };

      const prevAccountsFlag = accountsFlagsMap.value.get(f.flag_id);

      if (prevAccountsFlag) {
        accountsFlag.id = prevAccountsFlag.id;
      }

      return accountsFlag;
    });

  updateAccountFlags(
    {
      accounts_flags_attributes,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
}
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>account flags</template>

    <SoonaForm :id="formId" class="edit-account-flags-form" @submit="saveFlags">
      <SoonaError
        v-if="priorityErrors"
        :priority-errors="priorityErrors"
        no-margin
      />

      <fieldset :disabled="isLoading">
        <legend class="edit-account-flags-form__legend">
          select flags below to mark this account
        </legend>
        <div class="edit-account-flags-form__toggles">
          <SoonaToggle
            v-for="flag in flags"
            :key="flag.flag_id"
            v-model="flag.checked"
            type="checkbox"
            :name="flag.slug"
          >
            <template #label>{{ flag.title }}</template>
          </SoonaToggle>
        </div>
      </fieldset>
    </SoonaForm>

    <template #footer="{ close }">
      <SoonaButton variation="tertiary" size="medium" @on-click="close">
        cancel
      </SoonaButton>
      <SoonaButton
        :form="formId"
        type="submit"
        variation="primary"
        size="medium"
        :disabled="isLoading"
        :loading="isUpdatingAccountFlags"
      >
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.edit-account-flags-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__legend {
    margin-bottom: 0.5rem;
  }

  &__toggles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(14rem, 100%), 1fr));
    gap: 0.5rem;
  }
}
</style>
