<script setup>
import { computed, toRefs } from 'vue';
import { useInfiniteNotes } from '@/queries/notes/useInfiniteNotes';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import NoteDisplay from '@/components/notes/NoteDisplay.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useNonCreatorEditingAllowed } from '@/components/notes/useNonCreatorEditingAllowed';
import { useDigitalAssetAnnotate } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate.js';

const props = defineProps({
  newestFirst: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showAnnotationInstructions: {
    type: Boolean,
    default: true,
  },
  showNoNotesMessage: {
    type: Boolean,
    default: true,
  },
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    default: null,
  },
});

const { subjectType, subjectId, newestFirst } = toRefs(props);

const nonCreatorEditingAllowed = useNonCreatorEditingAllowed(subjectType);

const { supportsAnnotate } = useDigitalAssetAnnotate();

const {
  data: notesData,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isFetchingNextPage,
  error,
} = useInfiniteNotes(
  subjectType,
  subjectId,
  {
    itemsPerPage: 20,
  },
  {
    enabled: computed(() => !!subjectType.value && !!subjectId.value),
  }
);

const notesPagination = computed(() => {
  const paginationData = notesData.value?.pages?.at(-1)?.pagination;
  if (paginationData) {
    return paginationData;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const priorityErrors = usePriorityErrors(error);

const allRows = computed(() => {
  const notes = notesData.value
    ? notesData.value.pages.flatMap(d => d.notes)
    : [];

  return newestFirst.value ? notes : notes.toReversed();
});
</script>

<template>
  <div class="notes-list">
    <p
      v-if="showAnnotationInstructions && !readonly && supportsAnnotate"
      class="annotation-instructions"
    >
      <SoonaIcon name="annotation" size="small" />
      click on the image to pin a note.
    </p>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <div
      v-if="
        showNoNotesMessage &&
        !isLoading &&
        !error &&
        notesData &&
        notesPagination.totalCount < 1
      "
      class="notes-list__empty"
    >
      <SoonaIcon name="message-circle-lines-alt" />
      <p class="u-label--heavy">notes will appear here</p>
    </div>
    <div v-if="hasNextPage && !newestFirst" class="notes-list__next-page">
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        :loading="isFetchingNextPage"
        @on-click="fetchNextPage"
      >
        show older notes
      </SoonaButton>
    </div>
    <NoteDisplay
      v-for="note in allRows"
      :key="note.id"
      :non-creator-editing-allowed="nonCreatorEditingAllowed"
      :note="note"
      :subject-type="subjectType"
      :subject-id="subjectId"
      :readonly="readonly"
    />
    <div v-if="hasNextPage && newestFirst" class="notes-list__next-page">
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        :loading="isFetchingNextPage"
        @on-click="fetchNextPage"
      >
        show older notes
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.notes-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__empty {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    background: variables.$gray-10;
    border-radius: 0.625rem;
    color: variables.$gray-60;
    text-align: center;
  }

  &__next-page {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  &__composer {
    margin-top: auto;
  }

  .annotation-instructions {
    border-radius: 0.625rem;
    border: 1px solid variables.$gray-30;
    background: variables.$gray-10;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    font-size: 0.875rem;
    justify-content: center;
  }
}
</style>
