<script setup>
import { computed, ref } from 'vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import ManageAccountFlags from '@/components/user/anytime/dashboard/account-crew-tools/ManageAccountFlags.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/composables/useAccount';
import { useCapability } from '@/composables/useCapability';
import CreateInvoicedSubscriptionDialog from '@/components/user/anytime/dashboard/account-crew-tools/CreateInvoicedSubscriptionDialog.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const stripeDashboardUrl = import.meta.env.VITE_STRIPE_DASHBOARD_URL;

const accountId = computed(() => Number(props.accountId));
const invoicedSubscriptionFlag = useFlag('pegasus_invoiced_subscriptions');
const { hasCapability: canManageCrewBookingBuilder } = useCapability({
  capability: 'manage_crew_booking_builder',
});
const { account, hasActiveSubscription } = useAccount(accountId);

const tierSlug = ref(null);
</script>

<template>
  <div class="account-actions">
    <h3 class="u-subheader--heavy">account actions</h3>

    <div class="account-actions__actions">
      <ManageAccountFlags :account-id="accountId" />

      <SoonaButton
        v-if="account?.external_user_id"
        element="a"
        :href="`${stripeDashboardUrl}/customers/${account?.external_user_id}`"
        target="_blank"
        variation="secondary-gray"
        size="medium"
      >
        view on Stripe
      </SoonaButton>
      <SoonaButton
        element="router-link"
        :to="{ path: `/crew/bag/${accountId}` }"
        data-cypress="crew-tools-view-bag"
        variation="secondary-gray"
        size="medium"
      >
        view bag
      </SoonaButton>
      <SoonaDropdownMenu
        v-if="
          canManageCrewBookingBuilder &&
          !hasActiveSubscription &&
          invoicedSubscriptionFlag
        "
        copy="create invoiced subscription"
        variation="secondary-gray"
        data-cypress="crew-tools-invoiced-subx-dropdown"
      >
        <template #default="{ clickCapture, keydown, mouseover }">
          <SoonaDropdownMenuItem>
            <button
              role="menuitem"
              @click="tierSlug = 'tier-one'"
              @keydown="keydown"
              @mouseover="mouseover"
              @click.capture="clickCapture"
            >
              basic annual
            </button>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <button
              role="menuitem"
              @click="tierSlug = 'tier-two'"
              @keydown="keydown"
              @mouseover="mouseover"
              @click.capture="clickCapture"
            >
              standard annual
            </button>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
      <SoonaDropdownMenu
        v-if="canManageCrewBookingBuilder"
        copy="create shoot"
        variation="solid-black"
        data-cypress="crew-tools-create-shoot-dropdown"
      >
        <template #default="{ clickCapture, keydown, mouseover }">
          <SoonaDropdownMenuItem>
            <a
              role="menuitem"
              data-cypress="crew-tools-customer-booking"
              :href="`/#/crew/booking/new?account_id=${accountId}`"
              @click.capture="clickCapture"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              customer booking
            </a>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <a
              role="menuitem"
              data-cypress="crew-tools-internal-booking"
              :href="`/#/crew/booking/new?account_id=${accountId}&type=internal`"
              @click.capture="clickCapture"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              internal booking
            </a>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
    </div>
  </div>
  <!-- create invoiced subscription -->
  <CreateInvoicedSubscriptionDialog
    v-if="invoicedSubscriptionFlag && tierSlug && !hasActiveSubscription"
    :account-id="accountId"
    :tier-slug="tierSlug"
    @close="tierSlug = null"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-actions {
  padding: 1rem;
  border-radius: 0.3125rem;
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    & > :first-child {
      margin-right: auto;
    }
  }
}
</style>
