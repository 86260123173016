import { storeToRefs } from 'pinia';

import { EventSourceExtra } from './EventSourceExtra';
import { useAssistantStore } from './useAssistantStore';
import { isDuplicateEvent, parseResponseStream } from './utils';

export function useHandleEventStream({ functionHandler }) {
  let previousEvent = null;

  const store = useAssistantStore();
  const { eventSource } = storeToRefs(store);

  function handleEventStream(url, options) {
    if (store.eventSource) {
      store.eventSource.close();
    }

    const source = new EventSourceExtra(url, options);
    store.setEventSource(source);

    source.addEventListener('open', () => {});

    source.addEventListener('error', event => {
      if (event.eventPhase === EventSource.CLOSED) {
        source.close();
      }
      store.setIsWaiting(false);
    });

    source.addEventListener('message', event => {
      const { data } = JSON.parse(event.data);

      if (previousEvent && isDuplicateEvent(previousEvent, data)) {
        return;
      }

      previousEvent = data;

      const res = parseResponseStream(data);

      switch (res.event) {
        case 'thread.message.in_progress':
          store.appendMessage({ role: 'assistant', text: '' });
          store.setIsWaitingForInitialResponse(false);
          break;

        case 'thread.run.completed':
          store.setIsWaiting(false);
          source.close();
          break;

        case 'thread.message.delta':
          store.handleTextDelta(res.text);
          break;

        case 'thread.run.requires_action':
          // Bit of a cyclicle situation here - any ideas?
          // eslint-disable-next-line no-use-before-define
          handleRequiresFunction(res);
          break;

        default:
          break;
      }
    });

    source.stream();
  }

  const submitFunctionResult = async (runId, threadId, toolCallOutputs) => {
    const outputs = toolCallOutputs.map(toolCallOutput => ({
      ...toolCallOutput,
      output: JSON.stringify(toolCallOutput.output),
    }));

    handleEventStream('/api/assistants/run_action', {
      headers: {
        'Content-Type': 'application/json',
      },
      payload: JSON.stringify({
        run_id: runId,
        thread_id: threadId,
        tool_outputs: outputs,
      }),
    });
  };

  async function handleRequiresFunction(event) {
    const { runId, toolCalls } = event;

    const toolCallOutputs = await Promise.all(
      toolCalls.map(async toolCall => {
        const result = await functionHandler(toolCall.function);
        return {
          runId,
          suspend: result.suspend,
          name: toolCall.function.name,
          toolOutput: { output: result.response, tool_call_id: toolCall.id },
        };
      })
    );

    if (toolCallOutputs.some(({ suspend }) => suspend)) {
      store.setIsWaiting(false);
      store.setIsWaitingForInitialResponse(false);
      return store.setSuspendedToolOutputs(toolCallOutputs);
    }

    await submitFunctionResult(
      runId,
      store.threadId,
      toolCallOutputs.map(toolCallOutput => ({
        ...toolCallOutput.toolOutput,
      }))
    );
  }

  return { eventSource, handleEventStream, submitFunctionResult };
}
