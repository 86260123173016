import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';
import { computed } from 'vue';
import { useReEditsCollection } from '@/queries/re_edits_collections/useReEditsCollection';

/**
 * @param {import('vue').Ref<string | number>} accountId
 * @param {import('vue').Ref<string | number>} digitalAssetId
 * @returns {import('vue').ComputedRef<boolean>}
 */
export function useDigitalAssetNotesAreReadonly(accountId, digitalAssetId) {
  const { data } = useDigitalAsset(
    { accountId, assetId: digitalAssetId },
    // allow for stale data to lighten request loads. invalidations still trigger updates
    { staleTime: 60_000 }
  );

  const reEditsCollectionId = computed(
    () => data.value?.re_edits_collection_digital_asset?.collection_id
  );

  const { data: reEditsCollection } = useReEditsCollection(
    accountId,
    reEditsCollectionId,
    {
      enabled: computed(
        // early bail out and do not fetch re-edits collection if the asset is archived
        () => !!reEditsCollectionId.value && !data.value?.archived_at
      ),
    }
  );

  return computed(() => {
    if (!data.value || data.value.archived_at) {
      return true;
    }
    // data has loaded, we're not archived

    // no re-edits id, and we haven't returned yet, we're good and this is editable
    if (!reEditsCollectionId.value) {
      return false;
    }

    // we only care about re-edits collection if there is an id and the collection has loaded
    if (reEditsCollection.value) {
      return reEditsCollection.value.options?.status === 'submitted';
    }

    // default to readonly if anything is amiss
    return true;
  });
}
