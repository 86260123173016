import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';
import * as Sentry from '@sentry/vue';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<"accounts" | "reservations" | "digital_assets">} subjectType
 * @param {import('vue').MaybeRefOrGetter<number>} subjectId
 */
export function useCreateNote(subjectType, subjectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      content,
      asset,
      assets,
      note_type,
      location_metadata,
    }) => {
      const response = await http.post(
        `/${toValue(subjectType)}/${toValue(subjectId)}/notes`,
        {
          content,
          asset,
          assets,
          note_type,
          location_metadata,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(subjectType, subjectId),
      });
    },
    onError: error => {
      Sentry.captureException(new Error('failed to create note'), {
        extra: { error },
      });
    },
  });
}
