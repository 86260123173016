<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useCreateAddress } from 'src/queries/useCreateAddress';
import { useUpdateAddress } from 'src/queries/useUpdateAddress';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});
const emit = defineEmits(['cancel', 'save']);
const accountId = computed(() => Number(props.accountId));

const stripe = ref(null);
const stripeElement = ref(null);
const addressElement = ref(null);
const billingAddress = ref(null);
const newBillingEmail = ref('');
const newCompanyName = ref('');

const {
  account,
  billingAddressId,
  isSuccess: accountIsLoaded,
} = useAccount(accountId);

const { mutate: createAddress, error: createAddressError } =
  useCreateAddress(accountId);
const { mutate: updateAddress, isError: updateAddressError } =
  useUpdateAddress(accountId);
const {
  mutate: updateAccount,
  isPending: isPending,
  error: updateAccountErrors,
} = useUpdateAccount(accountId);

// NOTE: Should this settings be split to another file and reused?
const addressOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
      family: 'Lato',
      style: 'normal',
    },
  ],
  appearance: {
    rules: {
      '.Label': {
        fontWeight: '800',
        fontSize: '0.875rem',
        color: '#000000',
        lineHeight: '1.2857',
        letterSpacing: '0.015625rem',
      },
      '.Input': {
        borderColor: '#d6d7dc',
        boxShadow: 'none',
      },
    },
    variables: {
      colorPrimary: '#5566EA',
      fontFamily: 'Lato',
    },
  },
};

const getAccountUpdateData = () => {
  const data = {};
  // Billing email address
  if (newBillingEmail.value !== account.value.billing_email) {
    data.billing_email = newBillingEmail.value;
  }
  // Company name
  if (newCompanyName.value !== account.value.name) {
    data.name = newCompanyName.value;
  }

  return data;
};

const getAddressUpdateData = address => {
  return {
    id: address.id,
    address_1: address.line1,
    address_2: address.line2,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country_code: address.country,
    address_type: 'billing',
    is_default: true,
  };
};

const hasAddressChanged = address => {
  return (
    address.city !== billingAddress.value.city ||
    address.state !== billingAddress.value.state ||
    address.country !== billingAddress.value.country_code ||
    address.postal_code !== billingAddress.value.postal_code
  );
};

const hasNameOrEmailChanged = () => {
  return (
    newBillingEmail.value !== account.value.billing_email ||
    newCompanyName.value !== account.value.name
  );
};

const hasAddressLinesChanged = address => {
  return (
    address.line1 !== billingAddress.value.address_1 ||
    address.line2 !== billingAddress.value.address_2
  );
};

const cancel = () => {
  newBillingEmail.value = account.value.billing_email;
  newCompanyName.value = account.value.name;
  emit('cancel');
};

const save = async () => {
  if (stripeElement.value && addressElement.value) {
    const element = stripeElement.value.getElement('address');
    const { complete, value } = await element.getValue();

    if (complete) {
      const address = value.address;
      newCompanyName.value = value.name;
      const accountUpdateData = getAccountUpdateData();

      if (!billingAddress.value) {
        const addressData = getAddressUpdateData(address);
        createAddress(addressData);
      } else {
        if (hasAddressChanged(address)) {
          const addressData = getAddressUpdateData(address);
          createAddress(addressData);
        } else if (hasAddressLinesChanged(address)) {
          const addressData = {
            id: billingAddress.value.id,
            address_1: address.line1,
            address_2: address.line2,
          };
          updateAddress(addressData);
        }
      }

      if (hasNameOrEmailChanged()) {
        updateAccount(accountUpdateData);
      }

      emit('save');
    }
  }
};

const mountAddressElement = () => {
  if (window.Stripe) {
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
    stripeElement.value = stripe.value.elements(addressOptions);
    addressElement.value = stripeElement.value.create('address', {
      mode: 'billing',
      display: {
        name: 'organization',
      },
      defaultValues: {
        name: account.value?.name,
        address: {
          line1: billingAddress.value?.address_1,
          line2: billingAddress.value?.address_2,
          city: billingAddress.value?.city,
          state: billingAddress.value?.state,
          postal_code: billingAddress.value?.postal_code,
          country: billingAddress.value?.country_code,
        },
      },
    });
    addressElement.value.mount('#address-element');
  }
};

const priorityErrors = usePriorityErrors(
  createAddressError,
  updateAddressError,
  updateAccountErrors
);

watchEffect(() => {
  if (accountIsLoaded.value && account.value) {
    billingAddress.value = account.value.billing_address || {};
    newCompanyName.value = account.value.name || '';
    newBillingEmail.value = account.value.billing_email;
  }
  if (updateAccountErrors.value) {
    newCompanyName.value = account.value.name || '';
  }
});

onMounted(() => {
  mountAddressElement();
});
</script>

<template>
  <div class="billing-info-form">
    <SoonaError v-if="priorityErrors" class="feedback-dialog__error" />
    <div class="billing-info-form__edit-address">
      <SoonaTextfield
        v-model="newBillingEmail"
        class="billing-info-form__input"
        label="Billing email"
        name="billingEmail"
        :rules="['email']"
        type="email"
      />
      <form id="address-form">
        <div id="address-element"></div>
      </form>
    </div>
    <Capability
      class="billing-info-form__capability"
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account?.id"
    >
      <SoonaButton
        class="billing-info-form__cancel-btn"
        size="medium"
        variation="tertiary"
        :disabled="!billingAddressId"
        @on-click="cancel"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        size="medium"
        variation="primary"
        :disabled="isPending"
        @on-click="save"
      >
        save
      </SoonaButton>
    </Capability>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.billing-info-form {
  display: flex;
  flex-direction: column;

  &__edit-address {
    margin-bottom: 0.5rem;
  }

  &__input {
    padding-bottom: 0.75rem;
  }

  &__cancel-btn:disabled {
    background-color: transparent !important;
    border: none !important;
  }

  &__capability {
    display: flex;
    justify-content: space-between;
  }
}

#address-form {
  margin-bottom: 0.5rem;
}
</style>
