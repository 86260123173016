import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';

/**
 *
 * @param {Object} [params]
 * @param {number | Ref<number> | string | Ref<string>} [accountId]
 * @param {array | Ref<array>} [status]
 * @param {UseQueryOptions} queryOptions
 */
export function useContracts({ accountId, status }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.accountContracts(accountId),
    queryFn: async ({ signal }) => {
      const params = {};

      if (status) params['status'] = toValue(status);
      const response = await http.get(
        `/accounts/${toValue(accountId)}/contracts`,
        {
          params,
          signal,
        }
      );
      return {
        contracts: response.data?.contracts || [],
      };
    },
    ...queryOptions,
  });
}
