<script setup>
import { computed, ref, watchEffect, watch } from 'vue';
import CommunicationForm from './CommunicationForm.vue';
import ModelProductSelector from './ModelProductSelector.vue';
import PaymentSelector from './PaymentSelector.vue';
import PhotoProductSelector from './PhotoProductSelector.vue';
import ProServiceProductSelector from './ProServiceProductSelector.vue';
import ShootOptionsSelector from './ShootOptionsSelector.vue';
import StopMotionAndGifProductSelector from './StopMotionAndGifProductSelector.vue';
import VideoProductSelector from './VideoProductSelector.vue';
import BonusContentSelector from './BonusContentSelector.vue';
import CustomerDetails from './CustomerDetails.vue';
import { useReservation } from 'src/queries/useReservation';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useStore } from 'vuex';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { queryKeys } from '@/queries/query-keys';
import { useAccount } from '@/composables/useAccount';
import { useReservationAdditionalOrderStore } from '@/components/user/anytime/billing_and_orders/store/useReservationAdditionalOrderStore';
import { storeToRefs } from 'pinia';
import { useCreateReservationAdditionalChargeOrder } from '@/queries/orders/additional_charge/useCreateReservationAdditionalChargeOrder';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import PromoCode from '@/components/PromoCode.vue';

//props
const props = defineProps({
  reservationId: {
    type: String,
    default: null,
    required: true,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});

const emit = defineEmits([
  'compOrder',
  'noOrderCouponValidated',
  'noOrderCouponRemoved',
]);
const reservationId = computed(() => props.reservationId);
const discountObj = computed(() => props.discountObj);
const store = useStore();
const router = useRouter();
const queryClient = useQueryClient();

const reservationAdditionalOrderStore = useReservationAdditionalOrderStore();

const { preSalesTaxTotal, productsInCart, reducedProductsInCart } = storeToRefs(
  reservationAdditionalOrderStore
);

//vue query section
const { data: reservation } = useReservation(reservationId);

watch(reservation, () => {
  store.commit('reservation/SET_CURRENT_RESERVATION', reservation.value);
});

const { mutate: createAdditionalChargeOrder, error: errorCreatingOrder } =
  useCreateReservationAdditionalChargeOrder(reservationId);

function onSelectProduct(product) {
  reservationAdditionalOrderStore.incrementAdditionalChargeItem(product.value);
  store.dispatch('reservation/setAdditionalChargeItems', {
    [product.value]: 1,
  });
}

function onDeselectProduct(product) {
  reservationAdditionalOrderStore.removeAdditionalChargeItem(product.value);
  store.dispatch('reservation/removeAdditionalChargeItem', product.value);
}

//computed ids
const accountId = computed(() => {
  return reservation.value ? reservation.value.account.id : null;
});
const locationId = computed(() => {
  return reservation.value ? reservation.value.location.id : null;
});

//email section
const email = ref({
  address: '',
  subject: 'thanks for your order!',
  notes: '',
});

watch(
  () => email.value.notes,
  () => {
    store.dispatch('reservation/setAdditionalChargeMemo', email.value.notes);
  }
);

const errorMessage = ref('');

watchEffect(() => {
  email.value.address = reservation.value?.account.email;
});

//payment section
const selectedPaymentType = ref('');
const orderProcessing = ref(false);
const compReason = ref('');

const {
  billingAddressId,
  defaultCardPaymentMethod,
  isLoading: isLoadingAccount,
} = useAccount(accountId);

const defaultPayment = computed(() => {
  return defaultCardPaymentMethod.value;
});

const chargeDisabled = computed(() => {
  return (
    email.value.notes === '' ||
    email.value.address === '' ||
    email.value.subject === '' ||
    productsInCart.value.length <= 0 ||
    selectedPaymentType.value === '' ||
    isLoadingAccount.value ||
    (selectedPaymentType.value === 'comp_order' && compReason.value === '')
  );
});

const paymentOptions = computed(() => {
  const options = [
    {
      value: 'send_invoice',
      label: 'send invoice',
      description: ['customer must complete invoice for credits to be active'],
    },
    {
      value: 'comp_order',
      label: 'comp order',
      description: [
        'customer will not be charged',
        'order will appear in history with $0.00 charge',
      ],
    },
  ];
  if (defaultPayment.value) {
    options.unshift({
      value: 'card_on_file',
      label: 'charge card on file',
      subTitle:
        (defaultPayment.value?.payment_method_data?.brand ?? 'card brand') +
        ' ending in ' +
        (defaultPayment.value?.payment_method_data?.last4 ?? 'xxxx'),
      description: [],
      disabled: !defaultPayment.value || !billingAddressId.value,
    });
  }

  return options;
});

function onSelectPayment(option) {
  selectedPaymentType.value = option;
  if (selectedPaymentType.value === 'card_on_file') {
    store.dispatch('reservation/setAdditionalChargeImmediately', true);
    email.value.subject = 'thanks for your order!';
    emit('compOrder', false);
  } else if (selectedPaymentType.value === 'send_invoice') {
    store.dispatch('reservation/setAdditionalChargeImmediately', false);
    email.value.subject =
      'order invoice - please complete and we’ll get started!';
    emit('compOrder', false);
  } else {
    email.value.subject = 'thanks for your order!';
    emit('compOrder', true);
  }
}

function selectCompReason(reason) {
  compReason.value = reason;
}

const willCalculateSalesTax = computed(() => {
  return selectedPaymentType.value === 'card_on_file';
});

const isCompedOrder = computed(() => {
  return selectedPaymentType.value === 'comp_order';
});

async function createOrder() {
  orderProcessing.value = true;

  if (discountObj.value?.external_discount_id) {
    await store.dispatch(
      'reservation/setAdditionalChargeCoupon',
      discountObj.value?.external_discount_id
    );
  }

  if (isCompedOrder.value) {
    const params = {
      accountId: accountId.value,
      calculateSalesTax: willCalculateSalesTax.value,
      chargeImmediately: true,
      compedOrder: true,
      creditReason: compReason.value,
      products: productsInCart.value?.reduce((acc, product) => {
        acc[product.id] = product.quantity;
        return acc;
      }, {}),
      reservationId: reservationId.value,
      salesTax: {
        skip_calculation: true,
      },
    };
    createAdditionalChargeOrder(params, {
      onSuccess: async () => {
        orderProcessing.value = false;
        store.dispatch('reservation/clearAdditionalChargeItems');
        await router.push(`/reservation/${reservationId.value}/crew`);
      },
    });
  } else {
    store
      .dispatch('reservation/createAdditionalChargeOrder', {
        reservationId: reservationId.value,
        accountId: accountId.value,
        stripe: defaultPayment.value?.external_payment_method_id,
        calculateSalesTax: willCalculateSalesTax.value,
        salesTax: {
          soona_billing_address_id: billingAddressId.value,
          order_total: preSalesTaxTotal.value,
          calculate_sales_tax: willCalculateSalesTax.value,
          skip_calculation: selectedPaymentType.value !== 'card_on_file',
        },
      })
      .then(async ([, error]) => {
        if (error) {
          orderProcessing.value = false;
          errorMessage.value =
            error.status + ' ' + error.config.url + ' ' + error.data.message;
        } else {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.accountCreditInfo(accountId.value),
          });
          orderProcessing.value = false;
          store.dispatch('reservation/clearAdditionalChargeItems');
          await router.push(`/reservation/${reservationId.value}/crew`);
        }
      });
  }
}

watchEffect(() => {
  if (accountId.value !== null) {
    store.dispatch('payment/fetchDefaultPayment', accountId.value);
  }
});

const showDefaultPaymentOption = computed(() => {
  return defaultPayment.value && !billingAddressId.value;
});

const priorityErrors = usePriorityErrors(errorCreatingOrder);

const handleNoOrderCouponApply = e => {
  emit('noOrderCouponValidated', e);
};

const handleNoOrderCouponRemove = e => {
  emit('noOrderCouponRemoved', e);
};

const showPromoCode = computed(() => {
  if (!selectedPaymentType.value) return true;
  return selectedPaymentType.value !== 'comp_order';
});
</script>
<template>
  <div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <SoonaLoading
      :is-loading="orderProcessing"
      loading-text="order processing"
    />
    <SoonaLoading :is-loading="isLoadingAccount" loading-text="loading" />
    <div class="order-builder">
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">customer</h3>
        <div class="order-builder__selector-section__selector">
          <CustomerDetails :account-id="accountId" />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">shoot options</h3>
        <div class="order-builder__selector-section__selector">
          <ShootOptionsSelector :location-id="locationId" :editable="false" />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">photo</h3>
        <div class="order-builder__selector-section__selector">
          <PhotoProductSelector
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">video</h3>
        <div class="order-builder__selector-section__selector">
          <VideoProductSelector
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">bonus content</h3>
        <div class="order-builder__selector-section__selector">
          <BonusContentSelector
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">
          stop motion & gifs
        </h3>
        <div class="order-builder__selector-section__selector">
          <StopMotionAndGifProductSelector
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">models</h3>
        <div class="order-builder__selector-section__selector">
          <ModelProductSelector
            :reservation="reservation"
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">pro services</h3>
        <div class="order-builder__selector-section__selector">
          <ProServiceProductSelector
            :reservation="reservation"
            @on-select-product="onSelectProduct"
            @on-deselect-product="onDeselectProduct"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">payment</h3>
        <p v-if="showDefaultPaymentOption" class="u-label--small">
          client does not have a billing address so we cannot charge immediately
        </p>
        <div class="order-builder__selector-section__selector">
          <PaymentSelector
            :payment-options="paymentOptions"
            @select-payment="onSelectPayment"
            @select-comp-reason="selectCompReason"
          />
          <PromoCode
            v-if="showPromoCode"
            :no-order-discount="discountObj"
            :reservation-id="reservationId"
            :products="reducedProductsInCart"
            order-type="additional_charge"
            @no-order-coupon-validated="handleNoOrderCouponApply"
            @no-order-coupon-removed="handleNoOrderCouponRemove"
          />
        </div>
      </section>
      <section class="order-builder__padding order-builder__selector-section">
        <h3 class="order-builder__selector-section__heading">communication</h3>
        <div class="order-builder__selector-section__selector">
          <CommunicationForm
            v-model:email="email.address"
            v-model:subject-line="email.subject"
            v-model:notes="email.notes"
            :editable="false"
          />
        </div>
      </section>
      <section
        class="order-builder__padding order-builder__selector-section__selector order-builder__charge-button-section"
      >
        <p v-if="errorMessage" class="soona-flash soona-error">
          there was a problem placing this order:
          <b>{{ errorMessage }}</b>
        </p>
        <SoonaButton
          :disabled="chargeDisabled"
          class="order-builder__charge-button-section__charge-button"
          data-cypress="button-charge-additional-order"
          @click="createOrder"
        >
          charge order
        </SoonaButton>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.order-builder {
  margin: 1rem auto 0 auto;

  > * {
    max-width: 34.875rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__padding {
    padding: 0 1rem;
  }

  &__selector-section {
    border-bottom: 0.0625rem solid variables.$gray-30;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &__heading {
      @include variables_fonts.u-title--heavy;
      color: variables.$black-default;
      margin-bottom: 1.5rem;
    }

    &__selector {
      margin-bottom: 1.5rem;
    }
  }

  &__charge-button-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__charge-button {
      width: 100%;
    }
  }
}
</style>
