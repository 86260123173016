<script setup>
import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { addressToCityStateZip } from '@/lib/accounts/address';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const { account, billingAddress } = useAccount(accountId);
</script>
<template>
  <div class="client-info">
    <h3 class="u-subheader--heavy">Client info</h3>
    <div class="client-info__text-container">
      <p class="client-info__label">Name & billing address</p>
      <SoonaAvatar
        v-if="account"
        class="client-info__avatar"
        size="3rem"
        type="account"
        :name="account.name"
        :src="account.avatar"
        :title="account.name"
      />
      <p class="u-body--heavy">{{ account?.name }}</p>
      <p class="u-body--regular">
        {{ billingAddress?.address_1 }}
      </p>
      <p v-if="billingAddress?.address_2" class="u-body--regular">
        {{ billingAddress?.address_2 }}
      </p>
      <p class="u-body--regular">
        {{ addressToCityStateZip(billingAddress) }}
      </p>
    </div>
    <div class="client-info__text-container">
      <p class="client-info__label">Billing email</p>
      <p class="u-body--heavy">{{ account?.billing_email }}</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.client-info {
  display: inline-flex;
  flex-direction: column;
  min-width: 18.75rem;
  height: 100%;
  gap: 1rem;
  padding: 1.25rem;
  border: 0.063rem solid variables.$gray-30;
  border-radius: 0.625rem;

  &__avatar {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    @include variables_fonts.u-body--heavy;
    color: variables.$gray-60;
  }
}
</style>
