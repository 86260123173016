<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useCreateAddress } from 'src/queries/useCreateAddress';
import { useUpdateAddress } from 'src/queries/useUpdateAddress';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useAccount } from '@/composables/useAccount';
import { toCityStateZip } from '@/lib/address-formatter';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  borderlessMobile: {
    type: Boolean,
    required: false,
    default: false,
  },
  isActiveStep: {
    type: Boolean,
    required: false,
    default: undefined,
  },
});

const emits = defineEmits(['editBillingInfo', 'saveBillingInfo']);

const accountId = computed(() => Number(props.accountId));
const isActiveStep = computed(() => props.isActiveStep);
const isProgressiveStep = ref(typeof isActiveStep.value === 'boolean');

const isEditing = ref(false);
const canSaveAddress = ref(false);
const stripe = ref(null);
const stripeElement = ref(null);
const addressElement = ref(null);
const billingAddress = ref(null);
const newBillingEmail = ref('');
const newCompanyName = ref('');

const {
  account,
  billingAddressId,
  isSuccess: accountIsLoaded,
} = useAccount(accountId);

const showEdit = computed(
  () =>
    (!isEditing.value && !isProgressiveStep.value) ||
    (!isEditing.value && isProgressiveStep.value && billingAddressId.value)
);
const firstTimePayment = computed(
  () => isProgressiveStep.value && !billingAddressId.value
);

const { mutate: createAddress, error: createAddressError } =
  useCreateAddress(accountId);
const { mutate: updateAddress, isError: updateAddressError } =
  useUpdateAddress(accountId);
const {
  mutate: updateAccount,
  isPending: isPending,
  error: updateAccountErrors,
} = useUpdateAccount(accountId);

// NOTE: Should this settings be split to another file and reused?
const addressOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
      family: 'Lato',
      style: 'normal',
    },
  ],
  appearance: {
    rules: {
      '.Label': {
        fontWeight: '800',
        fontSize: '0.875rem',
        color: '#000000',
        lineHeight: '1.2857',
        letterSpacing: '0.015625rem',
      },
      '.Input': {
        borderColor: '#d6d7dc',
        boxShadow: 'none',
      },
    },
    variables: {
      colorPrimary: '#5566EA',
      fontFamily: 'Lato',
    },
  },
};

const getAccountUpdateData = () => {
  const data = {};
  // Billing email address
  if (newBillingEmail.value !== account.value.billing_email) {
    data.billing_email = newBillingEmail.value;
  }
  // Company name
  if (newCompanyName.value !== account.value.name) {
    data.name = newCompanyName.value;
  }

  return data;
};

const getAddressUpdateData = address => {
  return {
    id: address.id,
    address_1: address.line1,
    address_2: address.line2,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country_code: address.country,
    address_type: 'billing',
    is_default: true,
  };
};

const hasAddressChanged = address => {
  return (
    address.city !== billingAddress.value.city ||
    address.state !== billingAddress.value.state ||
    address.country !== billingAddress.value.country_code ||
    address.postal_code !== billingAddress.value.postal_code
  );
};

const hasNameOrEmailChanged = () => {
  return (
    newBillingEmail.value !== account.value.billing_email ||
    newCompanyName.value !== account.value.name
  );
};

const hasAddressLinesChanged = address => {
  return (
    address.line1 !== billingAddress.value.address_1 ||
    address.line2 !== billingAddress.value.address_2
  );
};

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
  emits('editBillingInfo', isEditing.value);
};

const cancel = () => {
  newBillingEmail.value = account.value.billing_email;
  newCompanyName.value = account.value.name;
  toggleEdit();
};

const save = async () => {
  console.log('save billing info');
  if (stripeElement.value && addressElement.value) {
    const element = stripeElement.value.getElement('address');
    const { complete, value } = await element.getValue();

    if (complete) {
      const address = value.address;
      newCompanyName.value = value.name;
      const accountUpdateData = getAccountUpdateData();

      if (!billingAddress.value) {
        const addressData = getAddressUpdateData(address);
        createAddress(addressData);
      } else {
        if (hasAddressChanged(address)) {
          const addressData = getAddressUpdateData(address);
          createAddress(addressData);
        } else if (hasAddressLinesChanged(address)) {
          const addressData = {
            id: billingAddress.value.id,
            address_1: address.line1,
            address_2: address.line2,
          };
          updateAddress(addressData);
        }
      }

      if (hasNameOrEmailChanged()) {
        updateAccount(accountUpdateData);
      }
      emits('saveBillingInfo');
      toggleEdit();
    }
  }
};

const mountAddressElement = () => {
  if (window.Stripe) {
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
    stripeElement.value = stripe.value.elements(addressOptions);
    addressElement.value = stripeElement.value.create('address', {
      mode: 'billing',
      display: {
        name: 'organization',
      },
      defaultValues: {
        name: account.value?.name,
        address: {
          line1: billingAddress.value?.address_1,
          line2: billingAddress.value?.address_2,
          city: billingAddress.value?.city,
          state: billingAddress.value?.state,
          postal_code: billingAddress.value?.postal_code,
          country: billingAddress.value?.country_code,
        },
      },
    });
    addressElement.value.mount('#address-element');
    addressElement.value.on('change', event => {
      if (event.complete && newBillingEmail.value) {
        canSaveAddress.value = true;
      }
    });
  }
};

const priorityErrors = usePriorityErrors(
  createAddressError,
  updateAddressError,
  updateAccountErrors
);

watchEffect(() => {
  if (accountIsLoaded.value && account.value) {
    billingAddress.value = account.value.billing_address || {};
    newCompanyName.value = account.value.name || '';
    newBillingEmail.value = account.value.billing_email;
    if (isEditing.value) {
      mountAddressElement();
    }
  }
  if (updateAccountErrors.value) {
    newCompanyName.value = account.value.name || '';
    isEditing.value = false;
  }
});

watchEffect(() => {
  // Start with isEditing as true if there is no billing address
  if (accountIsLoaded.value && !billingAddressId.value) {
    isEditing.value = isActiveStep.value ?? true;
  }
});
</script>

<template>
  <div
    class="billing-info"
    :class="{
      'billing-info--borderless-mobile': borderlessMobile,
    }"
  >
    <SoonaError v-if="priorityErrors" class="feedback-dialog__error" />
    <div class="billing-info__header">
      <h3 class="u-headline--heavy is-lowercase">Billing Info</h3>
      <p v-if="isEditing" class="u-label--regular">required</p>
    </div>
    <div class="field-wrapper">
      <div v-show="isEditing" class="billing-info__edit-address">
        <SoonaTextfield
          v-model="newBillingEmail"
          class="billing-info__input"
          label="Billing email"
          name="billingEmail"
          :rules="['email']"
          type="email"
        />
        <form id="address-form">
          <div id="address-element"></div>
        </form>
        <p class="u-label--regular">
          This information is used to calculate sales tax.
        </p>
      </div>
      <div v-show="!isEditing" class="billing-info__address-container">
        <p class="u-body--regular">{{ newBillingEmail }}</p>
        <p class="u-body--regular">
          {{ billingAddress?.address_1 }}
        </p>
        <p v-if="billingAddress?.address_2" class="u-body--regular">
          {{ billingAddress?.address_2 }}
        </p>
        <p class="u-body--regular">
          {{ toCityStateZip(billingAddress) }}
        </p>
      </div>
    </div>
    <Capability
      class="billing-info__capability"
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account?.id"
    >
      <a
        v-if="showEdit"
        class="billing-info__edit is-lowercase"
        :class="{
          'billing-info__edit--borderless-mobile': borderlessMobile,
        }"
        @click="toggleEdit"
      >
        Edit
      </a>
      <template v-else-if="isEditing">
        <SoonaButton
          v-if="!firstTimePayment"
          class="billing-info__cancel-btn"
          size="medium"
          variation="tertiary"
          :disabled="!billingAddressId"
          @on-click="cancel"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          variation="primary"
          :size="firstTimePayment ? 'large' : 'medium'"
          :disabled="isPending || !canSaveAddress"
          :class="{
            'billing-info__save-btn--wide': firstTimePayment,
          }"
          @on-click="save"
        >
          {{ firstTimePayment ? 'save billing info' : 'save' }}
        </SoonaButton>
      </template>
    </Capability>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.billing-info {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 0.0625rem solid variables.$gray-30;
  background: variables.$periwink-blue-10;
  padding: 1.25rem 1.5rem;

  &--borderless-mobile {
    border: none;
    padding: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 0.75rem;
  }

  &__edit-address {
    margin-bottom: 0.5rem;
  }

  &__input {
    padding-bottom: 0.75rem;
  }

  &__address-container {
    display: flex;
    flex-direction: column;
  }

  &__address-preview {
    @include variables_fonts.u-body--regular;
  }

  &__edit {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    @include variables_fonts.u-body--regular;
    text-decoration: underline;

    &--borderless-mobile {
      right: 0;
      top: 0;
    }
  }

  &__save-btn--wide {
    width: stretch;
  }

  &__cancel-btn:disabled {
    background-color: transparent !important;
    border: none !important;
  }

  &__capability {
    display: flex;
    justify-content: space-between;
  }
}

#address-form {
  margin-bottom: 0.5rem;
}

@media (min-width: variables.$screen-sm-min) {
  .billing-info {
    &--borderless-mobile {
      border: 0.0625rem solid variables.$gray-30;
      padding: 1.25rem 1.5rem;
    }
    &__edit {
      &--borderless-mobile {
        right: 1rem;
        top: 1.5rem;
      }
    }
  }
}
</style>
