<script setup>
import { ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const emit = defineEmits(['fetch-new-asset']);

const promptInput = ref(false);
const imageIdInput = ref('');

const fetchNewAsset = () => {
  emit('fetch-new-asset', imageIdInput.value);
  promptInput.value = false;
  imageIdInput.value = '';
};

const handleKeyEscape = () => {
  promptInput.value = false;
  imageIdInput.value = '';
};
</script>

<template>
  <div class="new-asset">
    <SoonaTextfield
      v-if="promptInput"
      v-model="imageIdInput"
      placeholder="digital asset id"
      class="new-asset__input"
      autofocus
      @keydown.enter="fetchNewAsset"
      @keydown.escape="handleKeyEscape"
    />
    <SoonaButton v-else variation="icon-plain-gray" @click="promptInput = true">
      <SoonaIcon name="circle-plus" />
    </SoonaButton>
  </div>
</template>

<style scoped lang="scss">
.new-asset {
  border: 0.0625rem dashed #000;
  border-radius: 0.625rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &__input {
    padding: 1rem;
  }
}
</style>
