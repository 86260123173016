<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import Autolinker from 'autolinker';
import { useCopy } from 'src/composables/useCopy';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import { useCapability } from '@/composables/useCapability';
import SoonaTelephoneDisplay from '@/components/ui_library/SoonaTelephoneDisplay.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { FriendlyRed50, Gray70, WhiteDefault } from '@/variables.module.scss';
import { useAccount } from '@/composables/useAccount';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import AccountInfoFlags from '@/components/user/anytime/dashboard/AccountInfoFlags.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useMe } from '@/composables/user/useMe';
import AccountInfoLinks from './AccountInfoLinks.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  account: {
    type: Object,
    required: true,
  },
  linkToProfile: {
    type: Boolean,
    default: false,
  },
  hidePii: {
    type: Boolean,
    default: false,
  },
});

const account = computed(() => props.account);
const accountId = computed(() => account.value?.id);

const { currentAccountId } = useMe();

const { hasSoonaStorage, isTaxExempt } = useAccount(accountId);

const { subscriptionShortName, subscriptionTier } =
  useActiveSubscription(accountId);
const preferredCredits = computed(
  () => account.value?.preferred_credit_count ?? 0
);

const avatarLoading = ref(false);

const store = useStore();
const isMyAccount = computed(() => currentAccountId.value === account.value.id);

const { copyText, copySuccess } = useCopy();

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const authorized = computed(() => {
  return isMyAccount.value || isFtSoonaStaff.value;
});

const getEmailUsername = computed(() => {
  if (account.value.email) {
    let emailUsername = account.value.owner.email.split('@');
    return emailUsername[0];
  } else {
    return undefined;
  }
});

const getEmailDomain = computed(() => {
  if (account.value.email) {
    let website = account.value.owner.email.split('@');
    return website[1];
  } else {
    return undefined;
  }
});

const getWebsite = computed(() => {
  let ws =
    account.value.profile_data?.lets_get_the_basics?.['data']?.['website'];
  if (!ws) return;
  return Autolinker.link(ws, {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'account-info-ellipsis',
  });
});

async function handleAvatarUploadComplete(blob) {
  avatarLoading.value = true;
  await store.dispatch('account/createAvatarFromUpload', {
    accountId: account.value.id,
    blob: blob,
  });
  avatarLoading.value = false;
}
</script>

<template>
  <div class="account-info drop-shadow-container" v-bind="$attrs">
    <div class="user-info-container">
      <div class="avatar-item">
        <SoonaFlag
          v-if="isSoonaStaff && account.vip"
          :background-color="FriendlyRed50"
          :text-color="WhiteDefault"
          title="vip"
          class="vip-badge"
        />
        <SoonaAvatar
          :name="account.name"
          size="5rem"
          :src="account.avatar_url"
          type="account"
        />
        <div v-if="authorized" class="avatar-overlay">
          <SoonaUpload
            class="avatar-uploader"
            class-overide="u-headline--regular is-marginless"
            :upload-complete="handleAvatarUploadComplete"
            :hide-label="true"
            :hide-icon="false"
            :camera-icon="true"
            :icon-size-overide="true"
            :is-multiple="false"
            accept=".jpeg, .jpg, .png, .gif"
          />
        </div>
        <beat-loader
          v-if="avatarLoading"
          color="#FFFFFF"
          size="10px"
          class="avatar-loader"
        />
      </div>
      <div class="text-item-container">
        <div class="text-item-container__top">
          <template v-if="!hidePii">
            <SoonaLink
              v-if="linkToProfile"
              :to="{ path: `/account/${account.id}/gallery` }"
              class="u-body--heavy"
              data-cypress="profile-account-name"
            >
              {{ account.name }}
            </SoonaLink>
            <h2
              v-else
              class="u-body--heavy"
              data-cypress="profile-account-name"
            >
              {{ account.name }}
            </h2>
            <p
              class="account-link account-info-ellipsis-container u-small--regular"
              v-html="getWebsite"
            />
            <p
              v-if="isSoonaStaff"
              class="email-copy account-info-ellipsis-container"
            >
              <span class="u-small--regular account-info-ellipsis">
                {{ getEmailUsername }}@<a
                  :href="`https://${getEmailDomain}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ getEmailDomain }}</a
                >
              </span>
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @on-click="copyText(account.email)"
              >
                <SoonaIcon name="duplicate" size="small" />
                <span class="u-visually-hidden">copy email</span>
              </SoonaButton>
              <span
                v-if="copySuccess"
                class="copied u-small--heavy"
                role="status"
              >
                copied!
              </span>
            </p>
            <p
              v-else
              class="u-small--regular email-copy account-info-ellipsis-container"
            >
              <span class="account-info-ellipsis">
                {{ account.owner.email }}
              </span>
            </p>
            <p class="u-small--regular phone-copy">
              <SoonaTelephoneDisplay :phone-number="account.owner.phone" />
            </p>
          </template>
          <h2 v-else class="u-body--heavy">{{ getEmailDomain }}</h2>
        </div>
        <AccountInfoFlags
          v-if="isSoonaStaff"
          :account-id="account.id"
          :account-flags="account.flags"
        />
        <SoonaFlag
          v-if="isSoonaStaff && isTaxExempt"
          key="tax-exempt"
          title="tax exempt"
          :text-color="WhiteDefault"
          :background-color="Gray70"
        />
        <AccountInfoLinks :account="account" :hide-pii="hidePii" />
      </div>
    </div>
    <div
      v-if="isSoonaStaff && (subscriptionShortName || preferredCredits > 0)"
      class="subscription-credits"
      :data-tier-slug="subscriptionTier?.slug"
    >
      <p v-if="hasSoonaStorage" class="account-tier u-badge--small">
        <SoonaIcon name="crown-duotone" size="x-small" />
        fast pass
      </p>
      <p
        v-if="subscriptionShortName !== 'fast pass'"
        class="account-tier u-badge--small"
      >
        <SoonaIcon
          :name="subscriptionTier?.icon_name ?? 'crown-duotone'"
          size="x-small"
        />
        {{ subscriptionShortName }}
      </p>
      <p v-if="preferredCredits > 0" class="credits-wrapper">
        <span class="u-headline--heavy credits-total">
          {{ preferredCredits }}
        </span>
        <span class="u-badge--small credits-text">credits</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.account-info {
  width: 100%;
  position: relative;
  container: account-info / inline-size;

  .copy {
    color: variables.$gray-20;
    cursor: pointer;
    margin-left: 0.25rem;
  }

  .copied {
    color: variables.$periwink-blue-50;
  }

  .account-link {
    /* to support text overflow ellipsis */
    display: flex;

    :deep(a) {
      color: variables.$periwink-blue-50;
    }
  }

  .email-copy {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    color: variables.$gray-60;

    a {
      text-decoration: none;
      color: variables.$gray-60;
    }
  }

  /* account for autolinker */
  :deep(.account-info-ellipsis) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .phone-copy {
    color: variables.$gray-60;
  }

  .user-info-container {
    display: flex;
    column-gap: 1rem;
    row-gap: 0.5rem;
    /* allow wrapping as a fallback for no container query support */
    flex-wrap: wrap;
    align-items: flex-start;

    .vip-badge {
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
    }

    .avatar-item {
      width: 5rem;
      position: relative;
      border-radius: 100rem;

      .avatar-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        color: variables.$white-default;
        border-radius: 100rem;
        overflow: hidden;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        @supports not (aspect-ratio: 1) {
          &::before {
            float: left;
            padding-top: 100%;
            content: '';
          }

          &::after {
            display: block;
            content: '';
            clear: both;
          }
        }

        & .avatar-uploader {
          width: 100%;
          height: 100%;
        }

        & :deep(.file-label) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover .avatar-overlay {
        display: flex;
      }

      .avatar-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text-item-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.5rem;
      /* to allow for text overflow ellipsis */
      min-width: 0;

      &__top {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .subscription-credits {
    position: absolute;
    top: 0;
    right: 0;
    background-color: variables.$periwink-blue-60;
    color: variables.$black-default;
    border-radius: 0 0.5625rem 0 0.625rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    align-items: center;

    &[data-tier-slug='tier-one'] {
      background-color: variables.$periwink-blue-20;
    }
    &[data-tier-slug='tier-two'] {
      background-color: variables.$bubbletape-pink-10;
    }
    &[data-tier-slug='tier-three'] {
      background-color: variables.$tangerine-30;
    }
    &[data-tier-slug='fast-pass'] {
      background: variables.$green-apple-10;
    }

    .account-tier {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      svg {
        height: 0.75rem;
        width: 0.75rem;
      }
    }

    .credits-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .credits-total {
        margin-bottom: -0.375rem;
      }

      .credits-text {
        color: variables.$white-translucent-80;
      }
    }
  }
}

/*
 * using max-width here so that the larger view with a flexbox wrap will work
 * as a fallback
 */
@container account-info (max-width: 30rem) {
  .account-info {
    .user-info-container {
      flex-direction: column;
      align-items: center;
    }

    .text-item-container,
    .text-item-container__top {
      align-items: center;
    }
  }

  .account-info-ellipsis-container {
    max-width: 100cqi;
  }
}
</style>
