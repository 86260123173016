import { computed, readonly, ref, toValue, watch } from 'vue';

/**
 * @callback assetMapFnCb
 * @description A function that maps the raw data to an asset that's used for the selection state.
 * For example, if we loop over cdas, this function could be
 * `cda => cda.digital_asset`, but if we are looping over digital assets, this
 * function would be `da => da`.
 * @param {Object} asset
 * @returns {Object | null | undefined} the digital asset
 */

/**
 * @description A generic composable to handle bulk selection of assets. we
 * have an `assetMapFn` here so that the selected assets can be nested data,
 * for example a digital asset inside a collection digital asset. This function
 * removes the need to remember what the heck is going on, and all the
 * selection change, and status will be handled consistently with the map fn.
 *
 * @param {Ref<Object | undefined>} rawData - raw data from an infinite query
 * @param {assetMapFnCb} assetMapFn - map function to access the digital asset
 * @returns {{
 *   onSelectionClick: onSelectionClickFn,
 *   resetSelection: resetSelectionFn,
 *   selectedAssets: import('vue').DeepReadonly<Ref<*[]>>,
 *   selectedAssetIdSet: import('vue').ComputedRef<Set<number>>
 * }}
 */
export function useBulkSelection(rawData, assetMapFn) {
  const selectedAssets = ref([]);
  const lastSelectedId = ref(null);

  watch(selectedAssets, () => {
    if (selectedAssets.value.length === 0) {
      lastSelectedId.value = null;
    }
  });

  /**
   * @callback resetSelectionFn
   * @returns {void}
   */
  function resetSelection() {
    selectedAssets.value = [];
    lastSelectedId.value = null;
  }

  /**
   *
   * @callback onSelectionClickFn
   * @param {Object} asset
   * @param {MouseEvent | undefined} event
   * @returns {void}
   */
  function onSelectionClick(asset, event) {
    const currentSelection = selectedAssets.value;
    const assetIsSelected = event?.currentTarget.checked;

    const mappedAsset = assetMapFn(asset);

    if (!event?.shiftKey) {
      selectedAssets.value = assetIsSelected
        ? currentSelection.concat(mappedAsset)
        : currentSelection.filter(a => a.id !== mappedAsset.id);
    } else {
      const clientData =
        toValue(rawData)?.pages?.flatMap(page => page.assets) ?? [];
      const clientDataDAIds = clientData.reduce((acc, a) => {
        const id = assetMapFn(a)?.id;
        if (Number.isInteger(id)) {
          acc.push(id);
        }
        return acc;
      }, []);

      const selectedIndex = clientDataDAIds.findIndex(
        a => a === mappedAsset.id
      );
      const _lastSelectedIndex = clientDataDAIds.findIndex(
        a => a === lastSelectedId.value
      );
      // fall back to single selection if lastSelectedId is not found
      const lastSelectedIndex =
        _lastSelectedIndex === -1 ? selectedIndex : _lastSelectedIndex;
      const start = Math.min(selectedIndex, lastSelectedIndex);
      const end = Math.max(selectedIndex, lastSelectedIndex);

      const newSelection = new Set(clientDataDAIds.slice(start, end + 1));
      const existingSelection = new Set(currentSelection.map(a => a.id));

      // all hail Set operations
      const newFinalSelection = assetIsSelected
        ? // keep and add both selections
          existingSelection.union(newSelection)
        : // remove the new selection from the existing
          existingSelection.difference(newSelection);

      selectedAssets.value = clientData.reduce((acc, a) => {
        const da = assetMapFn(a);
        if (newFinalSelection.has(da?.id)) {
          acc.push(da);
        }
        return acc;
      }, []);
    }

    lastSelectedId.value = mappedAsset.id;
  }

  const selectedAssetIdSet = computed(
    () => new Set(selectedAssets.value.map(a => a.id))
  );

  function isAssetSelected(asset) {
    return selectedAssetIdSet.value.has(assetMapFn(asset)?.id);
  }

  return {
    onSelectionClick,
    resetSelection,
    isAssetSelected,
    selectedAssets: readonly(selectedAssets),
  };
}
