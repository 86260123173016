<script setup>
import { computed, ref, watch, watchEffect, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useBag } from 'src/queries/bag/useBag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import AssetSection from 'src/components/checkout/AssetSection.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import LineItemSummaryPayment from '@/components/checkout/LineItemSummaryPayment.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useElementBounding, useTitle } from '@vueuse/core';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import { triggerContentCheckoutStartedEvent } from '@/api/accounts';
import SelectAllAssets from '@/components/checkout/SelectAllAssets.vue';
import BulkSelectActionBar from '@/components/checkout/BulkSelectActionBar.vue';
import { queryKeys } from '@/queries/query-keys';
import { useIntegrations } from '@/composables/useIntegrations';
import { usePayAddOnOrder } from '@/queries/checkouts/usePayAddOnOrder';
import { keepPreviousData, useQueryClient } from '@tanstack/vue-query';
import { useFlag } from '@/composables/useFlag';
import ShopMore from '@/components/account_gallery/ShopMore.vue';
import BillingInfo from '@/components/checkout/BillingInfo.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from 'src/composables/useAccount';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { storeToRefs } from 'pinia';
import CheckoutPromoCredits from '@/components/checkout/CheckoutPromoCredits.vue';
import { useCheckoutPromoCredits } from '@/components/checkout/useCheckoutPromoCredits';
import QuestHelper from '@/components/quest/QuestHelper.vue';

const { linkClicked } = useBaseEvents();
import { useRoute } from 'vue-router';

var _learnq = window._learnq || [];

const router = useRouter();
const store = useStore();
const salesTaxStore = useSalesTaxStore();
const queryClient = useQueryClient();

const currentPage = ref(1);
const itemsPerPage = ref(30);
const activeSubscriptionPromo = ref('');
const route = useRoute();

const apolloBagCheckoutTrialOptInFlag = useFlag(
  'apollo_bag_checkout_trial_opt_in'
);

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const { currentAccountId } = useMe();
const { eligibleForPlatformSubscriptionTrial } = useAccount(currentAccountId);

const {
  data: bagData,
  isLoading,
  isFetching,
  isSuccess,
} = useBag(
  currentAccountId,
  {
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!currentAccountId.value),
  }
);

const bag = computed(() => bagData.value?.data?.line_items || []);
const paymentMethod = ref(null);

const bagSummary = computed(() => bagData.value?.data?.bag_summary);
const bagTotals = computed(() => {
  return {
    subTotal: bagData.value?.data?.sub_total,
    discountTotal: bagData.value?.data?.discount_total,
    total: bagData.value?.data?.total,
  };
});

const bagPagination = computed(() => {
  if (bagData.value) {
    return bagData.value.pagination;
  } else {
    return {
      currentPage: 0,
      itemsPerPage: 0,
      totalCount: 0,
      totalPages: 0,
    };
  }
});

const trackLinkClicked = linkLabel => {
  linkClicked({
    context: route.meta.context,
    subContext: 'checkout promo credits',
    linkLabel: linkLabel,
    linkHref: `/account/${currentAccountId.value}/gallery/shop-more`,
  });
};

const bagCount = computed(() => bagPagination.value.totalCount);

const {
  enabled: checkoutPromoCreditsEnabled,
  addAssetsBtnCopy,
  addAssetsBtnVariation,
  meterValue,
  preferredCredits,
} = useCheckoutPromoCredits(currentAccountId);

const showCheckoutPromoCredits = computed(
  () => checkoutPromoCreditsEnabled.value && isSuccess.value
);

const { hasShopifyIntegration } = useIntegrations(currentAccountId);
const { mutateAsync: payAddOnOrder } = usePayAddOnOrder(
  currentAccountId,
  paymentMethod
);

function createAddOnOrders() {
  return store.dispatch('order/createAddOnOrders');
}

function createShopifyOrders() {
  return store.dispatch('order/createShopifyOrders');
}

const isProcessing = ref(false);

const hasContents = computed(() => bag.value.length > 0);

watchEffect(() => {
  if (hasContents.value) {
    let item = {
      $value: bagData.value.data.total,
      checkout_url: window.location.href,
      order: bagData.value.data,
      booking_id: bag.value[0]?.reservation_id,
    };
    _learnq.push(['track', 'Started Checkout', item]);
  }
});

async function processAddOnOrder(
  payment_method_id,
  payment_method_type,
  save_payment_method
) {
  isProcessing.value = true;
  try {
    if (hasShopifyIntegration.value) {
      await createShopifyOrders();
    } else {
      await createAddOnOrders();
      const payOrdersResponse = await payAddOnOrder({
        payment_method_id: payment_method_id,
        payment_method_type: payment_method_type,
        save_payment_method: save_payment_method,
        ...(activeSubscriptionPromo.value && {
          [activeSubscriptionPromo.value]: true,
        }),
      });
      const thanksRoute = { name: 'Thanks' };
      if (payOrdersResponse?.orders?.length === 1) {
        thanksRoute.query = { order_id: payOrdersResponse.orders[0].id };
      }
      await router.push(thanksRoute);
    }
  } finally {
    isProcessing.value = false;
  }
}

watch(currentPage, () =>
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
);
watch(itemsPerPage, () => (currentPage.value = 1));

useTitle('content checkout | soona');

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
  triggerContentCheckoutStartedEvent(currentAccountId.value);
});

// bulk select
const photoIds = computed(() => bagSummary.value?.photo_ids || []);
const selectedAssets = ref([]);
const allSelected = computed(() => {
  return selectedAssets.value?.length === photoIds.value.length;
});

watch(photoIds, newIds => {
  selectedAssets.value = selectedAssets.value.filter(id => newIds.includes(id));
});

const handleSelectAll = () => {
  if (allSelected.value) {
    selectedAssets.value = [];
  } else {
    selectedAssets.value = [...photoIds.value];
  }
};

const handleSelected = (checked, item) => {
  if (checked) {
    selectedAssets.value?.push(item.id);
  } else {
    selectedAssets.value = selectedAssets.value?.filter(i => i !== item.id);
  }
};

const handleAddOnsSaved = async () => {
  await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
  selectedAssets.value = [];
};

const canViewTrialOptInBanner = computed(() => {
  return (
    apolloBagCheckoutTrialOptInFlag.value &&
    eligibleForPlatformSubscriptionTrial.value &&
    !hasShopifyIntegration.value
  );
});

const updateActiveSubscriptionPromo = value => {
  activeSubscriptionPromo.value = value;
};

const checkoutWrapper = ref(null);

const { left, width } = useElementBounding(checkoutWrapper);

const { isCalculatingSalesTax, totalWithSurchargeInDollars } =
  storeToRefs(salesTaxStore);

const total = computed(() => {
  return totalWithSurchargeInDollars.value;
});

onUnmounted(() => {
  salesTaxStore.$reset();
});
</script>

<template>
  <div ref="checkoutWrapper" class="checkout">
    <SoonaLoading
      v-if="isLoading || isFetching || isProcessing"
      :is-loading="isLoading || isFetching || isProcessing"
      loading-text=""
    />
    <SoonaButton
      class="checkout__back"
      variation="tertiary"
      element="button"
      @click="
        () => {
          router.go(-1);
        }
      "
    >
      <SoonaIcon name="arrow-left" class="checkout__back-icon" />
      back
    </SoonaButton>
    <div class="checkout__header">
      <h1 class="checkout__title">
        checkout
        <em v-if="isSuccess">
          ({{ bagCount }} asset{{ bagCount === 1 ? '' : 's' }})
        </em>
      </h1>
      <QuestHelper v-if="showCheckoutPromoCredits" :hide="meterValue !== 1">
        <SoonaButton
          class="checkout__add-asset-btn"
          :variation="addAssetsBtnVariation"
          element="router-link"
          :to="`/account/${currentAccountId}/gallery/shop-more`"
          @on-click="() => trackLinkClicked(addAssetsBtnCopy)"
        >
          {{ addAssetsBtnCopy }}
        </SoonaButton>
      </QuestHelper>
    </div>
    <CheckoutPromoCredits
      v-if="showCheckoutPromoCredits"
      :account-id="currentAccountId"
    />
    <SoonaNoResults
      v-if="isSuccess && bag.length === 0"
      class="checkout__no-assets"
    >
      <template #header>your bag is empty</template>
    </SoonaNoResults>
    <template v-else-if="isSuccess">
      <div class="checkout__content">
        <LineItemSummaryPayment
          v-if="hasContents"
          class="checkout__summary-payment"
          :account-id="currentAccountId"
          :cart-totals="bagTotals"
          :cart-summary="bagSummary"
          :preferred-credits="preferredCredits"
          :can-view-trial-opt-in-banner="canViewTrialOptInBanner"
          @update:active-promo="updateActiveSubscriptionPromo"
        >
          <template #billing>
            <BillingInfo
              class="checkout__billing-info"
              :account-id="currentAccountId"
              borderless-mobile
            />
          </template>
          <template #payment>
            <SoonaPaymentMethods
              :stripe-payment-required="!hasShopifyIntegration && total > 0"
              :account-id="currentAccountId"
              :disable-confirm-button="isCalculatingSalesTax"
              :total="total"
              :payment-method-types="['card', 'us_bank_account']"
              :on-payment-action="processAddOnOrder"
              action-text="place order"
            />
          </template>
          <template #terms>
            <small class="checkout__terms">
              our crew commitment: we believe in quality content for all. if you
              don’t LOVE your content <b>we make it right</b>. please review our
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.soona.co/refund-policy"
                >cancellation and refund policy</a
              >. by placing an order with soona you agree to our
              <a href="https://soona.co/terms/" target="_blank"
                >terms of service</a
              >

              ({{ termsOfServiceDate }}).
            </small>
          </template>
        </LineItemSummaryPayment>
        <div class="checkout__assets">
          <SelectAllAssets
            v-if="photoIds.length > 0"
            :all-selected="allSelected"
            @toggle-selection="handleSelectAll"
          >
          </SelectAllAssets>
          <PaginatorFull
            v-model:page="currentPage"
            v-model:records-per-page="itemsPerPage"
            class="checkout__pagination"
            :has-full-width-nav="true"
            :hide-change-records-per-page="true"
            :page-count="bag.length"
            :total-count="bagCount"
            :total-pages="bagPagination?.totalPages"
          />
          <AssetSection
            :account-id="currentAccountId"
            :line-items="bag"
            :all-selected="allSelected"
            :selected-assets="selectedAssets"
            @asset-selected="handleSelected"
          />
          <PaginatorFull
            v-model:page="currentPage"
            v-model:records-per-page="itemsPerPage"
            class="checkout__pagination"
            :has-full-width-nav="true"
            :hide-change-records-per-page="true"
            :page-count="bag.length"
            :total-count="bagCount"
            :total-pages="bagPagination?.totalPages"
          />
        </div>
      </div>
      <ShopMore class="checkout__shop-more" :account-id="currentAccountId">
        <template #title>did you miss any?</template>
      </ShopMore>
      <BulkSelectActionBar
        :account-id="currentAccountId"
        :selected-assets="selectedAssets"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        @cancel-multi-select="() => (selectedAssets = [])"
        @add-ons-saved="() => handleAddOnsSaved()"
      />
    </template>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.checkout {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__add_asset_btn {
    padding: 0;
  }

  &__back {
    display: inline-flex;
    justify-content: flex-start;
    text-decoration: none;
    width: max-content;

    &-icon {
      transition: transform 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      color: variables.$black-default !important;

      .checkout__back-icon {
        transform: translateX(-0.1875rem);
      }
    }
  }

  &__terms {
    @include variables_fonts.u-small--regular;

    display: block;

    b {
      font-weight: 800;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: variables.$black-default;
      }
    }

    .booking-downpayment__layout--h4a &,
    .downpayment__layout--h4a & {
      text-align: center;
    }
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.75rem;

    em {
      @include variables_fonts.u-label--regular;

      font-style: normal;
    }
  }

  &__pagination {
    margin: 2.25rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__no-assets {
    flex-grow: 1;
    justify-content: center;
  }

  &__shop-more {
    margin-top: 4.5rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    gap: 1.75rem;

    &__title {
      @include variables_fonts.u-display--heavy;

      gap: 1rem;

      em {
        @include variables_fonts.u-title--regular;
      }
    }

    &__content {
      display: flex;
      align-items: flex-start;
      gap: 2rem;
    }

    &__assets {
      flex: 1 1 50%;
    }

    :deep(.checkout__summary-payment) {
      order: 1;
      flex: 1 1 50%;
      position: sticky;
      top: calc(var(--app-header-viewport-offset, 5.125rem) + 1.75rem);
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    &__assets {
      flex-basis: 60%;
    }

    :deep(.checkout__summary-payment) {
      flex-basis: 40%;
    }
  }
}
</style>
