<script setup>
import { computed } from 'vue';

import { useReservation } from '@/queries/useReservation';
import { useStore } from 'vuex';

import ReservationChat from 'src/components/chat/ReservationChat.vue';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  hideChat: {
    default: false,
    type: Boolean,
  },
  isChatOpen: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['update:isChatOpen']);

const hideChat = computed(() => props.hideChat);
const isChatOpen = computed(() => props.isChatOpen);
const reservationId = computed(() => props.reservationId);

const store = useStore();

const currentUser = computed(() => store.state.currentUser);

// use things
const {
  data: reservation,
  isLoading: reservationLoading,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: crewAssignments,
  isLoading: crewAssignmentsLoading,
  error: crewAssignmentsError,
} = useGetCrewAssignments(
  reservationId,
  {
    without_declined: true,
  },
  { enabled: computed(() => !!reservationId.value) }
);

const reservationUserIds = computed(() => {
  const assignedCrew = crewAssignments.value?.map(c => c.user_id) ?? [];
  const reservationAccountUsers =
    reservation.value?.account?.users?.map(u => u.id) ?? [];
  const locationUserId = reservation.value?.location?.user_id;

  return [locationUserId, ...assignedCrew, ...reservationAccountUsers];
});

const isProServiceUser = computed(() => {
  return currentUser.value.employee_type_department === 'pro_services';
});

const reservationAccountIsUsers = computed(() => {
  return currentUser.value.accountId === reservation.value.account_id;
});

const dataLoading = computed(() => {
  return reservationLoading.value || crewAssignmentsLoading.value;
});

const dataError = computed(() => {
  return reservationError.value || crewAssignmentsError.value;
});

const showChat = computed(() => {
  if (dataLoading.value || dataError.value || hideChat.value) {
    return false;
  }
  return (
    currentUser.value.userID &&
    // ensure chat isn't mounted when a pro service user or non reservation account user
    (!isProServiceUser.value || reservationAccountIsUsers.value)
  );
});

const priorityError = usePriorityError(reservationError, crewAssignmentsError);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaLoading
    v-if="dataLoading"
    :is-contained="true"
    is-loading
    loading-text="loading…"
  />
  <div class="media-chat">
    <ReservationChat
      v-if="showChat"
      :is-chat-open="isChatOpen"
      :current-user-id="currentUser.userID"
      :is-media-view="true"
      :reservation-cal-uuid="reservation.cal_uuid"
      :reservation-id="reservationId"
      :reservation-status="reservation.status"
      :reservation-user-ids="reservationUserIds"
      @update:is-chat-open="bool => emit('update:isChatOpen', bool)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.media-chat {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;

  :deep(.chat-button) {
    @include variables_fonts.u-badge--big;

    background-color: variables.$periwink-blue-50;
    border: none;
    border-radius: 0;
    border-top-left-radius: 0.625rem;
    bottom: 0;
    color: variables.$white-default;
    cursor: pointer;
    height: max-content;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 0;
    transition: 0.2s;
    width: max-content;

    &:hover,
    &:focus-visible {
      background-color: variables.$periwink-blue-60;
    }

    &:active {
      background-color: variables.$periwink-blue-50;
    }

    span {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 0.25rem;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  :deep(.modal-card) {
    border: none;
    border-left: 0.0625rem solid variables.$gray-30;
    border-radius: 0;
    height: 100% !important;
    min-height: 14rem;
    animation: 0.25s ease-out both k-fade-in;
  }

  :deep(.modal-card-head) {
    border-radius: 0;
  }

  :deep(.modal-card-body) {
    height: auto;
  }

  @media (min-width: 45.0625rem) {
    :deep(.modal-card) {
      width: 18.75rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    :deep(.modal-card) {
      // matches clamp values in MediaChat.vue
      width: clamp(14.25rem, 23.2vw, 23.4375rem);
    }
  }
}
</style>
