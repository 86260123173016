const baseFilter = {
  asset_type: 'original',
  origin: 'soona',
  ownership: 'soona',
  visibility: 'all',
};

export const filterLookup = {
  all: { ...baseFilter },
  edits: {
    ...baseFilter,
    ownership: 'customer',
    asset_type: 'purchased',
  },
  expert_picks: {
    ...baseFilter,
    collection_type: 'StaffPicksCollection',
  },
  favorites: {
    ...baseFilter,
    collection_type: 'FavoritesCollection',
  },
  hidden: { ...baseFilter, visibility: 'crew' },
  in_bag: { ...baseFilter, collection_type: 'BagCollection' },
  raws_no_preview: {
    ...baseFilter,
    independent_raws_only: true,
    visibility: 'crew',
  },
  archived: {
    ...baseFilter,
    asset_type: ['original', 'purchased'],
    ownership: 'customer',
    only_archived: true,
  },
  edits_with_web: {
    ...baseFilter,
    asset_type: 'purchased',
    media_type: ['animation', 'photo'],
    ownership: 'customer',
  },
};
