import { reactive, readonly } from 'vue';

export const useBatchEditsComponents = () => {
  const components = reactive(new Map());

  /**
   * @param {Symbol} symbol
   * @param handlers
   * @description register a component with the batch edits store
   * handlers should provide the following functions
   * - handleSubmit - save the current batch edits
   */
  const registerComponent = (symbol, handlers) => {
    if (typeof handlers.handleSubmit !== 'function') {
      console.warn(`${symbol.toString()} is missing handleSubmit`);
    }
    components.set(symbol, handlers);
  };

  const unregisterComponent = symbol => {
    components.delete(symbol);
  };

  const unregisterAllComponents = () => {
    components.clear();
  };

  return {
    components: readonly(components),
    registerComponent,
    unregisterComponent,
    unregisterAllComponents,
  };
};
