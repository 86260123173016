import { computed } from 'vue';
import { useStore } from 'vuex';

export function useProServiceFormData({ productsList, serviceType }) {
  const store = useStore();

  const manicureProducts = [...productsList.manicure];
  const pedicureProducts = [...productsList.pedicure];
  const hairCareProducts = [...productsList.hair];

  const sortAndSetupOptions = (
    options,
    firstOnly = false,
    useProductName = true
  ) => {
    options = options.sort((a, b) => parseInt(a.rate) - parseInt(b.rate));

    if (firstOnly) options = [options[0]];

    return options.map(product => ({
      label: `$${parseInt(product.rate)} ${product.name}`,
      value: useProductName ? `${product.name}` : 'yes',
      id: product.id,
      rate: product.rate,
      tax_code: product.tax_code,
    }));
  };

  const wardrobeOptionsPerson = [
    { label: 'standard wardrobe is great', value: 'standard wardrobe' },
    { label: "I'll send wardrobe for model", value: 'custom wardrobe' },
  ];

  const wardrobeOptionsPet = [
    { label: 'no', value: 'standard wardrobe' },
    { label: 'yes', value: 'custom wardrobe' },
  ];

  const wardrobeSelectLabel = computed(() =>
    serviceType == 'pet model'
      ? 'will you be sending clothes for this pet model?'
      : 'select wardrobe preference'
  );

  const wardrobeSelectSubtext = computed(() =>
    serviceType == 'pet model'
      ? undefined
      : 'models will arrive with standard wardrobe pieces unless otherwise indicated'
  );

  const hmuOptionsAlreadyPurchased = [
    { label: 'no - not this model', value: 'no' },
    { label: 'yes - this model', value: 'yes' },
  ];

  const shavedLegsOptions = [
    { label: 'yes', value: 'yes' },
    { label: 'no', value: 'no' },
    { label: 'no preference', value: 'no preference' },
  ];

  const pressOnColorOptions = computed(
    () => store.state.proService.pressOnColorOptions
  );
  const manicureOptions = computed(() => {
    let options = [{ label: 'clean bare nails', value: 'bare' }];

    if (manicureProducts) {
      options.push(...sortAndSetupOptions(manicureProducts));
    }

    return options;
  });

  const pedicureOptions = computed(() => {
    const options = [{ label: 'clean bare nails', value: 'bare' }];

    if (pedicureProducts) {
      options.push(...sortAndSetupOptions(pedicureProducts));
    }

    return options;
  });

  const hmuOptionsNotPurchased = computed(() => {
    const options = [{ label: 'no - I don’t need hair & makeup', value: 'no' }];

    if (hairCareProducts) {
      options.push(...sortAndSetupOptions(hairCareProducts, true, false));
    }

    return options;
  });

  const wardrobeOptions = computed(() =>
    serviceType == 'pet model' ? wardrobeOptionsPet : wardrobeOptionsPerson
  );

  const hmuOptions = computed(() =>
    store.getters['reservation/hasHMUService']
      ? hmuOptionsAlreadyPurchased
      : hmuOptionsNotPurchased.value
  );

  return {
    manicureOptions,
    pedicureOptions,
    pressOnColorOptions,
    shavedLegsOptions,
    wardrobeOptions,
    wardrobeSelectLabel,
    wardrobeSelectSubtext,
    hmuOptions,
  };
}
