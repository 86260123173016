<script setup>
import { useRouter } from 'vue-router';
import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { storeToRefs } from 'pinia';

import { useAiProducer } from './Composables/useAiProducer';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAssistantStore } from '@/components/ai_assistant/composables/useAssistantStore';

import ReturnButton from './ReturnButton.vue';
import ProjectSummary from './ProjectSummary.vue';
import CircleBackdrop from './CircleBackdrop.vue';
import EmptyFeedHeader from './MessageFeed/EmptyFeedHeader.vue';
import AiProducerMobileHeader from './AiProducerMobileHeader.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import AiProducerMessageFeed from './MessageFeed/AiProducerMessageFeed.vue';
import AssistantTextarea from '@/components/ai_assistant/AssistantTextarea.vue';

const {
  userInput,
  isWaiting,
  hasMessages,
  createThread,
  saveThread,
  submitMessage,
  resetUnmounted,
  isCreatingThread,
  scrollContainerRef,
} = useAiProducer();

const assistantStore = useAssistantStore();
const { threadId } = storeToRefs(assistantStore);

const router = useRouter();
const { pageViewed, linkClicked, inputChanged } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

onBeforeMount(() => {
  createThread();
});

onBeforeUnmount(() => {
  saveThread(threadId.value);
  resetUnmounted();
});

function goBack() {
  router.push('/');
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'exit producer',
    linkHref: '/',
  });
}

function submit() {
  submitMessage(userInput.value);
  inputChanged({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    inputLabel: 'user message',
    inputType: 'text',
    inputValue: null,
  });
}
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="producer-dialog"
      role="dialog"
      aria-modal="true"
    >
      <AiProducerMobileHeader />

      <article ref="scrollContainerRef" class="producer">
        <aside class="producer__left-column">
          <ReturnButton @click="goBack" />
        </aside>

        <section class="producer__central-column">
          <SoonaTransition
            name="fade-down"
            :exit-styles="{ position: 'absolute' }"
          >
            <EmptyFeedHeader v-if="!hasMessages" />
          </SoonaTransition>

          <SoonaTransition
            name="fade-up"
            :exit-styles="{ position: 'absolute' }"
          >
            <AiProducerMessageFeed v-if="hasMessages" />
          </SoonaTransition>
        </section>

        <SoonaTransition name="fade-left">
          <aside v-if="hasMessages" class="producer__right-column">
            <ProjectSummary>
              <template #header>project summary</template>
            </ProjectSummary>
          </aside>
        </SoonaTransition>
      </article>

      <AssistantTextarea
        v-model="userInput"
        class="producer__textarea"
        :is-loading="isWaiting || isCreatingThread"
        :placeholder="
          !hasMessages ? 'tell us about your upcoming project' : 'reply'
        "
        @submit="submit"
      />

      <CircleBackdrop />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.producer-dialog {
  inset: 0;
  position: fixed;
  background-color: variables.$periwink-blue-10;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;
}

.producer {
  height: 100%;
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: calc(100vh - 4rem);

  // cuts off the center portion of the scrolling container (zone below the text area)
  clip-path: polygon(
    /* Top-left corner */ 0 0,
    /* Top-right corner */ 100% 0,
    /* Bottom-right corner */ 100% 100%,
    /* Right-middle */ calc(50% + 23rem) 100%,
    /* Clip 100px in middle */ calc(50% + 23rem) calc(100% - 6.25rem),
    /* Clip 100px in middle */ calc(50% - 23rem) calc(100% - 6.25rem),
    /* Left-middle */ calc(50% - 23rem) 100%,
    /* Bottom-left corner */ 0 100%
  );

  &__central-column {
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 46rem;
    position: relative;
    flex-direction: column;
  }

  &__left-column,
  &__right-column {
    top: 0;
    padding: 1rem;
    display: none;
    position: sticky;
  }

  &__textarea {
    left: 1rem;
    z-index: 1;
    right: 1rem;
    bottom: 2rem;
    margin: 0 auto;
    position: fixed;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__central-column {
      padding: 0;
    }

    &__textarea {
      left: 0;
      right: 0;
      // adjust if column width changes
      max-width: 46rem;
    }
  }

  @media (min-width: 81.25rem) {
    grid-template-rows: 100vh;
    grid-template-columns: 17.5rem auto 17.5rem;

    &__left-column,
    &__right-column {
      display: block;
    }
  }
}
</style>
