import { createStore } from 'vuex';
import account from 'src/store/modules/account';
import anytime from 'src/store/modules/anytime';
import crewAssignment from 'src/store/modules/crewAssignment';
import currentUser from 'src/store/modules/currentUser';
import dashboard from 'src/store/modules/dashboard';
import employeeType from 'src/store/modules/employeeType';
import errors from 'src/store/modules/errors';
import events from 'src/store/modules/events';
import flash from 'src/store/modules/flash';
import inventoryPackages from 'src/store/modules/inventoryPackages';
import locations from 'src/store/modules/locations';
import order from 'src/store/modules/order';
import payment from 'src/store/modules/payment';
import proService from 'src/store/modules/proService';
import reservation from 'src/store/modules/reservation';
import reservations from 'src/store/modules/reservations';
import schedule from 'src/store/modules/schedule';
import soonaGenie from 'src/store/modules/soonaGenie';
import styleQuiz from 'src/store/modules/styleQuiz';
import tagSurvey from 'src/store/modules/tagSurvey';
import trendSetBuilder from 'src/store/modules/trendSetBuilder';
import user from 'src/store/modules/user';

export const store = createStore({
  modules: {
    account,
    anytime,
    crewAssignment,
    currentUser,
    dashboard,
    employeeType,
    errors,
    events,
    flash,
    inventoryPackages,
    locations,
    order,
    payment,
    proService,
    reservation,
    reservations,
    schedule,
    soonaGenie,
    styleQuiz,
    tagSurvey,
    trendSetBuilder,
    user,
  },
  strict: import.meta.env.DEV,
});
