import facebook_profile_photo from 'images/resizer/facebook-profile_photo-180x180.svg';
import facebook_cover_photo from 'images/resizer/facebook-cover_photo-820x312.svg';
import facebook_shared_image from 'images/resizer/facebook-shared_image-1200x360.svg';
import facebook_post_image from 'images/resizer/facebook-post_image-1200x630.svg';
import facebook_link_image from 'images/resizer/facebook-link_image-1200x628.svg';
import facebook_highlighted_image from 'images/resizer/facebook-highlighted_image-1200x717.svg';
import facebook_event_image from 'images/resizer/facebook-event_image-1920x1080.svg';
import facebook_group_cover_photo from 'images/resizer/facebook-group_cover_photo-1640x856.svg';
import facebook_stories from 'images/resizer/facebook-stories-1080x1920.svg';

import instagram_profile_photo from 'images/resizer/instagram-profile_photo-110x110.svg';
import instagram_square_photo from 'images/resizer/instagram-square_photo-1080x1080.svg';
import instagram_landscape_photo from 'images/resizer/instagram-landscape_photo-1080x608.svg';
import instagram_vertical_photo from 'images/resizer/instagram-vertical_photo-1080x1350.svg';
import instagram_story_igtv from 'images/resizer/instagram-stories-1080x1920.svg';

import x_profile_photo from 'images/resizer/x-profile_photo-400x400.svg';
import x_cover_photo from 'images/resizer/x-cover_photo-1500x500.svg';
import x_shared_link from 'images/resizer/x-shared_link-1200x628.svg';
import x_single_image_tweet from 'images/resizer/x-single_image_tweet-1200x675.svg';
import x_two_image_tweet from 'images/resizer/x-2_image_tweet-700x800.svg';
import x_three_image_tweet_left from 'images/resizer/x-3_image_tweet_left-700x800.svg';
import x_three_image_tweet_right from 'images/resizer/x-3_image_tweet_right-1200x686.svg';
import x_four_image_tweet from 'images/resizer/x-4_image_tweet-1200x600.svg';

import linkedin_profile_photo from 'images/resizer/linkedin-profile_photo-400x400.svg';
import linkedin_profile_banner from 'images/resizer/linkedin-profile_banner-1584x396.svg';
import linkedin_company_logo from 'images/resizer/linkedin-company_logo-300x300.svg';
import linkedin_company_banner from 'images/resizer/linkedin-company_banner-1536x768.svg';
import linkedin_company_main_image from 'images/resizer/linkedin-company_main_image-1128x376.svg';
import linkedin_company_images from 'images/resizer/linkedin-company_images-900x600.svg';
import linkedin_shared_image from 'images/resizer/linkedin-shared_image-1200x1200.svg';
import linkedin_shared_link from 'images/resizer/linkedin-shared_link-1200x627.svg';

import pinterest_profile_image from 'images/resizer/pinterest-profile_image-165x165.svg';
import pinterest_square_pin from 'images/resizer/pinterest-square_pin-1000x1000.svg';
import pinterest_optimal_pin from 'images/resizer/pinterest-optimal_pin-1000x1500.svg';
import pinterest_long_pin from 'images/resizer/pinterest-long_pin-1000x2100.svg';
import pinterest_infographic_pin from 'images/resizer/pinterest-infographic_pin-1000x3000.svg';
import pinterest_board_cover from 'images/resizer/pinterest-board_cover-800x800.svg';

import youtube_channel_icon from 'images/resizer/youtube-channel_icon-800x800.svg';
import youtube_channel_art from 'images/resizer/youtube-channel_art-2560x1440.svg';
import youtube_video_thumbnail from 'images/resizer/youtube-video_thumbnail-1280x720.svg';

import shopify_logo from 'images/resizer/shopify-logo-200x200.svg';
import shopify_square_product_image from 'images/resizer/shopify-square_product_image-2048x2048.svg';
import shopify_small_slideshow from 'images/resizer/shopify-small_slideshow-1200x400.svg';
import shopify_large_slideshow from 'images/resizer/shopify-large_slideshow-2000x600.svg';
import shopify_wide_slideshow from 'images/resizer/shopify-wide_slideshow-1128x376.svg';
import shopify_long_slideshow from 'images/resizer/shopify-long_slideshow-1200x600.svg';
import shopify_collection from 'images/resizer/shopify-collection_image-1024x1024.svg';
import shopify_banner from 'images/resizer/shopify-banner-2800x1000.svg';
import shopify_background from 'images/resizer/shopify-background-1920x1080.svg';
import shopify_featured_blog from 'images/resizer/shopify-featured_blog-1800x1000.svg';
import shopify_blog_content from 'images/resizer/shopify-blog_content-1000x350.svg';

import amazon_product_photo from 'images/resizer/amazon-product_photo-2000x2000.svg';
import amazon_full_width_image_long from 'images/resizer/amazon-full_width_image_long-970x300.svg';
import amazon_full_width_image_tall from 'images/resizer/amazon-full_width_image_tall-970x600.svg';
import amazon_brand_logo from 'images/resizer/amazon-brand_logo-400x400.svg';
import amazon_hero_image from 'images/resizer/amazon-hero_image-3000x600.svg';

import etsy_listing_image from 'images/resizer/etsy-listing_image-2000.svg';
import etsy_shop_icon from 'images/resizer/etsy-shop_icon-500x500.svg';
import etsy_big_shop_banner from 'images/resizer/etsy-big_shop_banner-1600x400.svg';
import etsy_mini_shop_banner from 'images/resizer/etsy-mini_shop_banner-1600x213.svg';
import etsy_collage_banner_2 from 'images/resizer/etsy-collage_banner_2-600x300.svg';
import etsy_collage_banner_3 from 'images/resizer/etsy-collage_banner_3-400x300.svg';
import etsy_collage_banner_4 from 'images/resizer/etsy-collage_banner_4-300x300.svg';
import etsy_carousel_banner from 'images/resizer/etsy-carousel_banner-1200x300.svg';
import etsy_profile_photo from 'images/resizer/etsy-profile_photo-500x500.svg';
import etsy_order_receipt_banner from 'images/resizer/etsy-order_receipt_banner-760x100.svg';
import etsy_team_logo from 'images/resizer/etsy-team_logo-170x170.svg';

import tiktok_videos from 'images/resizer/tiktok-videos-1080x1920.svg';
import tiktok_ads from 'images/resizer/tiktok-ads-720x1280.svg';
import tiktok_shop_photos from 'images/resizer/tiktok-shop_photos-600x600.svg';

const social_media_images = {
  facebook: {
    profile_image: facebook_profile_photo,
    cover: facebook_cover_photo,
    shared_image: facebook_shared_image,
    post_image: facebook_post_image,
    link_image: facebook_link_image,
    highlighted_image: facebook_highlighted_image,
    event_image: facebook_event_image,
    group_cover_photo: facebook_group_cover_photo,
    stories: facebook_stories,
  },
  instagram: {
    profile_photo: instagram_profile_photo,
    square_photo: instagram_square_photo,
    landscape_photo: instagram_landscape_photo,
    vertical_photo: instagram_vertical_photo,
    story_igtv: instagram_story_igtv,
  },
  x: {
    profile_photo: x_profile_photo,
    cover_photo: x_cover_photo,
    shared_link: x_shared_link,
    single_image_tweet: x_single_image_tweet,
    two_image_tweet: x_two_image_tweet,
    three_image_tweet_left: x_three_image_tweet_left,
    three_image_tweet_right: x_three_image_tweet_right,
    four_image_tweet: x_four_image_tweet,
  },
  linkedin: {
    profile_photo: linkedin_profile_photo,
    profile_banner: linkedin_profile_banner,
    company_logo: linkedin_company_logo,
    company_banner: linkedin_company_banner,
    company_main_image: linkedin_company_main_image,
    company_images: linkedin_company_images,
    shared_image: linkedin_shared_image,
    shared_link: linkedin_shared_link,
  },
  pinterest: {
    profile_image: pinterest_profile_image,
    square_pin: pinterest_square_pin,
    optimal_pin: pinterest_optimal_pin,
    long_pin: pinterest_long_pin,
    infographic_pin: pinterest_infographic_pin,
    board_cover: pinterest_board_cover,
  },
  youtube: {
    channel_icon: youtube_channel_icon,
    channel_art: youtube_channel_art,
    video_thumbnail: youtube_video_thumbnail,
  },
  shopify: {
    logo: shopify_logo,
    square_product_image: shopify_square_product_image,
    small_slideshow: shopify_small_slideshow,
    large_slideshow: shopify_large_slideshow,
    wide_slideshow: shopify_wide_slideshow,
    long_slideshow: shopify_long_slideshow,
    collection: shopify_collection,
    banner: shopify_banner,
    background: shopify_background,
    featured_blog: shopify_featured_blog,
    blog_content: shopify_blog_content,
  },
  amazon: {
    product_photo: amazon_product_photo,
    full_width_image_long: amazon_full_width_image_long,
    full_width_image_tall: amazon_full_width_image_tall,
    brand_logo: amazon_brand_logo,
    hero_image: amazon_hero_image,
  },
  etsy: {
    listing_image: etsy_listing_image,
    shop_icon: etsy_shop_icon,
    big_shop_banner: etsy_big_shop_banner,
    mini_shop_banner: etsy_mini_shop_banner,
    collage_banner_2: etsy_collage_banner_2,
    collage_banner_3: etsy_collage_banner_3,
    collage_banner_4: etsy_collage_banner_4,
    carousel_banner: etsy_carousel_banner,
    profile_photo: etsy_profile_photo,
    order_receipt_banner: etsy_order_receipt_banner,
    team_logo: etsy_team_logo,
  },
  tiktok: {
    videos: tiktok_videos,
    ads: tiktok_ads,
    shop_photos: tiktok_shop_photos,
  },
};

function socialMediaSizeImages(social_media, thumb) {
  if (social_media_images[social_media][thumb] === undefined) {
    return '';
  }

  return social_media_images[social_media][thumb];
}

export { socialMediaSizeImages };
