<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { computed, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useRetainer } from '@/queries/retainers/useRetainer';
import { useCapabilities } from '@/composables/useCapabilities';

const props = defineProps({
  accountId: {
    required: true,
    type: [String],
  },
});
const accountId = computed(() => props.accountId);

const capabilitiesRef = ref([
  { capability: 'withdraw_from_retainer' },
  { capability: 'fund_retainer' },
]);
const capabilities = useCapabilities(capabilitiesRef);

const { data: retainer } = useRetainer({ accountId });
</script>

<template>
  <div class="account-balance">
    <p class="u-subheader--heavy is-lowercase">Account balance</p>
    <p class="u-display--heavy">
      {{ toCurrency(retainer?.current_amount) }}
    </p>
    <div class="account-balance__funds">
      <router-link :to="`/account/${accountId}/retainer/add`">
        <SoonaButton
          v-if="capabilities.fund_retainer.hasCapability"
          class="account-balance__add-funds is-lowercase"
          variation="secondary-gray"
          size="medium"
        >
          Add balance
        </SoonaButton>
      </router-link>
      <SoonaButton
        v-if="capabilities.withdraw_from_retainer.hasCapability"
        class="account-balance__add-funds is-lowercase"
        variation="secondary-gray"
        size="medium"
      >
        Deduct from balance
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-balance {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: variables.$periwink-blue-10;
  border: 1px solid variables.$gray-30;
  border-radius: 10px;
  padding: 1.5rem;

  &__funds {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
  }
}
</style>
