<script setup>
import { computed, inject, toValue } from 'vue';
import { useRoute } from 'vue-router';
import { useMediaQuery } from '@vueuse/core';

import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useReEditsCollections } from '@/queries/re_edits_collections/useReEditsCollections';
import { useMe } from '@/composables/user/useMe';
import TopActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBarBase.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import DeleteDigitalAsset from '@/components/user/anytime/gallery/media-editor/DeleteDigitalAsset.vue';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import IntegrationsPublishButton from '@/components/user/anytime/products/IntegrationsPublishButton.vue';
import FavoriteDigitalAsset from '@/components/user/anytime/gallery/media-editor/FavoriteDigitalAsset.vue';
import ExpertPickDigitalAsset from '@/components/user/anytime/gallery/media-editor/ExpertPickDigitalAsset.vue';
import AddToBagDigitalAsset from '@/components/user/anytime/gallery/media-editor/AddToBagDigitalAsset.vue';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import { toCurrency } from '@/lib/currency';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import ReEditAlert from '@/components/user/anytime/ReEditAlert.vue';
import FileContentLabel from '@/components/user/anytime/gallery/FileContentLabel.vue';
import { GreenApple20 } from '@/variables.module.scss';
import AvailableMokkerCredits from '@/components/user/anytime/gallery/media-editor/AvailableMokkerCredits.vue';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import ReEditReservationDigitalAsset from '@/components/user/anytime/gallery/media-editor/ReEditReservationDigitalAsset.vue';

const isWide = useMediaQuery('(min-width: 60rem)');

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId, isIframeOpen } = storeToRefs(mediaEditorStore);

const {
  asset,
  assetTypePurchased,
  isIndependentRawAsset,
  rdaId,
  originatedByCustomer,
  ownedByCustomer,
  ownedBySoona,
  isSoonaCreatedInMediaEditor,
  AIStudioCreation,
  mediaUrl,
  rawUrl,
  isPhoto,
  reservationDigitalAsset,
  rdaSuccess,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { currentlyEditing, isAIExpandActive } = useMediaEditor();

const { currentAccountId } = useMe();
const { addToast } = useSoonaToast();

const { linkClicked } = useBaseEvents();
const route = useRoute();
const isWideScreen = useMediaQuery('(min-width: 48rem)');

const backUrl = inject(MEDIA_POPOVER_BACK_KEY);
const backText = inject(MEDIA_POPOVER_BACK_TEXT);

const backUrlCopy = computed(() => toValue(backText) || 'back');

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const assetBelongsCurrentAccount = computed(
  () => assetAccountId.value === currentAccountId.value
);

const isDeletable = computed(() => {
  const staffOrAccountMember =
    isFtSoonaStaff.value || assetBelongsCurrentAccount.value;

  return (
    (originatedByCustomer.value ||
      isSoonaCreatedInMediaEditor.value ||
      AIStudioCreation.value) &&
    staffOrAccountMember &&
    !isIframeOpen.value &&
    !!assetAccountId.value &&
    !!assetId.value
  );
});

// re-edits
const { data: reEditsDraftRequests, isSuccess: reEditsCollectionSuccess } =
  useReEditsCollections(currentAccountId, ['draft'], {
    enabled: computed(() => !!currentAccountId.value),
  });

const unsubmittedReEdits = computed(() => {
  return reEditsDraftRequests.value?.re_edits_collections || [];
});

const isInEditOrReEditCollection = computed(() => {
  return (
    reservationDigitalAsset.value?.digital_asset?.edits_collection_digital_asset
      ?.id ||
    reservationDigitalAsset.value?.digital_asset
      ?.re_edits_collection_digital_asset?.id
  );
});

const enableReEdits = computed(
  () =>
    rdaSuccess.value &&
    isInEditOrReEditCollection.value &&
    assetTypePurchased.value &&
    !currentlyEditing.value &&
    !!reservationDigitalAsset.value?.reservation_id &&
    reservationDigitalAsset.value.content_category !== 'behind_the_scenes' &&
    (currentAccountId.value === assetAccountId.value || isSoonaStaff.value)
);

// publish
const addOnProduct = computed(
  () => reservationDigitalAsset.value?.add_on_product
);

const contentCategory = computed(() => {
  if (
    !addOnProduct.value?.content_category ||
    reservationDigitalAsset.value?.content_category === 'default'
  )
    return null;
  return reservationDigitalAsset.value?.content_category?.replace(/_/g, ' ');
});

const isBTS = computed(() => {
  return contentCategory.value === 'behind the scenes';
});

// publish
const { disablePublishFlow } = useDisablePublishFlow({
  accountId: assetAccountId,
});
const showPublish = computed(() => {
  return (
    isPhoto.value &&
    ownedByCustomer.value &&
    (mediaUrl.value || rawUrl.value) &&
    !currentlyEditing.value &&
    asset.value?.account_id === currentAccountId.value &&
    !isIframeOpen.value &&
    !disablePublishFlow.value
  );
});

const trackBackClick = () => {
  if (backUrl.value) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media editor header',
      linklabel: 'back',
      linkHref: backUrl.value,
    });
  }
};
</script>

<template>
  <TopActionBarBase :is-wide="isWide">
    <slot />
    <template #details-top>
      <DeleteDigitalAsset
        v-if="isDeletable"
        :account-id="assetAccountId"
        :asset-id="assetId"
        @file-deleted="() => mediaEditorStore.closeAction()"
      />
      <FavoriteDigitalAsset class="header__favorite" />
      <ExpertPickDigitalAsset v-if="rdaId" />
    </template>
    <template #details-bottom>
      <SoonaButton
        size="medium"
        variation="tertiary"
        element="router-link"
        :to="backUrl"
        @on-click="trackBackClick"
      >
        {{ backUrlCopy }}
      </SoonaButton>
      <FileContentLabel
        v-if="contentCategory"
        :title="contentCategory"
        emoji="🌟"
        :color="GreenApple20"
      />
    </template>
    <template
      v-if="
        rdaSuccess && addOnProduct && !ownedByCustomer && !isIndependentRawAsset
      "
      #price
    >
      {{ toCurrency(+addOnProduct?.rate, 'USD', 0) }}
    </template>
    <template
      v-if="
        rdaSuccess && addOnProduct && !ownedByCustomer && !isIndependentRawAsset
      "
      #price-addendum
    >
      or 1
      <template v-if="isBTS">BTS</template>
      <template v-else-if="asset">{{ asset?.media_type }}</template>
      credit
      <SoonaHelperText title="applicable credits">
        applicable credits applied at checkout
      </SoonaHelperText>
    </template>
    <template #actions>
      <template v-if="enableReEdits">
        <ReEditReservationDigitalAsset
          :reservation-digital-asset="reservationDigitalAsset"
        />
      </template>
      <IntegrationsPublishButton
        v-if="showPublish"
        @asset-published="payload => addToast(payload.message, payload)"
      />
      <QuestHelper
        :hide="
          !currentlyEditing ||
          !ownedByCustomer ||
          isIframeOpen ||
          isAIExpandActive
        "
        quest-task-slug="media_editor"
      >
        <DownloadDigitalAsset
          v-if="
            (ownedByCustomer || isFtSoonaStaff) &&
            !isIframeOpen &&
            !isAIExpandActive
          "
        />
      </QuestHelper>
      <AddToBagDigitalAsset
        v-if="
          assetBelongsCurrentAccount &&
          rdaId &&
          ownedBySoona &&
          !isIndependentRawAsset
        "
        :add-on-product="addOnProduct"
      />
      <AvailableMokkerCredits
        v-if="
          assetBelongsCurrentAccount &&
          asset &&
          (isIframeOpen || isAIExpandActive)
        "
        :account-id="assetAccountId"
      />
    </template>
    <template
      v-if="reEditsCollectionSuccess && unsubmittedReEdits.length > 0"
      #warning
    >
      <ReEditAlert
        :unsubmitted-re-edits="unsubmittedReEdits"
        :font-size="isWideScreen ? 'normal' : 'small'"
      />
    </template>
  </TopActionBarBase>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.header {
  &__favorite {
    position: relative;
    right: 0;
    flex: 0 0 2rem;
    height: 2rem;
    width: 2rem;
  }
}
</style>
