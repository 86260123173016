<script setup>
import { computed, toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import AddAsset from './AddAsset.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  newAssets: {
    type: Array,
    default: () => [],
  },
  referenceListing: {
    type: Object,
  },
});

const emit = defineEmits([
  'fetch-product-family',
  'fetch-new-asset',
  'remove-new-asset',
]);
const { newAssets, referenceListing } = toRefs(props);
const stage = defineModel('stage');
const referenceAsinInput = defineModel('referenceAsinInput');

const fetchNewAsset = imageId => {
  emit('fetch-new-asset', imageId);
};

const removeNewAsset = index => {
  emit('remove-new-asset', index);
};

const normalizeAsset = asset => {
  if (!asset) return null;
  return {
    digital_asset_id: asset.id,
    url: asset.preview.url,
  };
};

const newAssetsNormalized = computed(() => {
  return newAssets.value.map(normalizeAsset);
});

const allAssets = computed(() => {
  return referenceListing.value?.assets.concat(newAssetsNormalized.value) ?? [];
});

const imageSlots = computed(() => {
  return Object.keys(stage.value);
});

const assetStagedToSlot = slot => {
  if (!stage.value[slot]) return null;
  return allAssets.value.find(
    image =>
      image.digital_asset_id == stage.value[slot] ||
      image.id == stage.value[slot] // new asset has no digital_asset_id
  );
};

const slotIsDisabled = index => {
  if (index === 0) return false;
  const previousSlotName = imageSlots.value[index - 1];
  return assetStagedToSlot(previousSlotName) === null;
};

const startDrag = (event, asset) => {
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData(
    'assetId',
    event.asset?.id ?? asset?.digital_asset_id ?? asset.id
  );
};

const onDrop = (event, slot) => {
  if (slotIsDisabled(imageSlots.value.indexOf(slot))) return;
  const assetId = event.dataTransfer.getData('assetId');
  if (!assetId) return;
  stage.value[slot] = assetId;
};

const removeImageFromSlot = slot => {
  stage.value[slot] = null;
  let newImageAssignments = Object.values(stage.value).filter(x => !!x);
  Object.keys(stage.value).forEach((slot, i) => {
    stage.value[slot] = newImageAssignments[i];
  });
};
</script>

<template>
  <div class="staging-ground">
    <h2 class="u-title--heavy">staging ground</h2>
    <h3 class="u-subheader--heavy">original assets</h3>
    <div class="staging-ground__input">
      <SoonaTextfield
        v-model="referenceAsinInput"
        class="staging-ground__input--textfield"
        placeholder="enter ASIN"
        label="reference ASIN"
        type="search"
        @keydown.enter="$emit('fetch-product-family')"
      />
      <SoonaButton
        variation="primary"
        @on-click="$emit('fetch-product-family')"
      >
        fetch product family
      </SoonaButton>
    </div>
    <div v-if="referenceListing" class="staging-ground__source-assets">
      <div
        v-for="image in referenceListing.assets"
        :key="image.digital_asset_id"
      >
        <img
          :src="image.url"
          class="staging-ground__image"
          draggable
          @dragstart="startDrag($event, image)"
        />
      </div>
    </div>
    <h3 class="u-subheader--heavy">new assets</h3>
    <div class="staging-ground__new-assets">
      <div
        v-for="(asset, i) in newAssets"
        :key="i"
        class="staging-ground__new-asset"
      >
        <SoonaButton
          class="staging-ground__new-asset--remove"
          variation="icon-transparent"
          @click="removeNewAsset(i)"
        >
          <SoonaIcon name="xmark" />
        </SoonaButton>
        <img
          :src="asset?.preview?.url"
          class="staging-ground__image"
          draggable
          @dragstart="startDrag($event, asset)"
        />
      </div>
      <AddAsset @fetch-new-asset="fetchNewAsset" />
    </div>
    <h3 class="u-subheader--heavy">available slots</h3>
    <div class="staging-ground__dropzones">
      <div
        v-for="(slot, i) in imageSlots"
        :key="slot"
        class="staging-ground__dropzone"
        :class="{
          'staging-ground__dropzone--disabled':
            slotIsDisabled(i) || !referenceListing,
        }"
        @drop="onDrop($event, slot)"
        @dragover.prevent
        @dragenter.prevent
      >
        <template v-if="assetStagedToSlot(slot)">
          <SoonaButton
            class="staging-ground__dropzone--remove"
            variation="icon-transparent"
            @click="removeImageFromSlot(slot)"
          >
            <SoonaIcon name="xmark" />
          </SoonaButton>
          <img
            :src="assetStagedToSlot(slot).url"
            class="staging-ground__image"
            draggable
            @dragstart="startDrag($event, assetStagedToSlot(slot))"
          />
        </template>
        <template v-else>
          {{ i + 1 }}
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.staging-ground {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 0.0625rem solid black;
  border-radius: 0.625rem;
  padding: 1rem;
  margin-bottom: 1.5rem;

  &__input {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: flex-end;
    width: 100%;

    &--textfield {
      flex-grow: 1;
      padding-bottom: 0;
    }
  }

  &__new-assets {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__new-asset {
    position: relative;

    &--remove {
      position: absolute;
      top: 0;
      right: 0;
      color: variables.$friendly-red-60;
    }
  }

  &__source-assets {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__image {
    max-width: 10rem;
    max-height: 10rem;
    object-fit: cover;
    border-radius: 0.625rem;
  }

  &__dropzones {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__dropzone {
    border: 0.0625rem dashed #000;
    border-radius: 0.625rem;
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    &--disabled {
      border-color: variables.$gray-60;
      background-color: variables.$gray-10;
      color: variables.$gray-60;
    }

    &--remove {
      position: absolute;
      top: 0;
      right: 0;
      color: variables.$friendly-red-60;
    }
  }
}
</style>
