<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    required: true,
    type: [String],
  },
});
const title = computed(() => props.title);
</script>

<template>
  <div class="create-contract-card">
    <p class="u-title--heavy is-lowercase">{{ title }}</p>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-contract-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: variables.$periwink-blue-10;
  border-radius: 10px;
  gap: 1.75rem;
  padding: 1.5rem;
}
</style>
