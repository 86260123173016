<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { useAccount } from '@/composables/useAccount';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import { toCityStateZip } from '@/lib/address-formatter';
import { toCurrency } from '@/lib/currency';
import ContractSummaryField from '../ContractSummaryField.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { isEmptyValue } from '@/lib/global/is-empty-value';
import { useCreateContract } from '@/queries/contracts/useCreateContract';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [String],
  },
  contractData: {
    required: true,
    type: [Object],
    default: () => ({}),
  },
});
const router = useRouter();
const accountId = computed(() => props.accountId);
const contractData = computed(() => props.contractData);
const { account, billingAddress } = useAccount(accountId);

const { mutate, isPending } = useCreateContract(accountId);

const proServiceDiscountRate = computed(() => {
  return !isEmptyValue(contractData.value.discount)
    ? `${parseInt(contractData.value.discount.percent_off)}%`
    : 'no discount applied';
});

const enterpriseIncluded = computed(
  () => !isEmptyValue(contractData.value.enterprisePrice)
);
const fastPassIncluded = computed(
  () => !isEmptyValue(contractData.value.fastPassPrice)
);

const contractDuration = computed(
  () =>
    `${dayjs(contractData.value.startDate).format('MM/DD/YYYY')} - ${dayjs(contractData.value.endDate).format('MM/DD/YYYY')}`
);

const contractItems = computed(() => {
  const items = [];

  if (contractData.value.enterprisePrice) {
    items.push({
      price_id: contractData.value.enterprisePrice.id,
      product_id: contractData.value.enterprisePrice.product.id,
      frequency_count: contractData.value.duration,
      fulfillment_frequency: 'monthly',
      subscription_type: 'business',
      total_amount: contractData.value.enterprisePrice.unit_amount,
    });
  }

  if (contractData.value.fastPassPrice) {
    items.push({
      price_id: contractData.value.fastPassPrice.id,
      product_id: contractData.value.fastPassPrice.product.id,
      frequency_count: contractData.value.duration,
      fulfillment_frequency: 'monthly',
      subscription_type: 'fast_pass',
      total_amount: contractData.value.fastPassPrice.unit_amount,
    });
  }

  return items;
});

const handleReview = () => {
  mutate(
    {
      account_id: accountId.value,
      contract_items_attributes: contractItems.value,
      discount_id: contractData.value.discount?.id,
      end_date: dayjs(contractData.value.endDate).utc().format(),
      gif_contract_price_attributes: {
        price_id: contractData.value.gifUnit.id,
        product_id: contractData.value.gifUnit.product.id,
        frequency_count: contractData.value.duration,
      },
      photo_contract_price_attributes: {
        price_id: contractData.value.photoUnit.id,
        product_id: contractData.value.photoUnit.product.id,
      },
      overdraft_type: contractData.value.overdraftType,
      start_date: dayjs(contractData.value.startDate).utc().format(),
      retainer_amount: contractData.value.retainerAmount,
      status: 'active',
      total_amount: contractData.value.totalAmount,
      ugc_contract_price_attributes: {
        price_id: contractData.value.ugcUnit.id,
        product_id: contractData.value.ugcUnit.product.id,
      },
      video_contract_price_attributes: {
        price_id: contractData.value.videoUnit.id,
        product_id: contractData.value.videoUnit.product.id,
      },
    },
    {
      onSuccess: () => {
        router.push({
          path: `/account/${accountId.value}/contracts`,
          replace: true,
        });
      },
    }
  );
};
</script>

<template>
  <div class="review-contract">
    <SoonaLoading
      v-if="isPending"
      is-loading
      loading-text="creating contract"
    />
    <div class="review-contract__account-info">
      <SoonaAvatar
        v-if="account"
        size="6rem"
        type="account"
        :name="account?.name"
        :src="account?.avatar_url"
        :title="account?.name"
      />
      <div>
        <p class="review-contract__label is-lowercase">
          Name & billing address
        </p>
        <p class="u-subheader--heavy">{{ account?.name }}</p>
        <p class="u-body--regular">
          {{ billingAddress?.address_1 }}
        </p>
        <p v-if="billingAddress?.address_2" class="u-body--regular">
          {{ billingAddress?.address_2 }}
        </p>
        <p class="u-body--regular">
          {{ toCityStateZip(billingAddress) }}
        </p>
      </div>
      <div>
        <p class="review-contract__label is-lowercase">Billing email</p>
        <p class="u-subheader--heavy">{{ account?.billing_email }}</p>
      </div>
    </div>
    <!-- summary -->
    <div class="review-contract__contract-summary">
      <div class="review-contract__summary-header">
        <p class="u-subheader--heavy is-lowercase">Total contract value</p>
        <p class="u-display--heavy is-lowercase">
          {{ toCurrency(contractData.totalAmount) }}
        </p>
      </div>
      <div class="review-contract__summary-content">
        <p class="review-contract__summary-duration">
          Duration: {{ contractDuration }}
        </p>
        <div class="review-contract__summary-row">
          <ContractSummaryField
            label="Account balance"
            :value="toCurrency(contractData.retainerAmount)"
          />
          <ContractSummaryField
            label="Platform membership"
            :value="enterpriseIncluded ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Fast Pass"
            :value="fastPassIncluded ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Pro service discount rate"
            :value="proServiceDiscountRate"
          />
        </div>
        <div class="review-contract__summary-row">
          <ContractSummaryField
            label="Photo unit rate"
            :value="
              toCurrency(
                contractData.photoUnit.unit_amount,
                contractData.photoUnit.currency,
                0
              )
            "
          />
          <ContractSummaryField
            label="Video clip unit rate"
            :value="
              toCurrency(
                contractData.videoUnit.unit_amount,
                contractData.videoUnit.currency,
                0
              )
            "
          />
          <ContractSummaryField
            label="GIF unit rate"
            :value="
              toCurrency(
                contractData.gifUnit.unit_amount,
                contractData.gifUnit.currency,
                0
              )
            "
          />
          <ContractSummaryField
            label="UGC unit rate"
            :value="
              toCurrency(
                contractData.ugcUnit.unit_amount,
                contractData.ugcUnit.currency,
                0
              )
            "
          />
        </div>
      </div>
    </div>

    <div class="review-contract__footer">
      <SoonaButton
        class="create-contract__review-btn"
        variation="solid-black"
        size="medium"
        @on-click="handleReview"
      >
        Confirm & create contract
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.review-contract {
  margin-top: 1rem;

  &__account-info {
    display: flex;
    margin-bottom: 3.125rem;

    div {
      margin-right: 3rem;
    }

    span {
      margin-right: 1.5rem;
    }
  }

  &__label {
    @include variables_fonts.u-label--heavy;
    color: variables.$gray-60;
    margin-bottom: 0.25rem;
  }

  &__contract-summary {
    border: 1px solid variables.$gray-30;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  &__summary-header {
    background-color: variables.$periwink-blue-10;
    border-bottom: 1px solid variables.$gray-30;
    padding: 1.5rem;
  }

  &__summary-content {
    padding: 1.5rem;
  }

  &__summary-duration {
    @include variables_fonts.u-body--heavy;
    color: variables.$gray-60;
    margin-bottom: 2.5rem;
  }

  &__summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;

    .contract-summary-field {
      flex: 1;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }
}

@media (max-width: 27rem) {
  .review-contract {
    margin-top: 0.5rem;

    &__account-info {
      flex-direction: column;
      gap: 1.5rem;
    }

    &__summary-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
