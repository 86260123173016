<script setup>
import { computed, ref } from 'vue';
import NoteTypeSelector from './NoteTypeSelector.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaUploadV2 from '@/components/uploader/SoonaUploadV2.vue';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  error: {
    type: Object,
    default: undefined,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['submit-note']);

const note = ref('');
const noteType = ref('default');
const noteAssets = ref([]);
const noteAssetError = ref(undefined);

const handleSubmitNote = () => {
  if (note.value) {
    let assets = noteAssets.value.map(asset => asset.signed_id);
    emit('submit-note', note.value, assets, noteType.value);

    note.value = '';
    noteAssets.value = [];
    noteType.value = 'default';
  }
};

const handleAssetUploadComplete = blob => {
  noteAssets.value.push({
    signed_id: blob.signed_id,
    filename: blob.filename,
  });
  noteAssetError.value = undefined;
};

const handleAssetUploadError = error => {
  noteAssetError.value = error;
  noteAssets.value = [];
};

const priorityError = usePriorityError(computed(() => props.error));
</script>
<template>
  <div class="note-input">
    <SoonaTextfield
      v-model.trim="note"
      element="textarea"
      label="note"
      hide-label
      placeholder="type a note..."
      rows="5"
      :disabled="isPending"
    />
    <div v-if="noteAssets.length" class="note-input__attachments">
      <p v-if="noteAssets.length === 1">
        <b>attached:</b> {{ noteAssets[0].filename }}
      </p>
      <p v-else-if="noteAssets.length > 1">
        <b>attached:</b> {{ noteAssets.length }} assets
      </p>
      <SoonaTooltip>
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="el => setRef(el)"
            size="small"
            variation="icon-plain-gray"
            :aria-describedby="ariaDescribedby"
            @on-click="noteAssets = []"
            @mouseenter="mouseenter"
            @focus="focus"
            @mouseleave="mouseleave"
            @blur="blur"
          >
            <SoonaIcon name="xmark" />
          </SoonaButton>
        </template>
        <template #tooltip-content>remove all uploaded files.</template>
      </SoonaTooltip>
    </div>
    <SoonaError v-if="noteAssetError || priorityError">
      {{ noteAssetError || priorityError }}
    </SoonaError>

    <div class="note-input__action-buttons">
      <NoteTypeSelector v-model:note-type="noteType" :disabled="isPending" />
      <SoonaUploadV2
        label="asset"
        :file-size-limit-mb="500"
        is-multiple
        @upload-complete="handleAssetUploadComplete"
        @upload-error="handleAssetUploadError"
      >
        <template #default="{ onUploadClick }">
          <SoonaButton
            size="medium"
            variation="secondary-gray"
            :disabled="isPending"
            @on-click="onUploadClick"
          >
            upload asset
          </SoonaButton>
        </template>
      </SoonaUploadV2>
      <SoonaButton
        size="medium"
        :disabled="!note"
        :loading="isPending"
        @on-click="handleSubmitNote"
      >
        add note
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.note-input {
  &__attachments {
    display: flex;
    gap: 0.25rem;
  }

  &__action-buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
