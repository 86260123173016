<script setup>
import {
  BlackDefault,
  FriendlyRed20,
  FriendlyRed30,
  FriendlyRed50,
  WhiteDefault,
  PeriwinkBlue60,
} from '@/variables.module.scss';
import { computed } from 'vue';
import { getFirstLetter } from '@/lib/text-transforms';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  avatarSrc: {
    type: String,
    default: null,
  },
  isFlippedX: {
    type: Boolean,
    default: false,
  },
  isFlippedY: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
  },
});

const nameLetter = computed(() => getFirstLetter(props.name));

const transform = computed(() => {
  if (props.isFlippedX && props.isFlippedY) {
    return 'rotate(180,14.5,14.5)';
  } else if (props.isFlippedY) {
    return 'rotate(90,14.5,14.5)';
  } else if (props.isFlippedX) {
    return 'rotate(270,14.5,14.5)';
  }

  return '';
});
</script>

<template>
  <svg
    :class="['annotation-pin', { 'annotation-pin--active': active }]"
    height="29"
    width="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :transform="transform">
      <path
        d="M0.50024 27.6232C0.496057 27.7581 0.546614 27.889 0.640415 27.9861C0.734216 28.0832 0.863289 28.1382 0.998285 28.1387C1.71401 28.1411 2.42844 28.1467 3.13979 28.1522C6.87842 28.1812 10.5318 28.2095 13.84 27.7856C17.7871 27.2799 21.3231 26.1229 23.998 23.4941C29.3121 18.2717 29.5894 9.76083 24.578 4.47965C19.5614 -0.806968 11.1787 -0.8212 5.86031 4.40547C3.18666 7.033 1.9286 10.5854 1.2948 14.5782C0.763368 17.9261 0.663739 21.6407 0.561728 25.4441C0.542315 26.1679 0.522817 26.8949 0.50024 27.6232Z"
        :fill="WhiteDefault"
        :stroke="active ? PeriwinkBlue60 : BlackDefault"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        :class="{ flippedX: isFlippedX, flippedY: isFlippedY }"
      />
    </g>
    <clipPath id="avatarClip">
      <circle cx="15" cy="14" r="10" />
    </clipPath>
    <image
      v-if="avatarSrc"
      id="avatarImage"
      :href="avatarSrc"
      x="5"
      y="4"
      width="20"
      height="20"
      clip-path="url(#avatarClip)"
    />
    <template v-else>
      <circle
        :stroke="FriendlyRed30"
        :fill="FriendlyRed20"
        cx="15"
        cy="14"
        r="10"
      />
      <text
        x="9"
        y="20"
        font-family="Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif"
        font-size="16"
        font-weight="800"
        :fill="FriendlyRed50"
      >
        {{ nameLetter }}
      </text>
    </template>
  </svg>
</template>

<style lang="scss" scoped>
@use '@/variables';

.annotation-pin {
  --base-annotation-size: 29px;
  --annotation-scale: 1;
  --annotation-size: calc(
    var(--base-annotation-size) * var(--annotation-scale) * 1
  );
  flex: 0 0 var(--annotation-size);
  height: var(--annotation-size);
  width: var(--annotation-size);
  display: block;

  &--active {
    --annotation-scale: 1.5;
    filter: drop-shadow(variables.$elevation-3);
  }
}
</style>
