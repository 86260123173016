<script setup>
import { computed, ref } from 'vue';

import { useMe } from '@/composables/user/useMe';
import { useSocialMedias, useSocialMediaSizes } from '@/queries/useSocialMedia';

import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextField from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SocialMedias from '@/components/user/anytime/gallery/media-editor/media-multiplier/SocialMedias.vue';
import ResizerListItem from '@/components/user/anytime/gallery/media-editor/media-multiplier/ResizerListItem.vue';
import SocialMediaSizes from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/SocialMediaSizes.vue';
import { useMediaEditorSubscriptionAccess } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorSubscriptionAccess';

const emit = defineEmits([
  'update-selector-data',
  'update-social-media-selection',
  'update-select-common-size',
  'update-social-media-platform',
]);

const maxDimension = defineModel('maxDimension', {
  type: Number,
  default: undefined,
});
const { currentAccountId } = useMe();

// common sizes
const selectedSize = ref({ name: '', width: 0, height: 0 });
const selectCommonSize = ref(false);
const selectedCommonSize = ref(null);
const commonSizes = [
  { label: 'original', value: 1, ratio: true },
  { label: 'freeform', value: 2, ratio: false },
  { label: '1:1 (square)', value: 3, ratio: true, width: 1, height: 1 },
  { label: '2:3 (vertical)', value: 4, ratio: true, width: 2, height: 3 },
  { label: '9:16 (vertical)', value: 5, ratio: true, width: 9, height: 16 },
  { label: '16:9 (horizontal)', value: 6, ratio: true, width: 16, height: 9 },
  { label: '3:2 (horizontal)', value: 7, ratio: true, width: 3, height: 2 },
];

const handleCommonSizeClick = () => {
  selectCommonSize.value = true;
  emit('update-select-common-size', true);
};

const handleCommonSizeClose = () => {
  selectCommonSize.value = false;
  maxDimension.value = undefined;
  emit('update-select-common-size', false);
};

const setCustomSize = data => {
  selectedCommonSize.value = data;
  const targetSize = commonSizes.find(size => size.value === data);

  emit('update-social-media-selection', {});
  emit('update-selector-data', {
    width: targetSize.width,
    height: targetSize.height,
    ratio: targetSize.ratio,
  });
};

// social media sizes
const selectedSocialMedia = ref();
const { hasSubscriptionDownloadAccess } =
  useMediaEditorSubscriptionAccess(currentAccountId);

const { data: socialMedias, isLoading: socialMediasLoading } =
  useSocialMedias();
const { data: socialMediaSizes, isLoading: socialMediaSizesLoading } =
  useSocialMediaSizes();

const selectedSocialMediaSizes = computed(() => {
  if (selectedSocialMedia.value === null) return [];

  return socialMediaSizes.value.filter(
    item => item.social_media === selectedSocialMedia.value.toLowerCase()
  );
});

const handleSelectSocialMedia = ({ name }) => {
  selectedSocialMedia.value = name;
  emit('update-social-media-platform', name);
};

const setSocialMediaSize = ({ name, width, height }) => {
  selectedSize.value = { name, width, height };
  emit('update-social-media-selection', {
    name: selectedSocialMedia.value,
    width: width,
    height: height,
  });
  emit('update-selector-data', {
    width: width,
    height: height,
    ratio: true,
  });
};

const handleClosePanel = () => {
  selectedSocialMedia.value = null;
  emit('update-social-media-platform', null);
};
</script>

<template>
  <div class="batch-image-resizer">
    <ResizerListItem
      v-if="!selectedSocialMedia && !selectCommonSize"
      key="custom-sizes-menu-item"
      icon="resize"
      @click="handleCommonSizeClick"
    >
      custom / common sizes
    </ResizerListItem>

    <template v-if="selectCommonSize">
      <div class="panel-header">
        <SoonaButton
          variation="icon-gray-outline"
          size="medium"
          @click="handleCommonSizeClose"
        >
          <SoonaIcon name="arrow-left" />
        </SoonaButton>
        <h3 class="u-subheader--heavy">custom / common sizes</h3>
      </div>
      <div class="panel-content">
        <SoonaSelect
          placeholder="choose"
          :model-value="selectedCommonSize"
          :options="commonSizes"
          @update:model-value="setCustomSize"
        >
          <template #label>common sizes</template>
        </SoonaSelect>
        <div class="panel-section">
          <p class="u-label--heavy">
            set a maximum dimension <span class="panel-label">(optional)</span>
          </p>
          <p class="u-label--regular panel-label">
            the maximum height or length of your images will not exceed this
            number
          </p>
          <SoonaTextField
            v-model="maxDimension"
            placeholder="enter maximum"
            name="max-dimension"
            type="number"
            label=""
            :min="100"
            :step="1"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="!!selectedSocialMedia">
        <SocialMediaSizes
          :key="`social-medias-panel-${selectedSocialMedia}`"
          :is-loading="socialMediaSizesLoading"
          :social-media-name="selectedSocialMedia"
          :social-media-items="selectedSocialMediaSizes"
          :selected-size="selectedSize.name"
          is-mobile-view
          minimal-padding-only
          @close-panel="handleClosePanel"
          @resize-selection="setSocialMediaSize"
        />
      </template>
      <SocialMedias
        v-else
        key="social-medias-menu-item"
        :is-loading="socialMediasLoading"
        :has-freemium-access="!hasSubscriptionDownloadAccess"
        :social-medias="socialMedias"
        :selected-social-media="selectedSocialMedia"
        is-mobile-view
        @select="handleSelectSocialMedia"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.batch-image-resizer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.panel-header {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 1rem;
  background: variables.$white-default;
}

.panel-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.panel-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.panel-section-2 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.panel-label {
  color: variables.$gray-70;
}

.text-field {
  padding-bottom: 0;
}
</style>
