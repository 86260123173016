<script setup>
import { computed } from 'vue';
import { Tangerine20, Tangerine80 } from 'src/variables.module.scss';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import DigitalAssetAnnotate from '@/components/notes/digital-asset-notes/DigitalAssetAnnotate.vue';
import { useFlag } from '@/composables/useFlag';
import { useDigitalAssetAnnotateProvider } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';

defineProps({
  showPendingFlag: {
    default: null,
    type: Boolean,
  },
});

const asset = defineModel('asset', {
  default: null,
  type: Object,
});

const assetId = computed(() => asset.value?.id);

const hasDigitalAssetAnnotateFlag = useFlag('toaster_asset_annotate');
useDigitalAssetAnnotateProvider(assetId);
</script>

<template>
  <div :key="asset?.file?.url || `comparison-item`" class="comparison-item">
    <div class="comparison-item__asset">
      <DigitalAssetAnnotate
        v-if="asset && asset.file.is_image && hasDigitalAssetAnnotateFlag"
        :account-id="asset.account_id"
        :digital-asset-id="asset.id"
        :loaded="true"
        :width="asset.file.width"
        :height="asset.file.height"
      >
        <template #default="{ setImgRef }">
          <img
            :ref="setImgRef"
            :src="asset.file.url"
            :alt="`${asset.edit_status} uploaded at ${asset.created_at}`"
          />
        </template>
      </DigitalAssetAnnotate>
      <img
        v-else-if="asset && asset.file.is_image"
        :src="asset.file.url"
        :alt="`${asset.edit_status} uploaded at ${asset.created_at}`"
      />
      <video
        v-else-if="asset && asset.file.is_video"
        :key="asset.preview_url"
        :poster="asset.preview_url"
        controls
        controlsList="nodownload"
        class="preview-media"
      >
        <source :key="asset.file.url" :src="asset.file.url" type="video/mp4" />
      </video>
      <div v-else class="comparison-item__empty">
        <SoonaIcon name="image-square" />
        <SoonaFlag
          v-if="showPendingFlag"
          :background-color="Tangerine20"
          font-size="0.75rem"
          :text-color="Tangerine80"
          title="pending"
        />
        <p class="u-body--heavy">no edits to show</p>
      </div>
    </div>
    <div class="comparison-item__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.comparison-item {
  background-color: variables.$white-default;
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;

  &:first-child {
    border-right: 0.0625rem solid variables.$gray-30;
  }

  &__asset {
    align-items: center;
    background-color: variables.$gray-10;
    border-bottom: 0.0625rem solid variables.$gray-30;
    display: flex;
    flex-shrink: 0;
    height: 60dvh;
    justify-content: center;

    img,
    video {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      min-width: 21.875rem;
      object-fit: contain;
      width: 100%;
    }
  }

  &__empty {
    align-items: center;
    color: variables.$gray-60;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    gap: 1rem;
    overflow: hidden;
    min-height: 18.75rem;
  }
}
</style>
