<script setup>
import { useTemplateRef, ref, computed, toValue } from 'vue';
import { autoUpdate, offset, useFloating } from '@floating-ui/vue';
import resizeToFit from 'intrinsic-scale';

import AnnotationPin from '@/components/notes/digital-asset-notes/AnnotationPin.vue';
import {
  fullSizeToScaledCoords,
  useDigitalAssetAnnotate,
} from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';

const props = defineProps({
  fullSizeX: {
    type: Number,
    required: true,
  },
  fullSizeY: {
    type: Number,
    required: true,
  },
  noteId: {
    type: Number,
    required: true,
  },
  noteCreateName: {
    type: String,
    required: true,
  },
  noteCreatorAvatarSrc: {
    type: String,
    default: null,
  },
  fullSizeHeight: {
    type: Number,
    defautl: null,
  },
  fullSizeWidth: {
    type: Number,
    defautl: null,
  },
  imgEl: {
    type: Object,
  },
});

const pinEl = useTemplateRef('pin');
const imgEl = computed(() => props.imgEl);
const { activeNoteId, setActiveNoteId } = useDigitalAssetAnnotate();

const fullSizeX = computed(() => props.fullSizeX);
const fullSizeY = computed(() => props.fullSizeY);
const fullSizeHeight = computed(() => props.fullSizeHeight);
const fullSizeWidth = computed(() => props.fullSizeWidth);

const isFlippedX = ref(false);
const isFlippedY = ref(false);

const updateOffset = ({ rects }) => {
  // allow for scenarios where the contain-ed image is not the same dimensions as the el
  const source = {
    width: toValue(fullSizeWidth),
    height: toValue(fullSizeHeight),
  };
  const target = {
    width: rects.reference.width,
    height: rects.reference.height,
  };
  const {
    width: containedWidth,
    y: containedY,
    x: containedX,
  } = resizeToFit('contain', source, target);

  const coords = fullSizeToScaledCoords({
    x: fullSizeX,
    y: fullSizeY,
    fullSizeWidth: fullSizeWidth,
    scaledWidth: containedWidth,
  });

  var { x, y } = coords;

  // check if the pin will go above the photo
  if (y < rects.floating.height) {
    isFlippedY.value = true;
    y = y + rects.floating.height;
  } else {
    isFlippedY.value = false;
  }

  // check if the pin will push past the right of the photo
  if (x + rects.floating.width > containedWidth) {
    isFlippedX.value = true;
    x = x - rects.floating.width;
  } else {
    isFlippedX.value = false;
  }

  return {
    mainAxis: -(y + containedY),
    crossAxis: x + containedX,
  };
};

const { floatingStyles } = useFloating(imgEl, pinEl, {
  whileElementsMounted: autoUpdate,
  middleware: [offset(updateOffset)],
  placement: 'top-start',
});
</script>

<template>
  <button
    ref="pin"
    class="annotate-note-button"
    :style="floatingStyles"
    @click="setActiveNoteId(noteId)"
  >
    <AnnotationPin
      :avatar-src="noteCreatorAvatarSrc"
      :name="noteCreateName"
      :active="noteId === activeNoteId"
      :is-flipped-x="isFlippedX"
      :is-flipped-y="isFlippedY"
    />
    <span class="u-visually-hidden">set note {{ noteId }} to be active</span>
  </button>
</template>

<style lang="scss" scoped>
.annotate-note-button {
  display: block;
  border: none;
  padding: 0;
  background: none;
  position: absolute;
}
</style>
