import { toValue } from 'vue';
import { useInfiniteQuery, useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

const getAlbumCollections = async ({
  signal,
  accountId,
  currentPage,
  itemsPerPage,
  searchQuery,
  sortBy,
  sortDirection,
}) => {
  const params = {};

  if (currentPage) params['page'] = toValue(currentPage);
  if (itemsPerPage) params['items'] = toValue(itemsPerPage);
  if (searchQuery) params['search_query'] = toValue(searchQuery);
  if (sortBy) params['order_by'] = toValue(sortBy);
  if (sortDirection) params['direction'] = toValue(sortDirection);

  const response = await http.get(
    `/accounts/${toValue(accountId)}/album_collections`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    collections: response.data?.album_collections || [],
  };
};

/**
 *
 * @param {string | number} accountId
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.sortBy]
 * @param {number} [params.sortDirection]
 */
export function useInfiniteAlbumCollections(
  accountId,
  { itemsPerPage, sortBy, sortDirection } = {}
) {
  return useInfiniteQuery({
    queryKey: queryKeys.collectionsDA(accountId, 'album_collection', {
      itemsPerPage,
      sortBy,
      sortDirection,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      return getAlbumCollections({
        signal,
        accountId,
        currentPage: pageParam.currentPage,
        itemsPerPage: pageParam.itemsPerPage,
        sortBy,
        sortDirection,
      });
    },
    initialPageParam: { currentPage: 1, itemsPerPage },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchQuery]
 * @param {string} [params.sortBy]
 * @param {string} [params.sortDirection]
 */
export function useAlbumCollections(
  accountId,
  { currentPage, itemsPerPage, searchQuery, sortBy, sortDirection } = {}
) {
  return useQuery({
    queryKey: queryKeys.collectionsDA(accountId, 'album_collection', {
      currentPage,
      itemsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
    }),
    queryFn: async ({ signal }) => {
      return getAlbumCollections({
        signal,
        accountId,
        currentPage,
        itemsPerPage,
        searchQuery,
        sortBy,
        sortDirection,
      });
    },
  });
}
