<script setup>
import { computed, toRefs, toRef, ref, watchEffect } from 'vue';
import AlertIcon from 'src/components/svgs/AlertIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import { useGetSuggestedReservationLocation } from 'src/queries/useGetSuggestedReservationLocation';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/composables/useAccount';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateStudioAccess } from '@/queries/reservations/useUpdateStudioAccess';
import { useReservationLocations } from 'src/queries/useReservationLocations';
import { useUpdateReservationLocation } from 'src/queries/useUpdateReservation';

const props = defineProps({
  reservationId: String,
  slug: String,
});

const { reservationId, slug } = toRefs(props);

const {
  reservation,
  isCompedDraft,
  isInternal,
  isSurprise,
  hasUnassignedLocation,
  isLoading: isReservationLoading,
} = useReservation(reservationId);

const {
  mutate: updateLocation,
  isPending: isUpdatingLocation,
  error: updateLocationError,
} = useUpdateReservationLocation(reservationId);

const membershipLevel = ref(reservation.value?.studio_access_level);

// api calls

// location
const { data: locations, error: locationsError } = useReservationLocations(
  reservationId,
  ['disabled', 'studio', 'remote', 'coming_soon']
);

// suggested location
const {
  data: suggestedLocation,
  isLoading: isLoadingSuggestedLocation,
  isSuccess: isSuggestedLocationSuccess,
} = useGetSuggestedReservationLocation(reservationId);

// studio access
const { mutate: updateStudioAccess } = useUpdateStudioAccess(
  toRef(props, 'reservationId')
);

const selectedReservationType = computed(
  () => reservation.value?.reservation_type
);

const showReservationTypeToggle = computed(() =>
  ['in_studio', 'anytime'].includes(reservation.value?.reservation_type)
);

const inStudioOptions = computed(() => {
  return (
    locations.value?.map(l => {
      const option = {
        label: `${l.location.display_name} (${l.location.location_type})`,
        value: l.location.id,
        selected: l.selected,
      };

      if (l.incompatible) option.label += ' - incompatible options';

      return option;
    }) || []
  );
});

const selectedLocationId = computed(() => {
  return inStudioOptions.value?.find(l => l.selected)?.value;
});

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const pegasusSuggestedDraftSubxFlag = useFlag(
  'pegasus_suggested_draft_subscriptions'
);
const pegasusHideBookingBasicMonthly = useFlag(
  'pegasus_hide_booking_basic_monthly'
);

const { isDigitalSubscriber, isFastPassSubscriber } = useAccount(
  reservation.value?.account_id
);

const isPreferred = computed(
  () => reservation.value?.account?.preferred?.is_preferred ?? false
);

const enforceMembershipSelection = computed(() => {
  if (phoenixStudioRentalFeeFlag.value && pegasusSuggestedDraftSubxFlag.value) {
    return (
      !isDigitalSubscriber.value &&
      !isPreferred.value &&
      !isCompedDraft.value &&
      !isInternal.value &&
      !isSurprise.value
    );
  }

  return false;
});

const handleUpdatingStudioAccess = () => {
  if (membershipLevel.value === 'day-pass') {
    updateStudioAccess({
      level: membershipLevel.value,
      billingCycle: null,
    });
  } else {
    const parts = membershipLevel.value.split('-');
    const level = parts.slice(0, -1).join('-');
    const billingCycle = parts[parts.length - 1];

    updateStudioAccess({
      level,
      billingCycle,
    });
  }
};

const handleUpdatingMembershipLevel = level => {
  membershipLevel.value = level;

  handleUpdatingStudioAccess();
};

const studioPassOptions = computed(() => {
  if (isFastPassSubscriber.value) {
    return [
      { label: 'No membership', value: 'day-pass' },
      { label: 'Basic membership (month)', value: 'tier-one-month' },
      { label: 'Standard membership (month)', value: 'tier-two-month' },
    ];
  }
  return [
    { label: 'No membership', value: 'day-pass' },
    { label: 'Basic membership (month)', value: 'tier-one-month' },
    { label: 'Basic membership (year)', value: 'tier-one-year' },
    { label: 'Standard membership (month)', value: 'tier-two-month' },
    { label: 'Standard membership (year)', value: 'tier-two-year' },
  ];
});

const newStudioPassOptions = computed(() => {
  if (isFastPassSubscriber.value) {
    return [
      { label: 'No membership', value: 'day-pass' },
      { label: 'Standard membership (month)', value: 'tier-two-month' },
    ];
  }
  return [
    { label: 'No membership', value: 'day-pass' },
    { label: 'Basic membership (year)', value: 'tier-one-year' },
    { label: 'Standard membership (month)', value: 'tier-two-month' },
    { label: 'Standard membership (year)', value: 'tier-two-year' },
  ];
});

const setReservationType = type => {
  let location = locations.value?.find(
    l => l.location.id === selectedLocationId.value
  );

  updateLocation({
    locationId: location.location.id,
    reservationType: type,
  });
};

const onSelect = locationId => {
  updateLocation({
    locationId: locationId,
    unscheduleReservation: true,
  });
};

watchEffect(() => {
  if (hasUnassignedLocation.value && isSuggestedLocationSuccess.value) {
    updateLocation({
      locationId: suggestedLocation?.value?.id,
    });
  }
});

watchEffect(() => {
  if (isCompedDraft.value || isSurprise.value) {
    handleUpdatingMembershipLevel('day-pass');
  }
});

const reservationTypeOptions = [
  { value: 'in_studio', label: 'in-studio' },
  { value: 'anytime', label: 'virtual' },
];

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  locationsError,
  updateLocationError,
  stepIsIncomplete
);
const isBusy = computed(
  () =>
    isReservationLoading.value ||
    isUpdatingLocation.value ||
    isLoadingSuggestedLocation.value
);
</script>

<template>
  <div v-if="showReservationTypeToggle">
    <SoonaSegmentedRadios
      label="reservation type"
      :label-visually-hidden="true"
      name="reservation-type"
      :disabled="isBusy"
      :options="reservationTypeOptions"
      :model-value="selectedReservationType"
      data-cypress="radio-reservation-type"
      @update:model-value="setReservationType"
    />
  </div>
  <div>
    <SoonaSelect
      :model-value="selectedLocationId"
      :options="inStudioOptions"
      :reduce="({ value }) => value"
      :disabled="isBusy"
      :loading="isBusy"
      data-cypress="select-studio-location"
      @update:model-value="onSelect"
    >
      <template #label>studio</template>
    </SoonaSelect>
  </div>

  <div
    v-if="
      enforceMembershipSelection &&
      phoenixStudioRentalFeeFlag &&
      pegasusSuggestedDraftSubxFlag
    "
  >
    <SoonaSelect
      :model-value="membershipLevel"
      :options="
        pegasusHideBookingBasicMonthly
          ? newStudioPassOptions
          : studioPassOptions
      "
      :reduce="({ value }) => value"
      :disabled="isBusy"
      :loading="isBusy"
      data-cypress="select-studio-pass"
      @update:model-value="handleUpdatingMembershipLevel"
    >
      <template #label>membership</template>
    </SoonaSelect>
  </div>

  <div class="warning-text">
    <AlertIcon aria-title="Warning" />
    <p>
      note: disabled and incompatible locations are marked as such
      <br />crew may select any location for any shoot <br />this includes
      shoots with unsupported/incompatible options for that location
    </p>
  </div>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style scoped lang="scss">
@use '@/variables';

.warning-text {
  display: flex;
  align-items: flex-start;
  margin-top: 0.75rem;
  color: variables.$gray-60;

  svg {
    flex: 0 0 1.25rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }
}
</style>
