<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  WhiteDefault,
  Gray90,
  BubbletapePink20,
  BubbletapePink80,
  PeriwinkBlue30,
} from 'src/variables.module.scss';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  assetId: {
    required: true,
    type: Number,
  },
  customUrl: {
    default: null,
    type: Object,
  },
  isReEdit: {
    default: false,
    type: Boolean,
  },
  product: {
    required: false,
    type: Object,
    default: null,
  },
  reservationId: {
    required: true,
    type: Number,
  },
  title: {
    required: true,
    type: String,
  },
  total: {
    required: false,
    type: Number,
    default: null,
  },
  usingPreferred: {
    required: false,
    type: Boolean,
    default: false,
  },
  showPrice: {
    required: false,
    type: Boolean,
    default: true,
  },
});

const assetId = computed(() => props.assetId);
const reservationId = computed(() => props.reservationId);
const title = computed(() => props.title);
const total = computed(() => props.total);
const usingPreferred = computed(() => props.usingPreferred);
const showPrice = computed(() => props.showPrice);

const assetUrl = computed(() => {
  return `/reservation/${reservationId.value}/asset/${assetId.value}`;
});

const assetDisplayPrice = value => `${toCurrency(value, 'USD', 0)}`;
const isReshopSale = computed(() =>
  props.product?.name?.includes('Reshop Sale')
);
</script>

<template>
  <div class="asset-heading">
    <div class="asset-heading--left">
      <h3>
        <router-link
          :to="customUrl ?? assetUrl"
          class="asset-heading__title"
          :data-cypress="`title-${title.toLowerCase().replace(/\s+/g, '-')}`"
        >
          {{ title }}
        </router-link>
      </h3>
      <SoonaFlag
        v-if="isReshopSale"
        title="sale"
        :background-color="Gray90"
        :text-color="WhiteDefault"
      >
        <template #icon-left>
          <SoonaIcon name="clock" size="small" class="clock" />
        </template>
      </SoonaFlag>
    </div>
    <div aria-title="asset base price" class="asset-heading--right">
      <SoonaFlag
        v-if="usingPreferred"
        title="preferred"
        :background-color="PeriwinkBlue30"
      />
      <p v-if="showPrice && isReshopSale" class="asset-heading--strikeout">
        {{ product?.name.includes('Photo') ? '$39' : '$93' }}
      </p>
      <p v-if="showPrice">{{ assetDisplayPrice(total) }}</p>
      <SoonaFlag
        v-else
        :background-color="BubbletapePink20"
        :text-color="BubbletapePink80"
        title="re-edit"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.asset-heading {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 0.75rem;

  &__title {
    @include variables_fonts.u-body--heavy;

    word-break: break-word;
  }

  &--left {
    display: flex;
    gap: 0.5rem;
    p {
      @include variables_fonts.u-body--heavy;
    }
  }

  &--right {
    display: flex;
    gap: 0.5rem;
    flex-grow: 1;
    justify-content: flex-end;
    p {
      @include variables_fonts.u-body--heavy;
    }
  }

  &--strikeout {
    text-decoration: line-through;
    text-decoration-color: variables.$friendly-red-50;
  }

  @media (min-width: variables.$screen-md-min) {
    margin-bottom: 1rem;

    &__title {
      @include variables_fonts.u-subheader--heavy;
    }
  }
}

a.asset-heading__title {
  text-decoration: underline;
  transition: color 0.1s ease-out;

  &:hover,
  &:focus-visible {
    color: variables.$periwink-blue-70;
  }

  &:active {
    color: variables.$periwink-blue-80;
    text-decoration: none;
  }
}
</style>
