import { useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';

export function useFlags() {
  return useQuery({
    queryKey: queryKeys.flags(),
    queryFn: async () => {
      const response = await http.get('/flags');

      return response.data;
    },
  });
}
