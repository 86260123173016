<script setup>
import { computed, toRefs } from 'vue';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import DigitalAssetNotesList from './DigitalAssetNotesList.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  autoFocusComposer: {
    type: Boolean,
    default: false,
  },
  digitalAssetId: {
    type: [Number, String],
    default: null,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
});

defineEmits(['close']);

const { accountId, digitalAssetId } = toRefs(props);

const {
  data: asset,
  isLoading: isAssetLoading,
  error: assetError,
} = useDigitalAsset({ accountId, assetId: digitalAssetId });

const priorityErrors = usePriorityErrors(assetError);

const assetTitle = computed(() => asset.value?.title);
</script>

<template>
  <SoonaDialog
    class="digital-asset-notes-dialog"
    top-affixed
    @close="$emit('close')"
  >
    <template #heading>
      <span class="digital-asset-notes-dialog__heading-wrap">
        <span
          class="digital-asset-notes-dialog__heading-img-wrap"
          :style="{ aspectRatio: asset?.preview?.ratio ?? 1 }"
        >
          <SoonaSkeleton
            v-if="isAssetLoading"
            class="digital-asset-notes-dialog__heading-img-placeholder"
          />
          <DigitalAssetImage
            v-else
            :src="asset?.preview?.url"
            :alt="asset?.preview?.alt_text"
          />
        </span>
        <span class="digital-asset-notes-dialog__heading-text">
          <span>notes</span>
          <span v-if="assetTitle" class="u-body--regular">
            {{ assetTitle }}
          </span>
        </span>
      </span>
    </template>
    <template v-if="priorityErrors" #header>
      <SoonaError :priority-errors="priorityErrors" no-margin />
    </template>
    <DigitalAssetNotesList
      :account-id="accountId"
      :auto-focus-composer="autoFocusComposer"
      :digital-asset-id="digitalAssetId"
      :placeholder="placeholder"
      :show-annotation-instructions="false"
      sticky-bottom-size="1.5rem"
    />
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.digital-asset-notes-dialog {
  &__heading-wrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__heading-img-wrap {
    max-height: 5rem;
    max-width: 5rem;
    flex: 0 0 auto;
  }

  &__heading-img-placeholder {
    height: 100%;
  }

  &__heading-text {
    display: flex;
    flex-direction: column;
  }
}
</style>
