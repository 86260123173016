import { computed } from 'vue';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import { useMokkerAvailableCredits } from '@/queries/mokker/useMokkerAvailableCredits';

export default accountId => {
  const {
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
    hasLiveSubscription,
    userCanUpgrade,
    isLoading: isLoadingSubscription,
    isSuccess: subscriptionSuccess,
  } = useActiveSubscription(accountId);

  const {
    data: availableCreditsResponse,
    isLoading: isLoadingAvailableCredits,
    isSuccess: availableCreditsSuccess,
    error: availableCreditsError,
  } = useMokkerAvailableCredits(accountId, {
    enabled: computed(() => !!accountId.value && hasLiveSubscription.value),
  });

  const isLoadingSubscriptionActionPermissions = computed(() => {
    return isLoadingSubscription.value || isLoadingAvailableCredits.value;
  });

  const subscriptionActionPermissionsSuccess = computed(() => {
    return (
      subscriptionSuccess.value &&
      (hasLiveSubscription.value ? availableCreditsSuccess.value : true)
    );
  });

  const hasAvailableCredits = computed(() => {
    return availableCreditsResponse.value?.available_credits > 0;
  });

  const canTakeBasicAction = computed(() => {
    return hasTierOneSubscription.value && hasAvailableCredits.value;
  });

  const canTakeStandardAction = computed(() => {
    return hasTierTwoSubscription.value || hasTierThreeSubscription.value;
  });

  const canTakeAction = computed(() => {
    return canTakeBasicAction.value || canTakeStandardAction.value;
  });

  const canUseBulkActions = computed(() => {
    return canTakeStandardAction.value;
  });

  const isBasicUserAndOutOfCredits = computed(() => {
    return (
      hasTierOneSubscription.value &&
      availableCreditsSuccess.value &&
      !hasAvailableCredits.value
    );
  });

  const optimizeButtonVariation = computed(() => {
    return canTakeAction.value ? 'solid-black' : 'pizzazz';
  });

  return {
    canTakeBasicAction,
    canTakeStandardAction,
    canTakeAction,
    canUseBulkActions,
    isBasicUserAndOutOfCredits,
    availableCreditsError,
    hasAvailableCredits,
    optimizeButtonVariation,
    userCanUpgrade,
    hasTierOneSubscription,
    isLoadingSubscriptionActionPermissions,
    subscriptionActionPermissionsSuccess,
  };
};
