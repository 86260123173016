<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { computed, toRefs } from 'vue';
import { useCreateInvoicedSubscription } from '@/queries/subscriptions/useCreateInvoicedSubscription';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';
import { useAccount } from '@/queries/account/useAccount';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  tierSlug: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { accountId, tierSlug } = toRefs(props);

const selectedTier = computed(() => {
  if (tierSlug.value === 'tier-one') {
    return 'basic';
  } else if (tierSlug.value === 'tier-two') {
    return 'standard';
  } else {
    return null;
  }
});

const { data: account, error: accountError } = useAccount(accountId);

const {
  mutate: createInvoicedSubscription,
  isPending: createInvoicedSubscriptionPending,
  error: createInvoicedSubscriptionError,
} = useCreateInvoicedSubscription(accountId);

const { data: basicTier, error: basicTierError } = useTierBySlug('tier-one');
const { data: standardTier, error: standardTierError } =
  useTierBySlug('tier-two');

const annualBasicPrice = computed(() => {
  return basicTier.value?.product.prices.find(
    x => x.recurring_interval === 'year'
  );
});

const annualStandardPrice = computed(() => {
  return standardTier.value?.product.prices.find(
    x => x.recurring_interval === 'year'
  );
});

const createInvoicedSubx = () => {
  let priceIds = [];
  let startDate = new Date();

  if (tierSlug.value === 'tier-one') {
    priceIds = [annualBasicPrice.value?.id];
  } else if (tierSlug.value === 'tier-two') {
    priceIds = [annualStandardPrice.value?.id];
  }

  if (!account.value?.subscription) {
    createInvoicedSubscription(
      {
        accountId: accountId.value,
        billingCycleAnchor: startDate,
        priceIds: priceIds,
        slug: tierSlug.value,
        startDate: startDate,
      },
      {
        onSuccess: () => {
          emit('close');
        },
      }
    );
  }
};

const priorityErrors = usePriorityErrors(
  createInvoicedSubscriptionError,
  accountError,
  basicTierError,
  standardTierError
);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>create invoiced subscription</template>
    <p>
      create an annual {{ selectedTier }} subscription and invoice the account
    </p>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :loading="createInvoicedSubscriptionPending"
        @on-click="createInvoicedSubx"
      >
        create subscription
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
