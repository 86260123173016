<script setup>
import { computed, onUnmounted, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddOnAnimiation from './AddOnAnimiation.vue';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import { useAccount } from '@/composables/useAccount';
import { usePriorityError } from '@/composables/usePriorityError';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';
import { useTitle } from '@vueuse/core';
import { toCurrency } from '@/lib/currency';
import { useCreateSubscription } from '@/queries/subscriptions/useCreateSubscription';
import { useCreateSubscriptionItem } from '@/queries/subscriptions/useCreateSubscriptionItem';
import { useUpdateInventoryPackage } from '@/queries/inventory/useUpdateInventoryPackage';
import { useUpdateReservation } from '@/queries/useUpdateReservation';
import { useIntegrations } from '@/composables/useIntegrations';
import { useSubscriptionItemProration } from '@/composables/subscription/useSubscriptionItemProration';
import SummaryPayment from '@/components/SummaryPayment.vue';
import { useSalesTax } from '@/composables/sales_tax/useSalesTax';
import EstimatedSalesTax from '@/components/sales_tax/EstimatedSalesTax.vue';
import BillingInfo from '@/components/checkout/BillingInfo.vue';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  tierSlug: {
    type: String,
    required: true,
  },
});
const router = useRouter();
const route = useRoute();

const { accountId, tierSlug } = toRefs(props);

const salesTaxStore = useSalesTaxStore();

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const {
  data: tier,
  isLoading: isTierLoading,
  error: tierError,
} = useTierBySlug(tierSlug);

const { hasSoonaStorage, soonaStoragePendingCancellation } =
  useAccount(accountId);

const isRenewSubscription = computed(() => {
  return !!(hasSoonaStorage.value && soonaStoragePendingCancellation.value);
});

useTitle(
  computed(() => `subscriptions - ${tier.value?.name ?? 'add-on'} | soona`)
);

const {
  account,
  subscriptionChargedViaStripe,
  isLoading: isLoadingAccount,
} = useAccount(accountId);

const activeSubscriptionId = computed(() => {
  return account.value?.subscription?.id;
});

const activeBaseSubscriptionItem = computed(() => {
  return account.value?.subscription?.subscription_items?.find(
    x => x.subscription_item_type === 'base'
  );
});

const formattedInterval = computed(() => {
  return activeBaseSubscriptionItem.value?.recurring_interval === 'year'
    ? 'yearly'
    : 'monthly';
});

const subscriptionPrice = computed(() => {
  return tier.value?.product.prices.find(
    x =>
      x.recurring_interval ===
      (activeBaseSubscriptionItem.value?.recurring_interval || 'month')
  );
});

const { hasShopifyIntegration } = useIntegrations(accountId);

// proration preview
// todo: race condition based on active subscription tries to fetch month price when yearly
const {
  addOnTotal,
  nextSubscriptionStart,
  isFetchingProratedData,
  proratedDataLoading,
  prorationDate,
  prorationSubtotalExcludingTaxInDollars,
  proratedTotalInDollars,
  prorationTaxAmountInDollars,
  proratedTotal,
  recurringInterval,
  remainingTime,
  showProrationPreview,
  subscriptionPriceId,
} = useSubscriptionItemProration(accountId, subscriptionPrice.value, {
  tierSlug: tier.value.slug,
});

const preSalesTaxSubtotal = computed(() => {
  return showProrationPreview.value
    ? null
    : subscriptionPrice.value?.unit_amount;
});

const productsList = computed(() => {
  const currentSubscription = account.value?.subscription;
  if (currentSubscription && hasShopifyIntegration.value) {
    const currentItem = currentSubscription.subscription_items[0];

    return [
      {
        id: currentItem.product_id,
        price_id: currentItem.price_id,
        quantity: 1,
      },
      {
        id: tier.value?.product?.id,
        price_id: subscriptionPrice.value?.id,
        quantity: 1,
      },
    ];
  } else {
    return [
      {
        id: tier.value?.product?.id,
        price_id: subscriptionPrice.value?.id,
        quantity: 1,
      },
    ];
  }
});

const {
  isLoading: isCalculatingSalesTax,
  taxAmountExclusiveDisplay,
  totalWithSurchargeInDollars,
  stripeSalesTaxCalculationId,
} = useSalesTax({
  accountId,
  preSalesTaxSubtotal,
  productsList,
  calculate: computed(
    () => !!tier.value?.product?.id && !!subscriptionPrice.value?.id
  ),
});

const totalOrProrationWithSurchargeInDollars = computed(() => {
  return showProrationPreview.value
    ? proratedTotalInDollars.value
    : totalWithSurchargeInDollars.value;
});

// create and update a subscription
const {
  mutate: createSubscription,
  isPending: subscriptionCreating,
  error: subscriptionCreatingError,
} = useCreateSubscription(accountId);

const {
  mutate: createSubscriptionItem,
  isPending: subscriptionItemCreating,
  error: errorCreateSubscriptionItem,
} = useCreateSubscriptionItem(accountId);

// inventory
const store = useStore();

const inventoryPackageId = computed(() => route.query?.package_id);
const reservationId = computed(() => route.query?.reservation_id);
const addPackage = computed(() => route.query?.add_package);
const currentPackage = computed(
  () => store.state.inventoryPackages.currentPackage
);

const {
  mutate: updateInventoryPackage,
  isPending: updateInventoryPackageLoading,
  error: updateInventoryPackageError,
} = useUpdateInventoryPackage(inventoryPackageId);

const {
  mutate: mutateReservation,
  isPending: updateReservationLoading,
  error: updateReservationError,
} = useUpdateReservation(reservationId);

const updatePostShootOption = () => {
  if (inventoryPackageId.value) {
    updateInventoryPackage({
      post_shoot_option: 'store_at_soona',
      required_action: 'needs_storage',
    });
  } else if (addPackage.value) {
    store.dispatch('inventoryPackages/createPackage', {
      packageAttributes: currentPackage.value,
    });
  } else {
    mutateReservation({ package_option: 'store_at_soona' });
  }
};

const completePaymentMethodCheckout = async (
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod,
  shopifyStoreDomain
) => {
  let startDate = new Date();

  if (!account.value?.subscription) {
    createSubscription(
      {
        accountId: accountId.value,
        billingCycleAnchor: startDate,
        isInternal: false,
        priceIds: [subscriptionPriceId.value],
        saveCard: savePaymentMethod,
        slug: tierSlug.value,
        startDate: startDate,
        termsAccepted: true,
        token: hasShopifyIntegration.value ? null : paymentMethodId,
        shopifyPaymentShopDomain: hasShopifyIntegration.value
          ? shopifyStoreDomain
          : null,
        taxCalculationId: stripeSalesTaxCalculationId.value,
      },
      {
        onSuccess: response => {
          if (tierSlug.value === 'fast-pass' && reservationId.value) {
            updatePostShootOption();
          }

          if (
            response?.result?.body?.data?.appSubscriptionCreate?.confirmationUrl
          ) {
            window.location.href =
              response.result.body.data.appSubscriptionCreate.confirmationUrl;
          } else if (reservationId.value) {
            window.location.href = `/#/reservation/${reservationId.value}/info`;
          } else {
            router.push(`/account/${accountId.value}/subscriptions`);
          }
        },
      }
    );
  } else {
    let body = {
      add_price_ids: [subscriptionPriceId.value],
      remove_price_ids: [],
      subscription_id: activeSubscriptionId.value,
      token: hasShopifyIntegration.value ? null : paymentMethodId,
      shop_domain: hasShopifyIntegration.value ? shopifyStoreDomain : null,
      tax_calculation_id: stripeSalesTaxCalculationId.value,
    };

    if (showProrationPreview.value) {
      body.proration_date = prorationDate.value;
    }
    createSubscriptionItem(body, {
      onSuccess: response => {
        if (
          response?.result?.body?.data?.appSubscriptionCreate?.confirmationUrl
        ) {
          window.location.href =
            response.result.body.data.appSubscriptionCreate.confirmationUrl;
        } else if (reservationId.value) {
          window.location.href = `/#/reservation/${reservationId.value}/info`;
        } else {
          router.push(`/account/${accountId.value}/subscriptions`);
        }
      },
    });
  }
};

const priorityError = usePriorityError(
  tierError,
  subscriptionCreatingError,
  updateInventoryPackageError,
  updateReservationError,
  errorCreateSubscriptionItem
);

const isLoading = computed(
  () =>
    // https://github.com/TanStack/query/issues/3975
    isLoadingAccount.value ||
    (proratedDataLoading.value && isFetchingProratedData.value) ||
    subscriptionCreating.value ||
    subscriptionItemCreating.value ||
    isTierLoading.value ||
    updateInventoryPackageLoading.value ||
    updateReservationLoading.value
);

const chargeViaStripe = computed(() => {
  return !hasShopifyIntegration.value || subscriptionChargedViaStripe.value;
});

const chargeUsingCardOnFile = computed(() => {
  if (
    account.value?.is_trialing &&
    account.value?.subscription?.external_subscription_id
  ) {
    return true;
  } else if (account.value?.is_trialing) {
    return false;
  } else if (
    account.value?.subscription &&
    account.value?.subscription.payment_provider === null
  ) {
    return false;
  } else {
    return !!account.value?.subscription;
  }
});

const tierDisplayName = computed(() => tier.value?.slug.replace('-', ' '));

onUnmounted(() => {
  salesTaxStore.$reset();
});
</script>

<template>
  <div class="subscriptions-add-on">
    <SoonaBackButton @click="() => router.go(-1)" />
    <SoonaLoading v-if="isLoading" is-loading />
    <template v-if="tier">
      <div class="subscriptions-add-on__body">
        <div class="subscriptions-add-on__content--left">
          <AddOnAnimiation
            :product-slug="tier.slug"
            :subheading="tier.product.subheading"
          />
        </div>
        <div class="subscriptions-add-on__content--right">
          <h2 class="subscriptions-add-on__content--heading u-display--heavy">
            {{ toCurrency(subscriptionPrice.unit_amount, 'USD', 0) }}
            <span class="subscriptions-add-on__content--heading-duration">{{
              formattedInterval
            }}</span>
          </h2>
          <p
            class="subscriptions-add-on__content--subheading u-subheader--heavy"
          >
            with {{ tierDisplayName }} you can:
          </p>
          <FeatureList
            :feature-list="tier.product.long_feature_list"
            :has-no-gap="true"
          />

          <SummaryPayment :total="totalOrProrationWithSurchargeInDollars">
            <tbody class="summary-payment__itemized-body">
              <tr>
                <th>{{ tierDisplayName }}</th>
                <th>{{ subscriptionPrice?.recurring_price }}</th>
              </tr>
              <tr class="summary-payment__itemized-subtotal">
                <th scope="row">subtotal</th>
                <td data-cypress="summary-payment-subtotal">
                  <template v-if="showProrationPreview">
                    {{ toCurrency(prorationSubtotalExcludingTaxInDollars) }}
                  </template>
                  <template v-else>
                    {{ toCurrency(subscriptionPrice.unit_amount) }}
                  </template>
                </td>
              </tr>
              <tr class="summary-payment__surcharge">
                <th scope="row" class="summary-payment__surcharge-text">
                  <EstimatedSalesTax />
                </th>
                <td data-cypress="summary-payment-surcharge">
                  <span v-if="isCalculatingSalesTax">calculating...</span>
                  <span v-else-if="showProrationPreview">{{
                    toCurrency(prorationTaxAmountInDollars)
                  }}</span>
                  <span v-else>{{ taxAmountExclusiveDisplay }}</span>
                </td>
              </tr>
            </tbody>

            <template
              v-if="showProrationPreview && hasShopifyIntegration"
              #disclaimer-below-summary
            >
              <SoonaAlert>
                <h4 class="u-subheader--heavy">
                  this will be added to your existing subscription
                </h4>
                <p>
                  due to Shopify app restrictions we will refund your current
                  subscription and re-enroll you with the addition of fast pass
                  above.
                </p>
              </SoonaAlert>
            </template>
            <template #billing>
              <BillingInfo
                class="subscriptions-add-on__billing-info"
                :account-id="accountId"
              />
            </template>
            <template #payment>
              <SoonaPaymentMethods
                :on-payment-action="completePaymentMethodCheckout"
                :stripe-payment-required="chargeViaStripe"
                :is-stripe-disclaimer-below-checkout-button="chargeViaStripe"
                :action-text="'enroll now'"
                :total="subscriptionPrice?.unit_amount"
                :account-id="accountId"
                :saveable-card="false"
                :payment-method-types="['card', 'us_bank_account']"
                :has-wide-payment-button="true"
                :show-using-card-on-file="chargeUsingCardOnFile"
                is-subscription-charge
                action-variation="solid-black"
              >
                <template #action-block>
                  <div v-if="showProrationPreview">
                    <div class="subscriptions-add-on__payment-container">
                      <h4 class="u-subheader--all-caps-black">due today</h4>
                      <p class="u-headline--heavy">
                        {{ proratedTotal }}
                      </p>
                    </div>
                    <p v-if="isRenewSubscription" class="next-payment">
                      next payment charged: {{ subscriptionEndDate }}
                    </p>
                    <p class="u-small--regular">
                      upgrade now for the remaining {{ remainingTime }} of your
                      existing plan.
                      <br />
                      <strong>
                        you will be charged {{ addOnTotal }} every
                        {{ recurringInterval }} starting
                        {{ nextSubscriptionStart }}.
                      </strong>
                    </p>
                  </div>
                  <div v-else>
                    <p class="u-small--regular">
                      <strong>
                        you will be charged every
                        {{ recurringInterval }} starting
                        {{ nextSubscriptionStart }}.
                      </strong>
                    </p>
                  </div>
                  <SoonaError v-if="priorityError" no-margin>
                    {{ priorityError }}
                  </SoonaError>
                </template>
              </SoonaPaymentMethods>
            </template>
          </SummaryPayment>
          <div class="subscriptions-add-on__terms u-small--regular">
            <span v-if="tierSlug === 'fast-pass'">
              fast pass storage allows for 1 shelf 48”w x 24”d x 18”h. oversized
              items are subject to additional fees.
              {{ subscriptionPrice.formatted_price }} + sales tax recurring fee
              is billed {{ formattedInterval }} starting today. should you
              cancel prior to the end of the billing period there is no
              proration in billing.
            </span>
            <span>
              <a
                class="subscriptions-add-on__terms--link"
                href="https://soona.co/terms/"
                target="_blank"
                >read full details here</a
              >.</span
            >
          </div>

          <div class="terms-of-use-section">
            <p class="terms-of-use-section__copy">
              our crew commitment: we believe in quality content for all. if you
              don’t LOVE your content <b>we make it right</b>. please review our
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.soona.co/refund-policy"
                class="terms-of-use-section__link"
                >cancellation and refund policy</a
              >. by placing an order with soona you agree to our
              <a
                href="https://soona.co/terms/"
                target="_blank"
                class="terms-of-use-section__link"
                >terms of service</a
              >

              ({{ termsOfServiceDate }}).
            </p>
            <p v-if="isPreferredOrder" class="terms-of-use-section__copy">
              purchase of preferred credits is subject to additional terms found
              <a
                href="https://soona.co/preferred-terms"
                target="_blank"
                class="terms-of-use-section__link"
                >here</a
              >.
            </p>
            <p
              v-if="isPreferredOrder && !isCompedOrder"
              class="terms-of-use-section__copy"
            >
              ACH payment is also available. email us at
              <a class="terms-of-use-section__link" href="mailto:hey@soona.co"
                >hey@soona.co</a
              >
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscriptions-add-on {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;

    @media (min-width: variables.$screen-md-min) {
      align-items: start;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, minmax(0, 25rem));
      justify-content: center;
      padding: 2rem;
    }
  }

  &__content {
    &--left {
      display: none;

      @media (min-width: variables.$screen-md-min) {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: end;
      }
    }

    &--heading {
      font-size: 3.75rem;

      &-duration {
        font-size: 2.5rem;
      }
    }

    &--subheading {
      color: variables.$gray-80;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  &__billing-info {
    margin-bottom: 1rem;
  }

  &__payment-container {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  &__terms {
    margin-top: 0.5rem;

    &--link {
      text-decoration: underline;
    }
  }

  .terms-of-use-section {
    display: none;

    &__copy {
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }

    &__link {
      text-decoration: underline;
    }

    &__mobile {
      display: block;
      margin-top: 1rem;
    }

    @media (min-width: variables.$screen-sm-min) {
      display: block;
      margin-top: 1rem;

      &__mobile {
        display: none;
      }
    }
  }
}
</style>
