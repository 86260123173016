<script setup>
import { toCurrency } from '@/lib/currency';
import ContractFundsProgress from '@/components/user/anytime/retainers/transactions/crew/add/ContractFundsProgress.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import { computed, ref, watchEffect } from 'vue';
import { useContracts } from '@/queries/contracts/useContracts';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useRetainerTransactionReasonCodes } from '@/queries/retainer_transactions/useRetainerTransactionReasonCodes';
import { useCreateRetainerTransaction } from '@/queries/retainer_transactions/useCreateRetainerTransaction';
import { useRouter } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const router = useRouter();

const accountId = computed(() => props.accountId);

const {
  mutate,
  isPending,
  error: errorCreatingTransaction,
} = useCreateRetainerTransaction(accountId);

const {
  data: contractsResponse,
  isLoading: isLoadingContracts,
  error: errorLoadingContracts,
} = useContracts({ accountId, status: ['active'] });

const contractOptions = computed(() => {
  return contractsResponse.value?.contracts?.map(contract => ({
    label: `contract ${contract.id}`,
    value: contract.id,
  }));
});

const selectedContractId = ref(null);
const selectedContract = computed(() => {
  return contractsResponse.value?.contracts?.find(
    contract => contract.is_active
  );
});

watchEffect(() => {
  if (selectedContract.value)
    selectedContractId.value = selectedContract.value.id;
});

const funds = ref(0);
const stripeInvoiceNumber = ref(null);

const selectedReason = ref('retainer');
const {
  data: reasonCodes,
  isLoading: isLoadingReasonCodes,
  error: errorLoadingReasonCodes,
} = useRetainerTransactionReasonCodes({ accountId });
const reasons = computed(() => {
  return reasonCodes.value?.reason_codes?.map(reason => ({
    label: reason.name,
    value: reason.id,
  }));
});

const disableSubmit = computed(() => {
  if (!selectedContract.value) return true;
  if (funds.value <= 0) return true;

  return !selectedReason.value;
});

const contractTotalRetainerFunded = computed(
  () => selectedContract.value?.retainer_amount_funded
);
const remainingFunds = computed(() => {
  const remaining =
    selectedContract.value?.retainer_amount - contractTotalRetainerFunded.value;
  return remaining ?? 0;
});

const addFunds = event => {
  const data = new FormData(event.target);
  const addedFunds = data.get('funds');
  const stripeInvoiceNumber = data.get('stripe-invoice-number');

  mutate(
    {
      body: {
        amount: +addedFunds,
        contract_id: selectedContract.value.id,
        reason_code: selectedReason.value,
        stripe_invoice_number: stripeInvoiceNumber,
        transaction_type: 'recharge',
      },
    },
    {
      onSuccess: () => {
        router.push(`/account/${accountId.value}/contracts`);
      },
    }
  );
};

const priorityErrors = usePriorityErrors(
  errorLoadingContracts,
  errorLoadingReasonCodes,
  errorCreatingTransaction
);

const isLoading = computed(() => {
  return isLoadingContracts.value || isLoadingReasonCodes.value;
});
</script>
<template>
  <SoonaForm class="add-funds-form" @submit="addFunds">
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <SoonaLoading :is-loading="isPending" loading-text="adding to balance" />
    <SoonaLoading :is-loading="isLoading" />
    <SoonaSelect
      v-model="selectedContractId"
      class="add-funds-form__select-contract"
      placeholder="select contract"
      :options="contractOptions"
      :loading="isLoadingContracts"
      :disabled="isLoadingContracts"
    >
      <template #label>Contract</template>
    </SoonaSelect>
    <div class="add-funds-form__info">
      <h3 class="u-title--heavy add-funds-form__info-title">
        Account balance info
      </h3>
      <div class="add-funds-form__total-balance">
        <p class="add-funds-form__total-balance--title">
          Total account balance
        </p>
        <p class="u-headline--heavy">
          {{ toCurrency(selectedContract?.retainer_amount) }}
        </p>
      </div>
      <ContractFundsProgress
        v-if="selectedContract"
        :remaining-funds="remainingFunds"
        :total-funded="contractTotalRetainerFunded"
        :total="selectedContract.retainer_amount"
      />
      <div class="add-funds-form__info-fields">
        <SoonaTextfield
          v-model="funds"
          label="Add funds to balance"
          name="funds"
          placeholder="0.00"
          required
          type="number"
        >
          <template #icon-left>
            <SoonaIcon size="medium" name="dollar-sign" />
          </template>
        </SoonaTextfield>
        <SoonaTextfield
          v-model="stripeInvoiceNumber"
          label="Stripe invoice number (optional)"
          name="stripe-invoice-number"
          type="text"
          placeholder="ABC-0001"
        />
      </div>
      <div class="add-funds-form__info-reason-code">
        <SoonaSelect
          v-model="selectedReason"
          placeholder="Reason for adding funds"
          :options="reasons"
          :loading="isLoadingContracts"
          :disabled="isLoadingContracts"
          label="label"
        >
          <template #label>Reason code</template>
        </SoonaSelect>
      </div>
    </div>
    <SoonaButton
      type="submit"
      class="add-funds-form__submit is-lowercase"
      variation="solid-black"
      :disabled="disableSubmit"
    >
      Add account balance
    </SoonaButton>
  </SoonaForm>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.add-funds-form {
  &__select-contract,
  &__info-title {
    margin-bottom: 1rem;
  }

  &__total-balance {
    margin-bottom: 1rem;

    &--title {
      @include variables_fonts.u-body--heavy;

      color: variables.$gray-60;
    }
  }

  &__info {
    border: 0.125rem solid variables.$gray-30;
    background-color: variables.$periwink-blue-10;
    border-radius: 0.625rem;
    padding: 1.563rem;
    margin-bottom: 1rem;

    &-fields {
      display: flex;
      gap: 1.25rem;

      & > * {
        flex: 1;
      }
    }
  }

  :deep(.vs__dropdown-toggle) {
    background-color: variables.$white-default;
  }

  &__submit {
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
}
</style>
