<script setup>
import { computed, ref, toRefs, useId, watchEffect } from 'vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import DigitalAssetNotesCondensed from '@/components/notes/digital-asset-notes/DigitalAssetNotesCondensed.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useInfiniteNotes } from '@/queries/notes/useInfiniteNotes';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
  submitLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['cancel', 'submit']);

const { accountId, digitalAssetId } = toRefs(props);

const { previewUrl, title } = useMediaEditorDigitalAsset(
  accountId,
  digitalAssetId
);

// same query as nested notes, so we'll let that handle errors, hopefully
const { data: notesData } = useInfiniteNotes(
  'digital_assets',
  digitalAssetId,
  {
    itemsPerPage: 1,
  },
  {
    // just in case
    enabled: computed(() => !!digitalAssetId.value),
  }
);

const totalCount = computed(
  () => notesData.value?.pages?.at(-1)?.pagination?.totalCount ?? 0
);

const showNotesRequiredError = ref(false);

watchEffect(() => {
  if (totalCount.value > 0) {
    showNotesRequiredError.value = false;
  }
});

function submit() {
  if (totalCount.value < 1) {
    showNotesRequiredError.value = true;
  } else {
    emit('submit');
  }
}

const hiwId = useId();
</script>

<template>
  <SoonaDialog max-width="40.5625rem" @close="$emit('cancel')">
    <template #heading>add to a re-edit request</template>

    <div class="request-re-edit-dialog">
      <div class="request-re-edit-dialog__asset">
        <img
          :src="previewUrl"
          :alt="title"
          class="request-re-edit-dialog__asset__preview"
        />
        <div class="request-re-edit-dialog__asset__notes">
          <h3 class="u-body--heavy">editing notes</h3>
          <DigitalAssetNotesCondensed
            :account-id="accountId"
            :digital-asset-id="digitalAssetId"
            placeholder="add editing notes"
          />
        </div>
      </div>

      <SoonaError v-if="showNotesRequiredError" no-margin>
        notes are required
      </SoonaError>

      <div class="request-re-edit-dialog__how-it-works">
        <h3 :id="hiwId" class="u-subheader--heavy">how it works</h3>
        <ul :aria-labelledby="hiwId" class="request-re-edit-dialog__list">
          <li>
            <SoonaIcon name="image-plus" />
            <div class="li-content">
              <p class="u-body--heavy">add more images to your request</p>
              <p>
                if you need re-edits for more images, add them all to one
                request. when you’re ready, submit the request from your
                original booking.
              </p>
            </div>
          </li>
          <li>
            <SoonaIcon name="clock" />
            <div class="li-content">
              <p class="u-body--heavy">automatically submitted after 6 hours</p>
              <p>
                if you don’t click “submit”, your request will be automatically
                submitted after 6 hours.
              </p>
            </div>
          </li>
          <li>
            <SoonaIcon name="send-alt-1" />
            <div class="li-content">
              <p class="u-body--heavy">fees invoiced for new requests</p>
              <p>
                if this was our mistake, there will be no charge for the re-edit
                and we’ll start immediately. if this is a new request, there
                will be an editing fee.
              </p>
            </div>
          </li>
          <li>
            <SoonaIcon name="party-horn" />
            <div class="li-content">
              <p class="u-body--heavy">
                delivered ASAP within 1-3 business days
              </p>
              <p>
                once approved, our editors will make the requested updates and
                add the new assets to your gallery.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <template #footer="{ close }">
      <SoonaButton variation="tertiary" size="medium" @on-click="close">
        cancel
      </SoonaButton>
      <SoonaButton
        variation="primary"
        size="medium"
        :loading="submitLoading"
        @on-click="submit"
      >
        add to request
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.request-re-edit-dialog {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__asset {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: flex-start;

    &__preview {
      flex: 0 1 14.375rem;
      max-width: 14.375rem;
      border-radius: 0.3125rem;
      object-fit: contain;
    }

    &__notes {
      flex: 1 1 14rem;
      display: flex;
      flex-direction: column;
      gap: 0.4375rem;
    }
  }

  &__how-it-works {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-block: 0.5rem;

    li {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      align-items: flex-start;

      svg {
        flex: 0 0 1.5rem;
      }

      .li-content {
        flex: 1 1 0;
      }
    }
  }
}
</style>
