<script setup>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaResponsiveImageCard from '@/components/ui_library/SoonaResponsiveImageCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { keepPreviousData } from '@tanstack/vue-query';
import { useInfiniteReservationDigitalAssets } from '@/queries/reservation_digital_assets/useInfiniteReservationDigitalAssets';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);
const reservationId = computed(() => props.reservationId);

const filters = {
  origin: 'soona',
  ownership: 'customer',
  asset_type: 'original',
  include_archived: true,
};
const {
  data: purchasedRdasPages,
  isLoading: isLoadingOriginalRdas,
  fetchNextPage,
  hasNextPage,
} = useInfiniteReservationDigitalAssets(
  reservationId,
  {
    itemsPerPage: 20,
    filters,
  },
  { placeholderData: keepPreviousData }
);

const purchasedOriginalRdas = computed(() =>
  (purchasedRdasPages.value?.pages || []).flatMap(page => page.assets)
);

const { mutate: assignOriginalAndParent, isPending: isAssigningOriginal } =
  useUpdateDigitalAsset(accountId);

const chooseOriginal = chosenOriginal => {
  assignOriginalAndParent(
    {
      assetId: digitalAssetId.value,
      body: {
        parent_id: chosenOriginal.id,
        original_id: chosenOriginal.original_id || chosenOriginal.id,
      },
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

const isLoading = computed(() => {
  return isLoadingOriginalRdas.value || isAssigningOriginal.value;
});

const loadingText = computed(() => {
  if (isAssigningOriginal.value) return 'assigning original';
  return 'loading originals';
});
</script>
<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>choose original</template>
    <template #default>
      <div class="choose-original__wrapper">
        <SoonaLoading
          v-if="isLoading"
          is-loading
          is-contained
          :loading-text="loadingText"
        />
        <div v-if="!purchasedOriginalRdas?.length">
          there are no purchased originals
        </div>
        <template v-else>
          <SoonaResponsiveImageCard
            v-for="rda in purchasedOriginalRdas"
            :key="rda.id"
            :media-item="rda.digital_asset"
            :aria-labelledby="`choose-original-${rda.id}`"
            class="image-card"
          >
            <template #content>
              <div class="image-card__content">
                <p :id="`choose-original-${rda.id}`" class="image-card__title">
                  {{ rda.digital_asset.title }}
                </p>
                <div>
                  <SoonaButton
                    :disabled="isAssigningOriginal"
                    @click="chooseOriginal(rda.digital_asset)"
                  >
                    choose
                  </SoonaButton>
                </div>
              </div>
            </template>
          </SoonaResponsiveImageCard>
          <SoonaButton
            v-if="hasNextPage"
            variation="secondary-gray"
            @on-click="fetchNextPage"
          >
            load more
          </SoonaButton>
        </template>
      </div>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
.choose-original {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.image-card {
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  &__title {
    word-break: break-word;
  }
}
</style>
