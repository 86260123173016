<script setup>
import { onMounted, watchEffect, ref } from 'vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import BookingCheckbox from 'src/components/booking/sign_in/BookingCheckbox.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaTelephoneField from '../ui_library/SoonaTelephoneField.vue';
import SoonaButton from '../ui_library/SoonaButton.vue';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { usePhoneHelper } from '@/composables/usePhoneHelper';
import { useUpdateUser } from 'src/queries/users/useUpdateUser';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  onChange: {
    type: Function,
    required: true,
  },
  handleNextStep: {
    type: Function,
    required: true,
  },
  editAccountInfo: {
    type: Function,
    required: true,
  },
  collapsed: {
    type: Boolean,
    required: false,
    default: false,
  },
  checkoutBuffer: {
    type: Boolean,
    required: false,
    default: false,
  },
  companyName: {
    type: String,
    required: false,
    default: '',
  },
});

const showError = ref(false);
const hasLoadedInitialData = ref(false);
const { me, currentAccountId, currentUserId } = useMe();
const { mutate: updateAccount } = useUpdateAccount(currentAccountId);
const { mutate: updateUser } = useUpdateUser(currentUserId);
const { isPhoneNumberValid } = usePhoneHelper();

const userData = ref({
  companyName: '',
  phoneNumber: '',
  acceptTextMessages: true,
});

const saveAccountInfo = async () => {
  const isValid = await isPhoneNumberValid(userData.value.phoneNumber);
  if (!isValid) {
    showError.value = true;
    throw new Error('Invalid phone number');
  }
  showError.value = false;
  const accountUpdates = {
    name: userData.value.companyName,
  };
  updateAccount(accountUpdates);

  const userUpdates = {
    phone: userData.value.phoneNumber,
    accept_text_messages: userData.value.acceptTextMessages,
  };
  updateUser(userUpdates, {
    onSuccess: () => {
      props.handleNextStep();
    },
  });
};

onMounted(() => {
  props.onChange(userData);
});

watchEffect(() => {
  userData.value.phoneNumber = me.value.phone;
  userData.value.companyName = props.companyName;
  if (!hasLoadedInitialData.value) {
    hasLoadedInitialData.value = true;
    if (userData.value.phoneNumber) {
      props.handleNextStep();
    }
  }
});

const handlePhoneChange = () => {
  showError.value = false;
};
</script>
<template>
  <div>
    <fieldset
      class="account-info"
      :class="{ 'checkout-buffer': checkoutBuffer }"
    >
      <div class="account-info__header">
        <legend class="account-info__title is-lowercase">Account Info</legend>
      </div>
      <SoonaForm v-if="!collapsed">
        <div class="account-info__text">
          <p class="u-label--heavy is-lowercase">Name</p>
          <p class="u-body--regular">{{ me.name }}</p>
        </div>
        <div class="account-info__text">
          <p class="u-label--heavy is-lowercase">email</p>
          <p class="u-body--regular">{{ me.email }}</p>
        </div>
        <SoonaTextfield
          v-model="userData.companyName"
          class="account-info__textfield"
          label="company name"
          type="text"
          @on-blur="onChange(userData)"
          ><template #helper>optional</template></SoonaTextfield
        >
        <SoonaTelephoneField
          v-model="userData.phoneNumber"
          class="account-info__textfield"
          :required="true"
          label="phone number"
          cypress-name="downpayment-input-phone"
          @on-blur="onChange(userData)"
          @update:model-value="handlePhoneChange()"
          ><template #helper>required</template></SoonaTelephoneField
        >
        <BookingCheckbox
          v-model="userData.acceptTextMessages"
          class="account-info__booking-checkbox"
        >
          yes! soona can text me! i understand message and data rates may apply.
        </BookingCheckbox>
        <SoonaButton
          class="account-info__save-info is-lowercase"
          size="large"
          data-cypress="downpayment-button-save-info"
          variation="primary"
          :disabled="showError || !userData.phoneNumber"
          @on-click="saveAccountInfo"
          >Save account info</SoonaButton
        >
      </SoonaForm>
      <div v-else class="account-info__preview-container">
        <p class="u-body--regular">{{ me?.name }}</p>
        <p class="u-body--regular">{{ me?.email }}</p>
        <p class="u-body--regular">{{ userData?.companyName }}</p>
        <p class="u-body--regular">{{ userData?.phoneNumber }}</p>
        <a class="account-info__edit-btn is-lowercase" @click="editAccountInfo"
          >Edit</a
        >
      </div>
    </fieldset>
    <SoonaError v-if="showError">
      {{ userData.phoneNumber }}
      please enter a valid phone number to continue.
    </SoonaError>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.account-info {
  border: 1px solid variables.$gray-30;
  background-color: variables.$periwink-blue-10;
  border-radius: 0.625rem;
  padding: 1.5rem;
  min-width: auto;
  position: relative;

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;

    &:hover {
      color: variables.$black-default;
    }
  }

  &__title {
    @include variables_fonts.u-title--heavy;
    font-size: 1.5rem;
  }

  &__textfield {
    padding: 0;
    margin: 1rem 0;
  }

  &__booking-checkbox {
    width: 100%;
  }

  .soona-flash {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0.3125rem;
    border: 1px solid transparent;
    margin: 0.625rem 0 3.25rem 0;
    padding: 0.625rem;

    &--error {
      border-color: variables.$roses-30;
      background-color: variables.$roses-10;
    }

    &__icon {
      flex: 0 0 1.25rem;
      margin: 0.125rem 0.5rem 0 0;
    }

    &__message {
      @include variables_fonts.u-body--regular;
    }
  }

  &__save-info {
    width: stretch;
  }

  &__preview-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  &__edit-btn {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    @include variables_fonts.u-body--regular;
    text-decoration: underline;
  }

  &__error {
    margin-top: 0.5rem;
  }

  &__text {
    gap: 0.25rem;
    margin: 1rem 0;
  }
}

.checkout-buffer {
  margin-top: 2.5rem;
}
</style>
