<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import { useContract } from '@/composables/contracts/useContract';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import ContractSummaryField from './ContractSummaryField.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  contractId: {
    required: true,
    type: [Number, String],
  },
});
const accountId = computed(() => props.accountId);
const contractId = computed(() => props.contractId);

const {
  contract: activeContract,
  contractTotalDisplay,
  accountManager,
  createdAt,
  enterpriseContractItem,
  discount,
  fastPassContractItem,
  gifUnitRateDisplay,
  photoUnitRateDisplay,
  ugcUnitRateDisplay,
  videoUnitRateDisplay,
} = useContract({ accountId, contractId });

const contractDuration = computed(
  () =>
    `${dayjs(activeContract.value?.start_date).format('MM/DD/YYYY')} - ${dayjs(
      activeContract.value?.end_date
    ).format('MM/DD/YYYY')}`
);
</script>

<template>
  <div class="contract-summary">
    <div class="contract-summary__account-manager">
      <SoonaAvatar
        :name="accountManager?.name"
        :url="accountManager?.avatar_url"
        :size="'2.5rem'"
        type="account"
      />
      <div class="contract-summary__account-manager-info">
        <p class="u-badge--small">MANAGED BY</p>
        <p class="u-body--heavy">
          {{ accountManager?.name }}
        </p>
      </div>
    </div>
    <div class="contract-summary__summary-content">
      <p class="u-subheader--heavy">Contract ID: {{ activeContract?.id }}</p>
      <p class="contract-summary__subtext">
        created on:
        {{ dayjs(createdAt).format('MM/DD/YYYY') }}
      </p>
      <div class="contract-summary__summary-row">
        <ContractSummaryField
          label="Total contract value"
          :value="contractTotalDisplay"
        />
        <ContractSummaryField
          label="Contract duration"
          :value="contractDuration"
        />
        <ContractSummaryField
          label="Platform membership"
          :value="!!enterpriseContractItem ? 'Included' : 'Not included'"
        />
        <ContractSummaryField
          label="Fast Pass"
          :value="!!fastPassContractItem ? 'Included' : 'Not included'"
        />
      </div>
      <div class="contract-summary__summary-row">
        <ContractSummaryField
          label="Pro service discount rate"
          :value="`${parseInt(discount?.percent_off)}%`"
        />
        <ContractSummaryField
          label="Photo unit rate"
          :value="photoUnitRateDisplay"
        />
        <ContractSummaryField
          label="Video clip unit rate"
          :value="videoUnitRateDisplay"
        />
        <ContractSummaryField
          label="GIF unit rate"
          :value="gifUnitRateDisplay"
        />
        <ContractSummaryField
          label="UGC unit rate"
          :value="ugcUnitRateDisplay"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-summary {
  border: 1px solid variables.$gray-30;
  border-radius: 10px;
  overflow: hidden;
  margin: 1.5rem 0;

  &__account-manager {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
    background-color: variables.$periwink-blue-20;
  }

  &__account-manager-info {
  }

  &__summary-content {
    padding: 1.5rem;
  }

  &__subtext {
    @include variables_fonts.u-badge--small;
    color: variables.$gray-60;
    margin-bottom: 2rem;
  }

  &__summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;
  }
}

@media (max-width: 27rem) {
  .contract-summary {
    &__summary-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
