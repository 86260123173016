import { computed, toValue } from 'vue';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';

export function useAccount(accountId) {
  const {
    data: account,
    error,
    isLoading,
    status,
    isSuccess,
  } = useAccountQuery(accountId, {
    enabled: computed(() => !!toValue(accountId)),
  });

  const isTaxExempt = computed(() => account.value?.tax_exempt);

  const hasCompletedCustomerQuestionnaire = computed(() => {
    const profileData = account.value?.profile_data;
    if (!profileData.customer_questionnaire_status) return true;

    return profileData.customer_questionnaire_status?.complete === true;
  });

  const hasActiveStoreIntegration = computed(() => {
    return account.value?.has_active_store_integration;
  });

  // subscription
  // NOTE: If you need anything related to the active subscription, please use the `useActiveSubscription` composable
  // payments_todo: remove this from account composable and solely use useActiveSubscription (pab-604)
  const {
    subscription,
    baseSubscriptionItem,
    hasActiveSubscription,
    isBusinessSubscriber,
    isPendingInvoiceSubscription,
    pendingInvoice,
    subscriptionBillingCycle,
    subscriptionChargedViaStripe,
    subscriptionTierSlug,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
    hasSoonaStorage,
  } = useActiveSubscription(accountId);

  const isFastPassSubscriber = computed(() => {
    return subscription.value?.tier?.slug === 'fast-pass';
  });

  const isDigitalSubscriber = computed(() => {
    return account.value?.is_digital_subscriber;
  });

  const eligibleForPlatformSubscriptionTrial = computed(() => {
    return account.value?.eligible_for_platform_subscription_trial;
  });

  const industryTitle = computed(() => {
    return account.value?.industry?.title;
  });

  const billingAddressId = computed(() => {
    return account.value?.billing_address?.id ?? null;
  });

  const hasEmbeddingsEnabled = computed(() => {
    return account.value?.embeddings_enabled;
  });

  const defaultPaymentMethod = computed(() => {
    return account.value?.payment_methods?.find(p => p.default);
  });

  const defaultCardPaymentMethod = computed(() => {
    return account.value?.payment_methods?.find(
      p => p.default && p.payment_method_type === 'card'
    );
  });

  const billingAddress = computed(() => account.value?.billing_address);

  const accountOwnerId = computed(() => {
    return account.value?.owner?.id;
  });

  const wasPreviousRetainerClient = computed(() => {
    return account.value?.previous_retainer_client ?? false;
  });

  const isActiveRetainerClient = computed(() => {
    return account.value?.is_active_retainer_client ?? false;
  });

  return {
    account,
    accountOwnerId,
    billingAddress,
    billingAddressId,
    defaultCardPaymentMethod,
    defaultPaymentMethod,
    error,
    isActiveRetainerClient,
    isLoading,
    status,
    isSuccess,
    baseSubscriptionItem,
    hasActiveStoreIntegration,
    hasActiveSubscription,
    hasCompletedCustomerQuestionnaire,
    hasSoonaStorage,
    eligibleForPlatformSubscriptionTrial,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
    isPendingInvoiceSubscription,
    isDigitalSubscriber,
    isBusinessSubscriber,
    isFastPassSubscriber,
    isTaxExempt,
    industryTitle,
    subscription,
    subscriptionBillingCycle,
    subscriptionChargedViaStripe,
    subscriptionTierSlug,
    pendingInvoice,
    hasEmbeddingsEnabled,
    wasPreviousRetainerClient,
  };
}
