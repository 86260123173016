import { computed } from 'vue';

/**
 * @param {Ref<Symbol>} subscription
 */
export function useSubscriptionBilling(subscription) {
  const isPendingInvoiceSubscription = computed(() => {
    return subscription.value?.is_invoice_payment_pending;
  });

  const pendingInvoice = computed(() => {
    return subscription.value?.pending_invoice;
  });

  const subscriptionBillingCycle = computed(() => {
    return subscription.value?.billing_cycle;
  });

  const subscriptionStartDate = computed(() => {
    return subscription.value?.start_date;
  });

  const subscriptionChargedViaStripe = computed(() => {
    return subscription.value?.payment_provider === 'stripe';
  });

  return {
    isPendingInvoiceSubscription,
    pendingInvoice,
    subscriptionBillingCycle,
    subscriptionChargedViaStripe,
    subscriptionStartDate,
  };
}
