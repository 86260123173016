import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | Ref<string>} context
 */
export function useGetProServiceFeedbackOptions(context) {
  return useQuery({
    queryKey: queryKeys.proServiceFeedbackOptions(context),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/pro_service_feedbacks/options/${context}`,
        {
          signal,
        }
      );

      return response.data;
    },
  });
}
