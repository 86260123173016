import { toValue } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useInternalPublishing(accountId, asin, queryOptions) {
  return useQuery({
    queryKey: queryKeys.internalPublishing(accountId, asin),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/internal_publishing/${toValue(asin)}.json`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

export function useInternalPublish(asin, queryOptions) {
  return useMutation({
    mutationFn: async params => {
      await http.post(`/internal_publishing/${toValue(asin)}.json`, params);
    },
    ...queryOptions,
  });
}
