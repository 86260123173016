import { useQuery } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { isEmptyValue } from '@/lib/global/is-empty-value';

/**
 * @param {MaybeRef<string>} accountId
 * @param {Object} [queryOptions]
 */
export function useActiveSubscription(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.activeSubscription(accountId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/active_subscription.json`,
        {
          signal,
        }
      );

      return isEmptyValue(response.data) ? null : response.data;
    },
    ...queryOptions,
  });
}
