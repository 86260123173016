<script setup>
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ref, watch, computed, onUnmounted, toRefs } from 'vue';
import { createBatchEditStore } from './store/useBatchEditStore';
import { useStartViewTransition } from '@/composables/useStartViewTransition';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import BatchResize from '@/components/batch_edits/resize/BatchResize.vue';
import BatchEditOption from '@/components/batch_edits/BatchEditOption.vue';
import BatchLocation from '@/components/batch_edits/location/BatchLocation.vue';
import BatchRemoveBackground from '@/components/batch_edits/remove_background/BatchRemoveBackground.vue';
import { useBatchEditTools } from '@/components/batch_edits/store/useBatchEditTools';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits([
  'close',
  'reset-selected-assets',
  'remove-selected-asset',
]);

const { accountId, selectedAssets } = toRefs(props);

const router = useRouter();
const route = useRoute();

const showGalleryOnly = ref(true);

const batchEditStore = createBatchEditStore({ selectedAssets, accountId });
const { isMobile, isDesktop, batchEditsAction, selectingLocation } =
  storeToRefs(batchEditStore);

const {
  BATCH_REMOVE_BACKGROUND_ACTION,
  BATCH_RESIZE_ACTION,
  batch_edit_tools,
} = useBatchEditTools();

const closeDialog = () => {
  emit('close');
  const { batch_edit, ...rest } = route.query;
  void batch_edit;
  router.replace({ query: rest });
};

const resetSelectedAssets = () => {
  emit('reset-selected-assets');
};

const removeSelectedAsset = asset => {
  emit('remove-selected-asset', asset);
};

const { startViewTransition } = useStartViewTransition();

const handleShowGalleryOnly = value => {
  startViewTransition(() => {
    showGalleryOnly.value = value;
  });
};

watch(isMobile, value => {
  if (value) handleShowGalleryOnly(true);
});

watch(batchEditsAction, value => {
  if (value) {
    router.push({ query: { ...route.query, batch_edit: true } });
  }
});

onBeforeRouteUpdate(async (to, from) => {
  if ('batch_edit' in from.query) {
    emit('close');
    const { batch_edit, ...rest } = from.query;
    void batch_edit;
    return { path: to.path, query: rest, hash: to.hash };
  }
});

const dialogSize = computed(() => {
  return batchEditsAction.value ? 'full-screen' : 'large';
});

const dialogTitle = computed(() => {
  if (batchEditsAction.value === BATCH_RESIZE_ACTION) {
    return 'crop / resize';
  } else if (batchEditsAction.value === BATCH_REMOVE_BACKGROUND_ACTION) {
    return 'remove background';
  } else {
    return 'batch edit';
  }
});

onUnmounted(() => {
  batchEditStore.$reset();
});
</script>

<template>
  <SoonaDialog
    :size="dialogSize"
    class="batch-edit-dialog"
    :class="{ 'batch-edit-dialog--no-padding': batchEditsAction }"
    @close="closeDialog"
  >
    <template #heading>
      <SoonaTransition name="fade" exit-absolute>
        <span v-if="!selectingLocation">{{ dialogTitle }}</span>

        <span v-else class="batch-edit-dialog__title">
          <SoonaButton
            variation="tertiary"
            @click="batchEditStore.locationGoBack"
          >
            <SoonaIcon name="arrow-left" />
            <span v-if="isDesktop" class="u-title--heavy">back</span>
          </SoonaButton>
        </span>
      </SoonaTransition>
    </template>

    <div v-if="!batchEditsAction" class="u-body--regular">
      edit multiple photos all at once!
    </div>

    <template v-if="!batchEditsAction">
      <template v-for="tool in batch_edit_tools" :key="tool.title">
        <BatchEditOption
          v-if="tool.active"
          :tool="tool"
          @set-action="batchEditStore.setBatchEditsAction(tool.symbol)"
        />
      </template>
    </template>
    <template v-else-if="!!batchEditsAction">
      <SoonaTransition name="fade-left" exit-name="fade">
        <BatchLocation v-if="selectingLocation" />
      </SoonaTransition>

      <BatchResize
        v-if="batchEditsAction === BATCH_RESIZE_ACTION"
        :show-gallery-only="showGalleryOnly"
        @close="closeDialog"
        @reset-selected-assets="resetSelectedAssets"
        @update-show-gallery-only="handleShowGalleryOnly($event)"
      />
      <BatchRemoveBackground
        v-else-if="batchEditsAction === BATCH_REMOVE_BACKGROUND_ACTION"
        @close="closeDialog"
        @reset-selected-assets="resetSelectedAssets"
        @remove-selected-asset="removeSelectedAsset"
      />
    </template>
    <template v-if="!!batchEditsAction && isMobile && !showGalleryOnly" #footer>
      <div
        class="batch-edit-dialog__footer"
        :class="{ 'batch-edit-dialog__footer--mobile': isMobile }"
      >
        <SoonaButton size="medium" variation="tertiary" @click="closeDialog">
          cancel
        </SoonaButton>
        <SoonaButton @click="() => handleShowGalleryOnly(true)">
          save selection
        </SoonaButton>
      </div>
    </template>
    <template v-else-if="!!batchEditsAction && !selectingLocation" #footer>
      <div
        class="batch-edit-dialog__footer"
        :class="{ 'batch-edit-dialog__footer--mobile': isMobile }"
      >
        <SoonaButton size="medium" variation="tertiary" @click="closeDialog">
          cancel
        </SoonaButton>
        <SoonaButton @click="batchEditStore.setSelectingLocation(true)">
          continue
        </SoonaButton>
      </div>
    </template>
  </SoonaDialog>
</template>
<style lang="scss">
.batch-edit-dialog {
  overflow: hidden;

  &__footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;

    &--mobile {
      justify-content: space-between;
    }
  }

  &__title {
    height: 2rem;
    display: flex;
    align-items: center;
  }

  .soona-dialog__footer {
    border-top: none !important;
  }

  &--no-padding {
    .soona-dialog__content {
      padding: 0;
      position: relative;

      &:not(:first-child) {
        padding-top: 0;
      }

      &:not(:last-child) {
        padding-bottom: 0;
      }
    }
  }
}
</style>
