<script setup>
import { computed, ref, toRefs, useId } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useGetProServiceFeedbackOptions } from '@/queries/pro_service_feedback/useGetProServiceFeedbackOptions';
import { useMe } from '@/composables/user/useMe';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useRouter } from 'vue-router';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateCrewAssignment } from '@/queries/useUpdateCrewAssignment';

const props = defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
  assignmentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { addToast } = useSoonaToast();
const router = useRouter();
const formId = useId();

const { assignmentId, reservationId } = toRefs(props);
const selectedOption = ref(undefined);
const additionalFeedback = ref(null);

const showNotes = computed(() => {
  return ['uncomfortable', 'other'].some(option =>
    selectedOption.value?.includes(option)
  );
});

const notesCopy = computed(() => {
  return selectedOption.value?.includes('other')
    ? {
        label: 'other reason',
        subtext: null,
        placeholder: 'please describe your reason in a few words',
      }
    : {
        label: 'additional feedback',
        subtext:
          'info about your preferences helps us make better scheduling decisions in the future!',
        placeholder: null,
      };
});

const unavailable = computed(() => {
  return selectedOption.value?.includes('not available');
});

const { currentAccountId, currentUserId } = useMe();

const {
  data: feedbackOptions,
  error: feedbackOptionsError,
  isLoading: feedbackOptionsLoading,
} = useGetProServiceFeedbackOptions('assignment');

const {
  mutate: updateCrewAssignment,
  isPending: updateCrewAssignmentPending,
  error: updateCrewAssignmentError,
} = useUpdateCrewAssignment({
  crewAssignmentId: assignmentId,
  reservationId: reservationId,
  onSuccess: async () => {
    if (unavailable.value) {
      await router.push({
        name: 'availability',
        params: { accountId: currentAccountId.value },
      });
    } else {
      addToast('thanks for your response', {
        variation: 'success',
      });

      emit('close');
    }
  },
});

const handleDecline = () => {
  updateCrewAssignment({
    status: 'declined',
    pro_service_feedback_attributes: {
      subject_type: 'CrewAssignment',
      subject_id: assignmentId.value,
      options: [selectedOption.value],
      notes: { additional_feedback: additionalFeedback.value },
      category: 'decline_assignment',
      user_id: currentUserId.value,
    },
  });
};

const isLoading = computed(
  () => feedbackOptionsLoading.value || updateCrewAssignmentPending.value
);

const priorityErrors = usePriorityErrors(
  feedbackOptionsError,
  updateCrewAssignmentError
);
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <SoonaLoading v-if="isLoading" is-contained is-loading />
    <template #heading> you’re about to decline this shoot </template>
    <SoonaForm
      :id="formId"
      class="decline-dialog--body"
      @submit="handleDecline"
    >
      <p>😿 we’re sorry this opportunity doesn’t seem like a good fit.</p>
      <fieldset class="decline-dialog--options">
        <legend class="u-label--heavy">reason for declining</legend>
        <p class="decline-dialog--options__subtext">
          choose the primary reason
        </p>
        <SoonaToggle
          v-for="(option, index) in feedbackOptions"
          :key="option"
          type="radio"
          :label="option"
          :model-value="selectedOption"
          :native-value="option"
          :data-cypress="`toggle-option-${index}`"
          @update:model-value="selectedOption = option"
        />
      </fieldset>
      <div v-if="unavailable" class="decline-dialog--availability-banner">
        <h3 class="u-body--heavy">
          okay! let us know if other times might work.
        </h3>
        <p>
          please update your availability now so we can offer you the best times
          for your schedule.
        </p>
      </div>
      <div v-if="showNotes" class="decline-dialog--additional-feedback">
        <SoonaTextfield
          v-model="additionalFeedback"
          class="decline-dialog--additional-feedback__textarea"
          :label="notesCopy.label"
          :placeholder="notesCopy.placeholder"
          element="textarea"
          type="text"
        >
          <template v-if="notesCopy.subtext" #subtext>
            <p class="decline-dialog--additional-feedback__text">
              {{ notesCopy.subtext }}
            </p>
          </template>
        </SoonaTextfield>
      </div>
    </SoonaForm>

    <template #footer="{ close }">
      <SoonaButton variation="tertiary" :disabled="isLoading" @click="close">
        nevermind
      </SoonaButton>
      <SoonaButton
        :disabled="!selectedOption"
        :loading="isLoading"
        type="submit"
        :form="formId"
        :data-cypress="'button-dialog-confirm'"
      >
        {{ unavailable ? 'decline & review availability' : 'decline' }}
      </SoonaButton>
    </template>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.decline-dialog {
  &--body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &--options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__subtext {
      @include variables_fonts.u-label--regular;

      color: variables.$gray-70;
    }
  }

  &--availability-banner {
    background: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
  }

  &--additional-feedback {
    &__textarea {
      padding-bottom: 0;
    }

    &__text {
      color: variables.$gray-70;
    }
  }
}
</style>
