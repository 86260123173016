import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {import('vue').maybeRefOrGetter<string | number>} accountId
 */
export function useUpdateAccountFlags(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/update_flags`,
        {
          account: body,
        }
      );

      return response.data;
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(data.id),
      });
    },
  });
}
