<script setup>
import { ref, toRefs, computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCapability } from '@/composables/useCapability';
import { useMe } from '@/composables/user/useMe';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useCreateReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useCreateReEditsCollectionDigitalAsset';
import { useDeleteReEditCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useDeleteReEditsCollectionDigitalAsset';
import { Roses60, Roses20 } from '@/variables.module.scss';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useReservation } from '@/composables/useReservation';
import { useReEditsCollection } from '@/queries/re_edits_collections/useReEditsCollection';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import RequestReEditDialog from '@/components/user/anytime/gallery/media-editor/RequestReEditDialog.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  reservationDigitalAsset: {
    type: Object,
    required: true,
  },
});

const { reservationDigitalAsset } = toRefs(props);
const { currentAccountId } = useMe();
const route = useRoute();
const router = useRouter();
const queryClient = useQueryClient();
const { addToast } = useSoonaToast();
const { hasCapability: canManageCustomerCollections } = useCapability({
  capability: 'manage_customer_collections',
});

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { reservationId, reEditsCDAId, reEditsCollectionId } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const { activeReEditsCollectionId } = useReservation(reservationId);

const daHasReEditRequest = computed(() => {
  return reservationDigitalAsset.value?.digital_asset
    ?.re_edits_collection_digital_asset?.id;
});

const { data: reEditsCollection, isLoading: reEditsLoading } =
  useReEditsCollection(assetAccountId, activeReEditsCollectionId, {
    enabled: computed(() => !!activeReEditsCollectionId.value),
  });

const submittedReEdits = computed(() => {
  return reEditsCollection.value?.options?.status === 'submitted';
});

const tooltipText = computed(() =>
  submittedReEdits.value
    ? 'there is a re-edit request in progress. when complete you can submit additional re-edit requests'
    : 'this photo has been added to your re-edit request order.'
);

const showReEditDialog = ref(false);

// submit re-edit
const {
  mutate: mutateCreateReEditsCollectionDigitalAsset,
  isPending: createPending,
} = useCreateReEditsCollectionDigitalAsset(
  assetAccountId,
  activeReEditsCollectionId,
  reservationId
);

function submitReEdit() {
  mutateCreateReEditsCollectionDigitalAsset(
    { digital_asset_id: assetId.value },
    {
      onSuccess: () => {
        addToast('re-edit request submitted', {
          variation: 'success',
        });
        showReEditDialog.value = false;
      },
      onError: () => {
        addToast('error submitting re-edit request', { variation: 'error' });
      },
    }
  );
}

/* cancel re-edit request */
const canCancel = computed(() => {
  if (!reEditsCollection.value || !reEditsCDAId.value) {
    return false;
  }

  const orderSubmitted = reEditsCollection.value.status === 'submitted';
  const reEditRequestSubmitted =
    reEditsCollection.value.options?.status === 'submitted';

  return (
    !reEditRequestSubmitted &&
    ((!orderSubmitted && currentAccountId.value === assetAccountId.value) ||
      canManageCustomerCollections.value)
  );
});

const { mutate: cancelCDA, isPending: cancelCDAPending } =
  useDeleteReEditCollectionDigitalAsset(assetAccountId, reEditsCollectionId);

const confirmCancel = ref(false);

// just in case we get into a weird state
watchEffect(() => {
  if (!canCancel.value) {
    confirmCancel.value = false;
  }
});

function cancelReEdit() {
  if (!reEditsCDAId.value) {
    return;
  }
  cancelCDA(
    { cdaId: reEditsCDAId },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.reservation(reservationId),
        });
        addToast('re-edit request canceled', { variation: 'success' });

        if (route.name === 'reservation-asset-re-edit-view') {
          await router.push({
            name: 'gallery',
            params: { reservationId: reservationId.value },
          });
        }
      },
      onError: () => {
        addToast('error canceling re-edit request', { variation: 'error' });
      },
      onSettled: () => {
        confirmCancel.value = false;
      },
    }
  );
}

const loading = computed(
  () => reEditsLoading.value || createPending.value || cancelCDAPending.value
);
</script>

<template>
  <div class="re-edit-reservation-digital-asset">
    <SoonaButton
      v-if="canCancel"
      variation="tertiary"
      size="medium"
      @on-click="confirmCancel = true"
    >
      remove from re-edit request
    </SoonaButton>
    <SoonaTooltip v-else>
      <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
        <SoonaButton
          :ref="el => setRef(el)"
          variation="tertiary"
          :disabled="
            !!daHasReEditRequest ||
            !!submittedReEdits ||
            loading ||
            (!reEditsCollection && activeReEditsCollectionId)
          "
          size="medium"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
          @click="showReEditDialog = true"
        >
          {{ daHasReEditRequest ? 're-edit requested' : 'request re-edit' }}
        </SoonaButton>
      </template>
      <template
        v-if="!!daHasReEditRequest || !!submittedReEdits"
        #tooltip-content
      >
        {{ tooltipText }}
      </template>
    </SoonaTooltip>
    <RequestReEditDialog
      v-if="showReEditDialog"
      :account-id="assetAccountId"
      :digital-asset-id="assetId"
      :submit-loading="createPending"
      @close="showReEditDialog = false"
      @submit="submitReEdit"
    />
    <SoonaDialog
      v-if="confirmCancel"
      role="alertdialog"
      icon-name="triangle-exclamation-solid"
      :icon-color="Roses60"
      :icon-bg="Roses20"
      @close="confirmCancel = false"
    >
      <template #heading>remove from re-edit request?</template>
      are you sure you want to remove this asset from the re-edit request?
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">no</SoonaButton>
        <SoonaButton variation="primary" @on-click="cancelReEdit">
          yes
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
.re-edit-reservation-digital-asset {
  display: flex;
  align-items: center;
}
</style>
