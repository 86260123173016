<template>
  <div class="profile-view">
    <AccountInfo
      v-if="isViewAccountTabs && accountQueryData && !errors"
      :account="accountQueryData"
      class="crew-account-info"
      :link-to-profile="true"
    />
    <SpeedyCheckoutBanner
      v-if="
        !errors &&
        authorized &&
        loaded &&
        speedyCheckoutFlag &&
        speedyCheckoutReservation?.fast_checkout_eligible
      "
      :checkout-deadline="speedyCheckoutReservation.fast_checkout_deadline"
      :reservation-id="speedyCheckoutReservation.id"
    />
    <ProfileLinks
      v-if="isViewAccountTabs && loaded && !errors"
      :account-id="accountId"
      :incomplete-traits-exist="incompleteTraitsExist"
    />
    <SoonaLoading
      v-if="!loaded"
      is-loading
      is-contained
      loading-text="loading…"
    />
    <router-view
      v-if="!errors && authorized && loaded"
      :incomplete-traits-exist="incompleteTraitsExist"
    />
    <section
      v-if="loaded && (errors || !authorized)"
      class="container is-flex justify-center items-center"
    >
      <div class="notification w-75">
        <h1 class="title">sorry, something went wrong…</h1>
        <div v-if="!authorized || errors.status == 403">
          this profile is private. if someone gave you this link, you'll need to
          sign into an account which has access to this profile.
        </div>
        <div v-else-if="errors.status == 404">
          we couldn't find your profile, if this continues to happen please
          reach out!
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useGetRecentlyWrappedReservations } from 'src/queries/useGetRecentlyWrappedReservations';
import { useInventoryPackages } from '@/queries/inventory/useInventoryPackages';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import ProfileLinks from 'src/components/user/anytime/dashboard/ProfileLinks.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SpeedyCheckoutBanner from '@/components/ui_library/SpeedyCheckoutBanner.vue';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';

export default {
  components: {
    SoonaLoading,
    ProfileLinks,
    AccountInfo,
    SpeedyCheckoutBanner,
  },
  props: ['accountId'],
  setup(props) {
    const { pageViewed } = useBaseEvents();
    const route = useRoute();
    const title = useTitle(null);
    const { hasCapability: isViewAccountTabs } = useCapability({
      capability: 'view_account_tabs',
    });

    const { hasCapability: canViewAssignedClientDetails } = useCapability({
      capability: 'view_assigned_client_details',
    });

    const { currentAccountId } = useMe();
    // fast checkout experiment
    const accountId = computed(() => props.accountId);
    const isMyAccount = computed(
      () => Number(props.accountId) === currentAccountId.value
    );
    const authorized = computed(
      () => isMyAccount.value || canViewAssignedClientDetails.value
    );

    const speedyCheckoutFlag = useFlag('apollo_speedy_checkout');

    const { data: accountQueryData } = useAccount(accountId);

    const { data: recentlyWrappedReservations } =
      useGetRecentlyWrappedReservations(accountId, {
        enabled: speedyCheckoutFlag.value,
      });

    // eligible packages for return
    const { data } = useInventoryPackages({
      accountId,
      hasEligibleReturns: true,
      itemsPerPage: 1,
    });

    const hasEligibleReturns = computed(
      () => data.value?.inventoryPackages.length > 0
    );

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
    });

    return {
      accountQueryData,
      authorized,
      canViewAssignedClientDetails,
      hasEligibleReturns,
      isMyAccount,
      isViewAccountTabs,
      recentlyWrappedReservations,
      speedyCheckoutFlag,
    };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
      errors: state => state.errors.errors,
    }),
    ...mapGetters('proService', ['profileIncompleteTraits']),
    incompleteTraitsExist() {
      return Object.values(this.profileIncompleteTraits).some(
        traitCount => traitCount > 0
      );
    },
    isProServiceAccount() {
      return this.account.owner.employee_type_title === 'pro services';
    },
    loadProServices() {
      return this.canViewAssignedClientDetails || this.isProServiceAccount;
    },
    speedyCheckoutReservation() {
      return this.recentlyWrappedReservations?.[0];
    },
  },
  watch: {
    async accountId() {
      if (this.accountId) {
        await this.loadFullAccount();
      }
    },
  },
  async beforeMount() {
    await this.loadFullAccount();
  },
  methods: {
    ...mapActions('account', ['loadAccount']),
    ...mapActions('proService', [
      'loadProServiceProfiles',
      'loadProServiceCategories',
    ]),
    ...mapActions('user', ['loadUser']),
    async loadFullAccount() {
      this.loaded = false;
      await this.loadAccount(this.accountId);
      await this.loadUser(this.account.account_userId);
      if (this.loadProServices) {
        await Promise.all([
          this.loadProServiceCategories(),
          this.loadProServiceProfiles(this.account.account_userId),
        ]);
      }
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.profile-view {
  position: relative;
  min-height: 12rem;
}

.crew-account-info {
  margin-bottom: 1.5rem;
}

.profile-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .profile-user-info-item {
    flex: 3 3 20rem;
    display: flex;
    flex-direction: column;
  }
  .profile-upcoming-bookings-item {
    flex: 4 4 30rem;
  }
}

@media only screen and (min-width: 880px) {
  .profile-grid {
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style>
