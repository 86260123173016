<script setup>
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';

defineProps({
  flagData: {
    default: null,
    type: Array,
  },
  options: {
    required: true,
    type: Array,
  },
  speed: {
    default: null,
    type: String,
  },
});

const asset = defineModel('asset', {
  default: null,
  type: Object,
});
</script>

<template>
  <div class="asset-details">
    <div class="u-label--regular asset-actions">
      <SoonaSelect v-model="asset" :options="options" />
      <div class="extra-actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="asset-title">
      <h2 class="u-subheader--heavy">{{ asset?.title }}</h2>
      <SoonaFlag
        v-for="(flag, ix) in flagData"
        :key="ix"
        :background-color="flag.backgroundColor"
        :text-color="flag.textColor"
        :title="flag.title"
      />
    </div>
    <div class="wrappable-content">
      <div class="asset-notes">
        <DigitalAssetNotesList
          v-if="asset && asset.id && asset.account_id"
          :account-id="asset.account_id"
          :digital-asset-id="asset.id"
          :show-no-notes-message="false"
          newest-first
        />
      </div>
      <div class="asset-add-ons">
        <!-- flexible slot for add-ons & other details -->
        <slot name="details" />
        <!-- gif info -->
        <dl v-if="asset?.options && speed">
          <dt class="u-label--heavy">dimensions</dt>
          <dd class="u-small--regular">
            {{ asset?.options.dimensions }}
          </dd>
          <dt class="u-label--heavy">speed</dt>
          <dd class="u-small--regular">{{ speed }}</dd>
          <dt class="u-label--heavy">format(s)</dt>
          <dd class="u-small--regular">
            {{ asset?.options.format.join('/') }} [{{
              asset?.options.direction
            }}]
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.asset-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.asset-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.extra-actions {
  display: flex;
  gap: 0.5rem;
}

.asset-title {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  width: 100%;
}

.wrappable-content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.asset-add-ons {
  flex-shrink: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.asset-notes {
  flex: 1 1 15rem;
}
</style>
