import { useContracts as useContractsQuery } from '@/queries/contracts/useContracts';
import { computed, toValue } from 'vue';

/**
 *
 * @param {Ref<number|string|undefined>} accountId
 * @param {array | Ref<array>} [statusues]
 */
export function useContracts({ accountId, statusues = [] }) {
  const { data, error, isLoading, status, isSuccess } = useContractsQuery(
    { accountId, status: statusues },
    {
      enabled: computed(() => !!toValue(accountId)),
    }
  );

  const contracts = computed(() => data.value?.contracts || []);

  const activeContracts = computed(() => {
    return (
      contracts.value?.filter(contract => contract.status === 'active') || []
    );
  });

  const activeContract = computed(() => {
    return activeContracts.value?.length > 0 ? activeContracts.value[0] : null;
  });

  return {
    activeContract,
    contracts,
    error,
    isLoading,
    isSuccess,
    status,
  };
}
