<script setup>
import { computed, ref } from 'vue';
import NotesCondensed from '../NotesCondensed.vue';
import DigitalAssetNotesDialog from '@/components/notes/digital-asset-notes/DigitalAssetNotesDialog.vue';
import { useDigitalAssetNotesAreReadonly } from '@/components/notes/digital-asset-notes/useDigitalAssetNotesAreReadonly';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    default: null,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
});

const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);

const readonly = useDigitalAssetNotesAreReadonly(accountId, digitalAssetId);

const seeAllNotesOpen = ref(false);
</script>

<template>
  <NotesCondensed
    :placeholder="placeholder"
    subject-type="digital_assets"
    :subject-id="digitalAssetId"
    :readonly="readonly"
    @see-all-notes="seeAllNotesOpen = true"
  />
  <DigitalAssetNotesDialog
    v-if="seeAllNotesOpen"
    :account-id="accountId"
    :digital-asset-id="digitalAssetId"
    :placeholder="placeholder"
    @close="seeAllNotesOpen = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.notes-condensed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4375rem;
  align-self: stretch;
}
</style>
