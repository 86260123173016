<script setup>
import { useId, useTemplateRef } from 'vue';

defineProps({
  label: {
    default: '',
    required: false,
    type: String,
  },
  align: {
    default: 'left',
    required: false,
    type: String,
    validator: align => ['left', 'right'].includes(align),
  },
  disabled: {
    default: false,
    required: false,
    type: Boolean,
  },
  required: {
    default: false,
    required: false,
    type: Boolean,
  },
  type: {
    required: true,
    type: String,
    validator: type => ['radio', 'checkbox', 'switch'].includes(type),
  },
  name: {
    default: null,
    type: String,
  },
  nativeValue: {
    default: '',
    required: false,
    type: [String, Number, Boolean, Function, Object, Array],
  },
  indeterminate: {
    default: false,
    required: false,
    type: Boolean,
  },
  trueValue: {
    type: [String, Number, Boolean, Function, Object, Array],
    default: true,
  },
  falseValue: {
    type: [String, Number, Boolean, Function, Object, Array],
    default: false,
  },
  cypressName: {
    type: String,
    default: 'soona-toggle',
  },
  hideVisualLabel: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const modelValue = defineModel({
  default: '',
  type: [String, Number, Boolean, Function, Object, Array],
});

const id = useId();
const inputEl = useTemplateRef('input');
const labelEl = useTemplateRef('label');

function inputFocus() {
  // macOS FireFox and Safari do not focus when clicked
  inputEl.value?.focus();
}

function labelClick() {
  labelEl.value?.click();
}
</script>

<template>
  <div class="soona-toggle-wrapper" :class="{ right: align === 'right' }">
    <input
      :id="id"
      ref="input"
      v-model="modelValue"
      :data-cypress="cypressName"
      :indeterminate="indeterminate"
      :type="type === 'radio' ? 'radio' : 'checkbox'"
      :disabled="disabled"
      :name="name"
      :required="required"
      :value="nativeValue"
      :true-value="trueValue"
      :false-value="falseValue"
      :class="{
        'soona-toggle__switch': type === 'switch',
        'soona-toggle__flip': type === 'switch' && align === 'right',
      }"
      @click.stop
    />
    <label
      ref="label"
      class="soona-toggle-label"
      :class="{ 'u-visually-hidden': hideVisualLabel }"
      :for="id"
      @click="inputFocus"
      @keydown.prevent.enter="labelClick"
      @keydown.prevent.space="labelClick"
    >
      <slot name="label">
        <span v-if="label">{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-toggle-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  &.right {
    flex-direction: row-reverse;
  }
}

/* common */
input {
  accent-color: variables.$periwink-blue-60;
  height: 1em;
  width: 1em;
  font-size: inherit;
  margin-top: 0.25em;
}

label {
  width: 100%;
}

/* switch */
input[type='checkbox'].soona-toggle__switch {
  $size-multiplier: 0.75;

  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  & + label {
    display: block;
    position: relative;
    padding-left: 4em * $size-multiplier;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 1.5em * $size-multiplier;
      transition:
        all 0.25s ease,
        box-shadow 0s linear;
      cursor: pointer;
    }

    &::before {
      left: 0;
      top: 0.2em * $size-multiplier;
      width: 3em * $size-multiplier;
      border: 0.2em * $size-multiplier solid variables.$gray-60;
      background: variables.$gray-60;
      border-radius: 1.1em * $size-multiplier;
    }

    &::after {
      left: 0;
      top: 0.2em * $size-multiplier;
      background-color: variables.$white-default;
      background-position: center center;
      border-radius: 50%;
      width: 1.5em * $size-multiplier;
      border: (0.15em * $size-multiplier) solid variables.$gray-60;
    }
  }

  &:checked + label::before {
    background-color: variables.$periwink-blue-60;
    border-color: variables.$periwink-blue-60;
  }

  &:checked + label::after {
    left: 1.5em * $size-multiplier;
    border-color: variables.$periwink-blue-60;
    color: variables.$periwink-blue-60;
  }

  &:focus + label::before,
  &:not(:disabled):active + label::before {
    box-shadow:
      0 0 0.5em rgba(0, 0, 0, 0.24),
      0 0 0 1px variables.$black-default;
  }

  &:not(:disabled):not(:focus):not(:active):hover + label::before {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.24);
  }

  &:disabled + label::before {
    background-color: variables.$gray-30;
    border-color: variables.$gray-30;
    cursor: default;
  }
  &:disabled + label::after {
    border-color: variables.$gray-30;
    cursor: default;
  }

  &:indeterminate + label::after {
    left: 0.8em * $size-multiplier;
  }
  :indeterminate + label::before {
    background-color: variables.$gray-40;
  }

  &.soona-toggle__flip {
    & + label::before,
    & + label::after {
      left: auto;
      right: 0;
    }
    & + label::after {
      left: auto;
      right: 1.6em * $size-multiplier;
    }
    &:checked + label::after {
      right: 0;
    }
    &:indeterminate + label::after {
      right: 0.8em * $size-multiplier;
    }
    & + label {
      padding-left: 0;
      padding-right: 4em * $size-multiplier;
    }
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  input[type='checkbox'].switch + label::before,
  input[type='checkbox'].switch + label::after {
    transition: none;
  }
}
</style>
