<script setup>
import { toRefs } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useRemoveBusinessSubscriptionItem } from '@/queries/subscriptions/useRemoveBusinessSubscriptionItem';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);
const { accountId } = toRefs(props);

const {
  mutate: removeBusinessSubscriptionItem,
  isPending: businessSubscriptionItemRemoving,
  error: businessSubscriptionItemRemovingError,
} = useRemoveBusinessSubscriptionItem(accountId);

const removeBusinessSubscription = () => {
  removeBusinessSubscriptionItem(
    {
      accountId: accountId.value,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

const priorityErrors = usePriorityErrors(businessSubscriptionItemRemovingError);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>remove enterprise tier</template>
    <p>
      are you sure you want to cancel enterprise tier and send cancellation
      emails?
    </p>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :loading="businessSubscriptionItemRemoving"
        @on-click="removeBusinessSubscription"
      >
        remove enterprise tier
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
