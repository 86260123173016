<script setup>
import { computed, ref, toRefs, useId } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useBatchUpdateuserProServiceProfiles } from '@/queries/pro_service_profiles/useBatchUpdateUserProServiceProfiles';
import { useCreateProServiceFeedback } from '@/queries/pro_service_feedback/useCreateProServiceFeedback';
import { useGetProServiceFeedbackOptions } from '@/queries/pro_service_feedback/useGetProServiceFeedbackOptions';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useUpdateProServiceProfile } from '@/queries/pro_service_profiles/useUpdateProServiceProfile';

const props = defineProps({
  profileId: {
    type: Number,
    default: null,
  },
  subject: {
    type: String,
    required: true,
  },
  subjectId: {
    type: Number,
    required: true,
  },
  userId: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(['close']);
const formId = useId();

const selectedOptions = ref([]);
const additionalFeedback = ref(null);
const otherReason = ref(null);

const {
  data: feedbackOptions,
  error: feedbackOptionsError,
  isLoading: feedbackOptionsLoading,
} = useGetProServiceFeedbackOptions('services');

const showOtherReason = computed(() => {
  return selectedOptions.value?.includes('other');
});

const updateSelected = (option, selected) => {
  if (selected) {
    selectedOptions.value?.push(option);
  } else {
    selectedOptions.value?.pop(option);
  }
};

const { profileId, subject, subjectId, userId } = toRefs(props);

const { mutate, isPending: creatingProServiceFeedback } =
  useCreateProServiceFeedback();

const { mutate: updateProfile, isPending: updatingProfile } =
  useUpdateProServiceProfile(userId, profileId);

const { mutate: batchUpdateProfiles, isPending: updatingProfiles } =
  useBatchUpdateuserProServiceProfiles(userId);

const handleDeactivateService = () => {
  let feedback = {
    subject_type: subject.value,
    subject_id: subjectId.value,
    user_id: userId.value,
    options: selectedOptions.value,
    notes: {
      other_reason: otherReason.value,
      additional_feedback: additionalFeedback.value,
    },
    category: 'deactivate_service',
  };

  if (subject.value === 'User') {
    batchUpdateProfiles(false, {
      onSuccess: () => {
        if (selectedOptions.value.length || additionalFeedback.value) {
          mutate(feedback);
        }

        emits('close');
      },
    });
  } else if (subject.value === 'ProServiceProfile') {
    updateProfile(
      { isActive: false },
      {
        onSuccess: () => {
          if (selectedOptions.value.length || additionalFeedback.value) {
            mutate(feedback);
          }

          emits('close');
        },
      }
    );
  }
};

const handleClose = () => {
  if (subject.value === 'User') {
    batchUpdateProfiles(true, { onSuccess: () => emits('close') });
  } else if (subject.value === 'ProServiceProfile') {
    updateProfile({ isActive: true }, { onSuccess: () => emits('close') });
  }
};

const isLoading = computed(
  () =>
    feedbackOptionsLoading.value ||
    creatingProServiceFeedback.value ||
    updatingProfile.value ||
    updatingProfiles.value
);

const priorityErrors = usePriorityErrors(feedbackOptionsError);
</script>

<template>
  <SoonaDialog @close="handleClose">
    <template #heading>👋 quick question before you go!</template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <SoonaForm
      :id="formId"
      class="deactivate-dialog--body"
      @submit="handleDeactivateService"
    >
      <fieldset class="deactivate-dialog--options">
        <legend class="u-label--heavy">
          why are you turning off your service?
        </legend>
        <p class="deactivate-dialog--text">choose all that apply</p>
        <SoonaToggle
          v-for="(option, index) in feedbackOptions"
          :key="option"
          type="checkbox"
          :label="option"
          :model-value="option"
          :data-cypress="`toggle-option-${index}`"
          @update:model-value="newVal => updateSelected(option, newVal)"
        />
      </fieldset>

      <SoonaTextfield
        v-if="showOtherReason"
        v-model="otherReason"
        class="deactivate-dialog--additional-feedback__textarea"
        label="other reason"
        type="text"
      />

      <SoonaTextfield
        v-model="additionalFeedback"
        class="deactivate-dialog--additional-feedback__textarea"
        label="additional feedback"
        placeholder="what can we do to improve"
        element="textarea"
        type="text"
      />

      <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    </SoonaForm>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close"
        >nevermind</SoonaButton
      >
      <SoonaButton
        :loading="isLoading"
        type="submit"
        :form="formId"
        :data-cypress="'button-dialog-confirm'"
      >
        {{ `turn off service${subject === 'User' ? 's' : ''}` }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.deactivate-dialog {
  &--body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &--options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &--text {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-70;
  }

  &--additional-feedback {
    &__textarea {
      padding-bottom: 0;
    }

    &__text {
      color: variables.$gray-70;
    }
  }
}
</style>
