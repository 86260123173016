import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCapabilities } from '@/api/capabilities';

/**
 * @typedef CapabilityParams
 * @type {object}
 * @property {string | Ref<string>} [subjectType]
 * @property {string | number | Ref<string | number>} [subjectId]
 */

/**
 * @param {CapabilityParams} capability
 */
export function createCapabilityQueryKey(capability) {
  return queryKeys.capability({
    subjectType: capability.subjectType,
    subjectId: capability.subjectId,
  });
}

/**
 *
 * @param {CapabilityParams} capability
 * @param {UseQueryOptions} [queryOptions]
 */
export const capabilityQueryConfig = (
  capability = {},
  queryOptions = undefined
) => {
  return {
    queryKey: createCapabilityQueryKey(capability),
    queryFn: ({ signal }) =>
      getCapabilities(
        {
          subjectType: toValue(capability.subjectType),
          subjectId: toValue(capability.subjectId),
        },
        signal
      ),
    gcTime: 60 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    ...queryOptions,
  };
};

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [params.subjectType]
 * @param {string | number | Ref<string | number>} [params.subjectId]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useCapability(
  { subjectType, subjectId } = {},
  queryOptions = undefined
) {
  return useQuery(
    capabilityQueryConfig({ subjectType, subjectId }, queryOptions)
  );
}
