<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useAddBusinessSubscriptionItem } from '@/queries/subscriptions/useAddBusinessSubscriptionItem';
import { computed, ref } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import { useAccount } from '@/composables/useAccount';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['close']);

const accountId = computed(() => props.accountId);

const { subscription } = useAccount(accountId);

const {
  mutate: addBusinessSubscriptionItem,
  isPending: businessSubscriptionItemAdding,
  error: businessSubscriptionItemAddingError,
} = useAddBusinessSubscriptionItem(accountId);

const paymentsRefundFlag = useFlag('refund_and_add_business');
const selectedRefundOption = ref(false);
const refundOptions = [
  {
    label: 'cancel & do not refund',
    value: false,
  },
  {
    label: 'cancel & refund',
    value: true,
  },
];

const addBusinessSubscription = () => {
  addBusinessSubscriptionItem(
    {
      accountId: accountId.value,
      refund: selectedRefundOption.value,
    },
    {
      onSuccess: () => {
        emits('close');
      },
    }
  );
};

const priorityError = usePriorityError(businessSubscriptionItemAddingError);
</script>
<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>add enterprise tier</template>
    <p>
      Are you sure you want to create a subscription and send welcome emails?
    </p>
    <br />
    <p v-if="subscription && paymentsRefundFlag">
      This user has an existing subscription. Please choose a refund option to
      refund the prorated amount.
    </p>
    <SoonaSegmentedRadios
      v-if="subscription && paymentsRefundFlag"
      label="refund options"
      name="refund options"
      :options="refundOptions"
      :model-value="selectedRefundOption"
      @update:model-value="e => (selectedRefundOption = e)"
    />
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <template #footer="{ close }">
      <SoonaButton
        variation="tertiary"
        data-cypress="button-dialog-cancel"
        @on-click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        :loading="businessSubscriptionItemAdding"
        data-cypress="button-dialog-confirm"
        @on-click="() => addBusinessSubscription()"
      >
        add enterprise tier
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
p {
  text-transform: lowercase;
}
</style>
