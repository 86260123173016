<script setup>
import { computed, ref, toRefs } from 'vue';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import {
  convertToHHmmss,
  convertToYYYYMMDDWithUTCTimeZone,
} from 'src/lib/date-formatters';
import AppointmentSelection from 'src/components/shared/AppointmentSelection.vue';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useCapability } from 'src/composables/useCapability';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const { reservation, scheduledTime, lengthOfShoot, isLoading, error } =
  useReservation(reservationId);

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(reservationId);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

// all available times
const showAllTimes = ref(false);

const toggleShowAllTimes = value => {
  showAllTimes.value = value;
};

const minimumDate = new Date().toString();

const selectedTimeSlot = computed(() => reservation.value?.start);

const handleTimeClick = newStart => {
  const startTime = convertToHHmmss(newStart, 'UTC');
  const start = `${convertToYYYYMMDDWithUTCTimeZone(newStart)}T${startTime}`;
  const endDate = new Date(
    new Date(newStart).getTime() + lengthOfShoot.value?.duration * 1000 - 1
  );
  const end = `${convertToYYYYMMDDWithUTCTimeZone(endDate)}T${convertToHHmmss(
    endDate,
    'UTC'
  )}`;
  mutate({ start, end });
};

const lastAvailableRescheduleDate = computed(() => {
  return !reservation.value || reservation.value.status === 'draft'
    ? null
    : reservation.value.last_available_reschedule_date;
});

const restrictedDays = computed(() => {
  let restrictedDaysOfWeek = [];

  if (!isFtSoonaStaff) restrictedDaysOfWeek = [...restrictedDaysOfWeek, 0, 6];

  return Array.from(new Set(restrictedDaysOfWeek));
});

const restrictedDates = computed(() => {
  return reservation.value?.location?.holidays;
});

const isBusy = computed(() => isLoading.value || isMutating.value);

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(error, updateError, stepIsIncomplete);
</script>

<template>
  <SoonaButton
    v-if="showAllTimes"
    variation="tertiary"
    :disabled="isBusy"
    class="in-studio-scheduling"
    @click="toggleShowAllTimes(false)"
  >
    show available times
  </SoonaButton>
  <SoonaButton
    v-else
    variation="tertiary"
    :disabled="isBusy"
    class="in-studio-scheduling"
    data-cypress="button-show-all-times"
    @click="toggleShowAllTimes(true)"
  >
    show all times
  </SoonaButton>
  <AppointmentSelection
    :class="{ 'in-studio-scheduling__all-slots': showAllTimes }"
    :reservation="reservation"
    :current-appointment="minimumDate"
    :last-available-date="lastAvailableRescheduleDate"
    :selected-time-slot="selectedTimeSlot"
    :scheduled-time="scheduledTime"
    :restricted-dates="restrictedDates"
    :restricted-days="restrictedDays"
    :is-vertical="true"
    :is-busy="isBusy"
    :show-all-times="showAllTimes"
    @time-selected="handleTimeClick"
  />
  <SoonaAlert v-if="showAllTimes">
    you are viewing all time slots regardless of bay and creative availability.
    be mindful of limitations before booking.
  </SoonaAlert>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';

.in-studio-scheduling {
  margin-bottom: 1rem;

  &__all-slots {
    :deep(
      .appointment-selection--vertical .appointment-selection__time-picker
    ) {
      margin-bottom: 0;
    }

    :deep(.appointment-selection__appt-days-button) {
      &:not(:disabled) {
        background-color: variables.$tangerine-20;

        &:hover {
          border-color: variables.$tangerine-40;
        }
      }
    }
  }
}
</style>
