import * as Sentry from '@sentry/vue';
import * as types from 'src/store/mutation-types';
// import segmentMixin from 'src/mixins/segmentMixin';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

const OPTIONS_MODE = 'shipping-options';
const SHIPMENT_MODE = 'shipping-shipment';
const CONFIRM_MODE = 'shipping-confirm';
const RATES_MODE = 'shipping-rates';
const PAYMENT_MODE = 'shipping-payment';
const COMPLETED_MODE = 'shipping-completed';
const RETURN_SHIPMENT_MODE = 'return-shipment';
const RETURN_RATES_MODE = 'return-shipping-rates';
const RETURN_PAYMENT_MODE = 'return-shipping-payment';
const RETURN_COMPLETED_MODE = 'return-shipping-completed';

const providers = [
  { id: 1, name: 'drop off' },
  { id: 2, name: 'USPS' },
  { id: 3, name: 'FEDEX' },
  { id: 4, name: 'UPS' },
  { id: 5, name: 'other' },
];

const addErrorFlash = (dispatch, errorText) => {
  dispatch(
    'flash/addFlashMessage',
    Object.assign({ type: 'error', text: errorText, timeout: true }, {}),
    { root: true }
  );
};

const addStripeErrorFlash = (dispatch, error) => {
  addErrorFlash(dispatch, error.message);
};

const addErrorFlashWithTranslation = (dispatch, errorCode, error) => {
  let errorText = 'An unknown error occurred.';

  switch (errorCode) {
    case 'card_declined':
      errorText = 'Your card was declined.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'expired_card':
      errorText = 'Your card has expired.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'card_error':
      errorText = 'There was an error processing your payment.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'order_status_not_created':
      errorText = 'There was an error confirming your order.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'order_status_not_confirmed':
      errorText = 'The reservation could not be cancelled.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'incorrect_cvc':
      errorText =
        'We could not verify your CVC. Please check your security code and try again.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'processing_error':
      errorText =
        'We could not process your card, if this continues to happen please give us a call';
      addErrorFlash(dispatch, errorText);
      break;
    case 'validation_failed':
    case 'invalid_stripe_request':
      addErrorFlash(dispatch, error);
      break;
    default:
      break;
  }
  if (!error) {
    addErrorFlash(dispatch, errorText);
  }
  return errorText;
};

const state = {
  currentReservation: {},
  shippingDetails: undefined,
  returnedShippingDetails: [],
  mode: OPTIONS_MODE,
  currentRates: {},
  currentRate: {},
  currentShipment: {},
  currentShippingDetail: {},
  freeShipping: false,
  flatRate: false,
  returnShipment: false,
  packagesRemaining: 0,
  selectedRates: [],
  orders: [],
  unpaidAdditionalChargeOrder: {},
  additionalChargeTagCategoryProducts: [],
  additionalChargeOrder: {
    products: {},
    salesTax: {},
    memo: '',
    chargeImmediately: false,
    coupon: null,
  },
  loading: false,
};

const getters = {
  currentReservation(state) {
    return state.currentReservation;
  },
  shotListNote(state) {
    return state.currentReservation.shot_list_note;
  },
  isPhoto(state, getters) {
    return getters.shootType === 'photo';
  },
  isVideo(state, getters) {
    return getters.shootType === 'video';
  },
  isAnytime(state) {
    return state.currentReservation.reservation_type === 'anytime';
  },
  isSurprise(state) {
    return state.currentReservation.reservation_type === 'surprise';
  },
  isReschedulable(state) {
    return !!state.currentReservation.reschedulable;
  },
  isBelowMaxReschedules(state) {
    const clientReschedules = state.currentReservation.past_appts.filter(
      appt => appt.updated_by_type == 'client'
    );
    return clientReschedules.length < 1;
  },
  hasOriginalStartAndEnd(state) {
    return (
      typeof state.currentReservation.start !== 'undefined' &&
      typeof state.currentReservation.end !== 'undefined'
    );
  },
  hasShotList(state) {
    return state.currentReservation.shot_count > 0;
  },
  hasPickedProServicesPreferences(state) {
    if (!state.currentReservation.purchased_pro_services) {
      return true;
    }
    let modelServices = [];
    let modelProviderChoices = [];
    state.currentReservation.purchased_pro_services.forEach(ps => {
      if (ps.typeform && ps.typeform.submissionId !== null) {
        modelProviderChoices.push(ps);
      }
      if (ps.name.includes('model')) {
        for (let i = 1; i <= ps.quantity; i++) {
          modelServices.push(ps.id);
        }
      }
    });
    state.currentReservation.reservation_line_items.forEach(rli => {
      if (modelServices.includes(rli.product_id)) {
        rli.pro_service_requirements.forEach(requirement => {
          if (requirement.choices.length > 0) {
            modelProviderChoices.push(rli);
          }
        });
      }
    });
    return modelProviderChoices.length >= modelServices.length;
  },
  hasHandModel(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    let models = state.currentReservation.purchased_pro_services_names.filter(
      ps => ps.name.includes('hand model')
    );
    return models.length > 0;
  },
  hasFullBodyModel(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    let models = state.currentReservation.purchased_pro_services_names.filter(
      ps => ps.name.includes('full body model')
    );
    return models.length > 0;
  },
  hasPetModel(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    let models = state.currentReservation.purchased_pro_services_names.filter(
      ps => ps.name.includes('pet model')
    );
    return models.length > 0;
  },
  hasFootModel(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    let models = state.currentReservation.purchased_pro_services_names.filter(
      ps => ps.name.includes('foot model')
    );
    return models.length > 0;
  },
  hasProServices(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    return state.currentReservation.purchased_pro_services_names.length > 0;
  },
  hasModelService(state) {
    return (
      state.currentReservation.purchased_pro_services_names?.some(psp =>
        psp.name.includes('model')
      ) ?? false
    );
  },
  hasHMUService(state) {
    if (!state.currentReservation.purchased_pro_services_names) {
      return false;
    }
    let hairmakeup =
      state.currentReservation.purchased_pro_services_names.filter(ps =>
        ps.name.includes('hair and makeup')
      );
    return hairmakeup.length > 0;
  },
  rescheduleDeadline(state) {
    return state.currentReservation.reschedule_deadline;
  },
  rescheduleUrl(state) {
    return state.currentReservation.reschedule_url;
  },
  shootType(state) {
    return state.currentReservation &&
      state.currentReservation.tags &&
      state.currentReservation.tags[0]
      ? state.currentReservation.tags
          .filter(t => ['photo', 'video'].includes(t.title.toLowerCase()))[0]
          .title.toLowerCase()
      : 'unknown';
  },
  getProviders() {
    return providers;
  },
  isLoading: state => {
    return state.loading;
  },
  orders(state) {
    return state.orders;
  },
  purchasedProServicesNames: state => {
    return state.currentReservation.purchased_pro_services_names;
  },
  productsInCart(state) {
    const productsInCart = [];
    state.additionalChargeTagCategoryProducts.forEach(product => {
      const quantity = state.additionalChargeOrder.products[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

      if (quantity > 0) {
        productsInCart.push({
          ...product,
          quantity: quantity,
        });
      }
    });
    return productsInCart;
  },
  tagCategoryProductsWithQuantities(state) {
    return state.additionalChargeTagCategoryProducts.map(product => {
      const quantity = state.additionalChargeOrder.products[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

      return {
        ...product,
        quantity,
      };
    });
  },
  tagCategoryPhotoProducts(state, getters) {
    return getters.tagCategoryProductsWithQuantities.filter(
      ({ product_type, shoot_type }) =>
        (product_type === 'add_on' && shoot_type === 'photo') ||
        (product_type === 'media_add_on' && shoot_type === 'photo')
    );
  },
  tagCategoryVideoProducts(state, getters) {
    return getters.tagCategoryProductsWithQuantities.filter(
      ({ product_type, shoot_type }) =>
        (product_type === 'add_on' && shoot_type === 'video') ||
        (product_type === 'media_add_on' && shoot_type === 'video')
    );
  },
  tagCategoryBonusContentProducts(state, getters) {
    return getters.tagCategoryProductsWithQuantities.filter(
      ({ product_type, shoot_type, content_category }) =>
        product_type === 'credit' &&
        shoot_type === 'video' &&
        content_category === 'behind_the_scenes'
    );
  },
  tagCategoryAnimationProducts(state, getters) {
    return getters.tagCategoryProductsWithQuantities
      .filter(
        ({ product_type, shoot_type }) =>
          (product_type === 'add_on' && shoot_type === 'animation') ||
          (product_type === 'media_add_on' && shoot_type === 'animation')
      )
      .sort((a, b) => a.product_type.localeCompare(b.product_type));
  },
  tagCategoryModelProducts(state, getters) {
    let products = getters.tagCategoryProductsWithQuantities.sort(
      (a, b) => a.id - b.id
    );
    return products
      .filter(({ product_type }) => product_type === 'reservation_model')
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  tagCategoryProServiceProducts(state, getters) {
    let products = getters.tagCategoryProductsWithQuantities.sort(
      (a, b) => a.id - b.id
    );
    return products
      .filter(({ product_type }) => product_type === 'reservation_service')
      .sort((a, b) => a.name.localeCompare(b.name));
  },
};

const mutations = {
  [types.SET_CURRENT_RESERVATION](state, reservation) {
    state.currentReservation = reservation;
  },
  [types.SET_SHIPPING_DETAILS](state, shippingInfo) {
    state.shippingDetails = shippingInfo;
  },
  [types.RESET_SHIPPING_DETAILS](state) {
    state.shippingDetails = undefined;
  },
  [types.SET_PACKAGES_REMAINING](state, packagesRemaining) {
    state.packagesRemaining = packagesRemaining;
  },
  [types.SET_SELECTED_RATES](state, rate) {
    state.selectedRates.push(rate);
  },
  [types.SET_RATES](state, rates) {
    state.currentRates = rates;
  },
  [types.SET_RATE](state, rate) {
    state.currentRate = rate;
  },
  [types.SET_FLAT_RATE](state) {
    state.flatRate = true;
  },
  [types.SET_RETURN_SHIPMENT](state) {
    state.returnShipment = true;
  },
  [types.SET_FREE_SHIPPING](state, freeShipping) {
    state.freeShipping = freeShipping;
  },
  [types.SET_CURRENT_SHIPMENT](state, shipment) {
    state.currentShipment = shipment;
  },
  [types.ADD_RETURNED_SHIPPING_DETAIL](state, shipment) {
    state.returnedShippingDetails.push(shipment);
  },
  [types.SET_SHIPPING_BUSY](state) {
    state.loading = true;
  },
  [types.SET_SHIPPING_READY](state) {
    state.loading = false;
  },
  [types.SET_SHIPPING_MODE_OPTIONS](state) {
    state.mode = OPTIONS_MODE;
  },
  [types.SET_SHIPPING_MODE_SHIPMENT](state) {
    state.mode = SHIPMENT_MODE;
  },
  [types.SET_SHIPPING_MODE_CONFIRM](state) {
    state.mode = CONFIRM_MODE;
  },
  [types.SET_SHIPPING_MODE_RATES](state) {
    state.mode = RATES_MODE;
  },
  [types.SET_SHIPPING_MODE_PAYMENT](state) {
    state.mode = PAYMENT_MODE;
  },
  [types.SET_SHIPPING_MODE_COMPLETED](state) {
    state.mode = COMPLETED_MODE;
  },
  [types.SET_SHIPPING_MODE_RETURN_COMPLETED](state) {
    state.mode = RETURN_COMPLETED_MODE;
  },
  [types.SET_SHIPPING_MODE_RETURN_SHIPMENT](state) {
    state.mode = RETURN_SHIPMENT_MODE;
  },
  [types.SET_SHIPPING_MODE_RETURN_RATES](state) {
    state.mode = RETURN_RATES_MODE;
  },
  [types.SET_SHIPPING_MODE_RETURN_PAYMENT](state) {
    state.mode = RETURN_PAYMENT_MODE;
  },
  [types.SET_CURRENT_SHIPPING_DETAIL](state, shipping_detail) {
    state.currentShippingDetail = shipping_detail;
  },
  [types.RESET_RATES](state) {
    state.currentRates = {};
  },
  [types.RESET_RATE](state) {
    state.currentRate = {};
  },
  [types.RESET_FLAT_RATE](state) {
    state.flatRate = false;
  },
  [types.RESET_CURRENT_SHIPMENT](state) {
    state.currentShipment = {};
  },
  [types.RESET_CURRENT_SHIPPING_DETAIL](state) {
    state.currentShippingDetail = {};
  },
  [types.RESET_RETURN_SHIPMENT](state) {
    state.returnShipment = false;
  },
  [types.RESET_SELECTED_RATES](state) {
    state.selectedRates = [];
  },
  [types.RESET_RETURNED_SHIPPING_DETAILS](state) {
    state.returnedShippingDetails = [];
  },
  [types.SET_ORDERS](state, orders) {
    state.orders = orders;
  },
  [types.SET_ADDITIONAL_CHARGE_TAG_CATEGORY_PRODUCTS](state, products) {
    state.additionalChargeTagCategoryProducts = products;
  },
  [types.SET_ADDITIONAL_CHARGE_COUPON](state, coupon) {
    state.additionalChargeOrder.coupon = coupon;
  },
  [types.SET_ADDITIONAL_CHARGE_ITEMS](state, payload) {
    state.additionalChargeOrder.products = {
      ...state.additionalChargeOrder.products,
      ...payload,
    };
  },
  [types.CLEAR_ADDITIONAL_CHARGE_ITEMS](state) {
    state.additionalChargeOrder.products = {};
  },
  [types.REMOVE_ADDITIONAL_CHARGE_ITEM](state, payload) {
    delete state.additionalChargeOrder.products[payload];
  },
  [types.SET_ADDITIONAL_CHARGE_MEMO](state, memo) {
    state.additionalChargeOrder.memo = memo;
  },
  [types.SET_ADDITIONAL_CHARGE_IMMEDIATELY](state, chargeImmediately) {
    state.additionalChargeOrder.chargeImmediately = !!chargeImmediately;
  },
  [types.SET_ADDITIONAL_CHARGE_ORDER](state, unpaidAdditionalChargeOrder) {
    state.unpaidAdditionalChargeOrder = unpaidAdditionalChargeOrder;
  },
  [types.ADD_SHOOT_EXCEPTION](state, shootException) {
    state.currentReservation.shoot_exceptions.push(shootException);
  },
  [types.REMOVE_SHOOT_EXCEPTION](state, shootExceptionId) {
    let exception = state.currentReservation.shoot_exceptions.find(
      se => se.id === shootExceptionId
    );

    let index = state.currentReservation.shoot_exceptions.indexOf(exception);

    if (index >= 0) {
      state.currentReservation.shoot_exceptions.splice(index, 1);
    }
  },
  SOCKET_RESERVATION_UPDATE(state, reservation) {
    if (reservation.id == state.currentReservation.id) {
      state.currentReservation = reservation;
    }
  },
};

const actions = {
  submitTypeform(
    { dispatch },
    { embeddedTypeformId, reservationId, productId, submissionId, submittedBy }
  ) {
    this.http
      .post(`reservations/${reservationId}/typeform_submissions`, {
        embedded_typeform_id: embeddedTypeformId,
        product_id: productId,
        submission_id: submissionId,
        submitted_by_user_id: submittedBy,
        reservation_id: reservationId,
      })
      .then(
        () => {
          dispatch('loadReservation', reservationId);
          return [null, null];
        },
        error => {
          Sentry.captureException(new Error('Failed to submit typeform'), {
            extra: error,
          });
          return [null, error.body];
        }
      );
  },
  hideProService({ dispatch }, { reservationId, lineItemId, reason }) {
    return this.http
      .put(
        `reservations/${reservationId}/reservation_line_items/${lineItemId}.json`,
        {
          display: false,
          hide_reason: reason,
        }
      )
      .then(() => {
        dispatch('loadReservation', reservationId).then(() => {
          return;
        });
      })
      .catch(error => {
        console.error(error, 'there was an issue hidding this PSP');
      });
  },
  loadReservation({ commit }, reservationId) {
    return this.http
      .get(`reservations/${reservationId}.json`)
      .then(response => {
        commit(types.SET_CURRENT_RESERVATION, response.data);
        queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
        return response.data;
      });
  },
  updateReservation({ commit, state }) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${state.currentReservation.id}`, {
          reservation: {
            name: state.currentReservation.name,
            website: state.currentReservation.website,
            moodboard: state.currentReservation.moodboard,
            social_media_tag: state.currentReservation.social_media_tag,
            description: state.currentReservation.description,
            product_size: state.currentReservation.product_size,
            shot_list_note: state.currentReservation.shot_list_note,
            target_platforms: state.currentReservation.target_platforms,
          },
        })
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error(
            error,
            'there was an issue updating the currentReservation'
          );
          reject(error);
        });
    });
  },
  loadShippingDetails({ commit }, { reservationId, returnable_only = false }) {
    return this.http
      .get(`reservations/${reservationId}/shipping_details.json`, {
        params: {
          returnable_only: returnable_only,
        },
      })
      .then(response => {
        commit(types.SET_SHIPPING_DETAILS, response.data);
      });
  },
  startShoot({ commit }, reservationId) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${reservationId}/start_shoot`)
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  },
  wrapShoot({ commit }, reservationId) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${reservationId}/complete`)
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  },
  checkFreeShippingAvailability() {
    return this.http
      .get(
        `reservations/${state.currentReservation.id}/shipping_details/free_shipping_availability.json`
      )
      .catch(error => {
        console.error(
          error,
          'there was an issue checking for free shipping availability'
        );
      });
  },
  validateAddress(_, { address, reservationId }) {
    return this.http
      .post(
        `reservations/${reservationId}/shipping_details/validate_address.json`,
        {
          name: address.name,
          street1: address.street1,
          street2: address.street2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          country: address.country,
        }
      )
      .catch(error => {
        // TODO look at new way to log to sentry
        console.error(error, 'address failed to validate');
      });
  },
  chooseShippingType({ commit }, { freeShipping }) {
    // const properties = {
    //   account: rootState.account,
    //   free_shipping: freeShipping,
    // };
    // segmentMixin.methods.$track('Choose Shipping Type Selected', properties);

    commit(types.SET_FREE_SHIPPING, freeShipping);
    commit(types.SET_SHIPPING_MODE_SHIPMENT);
  },
  createShipment(
    { commit, state },
    { address_from, address_to, parcels, packagesRemaining, reservationId }
  ) {
    commit(types.SET_SHIPPING_BUSY);

    let shipment = {
      address_to: address_to,
      address_from: address_from,
      parcels: parcels,
    };
    commit(types.SET_CURRENT_SHIPMENT, shipment);

    this.http
      .post(
        `reservations/${
          reservationId || state.currentReservation.id
        }/shipping_details/shipment.json`,
        { address_to, address_from, parcels }
      )
      .then(response => {
        if (state.returnShipment) {
          if (packagesRemaining >= 0) {
            commit(types.SET_PACKAGES_REMAINING, packagesRemaining);
            commit(types.SET_RATES, response.data);
            commit(types.SET_SHIPPING_MODE_RETURN_RATES);
          } else {
            commit(types.SET_PACKAGES_REMAINING, packagesRemaining);
            commit(types.SET_RATES, response.data);
            commit(types.SET_SHIPPING_MODE_RETURN_PAYMENT);
          }
        } else {
          if (state.freeShipping) {
            commit(types.SET_RATE, response.data[0]);
            commit(types.SET_SHIPPING_MODE_CONFIRM);
          } else {
            commit(types.SET_RATES, response.data);
            commit(types.SET_SHIPPING_MODE_RATES);
          }
          commit(types.SET_SHIPPING_READY);
        }
      })
      .catch(error => {
        console.error(
          error,
          'there was an issue contacting the shipping providers'
        );
      });
  },
  setFlatRate({ commit }) {
    commit(types.SET_FLAT_RATE);
  },
  addToSelectedRates({ commit }, { rate }) {
    commit(types.SET_SELECTED_RATES, rate);
  },
  submitSelectedRates({ commit }, { rate }) {
    commit(types.SET_SELECTED_RATES, rate);
    commit(types.SET_SHIPPING_MODE_RETURN_PAYMENT);
  },
  submitRate({ commit }, { rate }) {
    commit(types.SET_RATE, rate);
    commit(types.SET_SHIPPING_MODE_PAYMENT);
  },
  confirmFreeShipment(
    { state, commit },
    { shipping_amount, shipping_details }
  ) {
    commit(types.SET_SHIPPING_BUSY);

    this.http
      .post(
        `reservations/${state.currentReservation.id}/shipping_details/payment.json`,
        { free_shipping: true, shipping_amount, shipping_details }
      )
      .then(response => {
        commit(types.SET_CURRENT_SHIPPING_DETAIL, response.data);
        commit(types.SET_SHIPPING_READY);
        commit(types.SET_SHIPPING_MODE_COMPLETED);
      })
      .catch(error => {
        console.error(error, 'failed to confirm free shipment');
      });
  },
  submitPayment(
    { state, commit, dispatch, rootState },
    {
      stripe,
      card,
      confirm = {},
      saveCard,
      reservationId,
      shipping_amount,
      shipping_details,
      packagesRemaining = 0,
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.SET_SHIPPING_BUSY);

      if (stripe && card) {
        stripe.createToken(card).then(result => {
          if (result?.error) {
            addStripeErrorFlash(dispatch, result.error);
            return reject(result.error);
          }
          this.http
            .post(
              `reservations/${
                reservationId || state.currentReservation.id
              }/shipping_details/payment.json`,
              {
                confirm: {
                  token: result.token.id,
                },
                shipping_amount,
                shipping_details,
                save_card: saveCard,
              }
            )
            .then(
              response => {
                if (state.returnShipment) {
                  commit(types.ADD_RETURNED_SHIPPING_DETAIL, response.data);
                  if (packagesRemaining === 0) {
                    commit(types.SET_SHIPPING_MODE_RETURN_COMPLETED);
                    commit(types.SET_SHIPPING_READY);
                  }
                } else {
                  commit(types.SET_CURRENT_SHIPPING_DETAIL, response.data);
                  commit(types.SET_SHIPPING_READY);
                  commit(types.SET_SHIPPING_MODE_COMPLETED);
                }

                queryClient.invalidateQueries({
                  queryKey: queryKeys.inventoryPackages(),
                });
                resolve();
              },
              error => {
                let errorTranslation;

                console.error('confirm reservation error', error);
                commit(types.SET_SHIPPING_READY); // turn off loading state
                if (error.response.status !== 500) {
                  errorTranslation = addErrorFlashWithTranslation(
                    dispatch,
                    error.response.data.code,
                    error.response.data.message,
                    rootState
                  );
                } else {
                  errorTranslation = addErrorFlashWithTranslation(
                    dispatch,
                    'unknown_error',
                    rootState
                  );
                }
                reject(new Error(errorTranslation));
              }
            )
            .catch(error => {
              console.error(error, 'failed to submit shipping payment');
              reject(error);
            });
        });
      } else {
        this.http
          .post(
            `reservations/${
              reservationId || state.currentReservation.id
            }/shipping_details/payment.json`,
            {
              confirm,
              shipping_amount,
              shipping_details,
              save_card: saveCard,
            }
          )
          .then(
            response => {
              if (state.returnShipment) {
                commit(types.ADD_RETURNED_SHIPPING_DETAIL, response.data);
                if (packagesRemaining === 0) {
                  commit(types.SET_SHIPPING_MODE_RETURN_COMPLETED);
                  commit(types.SET_SHIPPING_READY);
                }
              } else {
                commit(types.SET_CURRENT_SHIPPING_DETAIL, response.data);
                commit(types.SET_SHIPPING_READY);
                commit(types.SET_SHIPPING_MODE_COMPLETED);
              }

              queryClient.invalidateQueries({
                queryKey: queryKeys.inventoryPackages(),
              });
              resolve();
            },
            error => {
              if (error.response.status !== 500) {
                addErrorFlashWithTranslation(
                  dispatch,
                  error.response.data.code,
                  error.response.data.message,
                  rootState
                );
              } else {
                addErrorFlashWithTranslation(
                  dispatch,
                  'unknown_error',
                  rootState
                );
              }
              reject(error);
            }
          )
          .catch(error => {
            console.error(error, 'failed to submit shipping payment');
            reject(error);
          });
      }
    });
  },
  newShipment({ commit }) {
    commit(types.RESET_RATES);
    commit(types.RESET_RATE);
    commit(types.RESET_CURRENT_SHIPMENT);
    commit(types.RESET_CURRENT_SHIPPING_DETAIL);
    commit(types.RESET_FLAT_RATE);
    commit(types.RESET_SELECTED_RATES);
    commit(types.RESET_RETURN_SHIPMENT);

    commit(types.SET_SHIPPING_MODE_OPTIONS);
  },
  newReturnOptions({ commit }) {
    commit(types.RESET_RATES);
    commit(types.RESET_RATE);
    commit(types.RESET_CURRENT_SHIPMENT);
    commit(types.RESET_CURRENT_SHIPPING_DETAIL);
    commit(types.RESET_FLAT_RATE);
    commit(types.RESET_SELECTED_RATES);
    commit(types.SET_RETURN_SHIPMENT);
    commit(types.RESET_RETURNED_SHIPPING_DETAILS);
  },
  loadOrders({ commit }, reservationId) {
    return this.http.get(`orders?reservation_id=${reservationId}`).then(
      response => commit(types.SET_ORDERS, response.data),
      error => {
        throw error;
      }
    );
  },
  clearOrders({ commit }) {
    commit(types.SET_ORDERS, []);
  },
  changeBookingType({ commit }, { bookingType }) {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `reservations/${state.currentReservation.id}/change_booking_type`,
          {
            booking_type: bookingType,
          }
        )
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error('change booking type error', error);
          reject(error);
        });
    });
  },
  reopenBooking({ commit }, reservationId) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${reservationId}/reopen_booking`)
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error('reopen booking error', error);
          reject(error);
        });
    });
  },
  unStart({ commit }, reservationId) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${reservationId}/unstart`)
        .then(response => {
          commit(types.SET_CURRENT_RESERVATION, response.data);
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve();
        })
        .catch(error => {
          console.error('unstart booking error', error);
          reject(error);
        });
    });
  },
  loadAdditionalChargeOrder({ commit }, orderId) {
    return this.http.get(`orders/${orderId}`).then(
      response => {
        commit(types.SET_ADDITIONAL_CHARGE_ORDER, response.data);
        return [response.data, null];
      },
      error => {
        return [null, error];
      }
    );
  },
  async payAdditionalChargeOrder(
    { commit },
    { orderId, stripe, card, saveCard, salesTax = {} }
  ) {
    return this.http
      .put(`orders/${orderId}`, {
        payment_params: {
          payment_method: stripe,
          payment_method_type: card,
          save_payment_method: saveCard,
        },
        sales_tax: salesTax,
      })
      .then(
        response => {
          commit(types.SET_ORDERS, response.data);
          return [response.data, null];
        },
        error => {
          Sentry.captureException('failed to pay additional charge order', {
            extra: error,
          });
          return [null, error.response];
        }
      );
  },
  setAdditionalChargeCoupon({ commit }, payload) {
    commit(types.SET_ADDITIONAL_CHARGE_COUPON, payload);
  },
  setAdditionalChargeItems({ commit }, payload) {
    commit(types.SET_ADDITIONAL_CHARGE_ITEMS, payload);
  },
  removeAdditionalChargeItem({ commit }, payload) {
    commit(types.REMOVE_ADDITIONAL_CHARGE_ITEM, payload);
  },
  clearAdditionalChargeItems({ commit }) {
    commit(types.CLEAR_ADDITIONAL_CHARGE_ITEMS);
    commit(types.SET_ADDITIONAL_CHARGE_MEMO, '');
    commit(types.SET_ADDITIONAL_CHARGE_IMMEDIATELY, false);
    commit(types.SET_ADDITIONAL_CHARGE_COUPON, null);
  },
  setAdditionalChargeMemo({ commit }, memo) {
    commit(types.SET_ADDITIONAL_CHARGE_MEMO, memo);
  },
  setAdditionalChargeImmediately({ commit }, chargeImmediately) {
    commit(types.SET_ADDITIONAL_CHARGE_IMMEDIATELY, chargeImmediately);
  },
  async createAdditionalChargeOrder(
    { commit, state },
    {
      isShopifyOrder = false,
      stripe = null,
      card = null,
      saveCard = false,
      salesTax = {},
      calculateSalesTax = false,
      reservationId = null,
      accountId = null,
    }
  ) {
    const { products, memo, chargeImmediately, coupon } =
      state.additionalChargeOrder;
    let paymentToken = null;
    if (stripe && card) {
      await stripe.createToken(card).then(
        response => {
          paymentToken = response.token.id;
        },
        error => {
          Sentry.captureException('failed to create stripe token', {
            extra: error,
          });
          throw new Error(error.response.data.message);
        }
      );
    }
    if (stripe && !card) {
      paymentToken = stripe;
    }
    return this.http
      .post(`orders.json`, {
        products,
        memo,
        charge_immediately: chargeImmediately,
        reservation_id: reservationId || state.currentReservation.id,
        account_id: accountId || state.currentReservation.account.id,
        coupon_code: coupon,
        save_card: saveCard,
        token: paymentToken,
        sales_tax: salesTax,
        calculate_sales_tax: calculateSalesTax,
      })
      .then(
        response => {
          if (isShopifyOrder) {
            window.top.location.href = response.data.confirmation_url;
          } else {
            commit(types.SET_ORDERS, response.data);
            return [response.data, null];
          }
        },
        error => {
          Sentry.captureException('failed to create additional charge order', {
            extra: error,
          });
          throw new Error(error.response.data.message);
        }
      );
  },
  async createPaymentMethodAdditionalChargeOrder(
    { commit, state },
    {
      isShopifyOrder = false,
      paymentMethodId = null,
      paymentMethodType = null,
      savePaymentMethod = false,
      salesTax = {},
    }
  ) {
    const { products, memo, chargeImmediately, coupon } =
      state.additionalChargeOrder;
    return this.http
      .post(`orders.json`, {
        products,
        memo,
        charge_immediately: chargeImmediately,
        reservation_id: state.currentReservation.id,
        account_id: state.currentReservation.account.id,
        coupon_code: coupon,
        payment_method_id: paymentMethodId,
        payment_method_type: paymentMethodType,
        save_payment_method: savePaymentMethod,
        sales_tax: salesTax,
      })
      .then(
        response => {
          if (isShopifyOrder) {
            window.top.location.href = response.data.confirmation_url;
          } else {
            commit(types.SET_ORDERS, response.data);
            return [response.data, null];
          }
        },
        error => {
          Sentry.captureException('failed to create additional charge order', {
            extra: error,
          });
          throw new Error(error.response.data.message);
        }
      );
  },
  cancelOrder({ commit }, orderId) {
    return this.http.delete(`orders/${orderId}`).then(
      response => {
        commit(types.SET_ORDERS, response.data);
        return [response.data, null];
      },
      error => {
        Sentry.captureException(
          new Error('failed to cancel additional charge order'),
          {
            extra: error,
          }
        );
        return [null, error.response];
      }
    );
  },
  resendOrder(_, orderId) {
    return this.http.put(`orders/${orderId}/resend_order`).then(
      response => {
        return [response.data, null];
      },
      error => {
        Sentry.captureException(
          new Error('failed to resend additional charge order'),
          {
            extra: error,
          }
        );
        return [null, error.response];
      }
    );
  },
  loadBayAvailability(_, { bookableSpaceId, date, reservationId }) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return this.http
      .get(
        `bookable_spaces/${bookableSpaceId}/availability.json?year=${year}&month=${month}&day=${day}&reservation_id=${reservationId}`
      )
      .then(response => {
        return response.data;
      });
  },
  rescheduleReservation({ dispatch }, { reservationId, start }) {
    return this.http
      .put(`reservations/${reservationId}/reschedule`, {
        reservation: {
          start,
        },
      })
      .then(
        async () => {
          await dispatch('loadReservation', reservationId);
          return [null, null];
        },
        error => [null, error.response?.error]
      );
  },
  createShootException(
    { commit },
    { reservationId, reason, exceptionType, note, unschedule }
  ) {
    return this.http
      .post(`reservations/${reservationId}/shoot_exceptions`, {
        reason: reason,
        exception_type: exceptionType,
        note: note,
        unschedule: unschedule,
      })
      .then(
        response => {
          commit(types.ADD_SHOOT_EXCEPTION, response.data);
          return [response.data, null];
        },
        error => {
          Sentry.captureException(
            new Error('failed to create a shoot exception'),
            {
              extra: error,
            }
          );
          return [null, error.response];
        }
      );
  },
  deleteShootException({ commit }, { reservationId, shootExceptionId }) {
    return (
      this.http
        .delete(
          `reservations/${reservationId}/shoot_exceptions/${shootExceptionId}`
        )
        .then(response => {
          commit(types.REMOVE_SHOOT_EXCEPTION, shootExceptionId);
          return [response.data, null];
        }),
      error => {
        Sentry.captureException(
          new Error('failed to delete a shoot exception'),
          {
            extra: error,
          }
        );
        return [null, error.response];
      }
    );
  },
  resendProServiceNotifications({ dispatch }, { reservationId }) {
    this.http
      .post(`reservations/${reservationId}/typeform_submissions/reminder`)
      .then(
        () => {
          dispatch('loadReservation', reservationId);
          return [null, null];
        },
        error => {
          Sentry.captureException(new Error('Failed to resend email'), {
            extra: error,
          });
          return [null, error.body];
        }
      );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
