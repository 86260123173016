<script setup>
import { ref } from 'vue';
import { mockTransactions } from './contract-dummy-data';
import ContractOrderHistoryTable from './ContractOrderHistoryTable.vue';
import dayjs from 'dayjs';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});
const transactions = ref(mockTransactions);
const isEmpty = false;

const handleSort = ({ column, order }) => {
  transactions.value = transactions.value.sort((a, b) => {
    console.log('sort', column);
    const dateA = dayjs(a[column]).unix();
    const dateB = dayjs(b[column]).unix();

    if (order === 'asc') {
      return dateA - dateB;
    }
    return dateB - dateA;
  });
};

const handleDownload = () => {
  console.log('download');
};
</script>

<template>
  <div class="contract-order-history">
    <div class="contract-order-history__header">
      <p class="u-subheader--heavy is-lowercase">Order History</p>
      <SoonaButton
        class="is-lowercase"
        variation="secondary-gray"
        size="medium"
        @on-click="handleDownload"
      >
        Download all
        <SoonaIcon name="arrow-down-to-bracket" />
      </SoonaButton>
    </div>
    <ContractOrderHistoryTable
      v-if="!isEmpty"
      class="contract-order-history__table"
      :account-id="accountId"
      :transactions="transactions"
      @on-sort="handleSort"
    />
    <div v-else class="contract-order-history__empty-state">
      <p class="u-title--heavy is-lowercase">Order history will show here</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-order-history {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    background: variables.$periwink-blue-10;
    border-radius: 6px;
    padding: 2.5rem 0.5rem;
  }
}
</style>
