<script setup>
import { computed } from 'vue';
import { toCityStateZip } from '@/lib/address-formatter';

// Props
const props = defineProps({
  account: {
    type: Object,
    required: true,
  },
});
const account = computed(() => props.account);
const billingAddress = computed(() => account.value?.billing_address ?? null);
const taxId = computed(() => account.value?.tax_id ?? null);

const SOONA_CONTACT = {
  name: 'soona co.',
  email: 'hey@soona.co',
  phone: '+1 512-270-3331',
  address_1: '1235 S Broadway',
  address_2: '',
  city: 'Denver',
  state: 'Colorado',
  postal_code: '80210',
  country: 'United States',
};
</script>

<template>
  <!-- Billing Address -->
  <div class="billing-address">
    <div class="billing-address__soona">
      <p class="u-small--heavy">{{ SOONA_CONTACT.name }}</p>
      <p class="u-small--regular">{{ SOONA_CONTACT.street1 }}</p>
      <p class="u-small--regular">{{ toCityStateZip(SOONA_CONTACT) }}</p>
      <p class="u-small--regular">{{ SOONA_CONTACT.country }}</p>
      <p class="u-small--regular">{{ SOONA_CONTACT.phone }}</p>
      <p class="u-small--regular">{{ SOONA_CONTACT.email }}</p>
    </div>
    <div class="billing-address__account">
      <p class="u-small--heavy">Bill to</p>
      <p class="u-small--regular">{{ account.name }}</p>
      <p class="u-small--regular">{{ account.email }}</p>
      <template v-if="billingAddress">
        <p class="u-small--regular">
          {{ billingAddress.address_1 }}
        </p>
        <p v-if="billingAddress.address_2" class="u-small--regular">
          {{ billingAddress.address_2 }}
        </p>
        <p
          v-if="
            billingAddress.city ||
            billingAddress.state ||
            billingAddress.postal_code
          "
          class="u-small--regular"
        >
          {{ toCityStateZip(billingAddress) }}
        </p>
      </template>
      <p v-if="taxId" class="u-small--regular">Tax ID (VAT): {{ taxId }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.billing-address {
  margin: 1.25rem 0;
  display: flex;

  &__soona {
    display: none;
  }
}

@media print {
  .billing-address {
    gap: 6.5rem;
    &__soona {
      display: block;
    }
  }
}
</style>
