<template>
  <div ref="soonaDropdownWrap" class="soona-dropdown" :data-open="open">
    <SoonaButton
      v-if="!$slots['trigger-button']"
      :id="buttonId"
      :ref="el => (buttonRef = el)"
      :aria-controls="menuId"
      :aria-expanded="open"
      aria-haspopup="menu"
      :disabled="disabled"
      :size="size"
      type="button"
      :variation="variation"
      :on-click="onButtonClick"
      :on-keydown="onButtonKeydown"
      :title="title"
      :data-cypress="dataCypress"
    >
      <template v-if="copy">{{ copy }}</template>
      <slot name="trigger-content" />
      <SoonaIcon
        v-if="!isIconButton && displayCaret"
        name="chevron-down"
        class="soona-dropdown__trigger__caret"
      />
    </SoonaButton>
    <slot
      name="trigger-button"
      :attrs="{
        ref: el => (buttonRef = el),
        id: buttonId,
        'aria-controls': menuId,
        'aria-expanded': open,
        'aria-haspopup': 'menu',
        disabled,
        'data-cypress': dataCypress,
      }"
      :is-open="open"
      :on-trigger-click="onButtonClick"
      :on-trigger-keydown="onButtonKeydown"
      :caret-class="'soona-dropdown-slotted-caret'"
    />
    <Transition name="menu-fade">
      <ul
        v-show="open"
        :id="menuId"
        :ref="el => (menuRef = el)"
        role="menu"
        :aria-labelledby="buttonId"
        :style="floatingStyles"
        :class="{ width: menuWidth }"
      >
        <slot
          name="default"
          :keydown="onMenuitemKeydown"
          :mouseover="onMenuitemMouseover"
          :click-capture="onMenuitemClickCapture"
        />
      </ul>
    </Transition>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import uniqueId from 'lodash/uniqueId';
import SoonaButton from './SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/vue';

/*
 * this implementation is adapted from the wonderful WAI-ARIA examples site
 * https://www.w3.org/WAI/ARIA/apg/example-index/menu-button/menu-button-links.html
 */

export default {
  components: {
    SoonaIcon,
    SoonaButton,
  },
  props: {
    copy: {
      default: undefined,
      required: false,
      type: String,
    },
    dataCypress: {
      type: String,
      required: false,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    displayCaret: {
      type: Boolean,
      default: true,
    },
    variation: {
      default: 'primary',
      type: String,
      validator: function (value) {
        return [
          'primary',
          'secondary-black',
          'secondary-gray',
          'secondary-transparent',
          'solid-black',
          'tertiary',
          'filter',
          'icon-primary',
          'icon-plain-gray',
          'icon-gray-outline',
          'icon-transparent',
        ].includes(value);
      },
    },
    size: {
      default: 'medium',
      type: String,
      validator: function (value) {
        return ['large', 'medium', 'small'].includes(value);
      },
    },
    menuWidth: {
      default: undefined,
      type: String,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  emits: ['buttonAction'],
  setup(props) {
    const soonaDropdownWrap = ref(null);
    const buttonRef = ref(null);
    const menuRef = ref(null);
    const isIconButton = computed(() => props.variation.startsWith('icon-'));
    const open = ref(false);

    const { floatingStyles } = useFloating(soonaDropdownWrap, menuRef, {
      whileElementsMounted: (...args) =>
        autoUpdate(...args, {
          // to fix errors in Cypress tests. remove if tests pass without this
          animationFrame: true,
        }),
      middleware: [offset(4), flip()],
      placement: 'bottom-start',
      open,
    });

    return {
      soonaDropdownWrap,
      buttonRef,
      menuRef,
      isIconButton,
      floatingStyles,
      open,
    };
  },
  data() {
    const baseId = uniqueId();
    return {
      menuId: `dropdown-menu-${baseId}`,
      buttonId: `dropdown-menu-button-${baseId}`,
    };
  },
  mounted() {
    window.addEventListener('mousedown', this.onBackgroundMousedown);

    if (!this.buttonRef?.contains || !this.buttonRef?.focus) {
      console.warn(
        '[SoonaDropdownMenu] trigger button does not expose `contains` and `focus` methods, some functionality will be broken'
      );
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousedown', this.onBackgroundMousedown);
  },
  methods: {
    openMenu() {
      this.open = true;
    },
    closeMenu() {
      this.open = false;
    },
    onButtonClick(event) {
      this.$emit('buttonAction');
      if (this.open) {
        this.closeMenu();
        this.buttonRef?.focus();
      } else {
        this.openMenu();
        this.setFocusToFirstMenuitem();
      }

      event.stopPropagation();
      event.preventDefault();
    },
    onButtonKeydown(event) {
      let flag = false;

      switch (event.key) {
        case ' ':
        case 'Enter':
        case 'ArrowDown':
        case 'Down':
          this.openMenu();
          this.setFocusToFirstMenuitem();
          flag = true;
          break;

        case 'Esc':
        case 'Escape':
          this.closeMenu();
          this.buttonRef?.focus();
          flag = true;
          break;

        case 'Up':
        case 'ArrowUp':
          this.openMenu();
          this.setFocusToLastMenuitem();
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onBackgroundMousedown(event) {
      const menu = this.menuRef;
      if (
        !menu?.contains(event.target) &&
        // trigger-button should expose a `contains` method
        !this.buttonRef.contains?.(event.target)
      ) {
        if (this.open) {
          this.closeMenu();
          this.buttonRef.focus();
          event.stopPropagation();
          event.preventDefault();
        }
      }
    },
    onMenuitemMouseover(event) {
      event.currentTarget.focus();
    },
    onMenuitemKeydown(event) {
      const target = event.currentTarget;
      const key = event.key;
      let flag = false;

      function isPrintableCharacter(str) {
        return str.length === 1 && str.match(/\S/);
      }

      // prevent hijacking keyboard shortcuts
      if (event.ctrlKey || event.altKey || event.metaKey) {
        return;
      }

      if (event.shiftKey) {
        if (isPrintableCharacter(key)) {
          this.setFocusByFirstCharacter(target, key);
          flag = true;
        }

        if (key === 'Tab') {
          this.buttonRef.focus();
          this.closeMenu();
          flag = true;
        }
      } else {
        switch (key) {
          case ' ':
            // "select" the menu item by clicking it
            target.click();
            flag = true;
            break;

          case 'Esc':
          case 'Escape':
            this.closeMenu();
            this.buttonRef.focus();
            flag = true;
            break;

          case 'Up':
          case 'ArrowUp':
            this.setFocusToPreviousMenuitem(target);
            flag = true;
            break;

          case 'ArrowDown':
          case 'Down':
            this.setFocusToNextMenuitem(target);
            flag = true;
            break;

          case 'Home':
          case 'PageUp':
            this.setFocusToFirstMenuitem();
            flag = true;
            break;

          case 'End':
          case 'PageDown':
            this.setFocusToLastMenuitem();
            flag = true;
            break;

          case 'Tab':
            this.closeMenu();
            break;

          default:
            if (isPrintableCharacter(key)) {
              this.setFocusByFirstCharacter(target, key);
              flag = true;
            }
            break;
        }
      }

      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onMenuitemClickCapture(e) {
      if (e.currentTarget.getAttribute('role') !== 'menuitemcheckbox') {
        this.closeMenu();
      }
    },
    getMenuitemNodes() {
      return this.menuRef.querySelectorAll('[role^="menuitem"]');
    },
    getFirstMenuitem() {
      return this.menuRef.querySelector('[role^="menuitem"]');
    },
    getLastMenuitem() {
      const items = this.menuRef.querySelectorAll('[role^="menuitem"]') || [];
      return items.length > 0 ? items[items.length - 1] : null;
    },
    setFocusToFirstMenuitem() {
      this.setFocusToMenuitem(this.getFirstMenuitem());
    },
    setFocusToLastMenuitem() {
      this.setFocusToMenuitem(this.getLastMenuitem());
    },
    setFocusToPreviousMenuitem(currentMenuitem) {
      let newMenuitem;
      const nodes = this.getMenuitemNodes();

      if (currentMenuitem === this.getFirstMenuitem()) {
        newMenuitem = this.getLastMenuitem();
      } else if (nodes && nodes.length > 0) {
        const index = Array.from(nodes).indexOf(currentMenuitem);
        newMenuitem = nodes[index - 1];
      } else {
        newMenuitem = null;
      }

      this.setFocusToMenuitem(newMenuitem);

      return newMenuitem;
    },
    setFocusToNextMenuitem(currentMenuitem) {
      let newMenuitem;
      const nodes = this.getMenuitemNodes();

      if (currentMenuitem === this.getLastMenuitem()) {
        newMenuitem = this.getFirstMenuitem();
      } else if (nodes && nodes.length > 0) {
        const index = Array.from(nodes).indexOf(currentMenuitem);
        newMenuitem = nodes[index + 1];
      } else {
        newMenuitem = null;
      }
      this.setFocusToMenuitem(newMenuitem);

      return newMenuitem;
    },
    setFocusToMenuitem(newMenuitem) {
      const nodes = this.getMenuitemNodes();

      nodes.forEach(item => {
        if (item === newMenuitem) {
          item.tabIndex = 0;
          newMenuitem.focus();
        } else {
          item.tabIndex = -1;
        }
      });
    },
    setFocusByFirstCharacter(currentMenuitem, char) {
      if (char.length > 1) {
        return;
      }
      // bail out if we can't find any menuitems
      const nodes = this.getMenuitemNodes();
      if (!nodes || nodes.length < 1) {
        return;
      }
      const nodesArr = Array.from(nodes);

      let index;

      const charLower = char.toLowerCase();

      // Get start index for search based on position of currentItem
      let start = nodesArr.indexOf(currentMenuitem) + 1;
      if (start >= nodesArr.length) {
        start = 0;
      }

      const firstChars = nodesArr.map(menuitemEl => {
        const text = menuitemEl.textContent.trim();
        return text ? text[0].toLowerCase() : '';
      });

      // Check remaining slots in the menu
      index = firstChars.indexOf(charLower, start);

      // If not found in remaining slots, check from beginning
      if (index === -1) {
        index = firstChars.indexOf(charLower, 0);
      }

      // If match was found...
      if (index > -1) {
        this.setFocusToMenuitem(nodes[index]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.soona-dropdown {
  pointer-events: auto;
  position: relative;

  [role='menu'] {
    position: absolute;
    z-index: 20;
    padding: 0.5rem 0;
    background-color: variables.$white-default;
    box-shadow: variables.$elevation-3;
    border-radius: 0.3125rem;
    min-width: 12.5rem;
    transition: opacity 0.1s ease-out;

    &.menu-fade-enter-from,
    &.menu-fade-leave-to {
      opacity: 0;
    }

    &.width {
      width: v-bind('menuWidth');
    }
  }

  &[data-open='true'] {
    :slotted(:deep(.soona-dropdown-slotted-caret)),
    .soona-dropdown__trigger__caret {
      transform: rotate(0.5turn);
    }
  }
}

:slotted(:deep(.soona-dropdown-slotted-caret)),
.soona-dropdown__trigger__caret {
  transition: transform 0.25s ease-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .soona-dropdown [role='menu'],
  :slotted(:deep(.soona-dropdown-slotted-caret)),
  .soona-dropdown__trigger__caret {
    transition: none !important;
  }
}
</style>
