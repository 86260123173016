import { computed, isRef, toValue } from 'vue';
import { hashKey, useQueries } from '@tanstack/vue-query';
import {
  capabilityQueryConfig,
  createCapabilityQueryKey,
} from '@/queries/useCapability';

/**
 * @typedef CapabilityParams
 * @type {object}
 * @property {string | Ref<string>} capability
 * @property {string | Ref<string>} [subjectType]
 * @property {string | number | Ref<string | number>} [subjectId]
 */

/**
 * @typedef CapabilitiesResult
 * @type {Object.<string, CapabilityResult>}
 *
 * @description an object with any string as the key, and the value of a CapabilityResult
 */

/**
 * @description this returns a computed ref with an object of capability names
 * and status/hasCapability pairs. because this is a ref at the top level, it
 * cannot be destructured, or reactivity will be broken.
 *
 * @param {import('vue').Ref<CapabilityParams[]>} capabilities
 * @returns {import('vue').Ref<CapabilitiesResult>}
 */
export function useCapabilities(capabilities) {
  if (!isRef(capabilities) || !Array.isArray(toValue(capabilities))) {
    throw new Error('[useCapabilities] must be called with a ref array');
  }

  // [query hash, capability object][]
  const queryHashCapabilityPairs = computed(() =>
    capabilities.value.map(cap => [
      hashKey(
        createCapabilityQueryKey({
          subjectType: cap.subjectType,
          subjectId: cap.subjectId,
        })
      ),
      cap,
    ])
  );

  // Map<query hash, capability object> (de-dupes the same query hashes)
  const uniqueCapabilitiesMap = computed(() => {
    return new Map(queryHashCapabilityPairs.value);
  });

  // generate an array of actual query configs for each query hash
  const capabilitiesOptions = computed(() => {
    return Array.from(uniqueCapabilitiesMap.value.values()).map(
      ({ subjectType, subjectId }) =>
        capabilityQueryConfig({ subjectType, subjectId })
    );
  });

  const queries = useQueries({ queries: capabilitiesOptions });

  return computed(() => {
    // Map<string, Object>, queryHash for the key, query result for the value
    const queriesMapped = new Map(
      Array.from(uniqueCapabilitiesMap.value.keys(), (queryHash, index) => [
        queryHash,
        queries.value[index],
      ])
    );

    // map over the array of capabilities and swap in the result for each one from the de-duped queries
    return queryHashCapabilityPairs.value.reduce(
      (acc, [queryHash, capability]) => {
        const query = queriesMapped.get(queryHash);

        acc[capability.capability] = {
          status: query.status,
          hasCapability:
            query.data?.includes(toValue(capability.capability)) || false,
        };

        return acc;
      },
      {}
    );
  });
}
