<template>
  <div class="batch-location-layout">
    <div class="batch-location-layout__header">
      <slot name="illustration" />
      <h2>
        <slot name="heading" />
      </h2>
    </div>

    <slot />
  </div>
</template>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.batch-location-layout {
  flex: 1;
  width: 100%;
  gap: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: variables.$screen-sm-min) {
    gap: 2.5rem;
    padding-top: 8rem;
  }

  &__header {
    h2 {
      @include variables_fonts.u-body--heavy;

      text-align: left;

      @media (min-width: variables.$screen-sm-min) {
        @include variables_fonts.u-display--heavy;

        text-align: center;
      }
    }
  }
}
</style>
