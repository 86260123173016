import { useMe } from '@/composables/user/useMe';
import { useCapability } from '@/composables/useCapability';
import { computed } from 'vue';
import { useFlag } from '@/composables/useFlag';

/**
 *
 * @param {Ref<Object | undefined>} account
 */
export function useAccountLinkDisplayLogic(account) {
  const { currentAccountId } = useMe();

  const { hasCapability: isSoonaStaff } = useCapability({
    capability: 'soona_staff',
  });

  const { hasCapability: isViewAccountTabs } = useCapability({
    capability: 'view_account_tabs',
  });

  const { hasCapability: isAssignedReservation } = useCapability({
    capability: 'assigned_reservation',
  });

  const paymentsRetainerMVP = useFlag('payments_retainer_mvp');

  const isMyAccount = computed(
    () => currentAccountId.value === account.value?.id
  );

  const isProServiceAccount = computed(() => {
    return account.value?.owner.employee_type_title === 'pro services';
  });

  const isEmployeeAccount = computed(() => {
    return account.value?.owner.employee_type_title !== null;
  });

  const showGalleryLink = computed(() => {
    return isSoonaStaff.value || isMyAccount.value;
  });

  const showAssignmentsLink = computed(() => {
    return !!(
      (isAssignedReservation.value || isSoonaStaff.value) &&
      account.value?.has_assignments &&
      isEmployeeAccount.value &&
      (isSoonaStaff.value || isMyAccount.value)
    );
  });

  const showProServiceProfile = computed(() => {
    return isEmployeeAccount.value && (isSoonaStaff.value || isMyAccount.value);
  });

  return {
    viewUserLinks: isSoonaStaff,
    viewCrewLinks: isViewAccountTabs,
    showGalleryLink,
    showAssignmentsLink,
    showCrewTools: isSoonaStaff,
    showProServiceProfile,
    showAvailability: isProServiceAccount,
    showInventory: isSoonaStaff,
    showContracts: computed(
      () => isSoonaStaff.value && paymentsRetainerMVP.value
    ),
  };
}
