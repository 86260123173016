<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  accentColorVariable: {
    type: String,
    default: null,
  },
  element: {
    type: String,
    default: undefined,
    validator: value => {
      return ['a', 'router-link', 'button', 'div'].includes(value);
    },
  },
  iconCta: {
    type: String,
    required: false,
  },
  iconPrimary: {
    type: String,
    required: true,
  },
  to: {
    type: [String, Object],
    default: null,
  },
});

const emit = defineEmits(['link-clicked']);

const iconCta = computed(() => props.iconCta);
const iconPrimary = computed(() => props.iconPrimary);

const rootComponent = computed(() => {
  if (props.element) {
    return props.element;
  } else if (
    typeof props.to === 'string' &&
    (props.to.startsWith('http') || props.to.includes('#/'))
  ) {
    return 'a';
  } else if (props.to) {
    return 'router-link';
  }
  return 'div';
});

const linkAttribute = computed(() => {
  if (rootComponent.value === 'router-link') {
    return {
      to: props.to,
    };
  } else if (rootComponent.value === 'a') {
    return {
      href: props.to,
    };
  } else {
    return {};
  }
});

function componentClick() {
  if (rootComponent.value === 'router-link' || rootComponent.value === 'a') {
    emit('link-clicked');
  }
}
</script>

<template>
  <component
    :is="rootComponent"
    v-bind="linkAttribute"
    class="hero-card"
    :class="{ 'u-button-reset': rootComponent === 'button' }"
    @click="componentClick"
  >
    <div class="hero-card__inner">
      <div v-if="iconPrimary" class="hero-card__icon-primary">
        <SoonaIcon :name="iconPrimary" />
      </div>
      <h3 v-if="$slots['default']" class="hero-card__title"><slot /></h3>
      <p v-if="$slots['subtitle']" class="u-label--regular hero-card__subtitle">
        <slot name="subtitle" />
      </p>
      <SoonaIcon v-if="iconCta" class="hero-card__icon-cta" :name="iconCta" />
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.hero-card {
  container-type: inline-size;
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.07);
  background-color: variables.$white-default;
  border-radius: 0.625rem;
  position: relative;
  transition: background-color 0.1s ease-out;
  text-wrap: wrap;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 0.625rem;
    background: variables.$bubbletape-pink-02;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:hover {
    color: variables.$black-default;

    &::before {
      opacity: 1;
    }
  }

  &:active {
    background-color: variables.$bubbletape-pink-10;

    &::before {
      opacity: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    padding: 0.75rem 0.5rem 1rem;
    min-width: 5rem;
  }

  &__icon-primary {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    background-color: v-bind('accentColorVariable');
    border-radius: 50%;
  }

  &__title {
    @include variables_fonts.u-small--heavy;

    font-weight: 800;
  }

  &__subtitle,
  &__icon-cta {
    transition: color 0.1s ease-out;
    display: none;
  }

  &:hover .hero-card__icon-cta {
    color: variables.$periwink-blue-60;
  }

  @container (min-width: 12.25rem) {
    &__inner {
      align-items: flex-start;
      text-align: left;
      padding: 0.75rem 1rem 1rem;
      gap: 1rem;
      height: 100%;
    }

    &__icon-primary {
      width: 4rem;
      padding: 0.75rem 1.5rem 0.75rem 1rem;
      border-radius: 0 3rem 3rem 0;
      margin-left: -1rem;
    }

    &__title {
      @include variables_fonts.u-title--heavy;
    }

    &__subtitle {
      display: block;
      margin-top: -0.75rem;
    }

    &__icon-cta {
      display: block;
      margin-top: auto;
      margin-left: auto;
    }
  }
}
</style>
