import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
/**
 *
 * @param { string | number } userId
 * @param { string | number } profileId
 */
export function useUpdateProServiceProfile(userId, profileId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      displayName,
      feedback,
      images,
      isActive,
      measurements,
      sampleWork,
      selectedList,
    }) => {
      let arrayParams = [];

      if (selectedList) {
        arrayParams = selectedList.map(item => {
          return { tag_id: item.id };
        });
      }

      let body = {
        ...(!!displayName && { name: displayName }),
        ...(!!sampleWork && { sample_work: sampleWork }),
        ...(!!measurements && { measurements: measurements }),
        ...(!!selectedList && { pro_service_profile_tags: arrayParams }),
        ...((isActive || isActive === false) && { is_active: isActive }),
        ...(!!images && { images: images }),
        ...(!!feedback && {
          pro_service_feedbacks_attributes: feedback,
        }),
      };
      const response = await http.put(
        `/users/${toValue(userId)}/pro_service_profiles/${toValue(profileId)}.json`,
        {
          pro_service_profile: body,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.proServiceProfiles({
          userId,
        }),
      });
    },
  });
}
