<script setup>
import { computed } from 'vue';
import AccountBalance from './AccountBalance.vue';
import ContractSummary from './ContractSummary.vue';
import ContractOrderHistory from './ContractOrderHistory.vue';
import { useContracts } from '@/composables/contracts/useContracts';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});
const accountId = computed(() => props.accountId);
const { activeContract } = useContracts({ accountId, statusues: ['active'] });
</script>

<template>
  <div class="crew-view-active-contract">
    <AccountBalance :account-id="accountId" />
    <ContractSummary
      :account-id="accountId"
      :contract-id="activeContract?.id"
    />
    <ContractOrderHistory :account-id="accountId" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.crew-view-active-contract {
}
</style>
