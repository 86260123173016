<script setup>
import NotesList from '@/components/notes/NotesList.vue';
import NoteCreate from '@/components/notes/NoteCreate.vue';
import { computed } from 'vue';
import { useDigitalAssetNotesAreReadonly } from '@/components/notes/digital-asset-notes/useDigitalAssetNotesAreReadonly';
import { useNonCreatorEditingAllowed } from '@/components/notes/useNonCreatorEditingAllowed';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  autoFocusComposer: {
    type: Boolean,
    default: false,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
  showAnnotationInstructions: {
    type: Boolean,
    default: true,
  },
  showNoNotesMessage: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  newestFirst: {
    type: Boolean,
    default: false,
  },
  stickyBottomSize: {
    type: String,
    default: '1rem',
  },
});

const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);

const readonly = useDigitalAssetNotesAreReadonly(accountId, digitalAssetId);
const nonCreatorEditingAllowed = useNonCreatorEditingAllowed('digital_assets');
const editingAllowed = computed(
  () => !readonly.value || nonCreatorEditingAllowed.value
);
</script>

<template>
  <NoteCreate
    v-if="editingAllowed && newestFirst && digitalAssetId"
    :auto-focus="autoFocusComposer"
    :placeholder="placeholder"
    subject-type="digital_assets"
    :subject-id="digitalAssetId"
    class="digital-asset-notes-list__composer-top"
  />
  <NotesList
    subject-type="digital_assets"
    :subject-id="digitalAssetId"
    :show-no-notes-message="showNoNotesMessage"
    :newest-first="newestFirst"
    :readonly="readonly"
    class="digital-asset-notes-list"
    :data-newest-first="newestFirst"
    :show-annotation-instructions="showAnnotationInstructions"
  />
  <div
    v-if="editingAllowed && !newestFirst && digitalAssetId"
    class="digital-asset-notes-list__composer-bottom-wrap"
  >
    <NoteCreate
      :auto-focus="autoFocusComposer"
      :placeholder="placeholder"
      subject-type="digital_assets"
      :subject-id="digitalAssetId"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.digital-asset-notes-list[data-newest-first='false'] {
  margin-bottom: 1rem;
}

.digital-asset-notes-list__composer-top {
  margin-bottom: 1rem;
}

.digital-asset-notes-list__composer-bottom-wrap {
  margin-top: auto;
  position: sticky;
  bottom: calc(-1 * v-bind(stickyBottomSize));
  padding-bottom: v-bind(stickyBottomSize);
  background-color: variables.$white-default;
  margin-bottom: calc(-1 * v-bind(stickyBottomSize));
}
</style>
