<script setup>
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';

import { queryKeys } from '@/queries/query-keys';
import { useMasonryAssets } from '../useMasonryAssets';
import { useBatchEditStore } from '../store/useBatchEditStore';
import { useSubmitBatchEdits } from '@/queries/batch_edits/useSubmitBatchEdits';

import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import { useBatchEditTools } from '@/components/batch_edits/store/useBatchEditTools';
import BatchRemoveBackgroundAsset from './BatchRemoveBackgroundAsset.vue';

const emit = defineEmits([
  'close',
  'reset-selected-assets',
  'remove-selected-asset',
]);

const batchEditStore = useBatchEditStore();
const {
  accountId,
  selectedAssets,
  isMobile,
  locationSelectedAlbums,
  locationAlbumName,
} = storeToRefs(batchEditStore);

const { BATCH_REMOVE_BACKGROUND_ACTION } = useBatchEditTools();

const {
  mutate: batchRemoveBackground,
  isPending,
  error,
} = useSubmitBatchEdits('remove_background');

const router = useRouter();
const queryClient = useQueryClient();

const handleSubmit = async () => {
  batchRemoveBackground(
    {
      asset_ids: selectedAssets.value.map(asset => asset.id),
      album_title: locationAlbumName.value,
      album_ids: locationSelectedAlbums.value,
    },
    {
      onSuccess: async data => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: queryKeys.collectionsDA(accountId),
          }),
        ]);
        if (data.album_ids.length === 0 || data.album_ids.length > 1) {
          emit('reset-selected-assets');
          emit('close');
        } else {
          batchEditStore.$reset();
          await router.push({
            name: 'album-assets',
            params: {
              accountId: accountId.value,
              collectionId: data.album_ids[0],
            },
          });
        }
      },
    }
  );
};

onMounted(() => {
  batchEditStore.registerComponent(BATCH_REMOVE_BACKGROUND_ACTION, {
    handleSubmit: handleSubmit,
  });
});

const wrapper = ref(null);

const { assetRows, gutter, rowHeight, offsetTop } = useMasonryAssets({
  wrapper,
  gapRem: 1,
  heightRem: 16.5,
  assets: selectedAssets,
});
</script>

<template>
  <div ref="wrapper" class="batch-edits">
    <SoonaLoading v-if="isPending" is-contained is-loading />
    <SoonaError v-else-if="error">{{ error }}</SoonaError>

    <template v-if="isMobile">
      <BatchRemoveBackgroundAsset
        v-for="asset in selectedAssets"
        :key="asset.id"
        :asset="asset"
        @click="emit('remove-selected-asset', asset)"
      />
    </template>
    <template v-else>
      <InfiniteGallery
        v-slot="{ data }"
        :gap="gutter"
        :rows="assetRows"
        :height="rowHeight"
        :offset-top="offsetTop"
      >
        <BatchRemoveBackgroundAsset
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          @click="emit('remove-selected-asset', asset)"
        />
      </InfiniteGallery>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.batch-edits {
  gap: 1rem;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: flex-start;
  background: variables.$gray-10;
}
</style>
