import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from 'src/queries/query-keys';
export function useUpdateCrewAssignment({
  crewAssignmentId,
  reservationId,
  onSuccess,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      await http.put(
        `/reservations/${toValue(reservationId)}/crew_assignments/${toValue(crewAssignmentId)}`,
        {
          crew_assignment: params,
        }
      );
    },
    onSuccess: async () => {
      if (onSuccess) {
        await onSuccess();
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.assignedReservations(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId.value),
        }),
      ]);
    },
  });
}
