<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  remainingFunds: {
    type: [Number, String],
    required: true,
  },
  total: {
    type: [Number, String],
    required: true,
  },
  totalFunded: {
    type: [Number, String],
    required: true,
  },
});

const total = computed(() => +props.total);
const totalFunded = computed(() => +props.totalFunded);
</script>
<template>
  <div class="contract-funds">
    <p class="u-body--heavy contract-funds__info-text">
      {{ toCurrency(totalFunded) }} of the total {{ toCurrency(total) }} account
      balance for this contract has been added to the client’s account.
    </p>
    <progress
      class="progress contract-funds__progress-bar"
      :value="totalFunded"
      :max="total"
    />
    <div class="contract-funds__details">
      <div class="contract-funds__section">
        <div
          class="contract-funds__legend contract-funds__legend--funded"
        ></div>
        <div>
          <p class="u-label--heavy contract-funds__section--title">
            Funds added
          </p>
          <p class="u-body--heavy">{{ toCurrency(totalFunded) }}</p>
        </div>
      </div>
      <div class="contract-funds__section contract-funds__remaining-section">
        <div>
          <p class="u-label--heavy contract-funds__section--title">
            Remaining funds
          </p>
          <p class="u-body--heavy">{{ toCurrency(remainingFunds) }}</p>
        </div>
        <div
          class="contract-funds__legend contract-funds__legend--remaining"
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-funds {
  margin-bottom: 1rem;

  &__info-text {
    margin-bottom: 0.625rem;
  }

  &__progress-bar {
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__section {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    &--title {
      color: variables.$gray-60;
    }
  }

  &__remaining-section {
    text-align: right;
  }

  &__legend {
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 0.25rem;

    &--funded {
      background: variables.$periwink-blue-60;
    }

    &--remaining {
      background: variables.$periwink-blue-30;
    }
  }
}
</style>
