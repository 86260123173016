import { computed, toValue } from 'vue';

export const useResizeCalculations = (mediaWidth, mediaHeight, cropperEl) => {
  const biggerSize = sizes => Math.max(...sizes);
  const smallerSize = sizes => Math.min(...sizes);

  const smallerMediaSize = computed(() =>
    Math.min(mediaWidth.value, mediaHeight.value)
  );
  const biggerMediaSize = computed(() =>
    Math.max(mediaWidth.value, mediaHeight.value)
  );

  const isImageHorizontal = computed(
    () => mediaWidth.value > mediaHeight.value
  );

  const useSelectorCalculations = selectorData => {
    let { ratio, width: selectorWidth, height: selectorHeight } = selectorData;
    const selectorRatio = selectorWidth / selectorHeight;
    const isSelectorSquare = selectorWidth === selectorHeight;
    const isSelectorHorizontal = selectorWidth > selectorHeight;

    return {
      ratio,
      selectorRatio,
      isSelectorSquare,
      selectorWidth,
      selectorHeight,
      isSelectorHorizontal,
    };
  };

  const calculateBoxSize = selectorData => {
    const {
      selectorWidth,
      selectorHeight,
      isSelectorSquare,
      isSelectorHorizontal,
    } = useSelectorCalculations(selectorData);

    const w = toValue(mediaWidth);
    const h = toValue(mediaHeight);

    const ratio =
      selectorWidth > selectorHeight
        ? selectorHeight / selectorWidth
        : selectorWidth / selectorHeight;

    if (isSelectorSquare)
      return {
        width: smallerSize([h, w]),
        height: smallerSize([h, w]),
      };

    if (isSelectorHorizontal)
      return {
        width: biggerSize([w, selectorWidth]),
        height: biggerSize([w, selectorWidth]) * ratio,
      };

    return {
      width: biggerSize([h, selectorHeight]) * ratio,
      height: biggerSize([h, selectorHeight]),
    };
  };

  const getTargetWidth = selectorData => {
    const {
      ratio,
      selectorRatio,
      selectorWidth,
      isSelectorSquare,
      isSelectorHorizontal,
    } = useSelectorCalculations(selectorData);

    if (!ratio || !selectorWidth) return mediaWidth.value;

    if (isSelectorSquare) return smallerMediaSize.value;

    if (isSelectorHorizontal) {
      return isImageHorizontal.value
        ? biggerMediaSize.value
        : smallerMediaSize.value;
    }

    return isImageHorizontal.value
      ? smallerMediaSize.value * selectorRatio
      : biggerMediaSize.value * selectorRatio;
  };

  const getTargetHeight = selectorData => {
    const {
      ratio,
      selectorRatio,
      selectorHeight,
      isSelectorSquare,
      isSelectorHorizontal,
    } = useSelectorCalculations(selectorData);

    if (!ratio || !selectorHeight) return mediaHeight.value;

    if (isSelectorSquare) return smallerMediaSize.value;

    if (isSelectorHorizontal) {
      return isImageHorizontal.value
        ? biggerMediaSize.value * selectorRatio
        : smallerMediaSize.value * selectorRatio;
    }

    return isImageHorizontal.value
      ? smallerMediaSize.value
      : biggerMediaSize.value;
  };

  const calculateBoxPosition = selectorData => {
    let { name, custom } = selectorData;

    let width = getTargetWidth(selectorData);
    let height = getTargetHeight(selectorData);

    if (!custom) {
      const boxSize = calculateBoxSize(selectorData);
      width = boxSize.width;
      height = boxSize.height;
    }

    cropperEl.value?.setCoordinates(({ imageSize }) => {
      // these calculations are assuming the image on the canvas is the full size image
      // if we use a smaller image we might need to adjust
      const leftPositioning = (imageSize.width - width) / 2;
      const topPositioning = (imageSize.height - height) / 2;

      return {
        left: leftPositioning,
        top: topPositioning,
        width: width,
        height: height,
        name: name,
      };
    });
  };

  return { calculateBoxPosition };
};
