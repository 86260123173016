<script setup>
import { computed, onMounted } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';
import { useIntegrations } from '@/composables/useIntegrations';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import BillingAndOrderHeading from '@/components/user/anytime/billing_and_orders/BillingAndOrderHeader.vue';
import DefaultPaymentCards from '@/components/user/anytime/billing_and_orders/DefaultPaymentCards.vue';
import IntegrationBillingSection from '@/components/user/anytime/billing_and_orders/IntegrationBillingSection.vue';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { isActiveRetainerClient } = useAccount(computed(() => props.accountId));
const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);
const route = useRoute();
useTitle('billing & order history | soona');

// matching logic for viewing the account subscriptions route
const { hasCapability: canVewAccountSubscription } = useCapability({
  capability: 'view_account_subscription',
  subjectType: 'account',
  subjectId: accountId,
});
const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});

const canViewSubscriptions = computed(() => {
  return canVewAccountSubscription.value || canCrewManageSubscription.value;
});

const { hasShopifyIntegration } = useIntegrations(accountId);
</script>

<template>
  <BillingAndOrderHeading
    :account-id="accountId"
    :has-contracts="isActiveRetainerClient"
  />
  <DefaultPaymentCards v-if="!hasShopifyIntegration" :account-id="accountId" />
  <IntegrationBillingSection :account-id="accountId" />
  <nav class="order-invoices-tabs">
    <SoonaTab
      :to="{ path: `/account/${accountId}/orders` }"
      :is-selected="route.path === `/account/${accountId}/orders`"
    >
      <template #copy>studio payments</template>
    </SoonaTab>
    <SoonaTab
      v-if="canViewSubscriptions"
      :to="{ path: `/account/${accountId}/orders/subscription-invoices` }"
      :is-selected="
        route.path === `/account/${accountId}/orders/subscription-invoices`
      "
    >
      <template #copy>subscription payments</template>
    </SoonaTab>
  </nav>
  <router-view />
</template>

<style lang="scss" scoped>
.order-invoices-tabs {
  display: flex;
  overflow-x: auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
</style>
