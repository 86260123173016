<script setup>
import { Cropper } from 'vue-advanced-cropper';
import { nextTick, watchEffect, computed, ref, toRefs, watch } from 'vue';
import { useResizeCalculations } from '@/components/batch_edits/resize/useResizeCalculations';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import 'vue-advanced-cropper/dist/style.css';
import { useMediaQuery } from '@vueuse/core';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  asset: {
    required: true,
    type: Object,
  },
  selectorData: {
    required: true,
    type: Object,
  },
  cropData: {
    type: Object,
    default: () => ({}),
  },
  showData: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update-crop-data']);

const { asset, selectorData, cropData } = toRefs(props);
const assetAccountId = computed(() => asset.value.account_id);
const assetId = computed(() => asset.value.id);
const isLoading = ref(true);
const isDesktop = useMediaQuery('(min-width: 40rem)');
const isMobile = computed(() => !isDesktop.value);
const cropperKey = ref(1);
const {
  mediaWidth,
  mediaHeight,
  previewUrl,
  previewWidth,
  previewHeight,
  assetLoading,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const cropperEl = ref();
const selectedCoordinates = ref({});
const defaultSize = ref(undefined);
const defaultPosition = ref(undefined);

const widthRatio = computed(() => {
  return mediaWidth.value / previewWidth.value;
});

const heightRatio = computed(() => {
  return mediaHeight.value / previewHeight.value;
});

const stencilProps = computed(() => {
  if (!selectorData.value?.ratio) return {};
  return {
    aspectRatio: selectorData.value?.width / selectorData.value?.height,
  };
});

const scaleCoordinates = coordinates => {
  return {
    left: coordinates.left * widthRatio.value,
    top: coordinates.top * heightRatio.value,
    width: coordinates.width * widthRatio.value,
    height: coordinates.height * heightRatio.value,
  };
};

const change = ({ coordinates }) => {
  selectedCoordinates.value = coordinates;

  if (coordinates.width === 0 && coordinates.height === 0) return;

  emits('update-crop-data', assetId.value, scaleCoordinates(coordinates));
};

const { calculateBoxPosition } = useResizeCalculations(
  previewWidth,
  previewHeight,
  cropperEl
);

watch(selectorData, () => {
  calculateBoxPosition(selectorData.value);
});

const readyToSetDefault = computed(
  () =>
    previewWidth.value &&
    previewHeight.value &&
    !defaultSize.value &&
    !defaultPosition.value
);

watchEffect(() => {
  if (readyToSetDefault.value) {
    const coordinates = cropData.value?.mode_inputs?.coordinates;
    if (coordinates) {
      defaultSize.value = {
        width: coordinates.width / widthRatio.value,
        height: coordinates.height / heightRatio.value,
      };
      defaultPosition.value = {
        top: coordinates.top / heightRatio.value,
        left: coordinates.left / widthRatio.value,
      };
    } else {
      defaultSize.value = {
        width: previewWidth.value,
        height: previewHeight.value,
      };
      defaultPosition.value = {
        top: 0,
        left: 0,
      };
    }
  }
});

watchEffect(() => {
  if (Object.keys(selectedCoordinates.value).length > 0 && isLoading.value) {
    isLoading.value = false;
    nextTick(() => {
      cropperKey.value++;
    });
  }
});

const refreshCropper = () => {
  cropperEl.value.refresh();
};

const renderCropper = computed(
  () => !assetLoading.value && defaultSize.value && defaultPosition.value
);
</script>
<template>
  <div
    class="batch-cropper-wrapper"
    :class="{ 'batch-cropper-wrapper--mobile': isMobile }"
  >
    <SoonaSkeleton v-if="isLoading" class="batch-cropper-wrapper__skeleton" />
    <Cropper
      v-show="!isLoading"
      v-if="renderCropper"
      :key="cropperKey"
      ref="cropperEl"
      min-width="100"
      min-height="100"
      default-boundaries="fit"
      image-restriction="fit-area"
      class="batch-cropper-wrapper__cropper"
      :src="previewUrl"
      :resize-image="false"
      :default-size="defaultSize"
      :default-position="defaultPosition"
      :stencil-props="stencilProps"
      @change="change"
      @ready="refreshCropper"
    />
  </div>
</template>

<style lang="scss">
@use 'src/variables';

.batch-cropper-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__cropper {
    min-width: 0;
    min-height: 0;
    width: fit-content;
    height: fit-content;
  }

  &--mobile {
    align-items: center;

    .batch-cropper-wrapper__skeleton {
      height: 20rem;
      width: 20rem;
    }
  }

  &__skeleton {
    height: 12rem;
    width: 12rem;
  }
}

.vue-advanced-cropper__boundaries,
.vue-advanced-cropper__image-wrapper,
.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  border-radius: 0.3125rem;
}

.vue-line-wrapper .vue-simple-line {
  border-color: variables.$periwink-blue-70;

  &--east {
    border-right-width: 0.0625rem;
  }
  &--west {
    border-left-width: 0.0625rem;
  }
  &--south {
    border-bottom-width: 0.0625rem;
  }
  &--north {
    border-top-width: 0.0625rem;
  }
}
.vue-simple-handler {
  border-style: solid;
  border-radius: 0.5rem;
  border-width: 0.0625rem;
  border-color: variables.$periwink-blue-70;

  &--west,
  &--east {
    width: 0.3125rem;
    height: 0.625rem;
  }

  &--north,
  &--south {
    width: 0.625rem;
    height: 0.3125rem;
  }

  &--west-north,
  &--west-south,
  &--east-north,
  &--east-south {
    width: 0.5rem;
    height: 0.5rem;
  }
}
</style>
