<script setup>
import { computed } from 'vue';
import ProServiceTypePill from 'src/components/directory/ProServiceTypePill.vue';
import groupBy from 'lodash/groupBy';
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '../ui_library/SoonaButton.vue';
import AvailabilityFlag from '../directory/AvailabilityFlag.vue';
import { useFlag } from '@/composables/useFlag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  profile: Object,
});

const emits = defineEmits(['open-book-model-dialog']);

const { linkClicked } = useBaseEvents();
const route = useRoute();

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const profileCardName = computed(() => {
  let name = '';
  if (props.profile.name) {
    name = name + props.profile.name + ' - ';
  }
  if (props.profile.user.profile_full_name) {
    name = name + props.profile.user.profile_full_name;
  } else {
    name = name + props.profile.user.name;
  }
  return name;
});
const proServiceTypeTag = computed(() => {
  return props.profile.pro_service_profile_tags.find(
    t => t.tag_category.title === 'pro services'
  );
});
const genderIdentityTag = computed(() => {
  return props.profile.pro_service_profile_tags.find(
    t => t.tag_category.title === 'gender identity'
  );
});
const ageTag = computed(() => {
  return props.profile.pro_service_profile_tags.find(
    t => t.tag_category.title === 'age group'
  );
});
const skinToneTag = computed(() => {
  return props.profile.pro_service_profile_tags.find(
    t => t.tag_category.title === 'skin tone'
  );
});
const sampleWork = computed(() => {
  return (
    ['content creator'].includes(props.profile?.service_type) &&
    !!props.profile?.sample_work
  );
});
const sampleWorkUrl = computed(() => {
  if (props.profile?.sample_work.includes('http')) {
    return props.profile?.sample_work;
  }

  return '//' + props.profile?.sample_work;
});

const otherTags = computed(() => {
  const tagsByCategory = groupBy(
    props.profile.pro_service_profile_tags.filter(
      t =>
        !['pro services', 'gender identity', 'age group', 'skin tone'].includes(
          t.tag_category.title
        )
    ),
    'tag_category.id'
  );

  return Object.values(tagsByCategory).map(tagList => {
    return tagList.reduce((acc, cur) => {
      return {
        ...acc,
        ...cur,
        title: acc.title ? `${acc.title}, ${cur.title}` : cur.title,
      };
    });
  }, {});
});
function formatServiceMeasurements(item) {
  const measurement = props.profile.required_measurements.find(
    m => m.measurement_key === item.key
  );
  if (item.key === 'height') {
    const feet = Math.floor(item.value / 12);
    const inches = item.value % 12;

    return `${feet}ft. ${inches}in.`;
  } else {
    return `${item.value}${measurement.format}.`;
  }
}

const modelBookingButtonCopy = computed(() => {
  if (props.profile?.service_type?.includes('model')) {
    return 'book this model';
  } else return 'book this provider';
});

function openBookModelDialog(profile) {
  emits('open-book-model-dialog', profile);
  linkClicked({
    context: route.meta.context,
    subContext: 'profile card',
    linkLabel: modelBookingButtonCopy.value,
    linkHref: null,
  });
}

const showAvailability = useFlag('apollo_pro_service_availability');
const showBookingButton = useFlag('apollo_directory_booking');
</script>

<template>
  <div class="pro-service-profile-card drop-shadow-container">
    <div class="profile-card-carousel">
      <SoonaCarousel
        class="soona-carousel-component drop-shadow-container"
        :class="{ 'inactive-profile': !profile.is_active }"
        :images="profile.image_urls"
        :wrap-around="false"
        :image-pips="true"
        height="9.375rem"
        width="9.375rem"
      />
      <SoonaButton
        v-if="showBookingButton"
        variation="solid-black"
        size="medium"
        :on-click="() => openBookModelDialog(profile)"
      >
        {{ modelBookingButtonCopy }}
      </SoonaButton>
    </div>
    <div class="profile-content">
      <div class="profile-content-header">
        <div
          class="name-row"
          :class="{ 'inactive-profile': !profile.is_active }"
        >
          <p class="name">
            {{ isFtSoonaStaff ? profileCardName : profile.provider_name }}
          </p>
          <p class="locations">
            {{ profile.user.locations.join(' - ') }}
          </p>
        </div>
        <img
          v-if="!profile.is_active"
          class="inactive-icon"
          src="@images/inactive-eye.svg"
          alt="inactive profile"
          title="inactive"
          :data-cypress="profileCardName + '-inactive-icon'"
        />
      </div>
      <div class="flags">
        <ProServiceTypePill
          :pro-service-type="proServiceTypeTag?.title"
          :class="{ 'inactive-profile': !profile.is_active }"
        />
        <AvailabilityFlag
          v-if="
            (showAvailability || isFtSoonaStaff) &&
            profile.availability !== null &&
            profile.availability !== undefined
          "
          :availability="profile.availability"
        />
      </div>
      <div class="traits" :class="{ 'inactive-profile': !profile.is_active }">
        <div
          v-if="genderIdentityTag || skinToneTag || ageTag || sampleWork"
          class="trait-column"
        >
          <div v-if="genderIdentityTag" class="trait">
            <p class="trait-title">
              {{ genderIdentityTag.tag_category.title }}:
            </p>
            <p class="trait-value">
              {{ genderIdentityTag.title }}
            </p>
          </div>
          <div v-if="skinToneTag" class="trait">
            <p class="trait-title">{{ skinToneTag.tag_category.title }}:</p>
            <p class="trait-value">
              {{ skinToneTag.title }}
            </p>
          </div>
          <div v-if="ageTag" class="trait">
            <p class="trait-title">{{ ageTag.tag_category.title }}:</p>
            <p class="trait-value">
              {{ ageTag.title }}
            </p>
          </div>
          <div v-if="sampleWork" class="trait">
            <p class="trait-title">sample work:</p>
            <p class="trait-value">
              <SoonaButton
                class="trait-link"
                element="a"
                variation="tertiary"
                target="_blank"
                :href="sampleWorkUrl"
              >
                {{ profile.sample_work }}
              </SoonaButton>
            </p>
          </div>
        </div>
        <div class="trait-column">
          <div v-for="tag in otherTags" :key="tag.id" class="trait">
            <p class="trait-title">{{ tag.tag_category.title }}:</p>
            <p class="trait-value">
              {{ tag.title }}
            </p>
          </div>
        </div>
        <div class="trait-column">
          <div
            v-for="measurement in profile.measurements"
            :key="measurement.key"
            class="trait"
          >
            <p v-if="measurement.value" class="trait-title">
              {{ measurement.key }}:
            </p>
            <p v-if="measurement.value" class="trait-value">
              {{ formatServiceMeasurements(measurement) }}
            </p>
          </div>
        </div>
      </div>
      <SoonaButton
        v-if="isFtSoonaStaff"
        class="card-footer-button"
        element="a"
        target="_blank"
        :href="`/user/anytime#/account/${profile.user.account_id}/pro-service-profile`"
        variation="tertiary"
        size="medium"
      >
        view profile
      </SoonaButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.pro-service-profile-card {
  background-color: variables.$white-default;
  padding: 1.5rem;
  display: flex;
  gap: 1.75rem;
  border-radius: 0.625rem;
  color: variables.$black-default;
  width: 100%;

  .profile-card-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    .soona-carousel-component {
      width: 10.9375rem !important;
      height: 10.9375rem !important;
      border-radius: 0.625rem !important;
      &.drop-shadow-container {
        padding: 0.625rem;
        border-radius: 0.625rem;
      }
    }
  }
  .profile-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .profile-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      .name-row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .name {
          font-weight: 900;
          border-right: solid 0.1875rem variables.$friendly-red-50;
          padding-right: 0.5rem;
          margin-right: 0.5rem;
        }
        .locations {
          @include variables_fonts.u-body--all-caps-black;
          font-size: 0.9rem;
        }
      }

      .inactive-icon {
        padding-right: 0.75rem;
      }
    }

    .flags {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      align-items: center;
    }

    .traits {
      display: flex;
      flex-wrap: wrap;

      .trait-column {
        display: flex;
        flex-direction: column;
      }
      .trait-column:first-of-type {
        flex: 0 1 18.75rem;
      }

      .trait {
        flex-wrap: wrap;
        display: flex;
        gap: 0.25rem;
        .trait-title {
          font-weight: 800;
        }
        .trait-link {
          text-decoration: underline;
        }
      }
    }
  }

  .card-footer-button {
    align-self: flex-end;
    margin-top: auto;
  }

  .inactive-profile {
    opacity: 0.5;
  }

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    .profile-content {
      align-items: center;
      justify-content: center;
      .card-footer-button {
        align-self: center;
      }
    }
    .trait-column:first-of-type {
      margin: 0;
    }
  }
}
</style>
