<script setup>
import { computed } from 'vue';

const props = defineProps({
  isFlyoutOpen: {
    default: false,
    type: Boolean,
  },
  position: {
    default: 'left',
    type: String,
    validator: function (value) {
      return ['left', 'right'].includes(value);
    },
  },
  contentWidth: {
    type: String,
    default: '16.375rem',
  },
});

const isFlyoutOpen = computed(() => props.isFlyoutOpen);
const position = computed(() => props.position);

const contentWidth = computed(() => {
  // allow for null and undefined to use the default
  return props.contentWidth ?? '16.375rem';
});
</script>

<template>
  <div
    class="action-bar"
    :class="{
      'action-bar--reverse': position === 'left',
    }"
  >
    <Transition name="flyout-slide" mode="out-in">
      <div
        v-if="$slots['flyout-content'] && isFlyoutOpen"
        class="action-bar__flyout"
        :class="{
          'action-bar__flyout--left': position === 'left',
          'action-bar__flyout--right': position === 'right',
        }"
      >
        <div class="action-bar__flyout-inner">
          <p
            v-if="$slots['flyout-heading']"
            class="u-subheader--heavy action-bar__flyout-heading"
          >
            <slot name="flyout-heading" />
          </p>
          <p
            v-if="$slots['flyout-subheading']"
            class="u-label--regular action-bar__flyout-subheading"
          >
            <slot name="flyout-subheading" />
          </p>
          <div class="action-bar__flyout-content">
            <slot name="flyout-content" />
          </div>
        </div>
      </div>
    </Transition>
    <div
      class="action-bar__options"
      :class="{
        'action-bar__options--left': position === 'left',
        'action-bar__options--right': position === 'right',
      }"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.action-bar {
  display: flex;
  align-items: flex-end;

  &--reverse {
    flex-direction: row-reverse;
  }

  &__options {
    background-color: variables.$white-default;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100%;
    width: 7.5rem;
    border: solid variables.$gray-30;
    border-width: 0 0 0 0.0625rem;
    padding: 0.75rem 0;

    &--left {
      border-width: 0 0.0625rem 0 0;
    }
  }

  &__flyout {
    height: 100%;
    width: v-bind(contentWidth);
    overflow: hidden;
    background-color: variables.$white-default;
    position: relative;
    border: solid variables.$gray-30;
    border-width: 0 0 0 0.0625rem;
    transition: width 0.5s cubic-bezier(0.22, 1, 0.36, 1);

    &-inner {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background-color: variables.$white-default;
      height: 100%;
      overflow-y: auto;
      width: v-bind(contentWidth);
    }

    &-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &--left {
      display: flex;
      justify-content: flex-end;
      background-color: variables.$white-default;
      border-width: 0 0.0625rem 0 0;
    }
  }

  &__flyout-heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__flyout-subheading {
    color: variables.$gray-60;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .flyout-slide-enter-from,
  .flyout-slide-leave-to {
    width: 0;
  }
}
</style>
