<script setup>
import { computed } from 'vue';
import {
  Gray30,
  GreenApple30,
  WhiteDefault,
  GreenApple10,
  FriendlyRed20,
  FriendlyRed50,
} from '@/variables.module.scss';
import dayjs from 'dayjs';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  listing: Object,
  referenceListing: Object,
  stagedAssets: Object,
  isApproved: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['toggle-approval']);

const isReference = computed(() => {
  return props.listing.asin === props.referenceListing.asin;
});

const isOutlier = computed(() => {
  const listingAssetIds = props.listing.assets.map(
    asset => asset.digital_asset_id
  );
  const referenceAssetIds = props.referenceListing.assets.map(
    asset => asset.digital_asset_id
  );

  if (listingAssetIds.length !== referenceAssetIds.length) return true;
  for (let i = 0; i < listingAssetIds.length; i++) {
    if (listingAssetIds[i] !== referenceAssetIds[i]) return true;
  }
  return false;
});

const borderColor = computed(() =>
  isReference.value ? GreenApple30 : isOutlier.value ? FriendlyRed50 : Gray30
);

const backgroundColor = computed(() =>
  isReference.value
    ? GreenApple10
    : isOutlier.value
      ? FriendlyRed20
      : WhiteDefault
);

const listingUrl = computed(
  () => `https://www.amazon.com/dp/${props.listing?.asin}`
);

const imageIsOutlier = (image, index) => {
  const referenceImageAtIndex = props.referenceListing.assets[index];
  return image.digital_asset_id !== referenceImageAtIndex?.digital_asset_id;
};
</script>

<template>
  <div class="staging-card">
    <div class="staging-card__header">
      <div class="staging-card__info">
        <div class="staging-card__title--wrapper">
          <div>
            <h2 class="u-subheader--heavy">{{ listing.name }}</h2>
            <a :href="listingUrl" class="staging-card__link" target="_blank">
              view listing
              <div>
                <SoonaIcon name="arrow-up-right-from-square" size="small" />
              </div>
            </a>
          </div>
          <div class="staging-card__approval">
            <input
              type="checkbox"
              :checked="isApproved"
              @click="$emit('toggle-approval', listing.asin)"
            />
          </div>
        </div>
        <dl>
          <dt class="u-label--heavy">refreshed at:</dt>
          <dd>{{ dayjs(listing.updated_at) }}</dd>
          <dt class="u-label--heavy">ASIN:</dt>
          <dd>{{ listing.asin }}</dd>
          <dt class="u-label--heavy">SKU:</dt>
          <dd>{{ listing.sku }}</dd>
        </dl>
      </div>
    </div>
    <div class="staging-card__current">
      <p>current:</p>
      <div class="staging-card__images">
        <div v-for="(image, i) in listing.assets" :key="image.digital_asset_id">
          <img
            :src="image.url"
            class="staging-card__image"
            :class="{
              'staging-card__image--outlier': imageIsOutlier(image, i),
            }"
          />
        </div>
      </div>
    </div>
    <hr />
    <div class="staging-card__staged">
      <p>staged:</p>
      <div class="staging-card__images">
        <div v-for="image in stagedAssets" :key="image.id">
          <img :src="image.url" class="staging-card__image" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.staging-card {
  border: 0.0625rem solid v-bind(borderColor);
  background-color: v-bind(backgroundColor);
  border-radius: 0.625rem;
  overflow: hidden;
  min-height: 4.5rem;
  width: 100%;
  padding: 1rem;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.5rem;
  }

  &__title--wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__approval {
    input {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__info {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &__current,
  &__staged {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;
  }

  &__images {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__image {
    max-width: 5rem;
    max-height: 5rem;
    object-fit: cover;
    border-radius: 0.3125rem;

    &--outlier {
      border: 0.125rem solid variables.$friendly-red-60;
    }
  }
}
</style>
