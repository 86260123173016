<script setup>
import { toRefs } from 'vue';
import { socialMediaSizeImages } from './socialMediaSizeImages';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
  socialMediaItems: {
    type: Array,
    required: true,
  },
  socialMediaName: {
    type: String,
    required: false,
    default: '',
  },
  selectedSize: {
    type: String,
    required: false,
    default: '',
  },
  minimalPaddingOnly: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close-panel', 'resize-selection']);

const {
  isLoading,
  isMobileView,
  socialMediaItems,
  socialMediaName,
  selectedSize,
} = toRefs(props);

const displaySocialMediaSize = ({ width, height }) => {
  if (!width) return `${height} on shortest size`;
  if (!height) return `${width} on shortest size`;

  return `${width} × ${height}`;
};

const handleSocialMediaSizeClick = ({ name, size }) => {
  emits('resize-selection', {
    name,
    ...size,
  });
};
</script>

<template>
  <div
    id="test"
    class="social-media-sizes active"
    :class="{ 'social-media-sizes--desktop': !isMobileView }"
  >
    <SoonaSkeleton v-if="isLoading" />
    <template v-else>
      <div
        class="panel-header"
        :class="{ 'panel-header--minimal-padding-only': minimalPaddingOnly }"
      >
        <SoonaButton
          variation="icon-gray-outline"
          size="medium"
          @click="emits('close-panel')"
        >
          <SoonaIcon name="arrow-left" />
        </SoonaButton>
        <h3 class="u-subheader--heavy">
          {{ socialMediaName }}
        </h3>
      </div>
      <div
        class="social-media-sizes__panel"
        :class="{
          'social-media-sizes__panel--minimal-padding-only': minimalPaddingOnly,
        }"
      >
        <component
          :is="socialMediaSize.sizes ? 'div' : 'button'"
          v-for="socialMediaSize in socialMediaItems"
          :key="socialMediaSize.name"
          :class="[
            socialMediaSize.sizes
              ? 'social-media-sizes__group'
              : 'u-button-reset social-media-sizes__item',
            selectedSize === socialMediaSize.name ? 'active' : null,
          ]"
          @click="
            socialMediaSize.sizes
              ? null
              : handleSocialMediaSizeClick(socialMediaSize)
          "
        >
          <template v-if="socialMediaSize.sizes">
            <h4 class="u-label--regular">{{ socialMediaSize.name }}</h4>
            <button
              v-for="groupItem in socialMediaSize.sizes"
              :key="groupItem.name"
              class="u-button-reset social-media-sizes__item"
              :class="selectedSize === groupItem.name ? 'active' : null"
              @click="handleSocialMediaSizeClick(groupItem)"
            >
              <img
                class="social-media-sizes__img"
                :src="
                  socialMediaSizeImages(
                    socialMediaSize.social_media,
                    groupItem.thumb
                  )
                "
                alt=""
              />
              <span class="social-media-sizes__info">
                <span class="u-label--heavy social-media-sizes__title">{{
                  groupItem.name
                }}</span>
                {{ displaySocialMediaSize(groupItem.size) }}
              </span>
            </button>
          </template>
          <template v-else>
            <img
              :src="
                socialMediaSizeImages(
                  socialMediaSize.social_media,
                  socialMediaSize.thumb
                )
              "
              alt=""
              class="social-media-sizes__img"
            />
            <span class="social-media-sizes__info">
              <span class="u-label--heavy social-media-sizes__title">{{
                socialMediaSize.name
              }}</span>
              {{ displaySocialMediaSize(socialMediaSize.size) }}
            </span>
          </template>
        </component>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use 'src/variables';

.social-media-sizes {
  background:
    linear-gradient(rgba(255, 255, 255, 0), variables.$white-default 70%) center
      bottom,
    linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.18)) center bottom;
  background-repeat: no-repeat;
  background-size:
    100% 2.5rem,
    100% 0.75rem;
  background-attachment: local, scroll;
  background-color: variables.$white-default;
  padding-bottom: 1rem;
  top: 0;
  bottom: 3.75rem;
  left: 100%;
  width: 100%;
  transition: left 0.4s;
  overflow: auto;

  &--desktop {
    position: absolute;
  }

  &.active {
    left: 0;
  }

  &__panel {
    margin-top: 0.25rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &--minimal-padding-only {
      padding: 0;
    }
  }

  .panel-header {
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    background: variables.$white-default;

    &--minimal-padding-only {
      padding: 0;
      padding-bottom: 1rem;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    + .social-media-sizes__group {
      margin-top: 0.75rem;
    }

    h4 {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    padding: 0 0.25rem 0 0;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.625rem;
    white-space: normal;

    .social-media-sizes__info {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 0.75rem;
      color: variables.$gray-60;
    }

    .social-media-sizes__title {
      color: variables.$black-default;
    }

    &.active {
      outline-offset: -0.125rem;
      outline: 0.125rem solid variables.$periwink-blue-70;
      background: variables.$gray-10;
      border-radius: 0.625rem;
    }

    &:hover {
      background: variables.$gray-10;
    }
  }

  &__img {
    min-height: 3rem;
    width: 4.5rem;
    flex: 0 0 4.5rem;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$gray-10;
    border-radius: 0.625rem;
  }
}
</style>
