<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
const emits = defineEmits(['setAction']);
const tool = defineModel('tool', {
  type: Object,
  required: true,
});
</script>

<template>
  <button class="batch-edit-option" @click="emits('setAction', tool.slug)">
    <span class="batch-edit-option__left">
      <label class="batch-edit-option__tool">
        <span class="batch-edit-option__tool-icon">
          <SoonaIcon :name="tool.icon" size="x-large" />
        </span>
        {{ tool.caption }}
      </label>
    </span>
    <span class="batch-edit-option__center">
      <span class="batch-edit-option__title u-body--heavy">{{
        tool.title
      }}</span>
      <span class="batch-edit-option__description u-label--regular">
        {{ tool.description }}
      </span>
    </span>
    <span class="batch-edit-option__right">
      <SoonaIcon
        size="large"
        name="arrow-right"
        class="batch-edit-option__right-icon"
      />
    </span>
  </button>
</template>
<style scoped lang="scss">
@use 'src/variables';
.batch-edit-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
  background-color: variables.$white-default;
  padding: 0.5rem;
  gap: 1rem;
  border-radius: 0.625rem;
  width: 100%;
  &:hover {
    background-color: variables.$gray-10;
  }
  &__left {
    padding: 1.5rem;
    background-color: variables.$bubbletape-pink-20;
    border-radius: 0.625rem;
  }
  &__title {
    font-weight: bold;
  }
  &__description {
    color: variables.$gray-70;
  }
  &__right-icon {
    color: black;
  }
  &__center {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    gap: 0.25rem;
  }
  &__right {
    margin-left: auto;
  }
  &__tool-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
