import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';

export function useToggleVipStatus(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/toggle_vip_status`
      );

      return response.data;
    },
    onSuccess: async result => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(result.id),
      });
    },
  });
}
