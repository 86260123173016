import { computed, inject, provide, readonly, ref, toValue, watch } from 'vue';

const DIGITAL_ASSET_ANNOTATE_ROOT_KEY = Symbol('DigitalAssetAnnotateRoot');

/**
 * @typedef DigitalAssetAnnotateState
 * @type {object}
 * @property {import('vue').DeepReadonly<number | null>} activeNoteId
 * @property {(digitalAssetId: number) => void} setActiveNoteId
 * @property {() => void} resetActiveNoteId
 */

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} digitalAssetId
 */
export function useDigitalAssetAnnotateProvider(digitalAssetId) {
  const activeNoteId = ref(null);

  function setActiveNoteId(newActiveNoteId) {
    activeNoteId.value =
      newActiveNoteId === activeNoteId.value ? null : newActiveNoteId;
  }

  function resetActiveNoteId() {
    activeNoteId.value = null;
  }

  watch(
    () => toValue(digitalAssetId),
    () => {
      resetActiveNoteId();
    }
  );

  provide(DIGITAL_ASSET_ANNOTATE_ROOT_KEY, {
    activeNoteId: readonly(activeNoteId),
    setActiveNoteId,
    resetActiveNoteId,
  });
}

/**
 *
 * @returns {DigitalAssetAnnotateState}
 */
export function useDigitalAssetAnnotate() {
  const state = inject(DIGITAL_ASSET_ANNOTATE_ROOT_KEY, null);

  return {
    ...state,
    supportsAnnotate: computed(() => !!state),
  };
}

export function fullSizeToScaledCoords({ x, y, fullSizeWidth, scaledWidth }) {
  const ratio = toValue(fullSizeWidth) / toValue(scaledWidth);

  return {
    x: Math.round(toValue(x) / ratio),
    y: Math.round(toValue(y) / ratio),
  };
}

export function scaledToFullSizeCoords({ x, y, fullSizeWidth, scaledWidth }) {
  const ratio = toValue(scaledWidth) / toValue(fullSizeWidth);

  return {
    x: Math.round(toValue(x) / ratio),
    y: Math.round(toValue(y) / ratio),
  };
}
