import { useFlag } from '@/composables/useFlag';
const BATCH_RESIZE_ACTION = Symbol('batch-resize-action');
const BATCH_REMOVE_BACKGROUND_ACTION = Symbol('batch-remove-background-action');

export function useBatchEditTools() {
  const bulkResizeFlag = useFlag('pegasus_bulk_edit');
  const bulkRemoveBackgroundFlag = useFlag('pegasus_bulk_remove_background');

  const batch_edit_tools = [
    {
      icon: 'resize',
      title: 'crop / resize',
      slug: 'resize',
      description: 'change the scale and/or cropping of your images',
      symbol: BATCH_RESIZE_ACTION,
      active: bulkResizeFlag.value,
    },
    {
      icon: 'scissors',
      title: 'remove background',
      slug: 'remove_background',
      description: 'create a transparent background PNG file after you save',
      symbol: BATCH_REMOVE_BACKGROUND_ACTION,
      active: bulkRemoveBackgroundFlag.value,
    },
  ];

  return {
    BATCH_RESIZE_ACTION,
    BATCH_REMOVE_BACKGROUND_ACTION,
    batch_edit_tools,
  };
}
