<script setup>
import { computed, ref } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useCancelSubscriptionItem } from '@/queries/subscriptions/useCancelSubscriptionItem';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  subscriptionId: {
    type: Number,
    required: true,
  },
  subscriptionItemPriceId: {
    type: Number,
    required: false,
  },
  subscriptionItemId: {
    type: Number,
    required: false,
  },
  subscriptionItemType: {
    type: String,
    required: true,
  },
  subscriptionCurrentPeriodEnd: {
    type: [String, null],
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => props.accountId);
const subscriptionId = computed(() => props.subscriptionId);
const subscriptionItemId = computed(() => props.subscriptionItemId);

const itemType = computed(() => {
  if (props.subscriptionItemType === 'add-on') {
    return 'add-on';
  }
  return 'plan';
});

// hard coding these for now, can follow up to fetch these values from the backend;
const cancellationReasons = [
  {
    label: 'expected something different',
    value: 'expected_something_different',
  },
  {
    label: 'missing something I needed',
    value: 'missing_something_I_needed',
  },
  {
    label: 'no longer need storage',
    value: 'no_longer_need_storage',
  },
  {
    label: 'content needs have changed',
    value: 'content_needs_have_changed',
  },
  {
    label: 'business changes no longer needed',
    value: 'business_changes_no_longer_needed',
  },
  {
    label: 'I was auto enrolled',
    value: 'I_was_auto_enrolled',
  },
  {
    label: 'too expensive',
    value: 'too_expensive',
  },
  {
    label: 'other',
    value: 'other',
  },
];

const {
  mutate: cancelSubscriptionItem,
  error: errorcancelSubscriptionItem,
  isLoading: subscriptionItemDeleting,
} = useCancelSubscriptionItem(accountId);

const cancellationReason = ref(undefined);
const cancellationDetails = ref(undefined);

function cancel(close) {
  let body = {
    cancellation_details: cancellationDetails.value,
    cancellation_reason: cancellationReason.value,
    remove_item_id: subscriptionItemId.value,
    subscription_id: subscriptionId.value,
  };
  cancelSubscriptionItem(body, {
    onSuccess: response => {
      if (
        response?.result?.body?.data?.appSubscriptionCreate?.confirmationUrl
      ) {
        window.location.href =
          response.result.body.data.appSubscriptionCreate.confirmationUrl;
      } else {
        close();
      }
    },
  });
}

const priorityError = usePriorityError(errorcancelSubscriptionItem);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading
      >are you sure you want to cancel your {{ itemType }}?</template
    >
    <template #default>
      <div class="cancel-subscription-dialog">
        <p class="cancel-subscription-dialog__text">
          if you cancel now your {{ itemType }} will remain active and you can
          continue using
          {{ itemType === 'plan' ? 'premium features' : 'your add-on' }} until
          <strong>{{ subscriptionCurrentPeriodEnd }}</strong>
        </p>
        <SoonaSelect
          v-model="cancellationReason"
          class="cancel-subscription-dialog__reasons"
          :options="cancellationReasons"
          :disabled="subscriptionItemDeleting"
        >
          <template #label>reason for cancellation (required)</template>
        </SoonaSelect>
        <SoonaTextfield
          v-if="cancellationReason === 'other'"
          v-model="cancellationDetails"
          label="more details (optional)"
          name="cancellation-notes"
          placeholder="optional"
          element="textarea"
        />
      </div>
      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close"
        >nevermind! stay subscribed</SoonaButton
      >
      <SoonaButton
        variation="primary"
        :loading="subscriptionUpdating"
        :disabled="!cancellationReason"
        @on-click="cancel(close)"
      >
        cancel plan
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.cancel-subscription-dialog {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
