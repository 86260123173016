<script setup>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useExemptSalesTax } from '@/queries/sales_tax/useExemptSalesTax';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['close']);

const accountId = computed(() => props.accountId);

const { mutate, isPending, error } = useExemptSalesTax(accountId);

const exempt = () => {
  mutate(null, {
    onSuccess: () => {
      emits('close');
    },
  });
};

const priorityError = usePriorityError(error);
</script>
<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>exempt from sales tax</template>
    <p>
      Marking the client tax exempt will not add sales tax to orders. Are you
      sure you want to mark them tax exempt?
    </p>
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <template #footer="{ close }">
      <SoonaButton
        variation="tertiary"
        data-cypress="button-dialog-cancel"
        @on-click="close"
      >
        Cancel
      </SoonaButton>
      <SoonaButton
        :loading="isPending"
        data-cypress="button-dialog-confirm"
        @on-click="exempt"
      >
        Exempt
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
p,
:deep(.soona-dialog__title),
:slotted(h2),
:slotted(.soona-dialog__title),
:deep(span) {
  text-transform: lowercase;
}
</style>
