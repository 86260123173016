import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

export function useCreateProServiceFeedback() {
  return useMutation({
    mutationFn: async body => {
      const response = await http.post('/pro_service_feedbacks', {
        pro_service_feedback: body,
      });

      return response.data;
    },
  });
}
