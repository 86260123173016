export const retainerPaths = [
  {
    path: '/account/:accountId/retainer/add',
    component: () =>
      import(
        '@/components/user/anytime/retainers/transactions/crew/add/AddFundsPage.vue'
      ),
    meta: {
      context: 'add retainer funds',
      requires_auth: true,
      requires_capability: 'fund_retainer',
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
];
