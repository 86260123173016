<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useCapability } from 'src/composables/useCapability';
import { useAccount } from '@/composables/useAccount';
import isObject from 'lodash/isObject';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import PaymentMethodsCard from '@/components/PaymentMethodsCard.vue';
import ShopifyIntegrationPrimarySelect from './user/anytime/products/shopify/ShopifyIntegrationPrimarySelect.vue';
import { track, userAndAccountTraits } from '@/lib/segment/segment';
import { storeToRefs } from 'pinia';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  disableConfirmButton: {
    type: Boolean,
    default: false,
  },
  paymentMethodTypes: {
    type: Array,
    default: () => ['card'],
    required: true,
  },
  forceForm: {
    type: Boolean,
    default: false,
  },
  total: {
    type: [String, Number],
    required: true,
  },
  actionText: {
    default: 'Submit Payment',
    type: String,
    required: false,
  },
  buttonVariation: {
    default: 'solid-black',
    type: String,
    required: false,
  },
  isStripeDisclaimerBelowCheckoutButton: {
    default: false,
    type: Boolean,
  },
  onPaymentAction: {
    type: Function,
    required: true,
  },
  stripePaymentRequired: {
    type: Boolean,
    required: true,
  },
  hasWidePaymentButton: {
    type: Boolean,
    default: false,
  },
  showUsingCardOnFile: {
    // TODO: this is a temp solution for 2024-01-25 launch
    type: Boolean,
    default: false,
  },
  saveableCard: {
    type: Boolean,
    default: true,
  },
  isSubscriptionCharge: {
    type: Boolean,
    default: false,
  },
  collapsed: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const store = useStore();
const route = useRoute();
const accountId = computed(() => props.accountId);
const forceForm = computed(() => props.forceForm);
const paymentErrors = ref(undefined);
const { account, billingAddressId } = useAccount(accountId);
const { hasShopifyIntegration } = useIntegrations(accountId);
const { hasCapability: canManageDefaultPayment } = useCapability({
  capability: 'manage_default_payment',
  subjectType: 'account',
  subjectId: accountId,
});

const stripePaymentRequired = computed(() => props.stripePaymentRequired);
const stripe = ref(null);
const card = ref(null);
const card_element = ref();
const stripeErrorText = ref(undefined);
const loading = ref(false);
const showCardForm = ref(false);
const savePaymentMethod = ref(true);
const hasPaymentMethods = ref(false);
const paymentMethod = ref(undefined);
const selectedPaymentMethod = ref(undefined);
const paymentMethodType = ref(undefined);
const usingSavedPaymentMethod = ref(false);
const shopifyPaymentStoreDomain = ref(null);
const showSaveToggle = ref(true);
const showBankDisclaimer = computed(() => {
  return paymentMethodType.value === 'us_bank_account';
});
const paymentMethods = ref([]);
const defaultPaymentMethod = ref(undefined);

const total = computed(() => parseFloat(props.total));
const showUsingCardOnFile = computed(() => props.showUsingCardOnFile);
const paymentMethodTypes = computed(() => props.paymentMethodTypes);
const requireSavedPayment = computed(() => route.name === 'fast-pass');
const isSubscriptionCharge = computed(() => props.isSubscriptionCharge);
const buttonColor = computed(() => props.buttonVariation);
// convert into vue query once events are settled or try to use account data?
const currentUser = computed(() => store.state.currentUser);
const isSubscriber = computed(() => {
  if (requireSavedPayment.value) {
    return true;
  } else {
    return account.value?.member;
  }
});

const salesTaxStore = useSalesTaxStore();
const { isCalculatingSalesTax } = storeToRefs(salesTaxStore);

const resetErrors = () => {
  store.dispatch('order/resetErrors');
};

const options = computed(() => ({
  mode: 'payment',
  amount: parseInt(total.value * 100),
  currency: 'usd',
  paymentMethodCreation: 'manual',
  paymentMethodTypes: paymentMethodTypes.value,
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
      family: 'Lato',
      style: 'normal',
    },
  ],
  appearance: {
    rules: {
      '.Label': {
        textTransform: 'lowercase',
        fontWeight: '800',
        fontSize: '0.875rem',
        color: '#000000',
        paddingBottom: '0.275rem',
      },
      '.TabLabel': {
        textTransform: 'lowercase',
      },
    },
    variables: {
      backgroundColor: '#FFFFF',
      colorPrimary: '#5566EA',
      fontFamily: 'Lato',
    },
  },
}));

const handleChange = event => {
  paymentMethodType.value = event.value.type;

  if (
    paymentMethodType.value !== 'us_bank_account' &&
    paymentMethodType.value !== 'card'
  ) {
    showSaveToggle.value = false;
    savePaymentMethod.value = false;
  } else {
    savePaymentMethod.value = true;
  }
};

const getDefaultCardValues = () => {
  const account_billing_address = account.value?.billing_address;
  return {
    defaultValues: {
      billingDetails: {
        name: account.value?.name,
        email: account.value?.email,
        address: {
          postal_code: account_billing_address?.postal_code,
          city: account_billing_address?.city,
          country: account_billing_address?.country_code,
          line1: account_billing_address?.address1,
          line2: account_billing_address?.address2,
          state: account_billing_address?.province,
        },
      },
    },
    paymentMethodOrder: ['card', 'us_bank_account', 'apple_pay', 'google_pay'],
    wallets: {
      applePay: forceForm.value ? 'never' : 'auto',
      googlePay: forceForm.value ? 'never' : 'auto',
    },
  };
};

const mountStripeElement = () => {
  if (window.Stripe) {
    if (!stripe.value || !card_element.value || !card.value) {
      // Check if stripe has been loaded in component
      stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
      card_element.value = stripe.value.elements(options.value);
      const defaultCardValues = getDefaultCardValues();
      card.value = card_element.value.create('payment', defaultCardValues);
      card.value.mount('#payment-element');
      card.value.addEventListener('change', handleChange);
    } else {
      // NOTE: Is billing information still needed for the stripe card element?
      // If stripe has already been loaded, check for billing changes
      const defaultCardValues = getDefaultCardValues();
      card.value.update(defaultCardValues);
    }
  } else {
    console.error(
      'Stripe does not exist on the window. Could not initialize Stripe or elements.'
    );
  }
};

const setupPaymentForm = () => {
  if (isSubscriptionCharge.value && stripePaymentRequired.value) {
    if (showUsingCardOnFile.value) {
      showCardForm.value = false;
    } else {
      showCardForm.value = true;
      mountStripeElement();
    }
  } else if (
    !hasPaymentMethods.value &&
    stripePaymentRequired.value &&
    !showUsingCardOnFile.value
  ) {
    showCardForm.value = true;
    savePaymentMethod.value = true;
    mountStripeElement();
  }
};

watch(
  () => stripePaymentRequired.value,
  async () => {
    //stripe needs the dom to be rendered before mounting to it, next tick makes sure we wait until after render to mount stripe
    await nextTick(setupPaymentForm);
  }
);

watch(savePaymentMethod, value => {
  if (requireSavedPayment.value) {
    savePaymentMethod.value = true;
  }
  track('Stripe - Save Default Payment Changed', {
    save_default_card: value,
    ...userAndAccountTraits(currentUser.value),
  });
});

const toggleCardForm = () => {
  mountStripeElement();
  usingSavedPaymentMethod.value = false;
  showCardForm.value = true;
  track(
    'Stripe - Soona Payment Change Clicked',
    userAndAccountTraits(currentUser.value)
  );
  stripeErrorText.value = undefined;
  resetErrors();
};

const toggleSavedMethods = () => {
  usingSavedPaymentMethod.value = true;
  showCardForm.value = false;
  stripeErrorText.value = undefined;
  selectedPaymentMethod.value = defaultPaymentMethod.value;
  resetErrors();
};

// shopify payment store
const setShopifyPaymentStoreDomain = storeDomain => {
  shopifyPaymentStoreDomain.value = storeDomain;
};

const paymentAction = async () => {
  paymentErrors.value = undefined;
  loading.value = true;

  try {
    if (!stripePaymentRequired.value) {
      paymentMethod.value = null;
      paymentMethodType.value = null;
      savePaymentMethod.value = null;
    } else if (usingSavedPaymentMethod.value) {
      paymentMethod.value =
        selectedPaymentMethod.value.external_payment_method_id;
      paymentMethodType.value = selectedPaymentMethod.value.type;
      savePaymentMethod.value = false;
    } else if (showUsingCardOnFile.value) {
      paymentMethod.value = null;
      paymentMethodType.value = null;
      savePaymentMethod.value = null;
    } else {
      if (isObject(card.value)) {
        card.value.update({ disabled: true });
      }

      await card_element.value.submit();
      const createdPaymentMethod = await stripe.value.createPaymentMethod({
        elements: card_element.value,
      });

      paymentMethod.value = createdPaymentMethod.paymentMethod.id;
    }
    await props.onPaymentAction(
      paymentMethod.value,
      paymentMethodType.value,
      savePaymentMethod.value,
      shopifyPaymentStoreDomain.value
    );
  } catch (error) {
    paymentErrors.value = error;
    console.error(error);
  } finally {
    loading.value = false;

    if (isObject(card.value)) {
      card.value.update({ disabled: false });
    }
  }
};

onMounted(() => {
  const loadedCallback = () => {
    if (forceForm.value) {
      showCardForm.value = true;
      showSaveToggle.value = false;
      savePaymentMethod.value = true;
      usingSavedPaymentMethod.value = false;
      setupPaymentForm();
    } else if (account.value?.payment_methods?.length > 0) {
      paymentMethods.value = account.value?.payment_methods;
      hasPaymentMethods.value = true;
      usingSavedPaymentMethod.value = true;
      defaultPaymentMethod.value = account.value.payment_methods.find(
        payment_method => payment_method.default === true
      );
      selectedPaymentMethod.value = defaultPaymentMethod.value;
    } else {
      showCardForm.value = true;
      setupPaymentForm();
    }
  };

  watch([account], loadedCallback);

  if (account.value) {
    loadedCallback();
  }
});

const setSelectedPaymentMethod = paymentMethod => {
  selectedPaymentMethod.value = paymentMethod;
  paymentMethodType.value = paymentMethod.payment_method_type;
};
</script>

<template>
  <div class="soona-payment">
    <div
      v-if="stripePaymentRequired && showUsingCardOnFile"
      class="u-label--heavy soona-payment__container"
    >
      <!-- TODO: this is a temp solution for 2024-01-25 launch -->
      payment method on file will be charged
      <small class="u-label--small soona-payment__edit-card">
        need to edit this credit card? go to your
        <a :href="`/#/account/${account?.id}/orders`">billing history</a> page.
      </small>
    </div>
    <div v-else-if="stripePaymentRequired" class="soona-payment__container">
      <SoonaButton
        v-if="showCardForm && hasPaymentMethods"
        class="soona-payment__back-to-saved"
        variation="tertiary"
        element="button"
        @click="toggleSavedMethods()"
      >
        <SoonaIcon name="arrow-left" />
        saved payment methods
      </SoonaButton>
      <div v-show="showCardForm && canManageDefaultPayment">
        <div id="payment-element"></div>
      </div>
      <template v-if="hasPaymentMethods && !showCardForm">
        <PaymentMethodsCard
          :payment-options="paymentMethods"
          :payment-method-types="paymentMethodTypes"
          @add-payment-method="toggleCardForm()"
          @selected-payment-method="setSelectedPaymentMethod($event)"
        />
      </template>
      <template
        v-if="
          (showCardForm && canManageDefaultPayment) ||
          (hasPaymentMethods && !showCardForm)
        "
      >
        <SoonaError v-if="stripeErrorText">
          {{ stripeErrorText }}
        </SoonaError>
        <div
          v-if="!isStripeDisclaimerBelowCheckoutButton"
          class="soona-payment__secure"
        >
          <SoonaIcon name="lock-alt" size="small" />
          secure payment processed by Stripe
        </div>
      </template>
      <template
        v-if="
          saveableCard &&
          showCardForm &&
          canManageDefaultPayment &&
          !hasShopifyIntegration
        "
      >
        <div class="field-toggle">
          <SoonaToggle
            v-if="!isSubscriber && showSaveToggle"
            v-model="savePaymentMethod"
            type="switch"
            label="save my payment method for faster check out"
          />
        </div>
      </template>
      <SoonaAlert v-if="!canManageDefaultPayment && !hasPaymentMethods">
        contact your account admin to setup a payment method.
      </SoonaAlert>
    </div>
    <div v-else class="soona-payment__shopify-billing">
      <ShopifyIntegrationPrimarySelect
        v-if="account?.id"
        :account-id="account.id"
        label="billed Shopify shop"
        :is-subscription-charge="isSubscriptionCharge"
        @set-shopify-payment-store-domain="setShopifyPaymentStoreDomain"
      />
    </div>
    <slot name="action-block" />
    <slot name="promo-code-button" />
    <SoonaButton
      type="button"
      data-cypress="payment-submit"
      :variation="buttonColor"
      :loading="loading"
      :disabled="
        (showCardForm && stripeErrorText !== undefined) ||
        (!canManageDefaultPayment && !hasPaymentMethods) ||
        ((!billingAddressId || disableConfirmButton || isCalculatingSalesTax) &&
          !forceForm)
      "
      class="soona-payment__submit"
      :class="{
        'soona-payment__submit--wide': hasWidePaymentButton,
      }"
      @on-click="paymentAction"
    >
      {{ actionText }}
    </SoonaButton>
    <slot name="buttons" />
    <div
      v-if="isStripeDisclaimerBelowCheckoutButton"
      class="soona-payment__secure"
    >
      <SoonaIcon name="lock-alt" size="small" />
      secure payment processed by Stripe
    </div>
    <p
      v-show="showBankDisclaimer && isSubscriptionCharge"
      class="u-small--regular"
    >
      if you use soona's services or purchase additional products periodically
      pursuant to soona’s terms, you authorize soona to debit your bank account
      periodically. payments that fall outside of the regular debits authorized
      above will only be debited after your authorization is obtained.
    </p>
    <p v-show="showBankDisclaimer" class="u-small--regular">
      by clicking confirm & pay, you authorize soona to debit the bank account
      specified above for any amount owed for charges arising from your use of
      soona’s services and/or purchase of products from soona, pursuant to
      soona’s website and terms, until this authorization is revoked. you may
      amend or cancel this authorization at any time by providing notice to
      soona with 30 (thirty) days notice. it can take up to 3 days for payments
      to process.
    </p>
    <slot name="footer-disclaimer" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.field-toggle {
  padding-top: 1rem;
}

.soona-payment {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex-grow: 1;

  &__container {
    flex-grow: 1;
  }

  &__back-to-saved {
    display: block;
    margin-bottom: 1rem;
  }

  &__edit-card {
    display: block;
    margin-top: 0.5rem;

    > a {
      text-decoration: underline;
      color: variables.$black-default;

      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
  }

  &__card {
    border: 0.0625rem solid variables.$gray-30;
    padding: 0.5rem;
    background-color: variables.$white-default;
    border-radius: 0.3125rem;
    transition: border-color 0.1s ease-out;

    &:hover {
      border-color: variables.$gray-50;
    }
  }

  &__shopify-billing {
    display: flex;
  }

  &__shopify-integration-select {
    width: max-content;
  }

  &__secure {
    @include variables_fonts.u-label--regular;

    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: variables.$black-default;
    margin: 1rem 0 0;

    > svg {
      display: block;
      flex: 0 0 1rem;
    }
  }

  &__submit {
    display: flex;
    width: 100%;
  }

  &__default-payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.25rem 0;
  }

  &__default-card {
    display: block;
    width: 1.6875rem;
    height: 1.5rem;
    margin: 0 0.75rem 0 0.25rem;
    flex: 0 0 1.6875rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__submit {
      margin: 0 auto;
      width: auto;
    }

    &__submit--wide {
      width: 100%;
    }
  }
}
</style>
