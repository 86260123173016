<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { computed, toRefs } from 'vue';
import { useCancelSubscriptionItem } from '@/queries/subscriptions/useCancelSubscriptionItem';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useAccount } from '@/queries/account/useAccount';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
});

defineEmits(['close']);
const { accountId } = toRefs(props);

const { data: account, error: accountError } = useAccount(accountId);

const activeSubscriptionId = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.id;
});

const activeFastPassSubscriptionItem = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'add_on'
  );
});

const {
  mutate: cancelSubscriptionItem,
  error: cancelFastPassError,
  isLoading: fastPastRemoving,
} = useCancelSubscriptionItem(accountId);

const cancelFastPassItem = () => {
  let body = {
    cancellation_details: 'staff removed fast pass',
    cancellation_reason: 'no_longer_need_storage',
    remove_item_id: activeFastPassSubscriptionItem.value?.id,
    subscription_id: activeSubscriptionId.value,
    is_internal: true,
  };
  cancelSubscriptionItem(body, {
    onSuccess: () => {
      window.location.reload();
    },
  });
};

const priorityErrors = usePriorityErrors(cancelFastPassError, accountError);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>remove fast pass</template>
    <p>set fast pass to expire at the end of the current billing cycle</p>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton :loading="fastPastRemoving" @on-click="cancelFastPassItem">
        remove fast pass
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
