<script setup>
import { ref, computed, watchEffect, toRefs } from 'vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import { useDiscounts } from '@/queries/discounts/useDiscounts';
import { usePrices } from '@/queries/prices/usePrices';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  initialData: {
    required: true,
    type: [Object],
    default: () => ({}),
  },
});

const emit = defineEmits(['update']);

const { initialData } = toRefs(props);

const discount = ref(initialData.value?.discount);
const gifUnit = ref(initialData.value?.gifUnit);
const photoUnit = ref(initialData.value?.photoUnit);
const ugcUnit = ref(initialData.value?.ugcUnit);
const videoUnit = ref(initialData.value?.videoUnit);

const selectedDiscountId = ref(null);

const { data: discountsData, isSuccess: isSuccessDiscounts } = useDiscounts({
  accountRequirement: ['active_contract_only'],
  itemsPerPage: 100,
});
const discounts = computed(() => discountsData.value?.discounts);
const discountOptions = computed(
  () =>
    discounts.value?.map(discount => ({
      label: `${parseInt(discount.percent_off)}%`,
      value: discount.id,
    })) ?? []
);

const selectedDiscount = computed(() =>
  discounts.value?.find(d => d.id === selectedDiscountId.value)
);

const { data: contractRateData, isSuccess: isSuccessPrices } = usePrices({
  defaultType: 0,
  priceType: 'one_time',
  productType: 'add_on',
  retainerOriented: true,
});

const photoUnitRates = computed(() => {
  return (
    contractRateData.value?.prices?.filter(
      r => r.price_context_type === 'photo'
    ) ?? []
  );
});
const photoUnitRateOptions = computed(() => {
  return photoUnitRates.value.map(r => ({
    label: toCurrency(r.unit_amount, r.currency),
    value: r.id,
  }));
});

const selectedPhotoUnitId = ref(photoUnit.value?.id);
const selectedPhotoUnit = computed(() =>
  photoUnitRates.value.find(r => r.id === selectedPhotoUnitId.value)
);

const videoUnitRates = computed(() => {
  return (
    contractRateData.value?.prices?.filter(
      r => r.price_context_type === 'video'
    ) ?? []
  );
});
const videoUnitRateOptions = computed(() => {
  return videoUnitRates.value.map(r => ({
    label: toCurrency(r.unit_amount, r.currency),
    value: r.id,
  }));
});

const selectedVideoUnitId = ref(videoUnit.value?.id);
const selectedVideoUnit = computed(() =>
  videoUnitRates.value.find(r => r.id === selectedVideoUnitId.value)
);

const gifUnitRates = computed(() => {
  return (
    contractRateData.value?.prices?.filter(
      r => r.price_context_type === 'gif'
    ) ?? []
  );
});
const gifUnitRateOptions = computed(() => {
  return gifUnitRates.value.map(r => ({
    label: toCurrency(r.unit_amount, r.currency),
    value: r.id,
  }));
});

const selectedGifUnitId = ref(gifUnit.value?.id);
const selectedGifUnit = computed(() =>
  gifUnitRates.value.find(r => r.id === selectedGifUnitId.value)
);

const ugcUnitRates = computed(() => {
  return (
    contractRateData.value?.prices?.filter(
      r => r.price_context_type === 'ugc'
    ) ?? []
  );
});
const ugcUnitRateOptions = computed(() => {
  return ugcUnitRates.value.map(r => ({
    label: toCurrency(r.unit_amount, r.currency),
    value: r.id,
  }));
});

const selectedUgcUnitId = ref(ugcUnit.value?.id);
const selectedUgcUnit = computed(() =>
  ugcUnitRates.value.find(r => r.id === selectedUgcUnitId.value)
);

const handleUpdate = () => {
  emit('update', {
    discount: selectedDiscount.value,
    photoUnit: selectedPhotoUnit.value,
    videoUnit: selectedVideoUnit.value,
    gifUnit: selectedGifUnit.value,
    ugcUnit: selectedUgcUnit.value,
  });
};

const initialUpdate = ref(false);
watchEffect(async () => {
  if (
    isSuccessDiscounts.value &&
    isSuccessPrices.value &&
    !initialUpdate.value
  ) {
    selectedDiscountId.value = discount.value?.id;
    selectedPhotoUnitId.value =
      selectedPhotoUnitId.value ||
      photoUnitRates.value.find(r => !!r.is_same_as_default_price)?.id;
    selectedVideoUnitId.value =
      selectedVideoUnitId.value ||
      videoUnitRates.value.find(r => !!r.is_same_as_default_price)?.id;
    selectedGifUnitId.value =
      selectedGifUnitId.value ||
      gifUnitRates.value.find(r => !!r.is_same_as_default_price)?.id;
    selectedUgcUnitId.value =
      selectedUgcUnitId.value ||
      ugcUnitRates.value.find(r => !!r.is_same_as_default_price)?.id;
    handleUpdate();
    initialUpdate.value = true;
  }
});
</script>

<template>
  <div class="create-discounts-rates">
    <p class="create-discounts-rates__title">Discounts & unit rates</p>
    <SoonaSelect
      v-model="selectedDiscountId"
      class="create-discounts-rates__discount-rate create-discounts-rates__rate-select"
      placeholder="0.0%"
      :options="discountOptions"
      @update:model-value="handleUpdate"
    >
      <template #label> Pro service discount rate</template>
      <template #subtext> models, stylists, producers, etc.</template>
    </SoonaSelect>
    <!-- Rates Row -->
    <div class="create-discounts-rates__rate-row">
      <SoonaSelect
        v-model="selectedPhotoUnitId"
        class="create-discounts-rates__rate-select"
        placeholder="$0.00"
        :options="photoUnitRateOptions"
        @update:model-value="handleUpdate"
      >
        <template #label>Photo unit rate</template>
      </SoonaSelect>
      <SoonaSelect
        v-model="selectedVideoUnitId"
        class="create-discounts-rates__rate-select"
        placeholder="$0.00"
        :options="videoUnitRateOptions"
        @update:model-value="handleUpdate"
      >
        <template #label>Video unit rate</template>
      </SoonaSelect>
      <SoonaSelect
        v-model="selectedGifUnitId"
        class="create-discounts-rates__rate-select"
        placeholder="$0.00"
        :options="gifUnitRateOptions"
        @update:model-value="handleUpdate"
      >
        <template #label>GIF unit rate</template>
      </SoonaSelect>
      <SoonaSelect
        v-model="selectedUgcUnitId"
        class="create-discounts-rates__rate-select"
        placeholder="$0.00"
        :options="ugcUnitRateOptions"
        @update:model-value="handleUpdate"
      >
        <template #label>UGC unit rate</template>
      </SoonaSelect>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.create-discounts-rates {
  display: flex;
  flex-direction: column;

  &__title {
    @include variables_fonts.u-title--heavy;
    text-transform: lowercase;
    margin-bottom: 1.5rem;
  }

  &__overdraft-container {
    p:first-child {
      margin-bottom: 0.5rem;
    }

    p:nth-child(2) {
      margin-bottom: 0.5rem;
    }
  }

  &__discount-rate {
    margin-bottom: 1.5rem;
  }

  &__rate-row {
    display: grid;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
  }

  &__rate-select {
    :deep(.vs__dropdown-toggle) {
      background: variables.$white-default;
    }
  }

  &__overdraft-radio-group {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      padding-bottom: 0.5rem;
    }
  }
}

@media (min-width: 33.75rem) {
  .create-discounts-rates {
    &__rate-row {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
