import { computed, readonly, ref } from 'vue';
import { defineStore } from 'pinia';

import { BATCH_EDIT_STORE_ID } from '@/stores/keys';
import { useMediaQuery } from '@vueuse/core';
import { useStartViewTransition } from '@/composables/useStartViewTransition';
import { useBatchEditsComponents } from '@/components/batch_edits/store/useBatchEditsComponents';

export const useBatchEditStore = defineStore(BATCH_EDIT_STORE_ID, () => {
  const { startViewTransition } = useStartViewTransition();

  const accountId = ref(null);
  const selectedAssets = ref([]);
  const batchEditsAction = ref(null);

  const locationAction = ref('');
  const locationAlbumName = ref('');
  const selectingLocation = ref(false);
  const locationSelectedAlbums = ref([]);

  const isSubmitting = ref(false);

  const isDesktop = useMediaQuery('(min-width: 40rem)');
  const isMobile = computed(() => !isDesktop.value);

  const {
    components,
    registerComponent,
    unregisterComponent,
    unregisterAllComponents,
  } = useBatchEditsComponents();

  const activeBatchAction = computed(() =>
    components.get(batchEditsAction.value)
  );

  function $reset() {
    selectedAssets.value = [];
    locationAction.value = null;
    locationAlbumName.value = '';
    batchEditsAction.value = null;
    selectingLocation.value = false;
    locationSelectedAlbums.value = [];
    unregisterAllComponents();
  }

  const locationGoBack = () => {
    if (locationAction.value) {
      return (locationAction.value = null);
    }

    selectingLocation.value = false;
  };

  const setBatchEditsAction = action => {
    startViewTransition(() => {
      batchEditsAction.value = action;
    });
  };

  const submitBatchAction = () => {
    if (!activeBatchAction.value) {
      // todo ?
      console.log('no action');
      return;
    }
    activeBatchAction.value?.handleSubmit();
  };

  return {
    isMobile,
    isDesktop,
    locationAction,
    isSubmitting: readonly(isSubmitting),
    setIsSubmitting: v => (isSubmitting.value = v),
    selectingLocation: readonly(selectingLocation),
    setSelectingLocation: v => (selectingLocation.value = v),
    locationAlbumName: readonly(locationAlbumName),
    setLocationAlbumName: name => (locationAlbumName.value = name),
    locationSelectedAlbums: readonly(locationSelectedAlbums),
    setLocationSelectedAlbums: ids => (locationSelectedAlbums.value = ids),

    batchEditsAction: readonly(batchEditsAction),
    setBatchEditsAction,

    registerComponent,
    unregisterComponent,

    selectedAssets: readonly(selectedAssets),
    setSelectedAssets: assets => (selectedAssets.value = assets),

    accountId: readonly(accountId),
    setAccountId: id => (accountId.value = id),

    $reset,
    locationGoBack,
    submitBatchAction,
  };
});

export const createBatchEditStore = ({ accountId, selectedAssets }) => {
  const store = useBatchEditStore();
  store.setAccountId(accountId);
  store.setSelectedAssets(selectedAssets);
  return store;
};
