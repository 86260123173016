<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useBatchEditStore } from '../store/useBatchEditStore';

import winkImage from '@images/wink.gif';
import SoonaLoading from '@/components/SoonaLoading.vue';
import BatchLocationLayout from './BatchLocationLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaItemCardSelectable from '@/components/ui_library/SoonaItemCardSelectable.vue';
import { useInfiniteAlbumCollections } from '@/queries/album_collections/useAlbumCollections';

const batchEditStore = useBatchEditStore();
const { accountId, locationSelectedAlbums } = storeToRefs(batchEditStore);

const {
  data: albumCollectionPages,
  fetchNextPage,
  hasNextPage,
  isLoading,
} = useInfiniteAlbumCollections(accountId, {
  itemsPerPage: 5,
});

const albumCollections = computed(() =>
  (albumCollectionPages.value?.pages || []).flatMap(page => page.collections)
);

const handleChange = albumId => {
  if (locationSelectedAlbums.value.includes(albumId)) {
    batchEditStore.setLocationSelectedAlbums(
      locationSelectedAlbums.value.filter(id => id !== albumId)
    );
  } else {
    batchEditStore.setLocationSelectedAlbums([
      ...locationSelectedAlbums.value,
      albumId,
    ]);
  }
};
</script>
<template>
  <BatchLocationLayout class="existing-album">
    <template #heading>add to an existing album</template>

    <div class="existing-album__albums-list">
      <SoonaLoading :is-loading="isLoading" />
      <SoonaItemCardSelectable
        v-for="album in albumCollections"
        :id="album.id"
        :key="album.id"
        :aria-labelledby="`${album.id}-heading`"
        :image-url="album?.preview?.url ?? winkImage"
        :checked="locationSelectedAlbums.includes(album.id)"
        name="albums"
        @change="() => handleChange(album.id)"
      >
        <div class="existing-album__album-option">
          <h3 :id="`${album.id}-heading`" class="u-body--heavy">
            {{ album.title }}
          </h3>
        </div>
      </SoonaItemCardSelectable>

      <div v-if="hasNextPage">
        <SoonaButton
          size="medium"
          variation="secondary-black"
          @click="fetchNextPage"
        >
          load more
        </SoonaButton>
      </div>
    </div>

    <div class="existing-album__footer">
      <SoonaButton @click="batchEditStore.submitBatchAction">
        save to album{{ locationSelectedAlbums.length > 1 ? 's' : '' }}
      </SoonaButton>
    </div>
  </BatchLocationLayout>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.existing-album {
  padding-bottom: 0;

  &__albums-list {
    width: 100%;
    gap: 0.75rem;
    display: flex;
    margin: 0 auto;
    max-width: 42rem;
    align-items: center;
    flex-direction: column;
  }

  &__album-option {
    height: 100%;
    display: flex;
    padding: 0 1rem;
    align-items: center;
  }

  &__footer {
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    text-align: end;
    padding: 1rem 0;
    position: sticky;
    max-width: 42rem;
    background: variables.$white-default;
  }
}
</style>
