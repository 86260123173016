import { computed, toValue } from 'vue';
import { useActiveSubscription as useActiveSubscriptionQuery } from '@/queries/subscriptions/useActiveSubscription';
import { useFlag } from '@/composables/useFlag';
import { useSubscriptionBilling } from '@/composables/subscriptions/useSubscriptionBilling';
import { useSubscriptionSoonaStorage } from '@/composables/subscriptions/useSubscriptionSoonaStorage';
import { useSubscriptionItems } from '@/composables/subscriptions/useSubscriptionItems';

/**
 *
 * @param {Ref<number|string|undefined>} accountId
 */
export function useActiveSubscription(accountId) {
  const {
    data: subscription,
    error,
    isLoading,
    status,
    isSuccess,
  } = useActiveSubscriptionQuery(accountId, {
    enabled: computed(() => !!toValue(accountId)),
  });

  const apolloBusinessTierFlag = useFlag('apollo_enterprise_business_tier');
  const fastPassOnTrialFlag = useFlag('pegasus_fast_pass_on_trial');
  const canUpgradeFlag = useFlag('apollo_subscription_upgrades');

  // payments_todo: hasActiveSubscription and hasLiveSubscription are always true on this endpoint but we'll refactor in a separate PR (pab-607)
  const hasActiveSubscription = computed(() => !!subscription.value);
  const hasLiveSubscription = computed(() => !!subscription.value);
  const isTrialing = computed(
    () =>
      subscription.value?.status === 'trialing' || subscription.value?.is_beta
  );

  const {
    activeAddOnSubscriptionItem,
    pendingCancelAddOnSubscriptionItem,
    subscriptionItems,
    baseSubscriptionItem,
  } = useSubscriptionItems(subscription);

  // fast pass
  const {
    canCancelFastPass,
    canEnrollFastPass,
    hasSoonaStorage,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
  } = useSubscriptionSoonaStorage(subscription);

  // billing
  const {
    isPendingInvoiceSubscription,
    pendingInvoice,
    subscriptionBillingCycle,
    subscriptionChargedViaStripe,
    subscriptionStartDate,
  } = useSubscriptionBilling(subscription);

  // tiers
  const subscriptionTier = computed(() => subscription.value?.tier);

  const subscriptionShortName = computed(
    () => subscriptionTier.value?.short_name
  );

  const subscriptionTierSlug = computed(() => subscriptionTier.value?.slug);

  const hasTierOneSubscription = computed(
    () => subscriptionTierSlug.value === 'tier-one'
  );

  const hasTierTwoSubscription = computed(
    () => subscriptionTierSlug.value === 'tier-two'
  );

  const hasTierThreeSubscription = computed(
    () => subscriptionTierSlug.value === 'tier-three'
  );

  const isBusinessSubscriber = computed(
    () => apolloBusinessTierFlag.value && hasTierThreeSubscription.value
  );

  const hasFastPassTierSubscription = computed(
    () => subscriptionTierSlug.value === 'fast-pass'
  );

  const userCanUpgrade = computed(() => {
    if (!subscription.value || canUpgradeFlag.value) {
      return true;
    }

    return !!(fastPassOnTrialFlag.value && isTrialing.value);
  });

  const isDigitalSubscriber = computed(
    () => subscription.value?.is_digital_subscriber
  );

  const hasPremiumEditsAccess = computed(() => {
    // can be removed when subscription feb launch is solid
    return !!(hasTierOneSubscription.value || hasTierTwoSubscription.value);
  });

  return {
    subscription,
    activeAddOnSubscriptionItem,
    baseSubscriptionItem,
    canCancelFastPass,
    canEnrollFastPass,
    error,
    hasActiveSubscription,
    hasLiveSubscription,
    hasPremiumEditsAccess,
    hasSoonaStorage,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
    hasFastPassTierSubscription,
    isBusinessSubscriber,
    isDigitalSubscriber,
    isLoading,
    isPendingInvoiceSubscription,
    isSuccess,
    pendingCancelAddOnSubscriptionItem,
    pendingInvoice,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
    status,
    subscriptionBillingCycle,
    subscriptionChargedViaStripe,
    subscriptionItems,
    subscriptionShortName,
    subscriptionStartDate,
    subscriptionTier,
    subscriptionTierSlug,
    userCanUpgrade,
  };
}
