<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import AcceptedFlagsAndActions from '@/components/user/anytime/reservation/crew_edits/asset_view/AcceptedFlagsAndActions.vue';
import PendingFlagsAndActions from '@/components/user/anytime/reservation/crew_edits/asset_view/PendingFlagsAndActions.vue';
import RejectedFlagsAndActions from '@/components/user/anytime/reservation/crew_edits/asset_view/RejectedFlagsAndActions.vue';
import UnarchiveButton from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/UnarchiveButton.vue';
import { BubbletapePink20, BubbletapePink80 } from '@/variables.module.scss';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  editsCollectionDigitalAsset: {
    type: Object,
    default: undefined,
  },
});

const accountId = computed(() => props.asset?.account_id);

const { data: editsCollection } = useEditsCollection(accountId, {
  enabled: computed(() => !!accountId.value),
});
</script>
<template>
  <AcceptedFlagsAndActions
    v-if="editsCollectionDigitalAsset?.edit_status === 'accepted'"
    class="header-flag-actions"
    :account-id="asset.account_id"
    :asset-id="asset.id"
    :visibility="asset.visibility"
  />
  <PendingFlagsAndActions
    v-else-if="editsCollectionDigitalAsset?.edit_status === 'pending'"
    class="header-flag-actions"
    :account-id="asset.account_id"
    :asset-id="asset.id"
    :edits-collection-digital-asset-id="editsCollectionDigitalAsset?.id"
    :edits-collection-id="editsCollection?.id"
    :visibility="asset.visibility"
  />
  <RejectedFlagsAndActions
    v-else-if="editsCollectionDigitalAsset?.edit_status === 'rejected'"
    class="header-flag-actions"
    :account-id="asset.account_id"
    :asset-id="asset.id"
    :edits-collection-digital-asset-id="editsCollectionDigitalAsset?.id"
    :edits-collection-id="editsCollection?.id"
    :visibility="asset.visibility"
  />
  <SoonaFlag
    v-else-if="editsCollectionDigitalAsset?.edit_status === 'customer_rejected'"
    :background-color="BubbletapePink20"
    :text-color="BubbletapePink80"
    title="re-edit requested"
  />
  <UnarchiveButton />
</template>
<style lang="scss" scoped>
.header-flag-actions {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
