import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {number} accountId
 */
export async function triggerContentCheckoutStartedEvent(accountId) {
  const response = await http.get(
    `/accounts/${accountId}/content_checkout_started`
  );
  return response.data;
}

export async function getAccount(accountId) {
  const response = await http.get(`/accounts/${accountId}.json`);
  return response.data;
}

/**
 *
 * @param {number} accountId
 * @param {Object} [body]
 */
export async function updateAccount(accountId, body = {}) {
  const response = await http.put(`/accounts/${accountId}`, {
    account: body,
  });

  return response.data;
}

/**
 *
 * @param {Object} [params]
 * @param {string | number} [accountId]
 * @param {string} [params.type]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function getReservations(
  accountId,
  { type, currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (type) params['type'] = type;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(`/accounts/${accountId}/reservations.json`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    reservations: response.data,
  };
}

export async function getRecentlyWrappedReservations(accountId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/recently_wrapped.json`,
    { signal }
  );

  return response.data;
}

export async function getDefaultPayment(accountId, signal) {
  const response = await http.post(
    `/accounts/${accountId}/default_payment`,
    null,
    {
      signal,
    }
  );

  return response.data;
}

export async function getAccountsRoles() {
  const response = await http.get('/accounts/roles');

  return response.data;
}

export async function updateAccountOwner(accountId, body = {}) {
  const response = await http.put(`/accounts/${accountId}/update_owner`, {
    account: body,
  });

  return response.data;
}

export async function getAccountStorageUsage(accountId) {
  const response = await http.get(`/accounts/${accountId}/storage_usage`);
  return response.data;
}

export async function payAddOnOrder(accountId, body = {}) {
  const response = await http.put(`/accounts/${accountId}/pay_orders.json`, {
    payment_method_attributes: body,
  });

  return response.data;
}
