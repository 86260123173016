<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import StagingGround from './StagingGround.vue';
import StagingCard from './StagingCard.vue';
import {
  useInternalPublishing,
  useInternalPublish,
} from '@/queries/useInternalPublishing';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const accountId = ref(import.meta.env.VITE_HEY_DUDE_ACCOUNT_ID); // hey dude's account id
const referenceAsinInput = ref('');
const referenceAsin = ref('');
const approvedChanges = ref([]);

const { data: productFamilyData } = useInternalPublishing(
  accountId,
  referenceAsin
);

const fetchProductFamily = () => {
  referenceAsin.value = referenceAsinInput.value;
  approvedChanges.value = [];
};

const referenceListing = computed(
  () => productFamilyData.value?.reference ?? null
);

const relatedListings = computed(
  () => productFamilyData.value?.related_products ?? []
);

const allListings = computed(() =>
  referenceListing.value
    ? [referenceListing.value, ...relatedListings.value]
    : []
);

const newAssets = ref([]);

const imageId = ref(null);

const { data: digitalAssetData } = useDigitalAsset(
  {
    accountId: accountId,
    assetId: imageId,
  },
  { enabled: computed(() => !!imageId.value) }
);

const fetchNewAsset = event => {
  imageId.value = event;
};

const removeNewAsset = index => {
  newAssets.value.splice(index, 1);
};

const newAsset = computed(() => digitalAssetData.value || null);

watchEffect(() => {
  if (newAsset.value) {
    newAssets.value.push(newAsset.value);
  }
});

const { mutate: publish } = useInternalPublish(referenceAsin);

const stage = ref({
  main_product_image_locator: null,
  other_product_image_locator_1: null,
  other_product_image_locator_2: null,
  other_product_image_locator_3: null,
  other_product_image_locator_4: null,
  other_product_image_locator_5: null,
  other_product_image_locator_6: null,
  other_product_image_locator_7: null,
  other_product_image_locator_8: null,
  swatch_product_image_locator: null,
});

watchEffect(() => {
  if (referenceListing.value) {
    // initialize stage with reference listing's assets
    stage.value = {
      main_product_image_locator:
        referenceListing.value.assets[0]?.digital_asset_id,
      other_product_image_locator_1:
        referenceListing.value.assets[1]?.digital_asset_id,
      other_product_image_locator_2:
        referenceListing.value.assets[2]?.digital_asset_id,
      other_product_image_locator_3:
        referenceListing.value.assets[3]?.digital_asset_id,
      other_product_image_locator_4:
        referenceListing.value.assets[4]?.digital_asset_id,
      other_product_image_locator_5:
        referenceListing.value.assets[5]?.digital_asset_id,
      other_product_image_locator_6:
        referenceListing.value.assets[6]?.digital_asset_id,
      other_product_image_locator_7:
        referenceListing.value.assets[7]?.digital_asset_id,
      other_product_image_locator_8:
        referenceListing.value.assets[8]?.digital_asset_id,
      swatch_product_image_locator:
        referenceListing.value.assets[9]?.digital_asset_id,
    };
  }
});

const stagedAssets = computed(() => {
  const result = [];
  Object.keys(stage.value).forEach((slot, index) => {
    const assetId = stage.value[slot];
    if (!assetId) return;
    let newAsset = newAssets.value.find(asset => asset.id == assetId);
    if (newAsset) {
      result.push({
        digital_asset_id: newAsset.id,
        image_position: index + 1,
        url: newAsset.preview.url,
      });
      return;
    }
    const asset = referenceListing.value?.assets.find(
      image => image.digital_asset_id == assetId
    );
    if (!asset) return;
    result.push({
      digital_asset_id: asset.digital_asset_id,
      image_position: index + 1,
      url: asset.url,
    });
  });
  return result;
});

const unapprovedChanges = computed(() =>
  allListings.value.filter(
    listing => !approvedChanges.value.includes(listing.asin)
  )
);

const toggleApproval = asin => {
  if (approvedChanges.value.includes(asin)) {
    approvedChanges.value = approvedChanges.value.filter(
      approvedAsin => approvedAsin !== asin
    );
  } else {
    approvedChanges.value = [...approvedChanges.value, asin];
  }
};

const displayConfirmationDialog = ref(false);

const publishStagedImages = () => {
  publish({
    account_id: accountId.value,
    reference_asin: referenceAsin.value,
    approved_changes: approvedChanges.value,
    desired_images: stagedAssets.value,
  });
  displayConfirmationDialog.value = false;
};

const openAllListings = () => {
  allListings.value.forEach(listing => {
    window.open(`https://www.amazon.com/dp/${listing.asin}`, '_blank');
  });
};

const totalListings = computed(() => {
  if (!referenceListing.value) return 0;
  return relatedListings.value.length + 1;
});
</script>

<template>
  <div class="dev-publishing">
    <StagingGround
      v-model:reference-asin-input="referenceAsinInput"
      v-model:stage="stage"
      :account-id="accountId"
      :reference-listing="referenceListing"
      :new-assets="newAssets"
      :new-asset="newAsset"
      @fetch-product-family="fetchProductFamily"
      @fetch-new-asset="fetchNewAsset"
      @remove-new-asset="removeNewAsset"
    />
    <SoonaButton
      variation="secondary-black"
      :disabled="!referenceListing"
      @click="openAllListings"
    >
      open all listings
      <SoonaIcon name="arrow-up-right-from-square" />
    </SoonaButton>
    <div class="dev-publishing__list">
      <h2 class="u-title--heavy">
        staged changes ({{ totalListings }} total listing{{
          totalListings === 1 ? '' : 's'
        }})
      </h2>
      <div class="dev-publishing__reference-asin">
        <StagingCard
          v-if="referenceListing"
          :reference-listing="referenceListing"
          :listing="referenceListing"
          :staged-assets="stagedAssets"
          :is-approved="approvedChanges.includes(referenceListing.asin)"
          @toggle-approval="toggleApproval($event)"
        />
      </div>
      <StagingCard
        v-for="listing in relatedListings"
        :key="listing.asin"
        :listing="listing"
        :reference-listing="referenceListing"
        :staged-assets="stagedAssets"
        :is-approved="approvedChanges.includes(listing.asin)"
        @toggle-approval="toggleApproval($event)"
      />
    </div>
    <div class="dev-publishing__publish">
      <SoonaButton
        variation="primary"
        :disabled="approvedChanges.length === 0"
        @click="displayConfirmationDialog = true"
      >
        publish
      </SoonaButton>
    </div>
  </div>
  <SoonaDialog
    v-if="displayConfirmationDialog"
    @confirm="publishStagedImages"
    @close="displayConfirmationDialog = false"
  >
    <template #heading>confirm changes</template>
    <template #default>
      <div class="confirmation-dialog">
        <h3 class="u-subheader--heavy">approved changes:</h3>
        <ul>
          <li v-for="asin in approvedChanges" :key="asin">
            {{ asin }}
          </li>
        </ul>

        <div v-if="unapprovedChanges.length">
          <h3 class="u-subheader--heavy">
            the following asins will not be updated:
          </h3>
          <ul>
            <li v-for="listing in unapprovedChanges" :key="listing.asin">
              {{ listing.asin }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <SoonaButton
        variation="tertiary"
        @click="displayConfirmationDialog = false"
      >
        cancel
      </SoonaButton>
      <SoonaButton variation="primary" @click="publishStagedImages">
        confirm
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.dev-publishing {
  &__image-select {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;

    &--input {
      flex-grow: 1;
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      align-items: baseline;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  &__publish {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

.confirmation-dialog {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ul {
    list-style-type: disc;
    padding-left: 1rem;

    li {
      margin-left: 1rem;
    }
  }
}
</style>
