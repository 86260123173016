import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} accountId
 */
export function useMarkNonExemptSalesTax(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/sales_tax/non_exempt`
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.accountSalesTax(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.bagSalesTax(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.orderSalesTax(accountId),
        }),
      ]);
    },
  });
}
