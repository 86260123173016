<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssets';

const props = defineProps({
  reservationId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: undefined,
  },
  editsCollectionId: {
    type: [String, Number],
    default: null,
  },
  title: {
    type: String,
    default: 'reservation gallery',
  },
  filters: {
    type: [Object],
    default: () => ({}),
  },
  selectedFilter: {
    type: String,
  },
});

const editsCollectionId = computed(() => props.editsCollectionId);
const reservationId = computed(() => props.reservationId);
const activeAssetId = computed(() => props.activeAssetId);
const title = computed(() => props.title);
const filters = computed(() => props.filters);

const currentPage = ref(null);
const userChangeablePage = ref(0);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});

const { data: filePages, isLoading } = useEditsCollectionDigitalAssets(
  editsCollectionId,
  {
    activeAssetId: activeAssetId,
    currentPage,
    filters,
    itemsPerPage: 12,
  },
  { placeholderData: keepPreviousData }
);

const files = computed(() => {
  return (
    filePages.value?.assets.filter(a => a.digital_asset?.preview?.url) || []
  );
});
</script>
<template>
  <CarouselBase
    v-if="files?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ title }}</template>
    <router-link
      v-for="ecda in files"
      :key="ecda.id"
      :to="{
        name: 'crew-edits-asset-view',
        params: {
          digitalAssetId: ecda.digital_asset.id,
          reservationId: reservationId,
        },
        query: {
          filter: selectedFilter,
        },
      }"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active':
          activeAssetId === ecda.digital_asset.id,
      }"
    >
      <DigitalAssetImage
        :src="ecda?.digital_asset?.preview?.url"
        :asset-type="ecda?.digital_asset?.asset_type"
      />
    </router-link>
  </CarouselBase>
</template>
