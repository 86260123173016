<script setup>
import { ref, computed } from 'vue';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useBulkCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useBulkCreateAlbumCollectionDigitalAsset';
import { useBulkDeleteAlbumCollectionDigitalAssets } from '@/queries/album_collection_digital_assets/useBulkDeleteAlbumCollectionDigitalAssets';
import { useCapability } from '@/composables/useCapability';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useMe } from '@/composables/user/useMe';
import { useRouter } from 'vue-router';
import { useUpdateBagCollection } from '@/queries/bag_collection/useUpdateBagCollection';
import { useUpdateFavoritesCollection } from '@/queries/favorites_collection/useUpdateFavoritesCollection';
import OrganizeMenu from '@/components/account_gallery/OrganizeMenu.vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import { useFlag } from '@/composables/useFlag';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import BulkDownloadDialog from '@/components/account_gallery/BulkDownloadDialog.vue';
import BatchEditDialog from '@/components/batch_edits/BatchEditDialog.vue';
import { useMediaQuery } from '@vueuse/core';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
  showAddToBag: {
    default: false,
    type: Boolean,
  },
  showRemoveFromAlbum: {
    default: false,
    type: Boolean,
  },
  albumCollectionId: {
    required: false,
    type: [Number, String],
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close', 'remove-selected-asset']);
const showSubscriptionsDialog = ref(false);
const showBatchEditDialog = ref(false);

const isDesktop = useMediaQuery('(min-width: 48rem)');
const isMobile = computed(() => !isDesktop.value);

const { actionBarIsMobile } = useActionBarMediaQuery();
const router = useRouter();

const accountId = computed(() => props.accountId);
const { currentAccountId, currentUserId } = useMe();
const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const selectedAssets = computed(() => props.selectedAssets);
const numberOfAssetsSelected = computed(() => selectedAssets.value.length);

const photoAssetsSelected = computed(() =>
  selectedAssets.value.filter(
    da => da.media_type === 'photo' && da.ownership === 'customer'
  )
);

const albumCollectionId = computed(() => props.albumCollectionId);

const { hasLiveSubscription } = useActiveSubscription(accountId);

const bulkEditFlagEnabled = useFlag('pegasus_bulk_edit');

// bulk download and file naming
const bulkDownloadFlag = useFlag('pegasus_bulk_download');
const bulkRemoveFromAlbumFlag = useFlag('pegasus_bulk_remove_from_album');
const showDownloadDialog = ref(false);

const downloadableAssetsSelected = computed(() => {
  return selectedAssets.value?.some(
    da => da.ownership === 'customer' && da.visibility === 'all'
  );
});

// bag
const baggableAssetsSelected = computed(() => {
  return selectedAssets.value?.some(
    da => da.ownership === 'soona' && !da?.bag_collection_digital_asset?.id
  );
});

const { hasCapability: hasBagCapability } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const { data: bagCollection } = useBagCollection(accountId);
const bagCollectionId = computed(() => bagCollection.value?.id);

const {
  mutate: updateBagCollectionAssets,
  isPending: isLoadingUpdateBagCollectionAssets,
  error: updateBagCollectionAssetsError,
} = useUpdateBagCollection(accountId, bagCollectionId);

const addToBag = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (
      asset.ownership !== 'customer' &&
      !asset.bag_collection_digital_asset?.id
    ) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateBagCollectionAssets(
    {
      bag_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

// album
const {
  mutate: bulkCreateAlbumCollectionDigitalAssets,
  error: updateAlbumCollectionError,
} = useBulkCreateAlbumCollectionDigitalAsset();

const {
  mutate: bulkDeleteAlbumCollectionDigitalAssets,
  error: updateAlbumCollectionDeleteError,
} = useBulkDeleteAlbumCollectionDigitalAssets();

const bulkAddToAlbum = collectionId => {
  bulkCreateAlbumCollectionDigitalAssets(
    {
      collectionId: collectionId,
      body: {
        digital_asset_ids: selectedAssets.value.map(a => a.id),
      },
    },
    {
      onSuccess: () => {
        emit('close');

        router.push({
          name: 'account-gallery',
          params: {
            accountId: accountId.value,
          },
          query: {
            collection_id: collectionId,
          },
        });
      },
    }
  );
};

const bulkRemoveFromAlbum = () => {
  bulkDeleteAlbumCollectionDigitalAssets(
    {
      collectionId: albumCollectionId.value,
      accountId: accountId.value,
      body: {
        digital_asset_ids: selectedAssets.value.map(a => a.id),
      },
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

// favorites
const { data: favoriteCollection } = useFavoritesCollection(accountId);
const favoritesCollectionId = computed(() => favoriteCollection.value?.id);

const {
  mutate: updateFavoritesCollectionAssets,
  error: updateFavoritesCollectionAssetsError,
} = useUpdateFavoritesCollection(accountId, favoritesCollectionId);

const bulkAddToFavorites = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (!asset.favorites_collection_digital_asset?.id) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateFavoritesCollectionAssets(
    {
      favorites_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

const hasError = computed(() => {
  return [
    updateBagCollectionAssetsError.value,
    updateAlbumCollectionError.value,
    updateAlbumCollectionDeleteError.value,
    updateFavoritesCollectionAssetsError.value,
  ].some(err => !!err);
});

// batch
function handleBulkEditClick() {
  if (hasLiveSubscription.value || isSoonaStaff.value) {
    showBatchEditDialog.value = true;
  } else {
    showSubscriptionsDialog.value = true;
  }
}
</script>

<template>
  <SoonaActionBar
    class="multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>
      <p class="u-label--heavy">
        {{ numberOfAssetsSelected }} asset{{
          numberOfAssetsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
    </template>
    <template #actions>
      <SoonaButton
        size="medium"
        :variation="
          actionBarIsMobile ? 'icon-transparent' : 'secondary-transparent'
        "
        @on-click="$emit('close')"
      >
        <SoonaIcon v-if="actionBarIsMobile" name="xmark" />
        <span :class="{ 'u-visually-hidden': actionBarIsMobile }">cancel</span>
      </SoonaButton>
      <div class="organize-menu">
        <SoonaButton
          v-if="
            albumCollectionId && showRemoveFromAlbum && bulkRemoveFromAlbumFlag
          "
          size="medium"
          :disabled="!isMyAccount"
          :variation="
            actionBarIsMobile ? 'icon-gray-outline' : 'secondary-black'
          "
          @on-click="bulkRemoveFromAlbum"
        >
          <SoonaIcon name="trash" />
          <span :class="{ 'u-visually-hidden': actionBarIsMobile }">
            remove from album
          </span>
        </SoonaButton>
        <SoonaButton
          v-if="bulkEditFlagEnabled"
          size="medium"
          :variation="
            hasLiveSubscription || isSoonaStaff
              ? isMobile
                ? 'icon-gray-outline'
                : 'secondary-black'
              : 'pizzazz'
          "
          :disabled="!isMyAccount"
          class="organize-menu__bulk-edit-button"
          @on-click="handleBulkEditClick"
        >
          <SoonaIcon name="wand-magic-sparkles" />
          <span :class="{ 'u-visually-hidden': actionBarIsMobile }">
            batch edit
          </span>
        </SoonaButton>
        <OrganizeMenu
          :account-id="accountId"
          :add-to-album="bulkAddToAlbum"
          :add-to-favorites="bulkAddToFavorites"
          :is-icon-button="actionBarIsMobile"
          :selected-assets="selectedAssets"
          @cancel="$emit('close')"
        />
      </div>
      <SoonaButton
        v-if="bulkDownloadFlag && selectedAssets.length < 100"
        size="medium"
        variation="secondary-black"
        :disabled="!downloadableAssetsSelected"
        @on-click="showDownloadDialog = true"
      >
        <SoonaIcon name="arrow-down-to-bracket" />
        download
      </SoonaButton>
      <SoonaButton
        v-if="showAddToBag"
        size="medium"
        :disabled="
          !hasBagCapability ||
          isLoadingUpdateBagCollectionAssets ||
          !baggableAssetsSelected
        "
        :loading="isLoadingUpdateBagCollectionAssets"
        @on-click="addToBag"
      >
        <SoonaIcon name="soona-bag" />
        add to bag
      </SoonaButton>
    </template>
  </SoonaActionBar>
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    selected-tier-slug="tier-one"
    @close="showSubscriptionsDialog = false"
  />
  <BatchEditDialog
    v-if="showBatchEditDialog"
    :account-id="accountId"
    :selected-assets="photoAssetsSelected"
    @close="showBatchEditDialog = false"
    @reset-selected-assets="emit('close')"
    @remove-selected-asset="asset => emit('remove-selected-asset', asset)"
  />
  <BulkDownloadDialog
    v-if="showDownloadDialog"
    :account-id="accountId"
    :selected-assets="selectedAssets"
    @close="showDownloadDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}

.organize-menu {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.download-form-custom-file-name {
  display: flex;
  margin-top: 2rem;

  h3 {
    margin-left: 1rem;
  }
}

.form-field-title {
  @include variables_fonts.u-subheader--heavy;
  color: variables.$black-default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.4375rem;
  label {
    padding-top: 0.1875rem;
  }
  .select-clear-button {
    min-width: initial;
  }
}

.form-field-subtitle {
  @include variables_fonts.u-label--heavy;
}

.custom-file-name-rules {
  margin: 0.5rem 0 1rem 0;
}

.custom-textfield-padding {
  padding-bottom: 1rem;
}

.checkbox-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkbox-option {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

input[type='checkbox'] {
  accent-color: variables.$periwink-blue-70;
  height: 1.125rem;
  width: 1.125rem;
}

.checkbox-content {
  display: flex;
  flex-direction: column;
}

.checkbox-title {
  font-weight: bold;
  font-size: 0.875rem;
  color: #000;
}

.checkbox-description {
  font-size: 12px;
  color: #6b7280;
}
</style>
