import { computed } from 'vue';

/**
 * @param {Ref<Symbol>} subscription
 */
export function useSubscriptionItems(subscription) {
  const activeAddOnSubscriptionItem = computed(() => {
    // we only have 1 add on, but we'll need to refactor this later if we have more
    return subscription.value?.subscription_items?.find(
      x => x.subscription_item_type === 'add_on' && x.status === 'active'
    );
  });

  const baseSubscriptionItem = computed(() => {
    return subscription.value?.subscription_items?.find(
      x => x.subscription_item_type === 'base'
    );
  });

  const pendingCancelAddOnSubscriptionItem = computed(() => {
    // we only have 1 add on, but we'll need to refactor this later if we have more
    return subscription.value?.subscription_items?.find(
      x =>
        x.subscription_item_type === 'add_on' &&
        x.status === 'pending_cancelation'
    );
  });

  const subscriptionItems = computed(() => {
    return subscription.value?.subscription_items;
  });

  return {
    activeAddOnSubscriptionItem,
    pendingCancelAddOnSubscriptionItem,
    subscriptionItems,
    baseSubscriptionItem,
  };
}
