import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param { string | number } userId
 */
export function useBatchUpdateuserProServiceProfiles(userId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async isActive => {
      const response = await http.put(
        `users/${toValue(userId)}/pro_service_profiles/batch_update_profiles.json`,
        { is_active: isActive }
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.proServiceProfiles({
          userId,
        }),
      });
    },
  });
}
