<script setup>
import { toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateCrewAssignment } from '@/queries/useUpdateCrewAssignment';

const props = defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
  assignment: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { addToast } = useSoonaToast();

const { assignment, reservationId } = toRefs(props);

const {
  mutate: updateCrewAssignment,
  isPending: updateCrewAssignmentPending,
  error: updateCrewAssignmentError,
} = useUpdateCrewAssignment({
  crewAssignmentId: assignment.value.id,
  reservationId: reservationId,
  onSuccess: async () => {
    addToast('thanks for your response', {
      variation: 'success',
    });

    emit('close');
  },
});

const handleDecline = () => {
  updateCrewAssignment({
    status: 'declined',
  });
};

const priorityErrors = usePriorityErrors(updateCrewAssignmentError);
</script>
<template>
  <SoonaDialog @close="emit('close')">
    <SoonaLoading v-if="updateCrewAssignmentPending" is-contained is-loading />
    <template #heading>
      decline {{ assignment.crew_role }} for {{ assignment.user_name }}?
    </template>
    <p>
      are you sure you want to decline this assignment and be removed from the
      crew on this booking? this cannot be undone and will notify the studio to
      replace this service on the booking.
    </p>
    <p>
      if you want to keep the booking and hope to change the time: please call
      the studio.
    </p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">
        nevermind
      </SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="handleDecline"
      >
        decline
      </SoonaButton>
    </template>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
  </SoonaDialog>
</template>
