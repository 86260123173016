export const publishingPaths = [
  {
    path: '/internal-publishing-tool',
    name: 'internal-publishing-tool',
    component: () =>
      import(
        '@/components/user/anytime/admin/publishing/InternalPublishingTool.vue'
      ),
    meta: {
      requires_capability: 'ft_soona_staff', // TO DO: lock this down to a more specific capability
      requires_auth: true,
    },
  },
];
