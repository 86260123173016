<script setup>
import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useCapability } from '@/composables/useCapability';
import BillingAndOrderHeading from '@/components/user/anytime/billing_and_orders/BillingAndOrderHeader.vue';
import CrewViewActiveContract from './CrewViewActiveContract.vue';
import NoContracts from '@/components/user/anytime/contracts/NoContracts.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});

const accountId = computed(() => props.accountId);
const { data: account } = useAccountQuery(accountId);
const { isActiveRetainerClient } = useAccount(accountId);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
</script>

<template>
  <section>
    <BillingAndOrderHeading
      v-if="!isSoonaStaff"
      :account-id="accountId"
      :has-contracts="isActiveRetainerClient"
    />
    <div class="crew-view-contracts">
      <!-- TODO: update this to make crew/client-specific -->
      <CrewViewActiveContract
        v-if="isActiveRetainerClient"
        :account-id="accountId"
      />
      <NoContracts
        v-else
        :account-id="accountId"
        :account-name="account?.name"
      />
    </div>
  </section>
</template>
