<script setup>
import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapabilities } from '@/composables/useCapabilities';
import { useGetAvailableDownPaymentCreditInfo } from '@/queries/reservations/useGetAvailableDownPaymentCreditInfo';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

const props = defineProps({
  alwaysShowTitles: Boolean,
  facets: {
    type: Object,
  },
  isBulkSelectOpen: {
    type: Boolean,
    default: false,
  },
  reservationId: [String, Number],
});

const selectedFilter = defineModel({
  type: String,
  default: '',
});

const reservationId = computed(() => props.reservationId);
const facets = computed(() => props.facets);

const galleryFilterButtons = ref();
const galleryFilterButtonsPinned = ref(false);
const matchMediaIsWide = useMediaQuery('(min-width: 64rem)');
let observer;

const style = getComputedStyle(document.body);

const createObserver = topOffset => {
  const topOffsetNum = parseInt(topOffset, 10);

  observer = new IntersectionObserver(
    ([e]) => {
      galleryFilterButtonsPinned.value =
        e.intersectionRatio < 1 && e.intersectionRect?.top === topOffsetNum;
    },
    {
      threshold: [1],
      rootMargin: `${-Math.abs(topOffsetNum)}px 0px 0px 0px`,
    }
  );
};

const capabilitiesRef = ref([
  { capability: 'ft_soona_staff' },
  { capability: 'raw_no_thumbnail' },
]);
const capabilities = useCapabilities(capabilitiesRef);

const filterOptions = computed(() => {
  if (!facets.value) return [];

  const options = [
    {
      label: 'all',
      value: 'all',
      count: facets.value.all_count,
    },
    {
      label: 'expert picks',
      value: 'expert_picks',
      count: facets.value.expert_picks_count,
      iconName: 'trophy',
      selectedIconName: 'trophy-solid',
    },
    {
      label: 'favorites',
      value: 'favorites',
      count: facets.value.favorites_count,
      iconName: 'heart',
      selectedIconName: 'heart-solid',
    },
    {
      label: 'in bag',
      value: 'in_bag',
      count: facets.value.in_bag_count,
      iconName: 'soona-bag',
      selectedIconName: 'soona-bag-solid',
    },
  ];

  if (capabilities.value?.ft_soona_staff?.hasCapability) {
    options.push({
      label: 'hidden',
      value: 'hidden',
      count: facets.value.hidden_count,
      iconName: 'eye-slash',
      selectedIconName: 'eye-slash',
    });
    options.push({
      label: 'archived',
      value: 'archived',
      count: facets.value.archived_count,
      iconName: 'box-archive',
      selectedIconName: 'box-archive',
    });
  }

  if (facets.value.edits_count > 0) {
    options[0].label = 'unpurchased';
    options.unshift({
      label: 'edits',
      value: 'edits',
      count: facets.value.edits_count,
    });
  }

  if (capabilities.value?.raw_no_thumbnail?.hasCapability) {
    options.push({
      label: 'raws no preview',
      value: 'raws_no_preview',
      count: undefined,
      iconName: 'file',
      selectedIconName: 'file-search-alt',
    });
  }

  return options;
});

// lifecycle hooks
onMounted(() => {
  createObserver(
    matchMediaIsWide.value ? style.getPropertyValue('--app-header-height') : 0
  );
  observer.observe(galleryFilterButtons.value);
});

onUnmounted(() => {
  observer.disconnect();
});

watch(matchMediaIsWide, val => {
  observer.disconnect();
  createObserver(val ? style.getPropertyValue('--app-header-height') : 0);
  observer.observe(galleryFilterButtons.value);
});

// credits
const { data: creditInfo } =
  useGetAvailableDownPaymentCreditInfo(reservationId);

const hasDownPaymentCredits = computed(() => {
  return (
    creditInfo.value?.photo_count > 0 ||
    creditInfo.value?.video_count > 0 ||
    creditInfo.value?.gif_count > 0 ||
    creditInfo.value?.bts_count > 0
  );
});

const pluralOrSingle = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

const bookingCreditsText = computed(() => {
  let credit_texts = [];

  for (const [key, value] of Object.entries(creditInfo.value)) {
    if (value > 0) {
      const creditType = key.replace('_count', '');
      credit_texts.push(
        `${value} ${pluralOrSingle(value, creditType, `${creditType}s`)}`
      );
    }
  }

  return credit_texts.join(' | ');
});
</script>

<template>
  <div class="gallery-filter">
    <div class="gallery-filter__top">
      <h3 class="gallery-filter__label">assets</h3>
      <div v-if="hasDownPaymentCredits" class="gallery-filter__credits">
        <SoonaIcon name="badge-dollar" />
        <p>
          booking credits: <span> {{ bookingCreditsText }}</span>
        </p>
      </div>
      <div class="gallery-filter__header_actions">
        <slot name="gallery-filter-header-actions" />
      </div>
    </div>
    <div
      ref="galleryFilterButtons"
      class="gallery-filter__buttons"
      :class="{ 'gallery-filter__buttons--pinned': galleryFilterButtonsPinned }"
    >
      <SoonaFilterSingle
        v-model="selectedFilter"
        :options="filterOptions"
        variation="friendly-red"
        size="large"
      >
        <template
          #option="{ count, iconName, label, selected, selectedIconName }"
        >
          <SoonaIcon
            v-if="iconName"
            size="medium"
            :name="selected && selectedIconName ? selectedIconName : iconName"
          />
          {{ label }}
          <span v-if="count" class="u-body--regular">({{ count }})</span>
        </template>
      </SoonaFilterSingle>
      <div class="gallery-filter__right">
        <slot name="gallery-filter-right" />
      </div>
    </div>
    <div class="gallery-filter__bottom">
      <slot
        name="gallery-filter-bottom"
        :staff-pick-count="facets?.expert_picks_count"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.gallery-filter {
  display: unset;

  &__top {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    row-gap: 1rem;
  }

  &__credits {
    align-items: center;
    display: flex;

    svg {
      color: variables.$black-default;
      margin-right: 0.25rem;
    }

    p {
      @include variables_fonts.u-label--regular;

      @media (min-width: variables.$screen-sm-min) {
        @include variables_fonts.u-body--regular;
      }
    }
  }

  &__label {
    @include variables_fonts.u-body--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-subheader--heavy;
    }
  }

  &__header_actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  &__buttons {
    position: sticky;
    top: calc(var(--app-header-viewport-offset) - 1px);
    z-index: 4;
    align-items: center;
    background: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 1rem;
    container-type: inline-size;

    &--pinned {
      box-shadow: variables.$elevation-3;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__right {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>
