export function toCityStateZip(address) {
  // NOTE: Same as BillingAddress method
  const { city = '', state = '', postal_code = '' } = address ?? {};
  if (!city) {
    return state ? `${state} ${postal_code}` : postal_code;
  }
  if (!state) {
    return `${city} ${postal_code}`;
  }
  return `${city}, ${state} ${postal_code}`;
}
