import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { string | number } userId
 */

export function useGetUserProServiceProfiles(userId) {
  return useQuery({
    queryKey: queryKeys.proServiceProfiles({
      userId,
    }),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/users/${toValue(userId)}/pro_service_profiles.json`,
        { signal }
      );
      return response.data;
    },
  });
}
