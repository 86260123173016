<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useBatchEditStore } from '../store/useBatchEditStore';

import BatchNewAlbum from './BatchNewAlbum.vue';
import BatchExistingAlbum from './BatchExistingAlbum.vue';
import BatchLocationSelect from './BatchLocationSelect.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const batchEditStore = useBatchEditStore();
const { locationAction, isSubmitting, selectedAssets } =
  storeToRefs(batchEditStore);

const loadingText = computed(
  () =>
    `Your ${selectedAssets.value.length} ${selectedAssets.value.length > 1 ? 'assets are' : 'asset is'} on their way—hang tight, just a little longer.`
);
</script>

<template>
  <div class="batch-location">
    <SoonaLoading
      is-contained
      :is-loading="isSubmitting"
      :loading-text="loadingText"
    />

    <SoonaTransition name="fade" exit-absolute>
      <BatchLocationSelect v-if="!locationAction" />

      <BatchNewAlbum v-else-if="locationAction === 'new-album'" />

      <BatchExistingAlbum v-else-if="locationAction === 'existing-album'" />
    </SoonaTransition>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.batch-location {
  inset: 0;
  z-index: 1;
  display: flex;
  padding-top: 0rem;
  position: absolute;
  flex-direction: column;
  background: variables.$white-default;
}
</style>
