import { computed } from 'vue';
import { useFlag } from '@/composables/useFlag';

/**
 * @param {Ref<Symbol>} subscription
 */
export function useSubscriptionSoonaStorage(subscription) {
  const fastPassOnTrialFlag = useFlag('pegasus_fast_pass_on_trial');

  const hasSoonaStorage = computed(
    () => !!subscription.value?.soona_storage?.id
  );

  // payments_todo: this is just current_period_end on the subscription object, let's look at consolidating this later on after the core work of pab-604 is done (pab-611)
  const soonaStorageCurrentPeriodEnd = computed(() => {
    return subscription.value?.soona_storage?.current_period_end;
  });

  const soonaStoragePendingCancellation = computed(() => {
    return !!subscription.value?.soona_storage?.pending_cancellation;
  });

  const canEnrollFastPass = computed(() => {
    return fastPassOnTrialFlag.value
      ? !hasSoonaStorage.value
      : !hasSoonaStorage.value && !subscription.value.is_trialing;
  });

  return {
    canCancelFastPass: hasSoonaStorage,
    canEnrollFastPass,
    hasSoonaStorage,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
  };
}
