import { toValue } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 * @returns UseMutationReturnType<*>
 */

export function useSubmitBatchEdits(action) {
  return useMutation({
    mutationFn: async body => {
      const response = await http.post(`/batch_edits/${toValue(action)}`, body);
      return response.data;
    },
  });
}
