<script setup>
import { computed } from 'vue';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters.js';
import SoonaOrderStatusFlag from '@/components/ui_library/SoonaOrderStatusFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  order: {
    required: true,
    type: Object,
  },
});

const totalAssetsOnOrder = computed(() => {
  if (!props.order.asset_count) return null;

  const assetCount = props.order.asset_count;
  let totalAssets = [];

  if (assetCount.images > 0) {
    totalAssets.push(
      `${assetCount.images} image${assetCount.images > 1 ? 's' : ''}`
    );
  }

  if (assetCount.gifs > 0) {
    totalAssets.push(`${assetCount.gifs} gif${assetCount.gifs > 1 ? 's' : ''}`);
  }

  if (assetCount.videos > 0) {
    totalAssets.push(
      `${assetCount.videos} video${assetCount.videos > 1 ? 's' : ''}`
    );
  }

  return totalAssets.join(', ');
});
</script>

<template>
  <div class="TimelineOrder">
    <hr class="order-divider" />
    <router-link
      class="order-header"
      :to="{
        name: 'crew-order',
        params: { orderId: order.id },
      }"
    >
      <div class="order-name">
        <div class="order-name__date">
          {{ convertToDDMMYYHHMM12z(order.created_at) }}
        </div>
        <div class="order-name__title">add-on order #{{ order.id }}</div>
      </div>
      <div class="order-status">
        <SoonaOrderStatusFlag :order-status="order.status" />
      </div>
      <p class="u-label--regular order-total-assets">
        {{ totalAssetsOnOrder }}
      </p>
      <SoonaIcon name="chevron-right" />
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.TimelineOrder {
  margin-bottom: 1.5rem;

  .order-divider {
    height: 0.25rem;
    margin: 0 0 1.5rem;
    background-color: variables.$bingo-blue;
  }

  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    background-color: variables.$periwink-blue-20;
    padding: 1rem;
    white-space: normal;
    border-color: variables.$gray-30;
    border-radius: 0.625rem;

    &:active {
      background-color: variables.$periwink-blue-10;
    }

    &:hover {
      background-color: variables.$periwink-blue-30;
    }

    &:focus {
      border-color: variables.$periwink-blue-60;
    }

    .order-status {
      flex: 1 1 auto;
    }

    .order-name {
      flex: 0 1 auto;

      &__date {
        @include variables_fonts.u-label--regular;
      }

      &__title {
        @include variables_fonts.u-subheader--heavy;
      }
    }
  }
}
</style>
