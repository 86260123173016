<script setup>
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useMasonryAssets } from '../useMasonryAssets';
import { useBatchEditStore } from '../store/useBatchEditStore';
import { useSubmitBatchEdits } from '@/queries/batch_edits/useSubmitBatchEdits';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import SoonaTextField from '@/components/ui_library/SoonaTextfield.vue';
import BatchCropper from '@/components/batch_edits/resize/BatchCropper.vue';
import BatchImageResizer from '@/components/batch_edits/resize/BatchImageResizer.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBatchEditTools } from '@/components/batch_edits/store/useBatchEditTools';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  showGalleryOnly: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'close',
  'update-show-gallery-only',
  'reset-selected-assets',
]);

const { BATCH_RESIZE_ACTION } = useBatchEditTools();

const batchEditStore = useBatchEditStore();
const {
  isMobile,
  selectedAssets,
  locationSelectedAlbums,
  locationAlbumName,
  accountId,
} = storeToRefs(batchEditStore);

const assetIds = computed(() => selectedAssets.value.map(asset => asset.id));

const pendingSubmission = ref(false);
const galleryKey = ref(1);
const selectorData = ref({});
const cropData = ref({});
const croppersRendered = ref({});
const socialMediaSelection = ref({});
const socialMediaPlatform = ref(null);
const maxDimension = ref(undefined);
const showCrop = ref(true);
const showResize = ref(false);
const selectCommonSize = ref(false);

const showGalleryOnly = computed(() => props.showGalleryOnly);

const updateSelectorData = data => {
  selectorData.value = data;
};

const setCropData = (assetId, coordinates) => {
  const mode_inputs = {};

  // only pass in resize coords in crop mode
  if (showCrop.value) {
    mode_inputs['coordinates'] = { ...coordinates };
  }

  if (Object.keys(socialMediaSelection.value).length > 0) {
    mode_inputs['target_size'] = { ...socialMediaSelection.value };
  } else if (maxDimension.value) {
    mode_inputs['target_size'] = {
      width: maxDimension.value,
      height: maxDimension.value,
    };
  }

  mode_inputs['mode'] = 'crop';

  cropData.value[assetId] = { mode_inputs: mode_inputs };
  croppersRendered.value[assetId] = true;
};

const normalizedCropData = computed(() => {
  if (showCrop.value) {
    return cropData.value;
  } else {
    // if in resize mode, set the target size to the asset's dimensions
    // and the crop location to the top-left corner
    const data = {};
    selectedAssets.value.forEach(asset => {
      data[asset.id] = {
        mode_inputs: {
          mode: 'resize',
          coordinates: {
            width: asset.file.width,
            height: asset.file.height,
            left: 0,
            top: 0,
          },
          target_size: {
            width: maxDimension.value,
            height: maxDimension.value,
          },
        },
      };
    });
    return data;
  }
});

const setMaxDimension = value => {
  maxDimension.value = Number(value);
  const targetSize = value ? { width: value, height: value } : null;

  assetIds.value.forEach(assetId => {
    let data = cropData.value[assetId];
    if (!data) {
      return;
    }
    if (targetSize) {
      data.mode_inputs['target_size'] = targetSize;
    } else {
      delete data.mode_inputs['target_size'];
    }
  });
};

const { mutate: batchResize, error } = useSubmitBatchEdits('resize');

const router = useRouter();
const route = useRoute();
const queryClient = useQueryClient();

const submitResults = async () => {
  batchResize(
    {
      crop_data: normalizedCropData.value,
      asset_ids: assetIds.value,
      album_title: locationAlbumName.value,
      album_ids: locationSelectedAlbums.value,
    },
    {
      onSuccess: async data => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: queryKeys.collectionsDA(accountId),
          }),
        ]);
        if (data.album_ids.length === 0 || data.album_ids.length > 1) {
          emit('reset-selected-assets');
          emit('close');
        } else {
          batchEditStore.$reset();
          router.push({
            name: 'album-assets',
            params: {
              accountId: accountId.value,
              collectionId: data.album_ids[0],
            },
          });
        }
      },
      onSettled: () => {
        batchEditStore.setIsSubmitting(false);
      },
    }
  );
};

const handleSubmit = async () => {
  batchEditStore.setIsSubmitting(true);

  nextTick(async () => {
    if (showResize.value) {
      await submitResults();
      return;
    }

    croppersRendered.value = {};
    galleryKey.value++;
    pendingSubmission.value = true;
  });
};

watch(
  cropData,
  () => {
    if (pendingSubmission.value) {
      if (
        Object.keys(croppersRendered.value).length === assetIds.value.length
      ) {
        pendingSubmission.value = false;
        nextTick(() => {
          submitResults();
        });
      }
    }
  },
  { deep: 1 }
);

const showData = ref(false);

onMounted(() => {
  batchEditStore.registerComponent(BATCH_RESIZE_ACTION, {
    handleSubmit: handleSubmit,
  });
});

onUnmounted(() => {
  batchEditStore.unregisterComponent(BATCH_RESIZE_ACTION);
});

const handleShowCrop = () => {
  showCrop.value = true;
  showResize.value = false;
  setMaxDimension(undefined);
};

const handleShowResize = () => {
  showCrop.value = false;
  showResize.value = true;
  setMaxDimension(undefined);
};

const handleUpdateSocialMediaPlatform = data => {
  socialMediaPlatform.value = data;
  setMaxDimension(undefined);
  if (!data) {
    socialMediaSelection.value = {};
  }
};

const wrapper = ref(null);

const { assetRows, gutter, rowHeight, offsetTop } = useMasonryAssets({
  wrapper,
  gapRem: 1,
  heightRem: 16.5,
  assets: selectedAssets,
});

const hideGallery = () => {
  emit('update-show-gallery-only', false);
};
</script>

<template>
  <div class="batch-resize">
    <SoonaError v-if="error">{{ error }}</SoonaError>

    <div
      v-if="!isMobile || (isMobile && !showGalleryOnly)"
      class="batch-resize__left"
    >
      <div
        v-if="!socialMediaPlatform && !selectCommonSize"
        class="batch-resize__tabs"
      >
        <SoonaTab
          copy="crop"
          :is-selected="showCrop"
          :to="{ query: route.query }"
          remove-bottom-border
          @on-click="handleShowCrop"
        />

        <SoonaTab
          copy="resize"
          :is-selected="showResize"
          :to="{ query: route.query }"
          remove-bottom-border
          @on-click="handleShowResize"
        />
      </div>

      <BatchImageResizer
        v-if="showCrop"
        :max-dimension="maxDimension"
        @update:max-dimension="setMaxDimension"
        @update-selector-data="updateSelectorData"
        @update-social-media-selection="data => (socialMediaSelection = data)"
        @update-social-media-platform="
          data => handleUpdateSocialMediaPlatform(data)
        "
        @update-select-common-size="data => (selectCommonSize = data)"
      />
      <div v-else-if="showResize" class="panel-content">
        <div class="panel-section">
          <p class="u-label--heavy">set a maximum dimension</p>
          <p class="panel-label u-label--regular">
            preserves the aspect ratio of each image, but sets a limit for the
            longest edge.
          </p>
          <p class="u-label--regular panel-label">
            the maximum height or length of your images will not exceed this
            number
          </p>
          <SoonaTextField
            v-model="maxDimension"
            placeholder="enter maximum"
            name="max-dimension"
            type="number"
            label=""
            :min="100"
            :step="1"
            @change="setMaxDimension($event.target.value)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile || (isMobile && showGalleryOnly)"
      ref="wrapper"
      class="batch-resize__gallery-wrapper batch-resize__gallery-wrapper--mobile"
    >
      <template v-if="showCrop">
        <template v-if="isMobile">
          <BatchCropper
            v-for="asset in selectedAssets"
            :key="asset.id"
            :asset="asset"
            :show-data="showData"
            :selector-data="selectorData"
            :is-loading="pendingSubmission"
            :style="{ width: asset.width + 'px' }"
            :crop-data="cropData[asset.id]"
            @update-crop-data="setCropData"
          />
        </template>
        <template v-else>
          <SmallInfiniteGallery
            v-slot="{ data }"
            :key="galleryKey"
            :gap="gutter"
            :rows="assetRows"
            :height="rowHeight"
            :offset-top="offsetTop"
            :overscan="pendingSubmission ? Infinity : 5"
            class="batch-resize__virtual-gallery"
          >
            <BatchCropper
              v-for="asset in data.assets"
              :key="asset.id"
              :asset="asset"
              :show-data="showData"
              :selector-data="selectorData"
              :is-loading="pendingSubmission"
              :style="{ width: asset.width + 'px' }"
              :crop-data="cropData[asset.id]"
              @update-crop-data="setCropData"
            />
          </SmallInfiniteGallery>
        </template>
      </template>
      <template v-else-if="showResize">
        <SmallInfiniteGallery
          v-slot="{ data }"
          :gap="gutter"
          :rows="assetRows"
          :height="rowHeight"
          :offset-top="offsetTop"
          :overscan="pendingSubmission ? Infinity : 5"
          class="batch-resize__virtual-gallery"
        >
          <img
            v-for="asset in data.assets"
            :key="asset.id"
            :src="asset?.preview?.url"
            :alt="asset?.preview?.alt_text"
            :style="{ width: asset.width + 'px' }"
            class="batch-resize__image"
          />
        </SmallInfiniteGallery>
      </template>
    </div>
    <SoonaButton
      v-if="isMobile && showGalleryOnly"
      class="batch-resize__options-btn"
      variation="secondary-black"
      @on-click="hideGallery"
    >
      options
      <SoonaIcon name="sliders" />
    </SoonaButton>
  </div>
</template>

<style scoped lang="scss">
@use 'src/variables';

.batch-resize {
  height: 100%;
  display: flex;
  overflow: auto;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  &__image {
    height: 100%;
    display: block;
    border-radius: 0.3125rem;
    background: url(@images/checkertile.svg);
  }

  &__tabs {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    border-bottom: 0.0625rem solid variables.$gray-30;
  }

  &__left {
    width: 20rem;
    height: 100%;
    overflow: auto;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  &__gallery-wrapper {
    flex: 1;
    gap: 1rem;
    height: 100%;
    display: flex;
    overflow: auto;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: flex-start;
    background: variables.$gray-10;

    &--mobile {
      padding: 0;
    }
  }

  &__virtual-gallery {
    padding: 1.5rem;
    max-height: 100%;
  }

  &__options-btn {
    position: absolute;
    bottom: 0;
    width: 85%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

.panel-content {
  gap: 1.5rem;
  display: flex;
  margin-top: 1.25rem;
  flex-direction: column;
}

.panel-section {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.panel-label {
  color: variables.$gray-70;
}
</style>
