<script setup>
import { computed, ref } from 'vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import EditAccountFlagsDialog from './EditAccountFlagsDialog.vue';
import { useAccount } from '@/composables/useAccount';
import { useIntegrations } from '@/composables/useIntegrations';
import { useFlag } from '@/composables/useFlag';
import { useCapability } from '@/composables/useCapability';
import ExemptSalesTaxDialog from '@/components/user/anytime/dashboard/sales_tax/ExemptSalesTaxDialog.vue';
import NonExemptSalesTaxDialog from '@/components/user/anytime/dashboard/sales_tax/NonExemptSalesTaxDialog.vue';
import RemoveFastPassDialog from '@/components/user/anytime/dashboard/account-crew-tools/RemoveFastPassDialog.vue';
import AddFastPassDialog from '@/components/user/anytime/dashboard/account-crew-tools/AddFastPassDialog.vue';
import UpgradeToEnterpriseDialog from '@/components/user/anytime/dashboard/enterprise/UpgradeToEnterpriseDialog.vue';
import RemoveEnterpriseTierDialog from '@/components/user/anytime/dashboard/enterprise/RemoveEnterpriseTierDialog.vue';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';
import { useToggleVipStatus } from '@/queries/account/useToggleVipStatus';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const { hasCapability: canMarkAccountTaxStatus } = useCapability({
  capability: 'mark_account_tax_status',
});
const { hasCapability: canModifySubscriptions } = useCapability({
  capability: 'modify_subscriptions',
});

const {
  account,
  hasActiveSubscription,
  hasSoonaStorage,
  soonaStorageCurrentPeriodEnd,
  soonaStoragePendingCancellation,
  subscriptionBillingCycle,
  isBusinessSubscriber,
  isDigitalSubscriber,
  isTaxExempt,
} = useAccount(accountId);

/* account flags */
const showEditFlagsDialog = ref(false);

/* fast pass */
const showFastPassConfirmationAddDialog = ref(false);
const showFastPassConfirmationRemoveDialog = ref(false);

const { hasShopifyIntegration } = useIntegrations(accountId);

const isTrialing = computed(() => account.value?.is_trialing);
const isPendingInvoiceSubscription = computed(() => {
  return account.value?.subscription?.is_invoice_payment_pending;
});
const pendingCancelAddOnSubscriptionItem = computed(() => {
  if (!account.value?.subscription) {
    return null;
  }
  // we only have 1 add on, but we'll need to refactor this later if we have more
  return account.value?.subscription.subscription_items.find(
    x =>
      x.subscription_item_type === 'add_on' &&
      x.status === 'pending_cancelation'
  );
});

const canEnrollInFastPass = computed(() => {
  return (
    account.value?.external_user_id &&
    !pendingCancelAddOnSubscriptionItem.value &&
    !hasShopifyIntegration.value &&
    !isTrialing.value &&
    !isPendingInvoiceSubscription.value
  );
});

/* vip */
const { mutate: toggleVipStatus, isPending: isTogglingVipStatus } =
  useToggleVipStatus(accountId);

/* enterprise subscription tier */
const showEnterpriseTierCreateDialog = ref(false);
const showEnterpriseTierRemoveDialog = ref(false);

const manageEnterpriseBusinessTierFlag = useFlag(
  'apollo_enterprise_business_tier'
);
const { subscriptionTier } = useActiveSubscription(accountId);
const { data: businessTier } = useTierBySlug('tier-three');

const activeFastPassSubscriptionItem = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'add_on'
  );
});
const paymentsRefundFlag = useFlag('refund_and_add_business');
const showEnterpriseTierButton = computed(() => {
  if (!manageEnterpriseBusinessTierFlag.value) return false;
  if (paymentsRefundFlag.value) {
    if (isBusinessSubscriber.value) return false;
    if (!hasActiveSubscription.value) return true;
    if (!activeFastPassSubscriptionItem.value) return true;
    if (hasShopifyIntegration.value) return false;
    return subscriptionBillingCycle.value === 'month';
  } else {
    return !isDigitalSubscriber.value;
  }
});

const showEnterpriseTierRemoveButton = computed(() => {
  return (
    manageEnterpriseBusinessTierFlag.value &&
    isDigitalSubscriber.value &&
    subscriptionTier.value?.slug === businessTier.value?.slug
  );
});

/* sales tax */
const showExemptSalesTaxDialog = ref(false);
const showNonExemptSalesTaxDialog = ref(false);
</script>

<template>
  <SoonaDropdownMenu variation="secondary-gray">
    <template #trigger-content>manage flags</template>

    <template #default="{ clickCapture, keydown, mouseover }">
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          @click="showEditFlagsDialog = true"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          edit flags
        </button>
      </SoonaDropdownMenuItem>
      <template v-if="canModifySubscriptions">
        <SoonaDropdownMenuItem
          v-if="
            hasSoonaStorage &&
            !hasShopifyIntegration &&
            !soonaStoragePendingCancellation
          "
        >
          <button
            role="menuitem"
            @click="showFastPassConfirmationRemoveDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            remove fast pass
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-if="canEnrollInFastPass && !hasSoonaStorage">
          <button
            role="menuitem"
            @click="showFastPassConfirmationAddDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            flag as fast pass
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-if="account">
          <button
            role="menuitem"
            :disabled="isTogglingVipStatus"
            @click="toggleVipStatus"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            {{ account.vip ? 'remove VIP flag' : 'flag as VIP' }}
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-if="soonaStoragePendingCancellation">
          <button
            role="menuitem"
            disabled
            :title="`ending on ${soonaStorageCurrentPeriodEnd}`"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            fast pass pending cancellation
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-if="showEnterpriseTierButton">
          <button
            role="menuitem"
            @click="showEnterpriseTierCreateDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            flag as enterprise tier
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-if="showEnterpriseTierRemoveButton">
          <button
            role="menuitem"
            @click="showEnterpriseTierRemoveDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            remove enterprise tier
          </button>
        </SoonaDropdownMenuItem>
      </template>
      <template v-if="canMarkAccountTaxStatus">
        <SoonaDropdownMenuItem v-if="isTaxExempt">
          <button
            role="menuitem"
            @click="showNonExemptSalesTaxDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            flag as tax non-exempt
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem v-else>
          <button
            role="menuitem"
            @click="showExemptSalesTaxDialog = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            flag as tax exempt
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </template>
  </SoonaDropdownMenu>

  <EditAccountFlagsDialog
    v-if="showEditFlagsDialog"
    :account-id="accountId"
    @close="showEditFlagsDialog = false"
  />

  <!-- fast pass -->
  <AddFastPassDialog
    v-if="showFastPassConfirmationAddDialog"
    :account-id="accountId"
    @close="showFastPassConfirmationAddDialog = false"
  />
  <RemoveFastPassDialog
    v-if="showFastPassConfirmationRemoveDialog"
    :account-id="accountId"
    @close="showFastPassConfirmationRemoveDialog = false"
  />

  <!-- subscription tier -->
  <UpgradeToEnterpriseDialog
    v-if="showEnterpriseTierCreateDialog"
    :account-id="accountId"
    @close="showEnterpriseTierCreateDialog = false"
  />
  <RemoveEnterpriseTierDialog
    v-if="showEnterpriseTierRemoveDialog"
    :account-id="accountId"
    @close="showEnterpriseTierRemoveDialog = false"
  />

  <!-- sales tax -->
  <ExemptSalesTaxDialog
    v-if="showExemptSalesTaxDialog"
    :account-id="accountId"
    @close="showExemptSalesTaxDialog = false"
  />
  <NonExemptSalesTaxDialog
    v-if="showNonExemptSalesTaxDialog"
    :account-id="accountId"
    @close="showNonExemptSalesTaxDialog = false"
  />
</template>
