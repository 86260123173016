<script setup>
import { computed, toRefs } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useAccount } from '@/queries/account/useAccount';
import { useCreateSubscription } from '@/queries/subscriptions/useCreateSubscription';
import { useCreateSubscriptionItem } from '@/queries/subscriptions/useCreateSubscriptionItem';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
});

defineEmits(['close']);
const { accountId } = toRefs(props);

const { data: account, error: accountError } = useAccount(accountId);

const { data: fastPassTier, error: fastPassTierError } =
  useTierBySlug('fast-pass');

const activeSubscriptionId = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.id;
});

const activeBaseSubscriptionItem = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'base'
  );
});

const fastPassSubscriptionPrice = computed(() => {
  return fastPassTier.value?.product.prices.find(
    x =>
      x.recurring_interval ===
      (activeBaseSubscriptionItem.value?.recurring_interval || 'month')
  );
});

const {
  mutate: createSubscription,
  isPending: subscriptionCreating,
  error: subscriptionCreatingError,
} = useCreateSubscription(accountId);

const {
  mutate: createSubscriptionItem,
  isPending: subscriptionItemCreating,
  error: subscriptionItemCreatingError,
} = useCreateSubscriptionItem(accountId);

const createOrUpdateSubscription = () => {
  if (!account.value?.subscription) {
    let startDate = new Date();

    createSubscription(
      {
        accountId: accountId.value,
        billingCycleAnchor: startDate,
        isInternal: true,
        priceIds: [fastPassSubscriptionPrice.value?.id],
        slug: 'fast-pass',
        startDate: startDate,
        useDefaultMethod: true,
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  } else {
    createSubscriptionItem(
      {
        add_price_ids: [fastPassSubscriptionPrice.value?.id],
        subscription_id: activeSubscriptionId.value,
        is_internal: true,
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  }
};

const priorityErrors = usePriorityErrors(
  subscriptionCreatingError,
  subscriptionItemCreatingError,
  fastPassTierError,
  accountError
);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>add fast pass</template>
    <p v-if="account?.external_user_id">
      create a subscription and charge the account
    </p>
    <p v-else>create a subscription and invoice the account</p>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :loading="subscriptionCreating || subscriptionItemCreating"
        @on-click="createOrUpdateSubscription(fastPassTier)"
      >
        add fast pass
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
