<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCreateABrief } from '@/composables/trend/useCreateABrief';
import {
  BubbletapePink30,
  GreenApple30,
  PeriwinkBlue30,
  Tangerine30,
} from '@/variables.module.scss';
import HeroCard from './HeroCard.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import SoonaVimeoPlayer from '@/components/SoonaVimeoPlayer.vue';
import ShootSetupDialog from '@/components/shared/ShootSetupDialog.vue';
import DuplicateReservationDialog from '@/components/reservation/DuplicateReservationDialog.vue';
import { useShootSetupDialog } from '@/composables/useShootSetupDialog';

const store = useStore();
const route = useRoute();

const { linkClicked } = useBaseEvents();

const currentAccountId = computed(() => store.state.currentUser?.accountId);
const userName = computed(() => store.getters['currentUser/userName']);

const showVideoOverview = ref(false);
const handleOverviewToggle = shouldOpen => {
  showVideoOverview.value = shouldOpen;
};

function trackLinkClicked(linkLabel, linkHref = null) {
  linkClicked({
    context: route.meta.context,
    subContext: 'hero banner',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
}

function seeHowItWorksClick() {
  handleOverviewToggle(true);
  trackLinkClicked('see how it works');
}

const utmContent = 'homepage_banner_create_ugc_brief';
const ugcButtonCopy = 'create a UGC brief';
const { handleCreateABrief } = useCreateABrief({
  utmContent,
  subContext: 'hero banner',
  buttonCopy: ugcButtonCopy,
});

// duplicate bookings
const {
  enableShootSetupDialog,
  displayShootSetupDialog,
  reservationIdToDuplicate,
  displayDuplicateBookingDialog,
  handleDisplayDuplicateReservationDialog,
  handleCloseDisplayDuplicateReservationDialog,
} = useShootSetupDialog(currentAccountId);
</script>

<template>
  <section class="hero-banner">
    <div class="hero-banner__heading">
      <h1 class="u-subheader--regular">👋 welcome {{ userName }}!</h1>
      <div class="hero-banner__title-wrapper">
        <h2 class="hero-banner__title">creative solutions start here.</h2>
        <button
          type="button"
          class="u-button-reset u-label--regular hero-banner__overview-btn"
          @click="seeHowItWorksClick"
        >
          <SoonaIcon name="play" size="small" />
          see how it works
        </button>
      </div>
      <SoonaDialog
        v-if="showVideoOverview"
        max-width="48rem"
        @close="handleOverviewToggle(false)"
      >
        <template #heading>how it works</template>
        <SoonaVimeoPlayer
          vimeo-id="908436947"
          additional-params="&h=9c11c24020"
          :show-close-x="false"
        />
      </SoonaDialog>
    </div>
    <div class="hero-banner__cards">
      <HeroCard
        v-if="enableShootSetupDialog"
        class="hero-banner__card"
        :accent-color-variable="BubbletapePink30"
        icon-primary="camera-alt-1"
        icon-cta="plus-large"
        data-cypress="hero-build-a-shoot"
        element="button"
        @click="displayShootSetupDialog = true"
      >
        build a shoot
        <template #subtitle>create and schedule photo or video shoots</template>
      </HeroCard>
      <HeroCard
        v-else
        class="hero-banner__card"
        :accent-color-variable="BubbletapePink30"
        icon-primary="camera-alt-1"
        icon-cta="plus-large"
        to="/booking/new"
        data-cypress="hero-build-a-shoot"
        @link-clicked="trackLinkClicked('build a shoot', '/booking/new')"
      >
        build a shoot
        <template #subtitle>create and schedule photo or video shoots</template>
      </HeroCard>
      <HeroCard
        class="hero-banner__card"
        :accent-color-variable="GreenApple30"
        icon-primary="ugc"
        icon-cta="plus-large"
        to="/"
        style="animation-delay: 0.1s"
        data-cypress="hero-ugc-brief"
        @click="handleCreateABrief"
      >
        {{ ugcButtonCopy }}
        <template #subtitle>get authentic content from top creators</template>
      </HeroCard>
      <HeroCard
        class="hero-banner__card"
        :accent-color-variable="PeriwinkBlue30"
        icon-primary="chart-simple"
        icon-cta="arrow-right"
        :to="`/user/anytime#/account/${currentAccountId}/listing-insights`"
        style="animation-delay: 0.2s"
        @link-clicked="
          trackLinkClicked(
            'improve listings',
            '/user/anytime#/account/listing-insights'
          )
        "
      >
        improve listings
        <template #subtitle>analyze and optimize your content scores</template>
      </HeroCard>
      <HeroCard
        class="hero-banner__card"
        :accent-color-variable="Tangerine30"
        icon-primary="wand-magic-sparkles"
        icon-cta="arrow-right"
        :to="`/user/anytime#/account/${currentAccountId}/media-editor`"
        style="animation-delay: 0.3s"
        @link-clicked="trackLinkClicked('transform images', '/media-editor')"
      >
        transform images
        <template #subtitle>
          instantly multiply one <br />image into many
        </template>
      </HeroCard>
    </div>
    <div class="hero-banner__footer">
      <p class="u-body--regular hero-banner__creative-at-scale">
        need creative at scale?
        <SoonaLink
          variation="black"
          to="https://soona.co/contact-sales"
          target="_blank"
          @click="
            trackLinkClicked(
              'talk to an expert',
              'https://soona.co/contact-sales'
            )
          "
        >
          talk to an expert
        </SoonaLink>
      </p>
    </div>
  </section>
  <ShootSetupDialog
    v-if="displayShootSetupDialog"
    :current-account-id="currentAccountId"
    segment-access-point="hero-banner"
    @close="displayShootSetupDialog = false"
    @duplicate-reservation="handleDisplayDuplicateReservationDialog"
  />
  <DuplicateReservationDialog
    v-if="displayDuplicateBookingDialog"
    :reservation-id="reservationIdToDuplicate"
    @close="handleCloseDisplayDuplicateReservationDialog"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.hero-banner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  border-radius: 0.625rem;
  background: variables.$bubbletape-pink-10;

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__title-wrapper {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  &__title {
    @include variables_fonts.u-headline--heavy;
  }

  &__overview-btn {
    color: variables.$black-default;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__card {
    flex: 1 1 40%;
    min-width: 5rem;
    animation:
      k-fade-in 0.7s both ease-out,
      slide-down 0.7s both cubic-bezier(0.25, 1, 0.5, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: k-fade-in 0.7s both ease-out;
    }
  }

  &__footer {
    margin: 0 auto;
  }

  @container (min-width: 27rem) {
    &__card {
      flex-basis: auto;
    }
  }

  &__creative-at-scale {
    flex: 1 1 100%;
    text-align: center;
  }

  @container (min-width: 42.375rem) {
    padding: 1.5rem;

    &__title {
      @include variables_fonts.u-display--heavy;
    }
  }

  @container (min-width: 60rem) {
    padding: 2rem;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-2em);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
