<script setup>
import { computed, ref } from 'vue';
import categories from '@/components/user/anytime/reservation/re_edits/categories';
import subCategories from '@/components/user/anytime/reservation/re_edits/sub_categories';
import { useInfiniteReEditsCollectionDigitalAssets } from '@/queries/re_edits_collection_digital_assets/useInfiniteReEditsCollectionDigitalAssets';
import BaseReEdits from '@/components/user/anytime/reservation/re_edits/BaseReEdits.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import EditorsReEditsItem from '@/components/user/anytime/reservation/re_edits/EditorsReEditsItem.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import CancelReEditRequestDialog from '@/components/user/anytime/reservation/re_edits/dialogs/CancelReEditRequestDialog.vue';
import CompleteReEditRequestDialog from '@/components/user/anytime/reservation/re_edits/dialogs/CompleteReEditRequestDialog.vue';
import BulkApplyReEditSubCategoriesDialog from '@/components/user/anytime/reservation/re_edits/dialogs/BulkApplyReEditSubCategoriesDialog.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);
const reEditCategory = ref('');

const { data } = useInfiniteReEditsCollectionDigitalAssets(collectionId, {});

// cancel
const showCancelReEditsModal = ref(false);
const toggleCancelModal = val => {
  showCancelReEditsModal.value = val;
};

// complete
const showCompleteReEditsModal = ref(false);
const toggleCompleteModal = val => {
  showCompleteReEditsModal.value = val;
};

// bulk apply
const bulkSelected = ref('');
const selectedReEdits = ref([]);
const showBulkApplySubCategoryModal = ref(false);

const resetBulkSelection = () => {
  selectedReEdits.value = [];
  bulkSelected.value = '';
};

const toggleBulkApplySubCategoryModal = (val, reset) => {
  showBulkApplySubCategoryModal.value = val;

  if (!showBulkApplySubCategoryModal.value && reset) {
    resetBulkSelection();
  }
};

const reEditAssets = computed(() =>
  (data.value?.pages || []).flatMap(page => page.assets)
);

const allIsSelected = computed(() => bulkSelected.value === 'all');

const setBulkSelected = val => {
  bulkSelected.value = val;

  if (allIsSelected.value) {
    selectedReEdits.value = reEditAssets.value;
  } else {
    selectedReEdits.value = [];
  }
};

const addToSelectedReEdits = reEdit => {
  selectedReEdits.value.push(reEdit);
};

const removeFromSelectedReEdits = id => {
  selectedReEdits.value = selectedReEdits.value.filter(sre => sre.id !== id);
};
</script>
<template>
  <div>
    <BaseReEdits>
      <template #header>re-edit requests:</template>
      <template #header_buttons>
        <SoonaButton
          size="medium"
          variation="tertiary"
          class="cancel-button"
          @click="toggleCancelModal(true)"
        >
          cancel re-edit order
        </SoonaButton>
        <SoonaButton size="medium" @click="toggleCompleteModal(true)">
          complete re-edit
        </SoonaButton>
      </template>
      <template #re_edits_content>
        <div class="re-edit-category">
          <h4 class="u-label--heavy">re-edit category</h4>
          <SoonaSelect
            v-model:model-value="reEditCategory"
            class="category-dropdown"
            placeholder="select reason"
            :options="categories"
            :searchable="true"
          />
        </div>
        <div class="re-edit-bulk-select">
          <SoonaDropdownMenu
            variation="secondary-black"
            copy="select"
            class="select-choice-dropdown"
          >
            <template #default="{ clickCapture, keydown, mouseover }">
              <SoonaDropdownMenuItem>
                <button
                  role="menuitem"
                  @click="() => setBulkSelected('all')"
                  @keydown="keydown"
                  @mouseover="mouseover"
                  @click.capture="clickCapture"
                >
                  all
                </button>
              </SoonaDropdownMenuItem>
              <SoonaDropdownMenuItem>
                <button
                  role="menuitem"
                  @click="() => setBulkSelected('none')"
                  @keydown="keydown"
                  @mouseover="mouseover"
                  @click.capture="clickCapture"
                >
                  none
                </button>
              </SoonaDropdownMenuItem>
            </template>
          </SoonaDropdownMenu>
          <template v-if="selectedReEdits.length || allIsSelected">
            <SoonaButton
              variation="secondary-black"
              size="medium"
              @click="() => toggleBulkApplySubCategoryModal(true)"
            >
              apply sub category
            </SoonaButton>
            <SoonaButton
              size="medium"
              variation="tertiary"
              @click="resetBulkSelection"
            >
              cancel
            </SoonaButton>
          </template>
        </div>
        <ul class="re-edits" aria-label="re-edit assets">
          <EditorsReEditsItem
            v-for="asset in reEditAssets"
            :key="asset.id"
            :re-edit="asset"
            :account-id="accountId"
            :collection-id="collectionId"
            :selected-re-edits="selectedReEdits"
            :sub-category-options="subCategories"
            @select-re-edit="val => addToSelectedReEdits(val)"
            @unselect-re-edit="id => removeFromSelectedReEdits(id)"
          />
        </ul>
      </template>
    </BaseReEdits>
    <!-- cancel dialog -->
    <CancelReEditRequestDialog
      v-if="showCancelReEditsModal"
      :account-id="accountId"
      :collection-id="collectionId"
      @close="() => toggleCancelModal(false)"
    />
    <!-- complete dialog -->
    <CompleteReEditRequestDialog
      v-if="showCompleteReEditsModal"
      :account-id="accountId"
      :collection-id="collectionId"
      :re-edit-category="reEditCategory"
      @close="() => toggleCompleteModal(false)"
    />
    <!-- bulk apply sub category -->
    <BulkApplyReEditSubCategoriesDialog
      v-if="showBulkApplySubCategoryModal"
      :account-id="accountId"
      :bulk-selected="bulkSelected"
      :collection-id="collectionId"
      :selected-re-edits="selectedReEdits"
      @close="reset => toggleBulkApplySubCategoryModal(false, reset)"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.re-edits {
  list-style: none;
}

.re-edit-category {
  .category-dropdown {
    margin-top: 0.5rem;
  }
}

.re-edit-bulk-select {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid variables.$gray-50;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
}
</style>
