<script setup>
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { useAccountLinkDisplayLogic } from '@/components/user/anytime/dashboard/useAccountLinkDisplayLogic';
import { useAccount } from '@/queries/account/useAccount';
import { useRoute } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  incompleteTraitsExist: {
    type: Boolean,
    default: false,
  },
});

const accountId = computed(() => props.accountId);

const { data: account } = useAccount(accountId, {
  enabled: computed(() => !!accountId.value),
});

const {
  showAssignmentsLink,
  showCrewTools,
  showProServiceProfile,
  showAvailability,
  showInventory,
  showContracts,
} = useAccountLinkDisplayLogic(account);

const route = useRoute();
</script>

<template>
  <nav class="profile-links">
    <SoonaTab
      v-if="showAssignmentsLink"
      :to="{ path: `/account/${accountId}/assignments` }"
      copy="assignments"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/assignments`"
    />
    <SoonaTab
      v-if="showCrewTools"
      :to="{ path: `/account/${accountId}/crew-tools` }"
      copy="crew tools"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/crew-tools`"
    />
    <SoonaTab
      v-if="showAvailability"
      :to="{ path: `/account/${accountId}/availability` }"
      copy="availability"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/availability`"
    />
    <SoonaTab
      v-if="showInventory"
      :to="{ path: `/account/${accountId}/inventory` }"
      copy="inventory"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/inventory`"
    />
    <SoonaTab
      v-if="showProServiceProfile"
      :to="{ path: `/account/${accountId}/pro-service-profile` }"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/pro-service-profile`"
    >
      <template #copy>
        <span class="pro-service-profile-link">
          pro service profile
          <SoonaIcon
            v-if="incompleteTraitsExist"
            name="circle-exclamation-solid"
            size="small"
          />
        </span>
      </template>
    </SoonaTab>
    <SoonaTab
      v-if="showContracts"
      :to="{ path: `/account/${accountId}/contracts` }"
      copy="contracts"
      remove-bottom-border
      :is-selected="route.path === `/account/${accountId}/contracts`"
    />
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.profile-links {
  display: flex;
  overflow-x: auto;
  border-bottom: 0.0625rem solid variables.$gray-30;
  margin-bottom: 1rem;

  .pro-service-profile-link {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;

    svg {
      color: variables.$friendly-red-50;
    }
  }
}
</style>
