<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useReservations } from 'src/queries/reservations/useReservations';
import InfoCard from '@/components/user/anytime/reservation/InfoCard.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { useSiteNavEvents } from '../platform-layout/useSiteNavEvents';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import { refDebounced } from '@vueuse/core';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  currentAccountId: {
    type: Number,
    required: true,
  },
  segmentAccessPoint: {
    type: String,
    required: true,
    validator: value => ['primary-nav', 'hero-banner'].includes(value), // update this field when adding new access points
  },
});

defineEmits(['duplicate-reservation']);

const { currentAccountId, segmentAccessPoint } = toRefs(props);
const { trackLinkClicked: trackSidebarLinkClicked } = useSiteNavEvents(); // sidebar navigation events
const { linkClicked } = useBaseEvents(); // generic events
const route = useRoute();

const search = ref('');
const searchDebounced = refDebounced(search, 200);
const currentPage = ref(1);
watch([searchDebounced], () => {
  currentPage.value = 1;
});

const {
  data: previousReservationsData,
  isLoading,
  error,
} = useReservations(
  {
    accountId: currentAccountId,
    filter: 'duplicatable',
    query: searchDebounced,
    itemsPerPage: 5,
    currentPage: currentPage,
  },
  { staleTime: 60 * 1000, keepPreviousData: true }
);

const previousBookings = computed(
  () => previousReservationsData.value?.reservations ?? []
);

// pagination
const previousBookingsPagination = computed(() =>
  previousReservationsData.value
    ? previousReservationsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

// segment
const trackCustomShootClick = () => {
  segmentAccessPoint.value === 'primary-nav'
    ? trackSidebarLinkClicked('custom shoot', '/book')
    : linkClicked({
        context: route.meta?.context ?? 'unknown',
        subContext: `shoot setup dialog via ${segmentAccessPoint.value}`,
        linkLabel: 'custom shoot',
        linkHref: '/book',
      });
};

const priorityError = usePriorityError(error);
</script>

<template>
  <SoonaDialog max-width="50%">
    <template #heading>set up a shoot</template>
    <article class="custom-shoot">
      <div class="custom-shoot__image">
        <img src="@images/plan-with-pros-400@2x.jpg" alt="" />
      </div>
      <div class="custom-shoot__content">
        <div class="custom-shoot__text">
          <h3 class="custom-shoot__title u-subheader--heavy">
            build a custom shoot
          </h3>
          <p class="custom-shoot__description">
            select models, stylists, and craft your ideal scene.
          </p>
        </div>
        <div class="custom-shoot__actions">
          <SoonaButton
            element="router-link"
            :to="{ name: 'createReservation' }"
            size="medium"
            @on-click="trackCustomShootClick"
          >
            custom shoot
          </SoonaButton>
        </div>
      </div>
    </article>
    <div class="shoot-setup-dialog__divider u-label--heavy">or</div>
    <div class="previous-bookings">
      <div>
        <h3 class="previous-bookings__title u-subheader--heavy">
          duplicate a shoot
        </h3>
        <p class="custom-shoot__description">
          select a shoot below to duplicate and customize.
        </p>
      </div>
      <SoonaTextfield
        ref="searchRef"
        v-model="search"
        type="search"
        label="search"
        name="previous-bookings-search"
        placeholder="search"
        hide-label
        class="previous-bookings__search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
      <SoonaError v-if="priorityError" :priority-errors="priorityError" />
      <div v-if="isLoading" class="previous-bookings__list">
        <SoonaSkeleton
          v-for="i in 3"
          :key="i"
          class="previous-bookings__loading--card"
        />
      </div>
      <div
        v-else-if="previousBookings.length === 0"
        class="previous-bookings__no-results"
      >
        <div class="no-results__wrapper">
          <div class="no-results__layer--one"></div>
          <div class="no-results__layer--two"></div>
          <div class="no-results__icon--wrapper">
            <SoonaIcon name="search-x" size="large" class="no-results__icon" />
          </div>
        </div>
        <p class="previous-bookings__no-results--text u-body--heavy">
          no shoots found. start fresh with a new one.
        </p>
      </div>

      <div v-else class="previous-bookings__list">
        <InfoCard
          v-for="booking in previousBookings"
          :key="booking.id"
          :reservation-id="booking.id"
          title-el="h4"
        >
          <template #actions>
            <SoonaButton
              size="medium"
              variation="secondary-black"
              @click="$emit('duplicate-reservation', booking.id)"
            >
              duplicate shoot
            </SoonaButton>
          </template>
        </InfoCard>
        <PaginatorSmall
          class="previous-bookings__paginator"
          :page="previousBookingsPagination.currentPage"
          :pages="previousBookingsPagination.totalPages"
          @page-change="currentPage = $event"
        />
      </div>
    </div>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables.scss';

.shoot-setup-dialog {
  &__divider {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    margin: 1rem 0;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid variables.$gray-30;
    }
  }
}

.custom-shoot {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;

  &__image {
    max-width: 6.125rem;
    max-height: 6.125rem;
    overflow: hidden;
    border-radius: 0.625rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 1rem;
  }

  &__text {
    flex: 1;
  }
}

.previous-bookings {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__loading--card {
    height: 4.5rem;
    width: 100%;
    border-radius: 0.625rem;
  }

  &__no-results {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
  }
}

.no-results {
  &__wrapper {
    position: relative;
  }

  &__icon {
    color: variables.$periwink-blue-70;

    &--wrapper {
      width: 3.625rem;
      height: 2.5rem;
      background-color: variables.$periwink-blue-20;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
    }
  }

  &__layer {
    width: 3.625rem;
    height: 2.5rem;

    &--one {
      position: absolute;
      width: 3.625rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      background: variables.$periwink-blue-30;
      z-index: -1;
      bottom: 0.25rem;
      right: 0.125rem;
      rotate: -5deg;
    }

    &--two {
      position: absolute;
      width: 3.625rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      background: variables.$periwink-blue-40;
      z-index: -2;
      bottom: 0.33rem;
      right: 0.25rem;
      rotate: -11deg;
      transform: rotateY(15deg);
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .custom-shoot {
    &__content {
      flex-flow: row nowrap;
    }
  }
}
</style>
