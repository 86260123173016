<script setup>
import { computed, ref, watch } from 'vue';
import { useNotes } from '@/queries/notes/useNotes';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { useDeleteNote } from '@/queries/notes/useDeleteNote';
import { useFlag } from '@/composables/useFlag';
import { keepPreviousData } from '@tanstack/vue-query';
import AccountCrewNotes from './AccountCrewNotes.vue';
import AvailableAccountCredits from '@/components/user/anytime/credits/AvailableAccountCredits.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import AccountRequirementsManage from '@/components/crew/account-requirements/AccountRequirementsManage.vue';
import AccountActions from '@/components/user/anytime/dashboard/account-crew-tools/AccountActions.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});
const accountId = computed(() => Number(props.accountId));

const toasterAccountRequirementsFlag = useFlag('toaster_account_requirements');

// accounts notes
const notesCurrentPage = ref(1);
const notesItemsPerPage = ref(10);
// go back to the first page when itemsPerPage changes
watch(notesItemsPerPage, () => {
  notesCurrentPage.value = 1;
});

const { data: accountNotesData } = useNotes(
  'accounts',
  accountId,
  {
    currentPage: notesCurrentPage,
    itemsPerPage: notesItemsPerPage,
  },
  { placeholderData: keepPreviousData }
);
const {
  mutate: createNote,
  isPending: isPendingNote,
  error: createNoteError,
} = useCreateNote('accounts', accountId);
const { mutate: deleteNote } = useDeleteNote('accounts', accountId);

const createNewUserNote = (reply, assets, noteType) => {
  createNote({
    content: reply,
    assets: assets,
    note_type: noteType,
  });
};

const deleteUserNote = note => {
  deleteNote(
    { noteId: note.id },
    {
      onSuccess: () => {
        notesCurrentPage.value = 1;
      },
    }
  );
};

const accountNotesPagination = computed(() => {
  if (accountNotesData.value) {
    return accountNotesData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const accountNotes = computed(() => accountNotesData.value?.notes ?? []);
</script>

<template>
  <div class="crew-tools-view">
    <AccountActions :account-id="accountId" />

    <AccountRequirementsManage
      v-if="toasterAccountRequirementsFlag"
      :account-id="accountId"
    />

    <div>
      <h3 class="u-subheader--heavy">crew notes</h3>
      <AccountCrewNotes
        :notes="accountNotes"
        :is-pending="isPendingNote"
        :error="createNoteError"
        @submit-note="createNewUserNote"
        @delete-note="deleteUserNote"
      >
        <template #paginator>
          <PaginatorFull
            v-model:page="notesCurrentPage"
            v-model:records-per-page="notesItemsPerPage"
            :total-pages="accountNotesPagination.totalPages"
            :total-count="accountNotesPagination.totalCount"
            :page-count="accountNotes.length"
          />
        </template>
      </AccountCrewNotes>
    </div>
    <AvailableAccountCredits :account-id="accountId" current-page="crewTools" />
  </div>
</template>

<style lang="scss" scoped>
.crew-tools-view {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>
