import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';

export function useAddBusinessSubscriptionItem() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const params = { subscription: {} };

      if (toValue(body['accountId'])) {
        params.subscription.account_id = body.accountId;
      }
      params.subscription.refund = toValue(body['refund']) ?? false;

      const response = await http.post(
        '/subscriptions/add_business_subscription.json',
        params
      );

      return response.data;
    },
    onSuccess: async data => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(data.account_id),
        }),
        queryClient.invalidateQueries({ queryKey: queryKeys.capability() }),
      ]);
    },
  });
}
