<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useMe } from '@/composables/user/useMe';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { triggerShippingCheckoutStartedEvent } from '@/api/shipping_detail';
import BillingInfo from '@/components/checkout/BillingInfo.vue';
import SoonaPaymentMethods from '@/components/SoonaPaymentMethods.vue';
import SummaryPayment from '@/components/SummaryPayment.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const { buttonClicked, pageViewed } = useBaseEvents();
const { currentAccountId } = useMe();
const { hasShopifyIntegration } = useIntegrations(currentAccountId);

const store = useStore();
const route = useRoute();
const title = useTitle();
const reservationId = computed(
  () => store.state.reservation?.currentReservation?.id
);
const currentRate = computed(() => store.state.reservation?.currentRate);
const currentReservationAccountId = computed(
  () => store.state.reservation.currentReservation?.account_id
);
const currentShipment = computed(
  () => store.state.reservation?.currentShipment || {}
);

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

onMounted(() => {
  title.value = `${route.meta.page_title} | soona`;

  pageViewed();
  triggerShippingCheckoutStartedEvent(reservationId.value, currentRate.value);
});

const overrideDurationTerms = terms => {
  if (
    terms ===
    'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
  ) {
    return 'delivery guaranteed within 3 business days based on your location';
  } else {
    return terms;
  }
};

const submitPaymentClicked = (stripe, card, saveCard) => {
  buttonClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    buttonLabel: 'checkout',
    buttonAction: 'submit payment',
  });

  const savePaymentMethod = hasShopifyIntegration.value ? false : !!saveCard;

  return store.dispatch('reservation/submitPayment', {
    card: card,
    saveCard: savePaymentMethod,
    shipping_amount: currentRate.value.amount,
    confirm: {
      token: stripe,
    },
    shipping_details: {
      rate_id: currentRate.value.rate_id,
      shipping_provider: currentRate.value.provider,
      customer_address: currentShipment.value.address_from,
      parcels: currentShipment.value.parcels,
      shipping_direction: 'inbound',
    },
  });
};

const shipToAddress = computed(() => {
  if (!currentShipment.value?.address_to) return '';

  const street = [
    currentShipment.value.address_to.street1,
    currentShipment.value.address_to.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_to.city}, ${currentShipment.value.address_to.state} ${currentShipment.value.address_to.zip}`;
});

const shipFromAddress = computed(() => {
  if (!currentShipment.value?.address_from) return '';

  const street = [
    currentShipment.value.address_from.street1,
    currentShipment.value.address_from.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_from?.city}, ${currentShipment.value.address_from?.state} ${currentShipment.value.address_from?.zip}`;
});
</script>
<template>
  <div class="shipping">
    <div class="column is-full is-centered soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            we're almost ready!
          </h2>

          <p class="soona-title-a1 mb-m"><b>ORDER CONFIRMATION:</b></p>

          <SummaryPayment :total="currentRate.amount">
            <tbody class="summary-payment__itemized-body">
              <!-- ship to -->
              <template v-if="shipToAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship to:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_to.name }}</p>
                    <p>
                      {{ shipToAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- ship from -->
              <template v-if="shipFromAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship from:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_from.name }}</p>
                    <p>
                      {{ shipFromAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- package details -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Package details:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    1 package x {{ currentShipment.parcels.length }}" x
                    {{ currentShipment.parcels.width }}" x
                    {{ currentShipment.parcels.height }}" ({{
                      currentShipment.parcels.weight
                    }}
                    lbs)
                  </p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- carrier -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Carrier:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    <b class="soona-title-a1">
                      {{ currentRate.provider }} {{ currentRate.service_level }}
                    </b>
                  </p>
                  <p class="is-lowercase">
                    {{ overrideDurationTerms(currentRate.duration_terms) }}
                  </p>
                  <p>${{ currentRate.amount }}</p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- subtotal -->
              <tr class="summary-payment__itemized-subtotal">
                <th>subtotal</th>
                <td>${{ currentRate.amount }}</td>
              </tr>
            </tbody>
            <template #billing>
              <BillingInfo
                class="shipping__billing-info"
                :account-id="currentReservationAccountId"
              />
            </template>
            <template #payment>
              <SoonaPaymentMethods
                :account-id="currentReservationAccountId"
                action-text="checkout"
                button-variation="primary"
                :is-stripe-disclaimer-below-checkout-button="true"
                :on-payment-action="submitPaymentClicked"
                :payment-method-types="['card', 'us_bank_account']"
                :stripe-payment-required="true"
                :total="currentRate.amount"
              />
            </template>
            <template #terms>
              <small class="summary-payment__terms">
                our crew commitment: we believe in quality content for all. if
                you don’t LOVE your content <b>we make it right</b>. please
                review our
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.soona.co/refund-policy"
                  >cancellation and refund policy</a
                >. by placing an order with soona you agree to our
                <a href="https://soona.co/terms/" target="_blank"
                  >terms of service</a
                >

                ({{ termsOfServiceDate }}).
              </small>
            </template>
          </SummaryPayment>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shipping {
  &__billing-info {
    margin-bottom: 1rem;
  }
}

.payment-info-title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.soona-refund-policy {
  background-color: variables.$gray-10;
  border-radius: variables.$control-radius;
  padding: 0.5rem;
}

.buttons {
  padding-top: 1rem;
}
</style>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  &__terms {
    @include variables_fonts.u-small--regular;

    display: block;

    b {
      font-weight: 800;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: variables.$black-default;
      }
    }

    .booking-downpayment__layout--h4a &,
    .downpayment__layout--h4a & {
      text-align: center;
    }
  }
  .summary-payment__itemized-body {
    .shipping-info-row {
      td,
      th {
        text-transform: none;

        &.faux-cell {
          display: none;
        }
      }
    }
  }
}
</style>
